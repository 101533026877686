import { injectIntl } from 'react-intl'

type LogoImageProps = {
    banner: string
    classes: any
}

const LogoImage = (props: LogoImageProps) => {
    let { banner } = props

    const classes = {
        logoContainer: {
            width: '100%',
            height: '100%',
        },
    }
    var logo: string
    if (banner === 'SunglassHut' || banner === 'Sunglass Hut - white label') {
        banner = 'Sunglass Hut'
    } else if (banner === 'Optical Prescription Spectacle Makers') {
        banner = 'OPSM'
    } else if (banner === 'David Clulow Opt' || banner === 'David Clulow Opt - white label') {
        banner = 'David_Clulow_Opticians'
    } else if (banner === 'David Clulow - white label') {
        banner = 'David_Clulow'
    } else if (banner === 'Oakley Vault') {
        banner = 'Oakley'
    }
    const fileName = banner.split(' ').join('_')
    switch (fileName) {
        case 'Alain_Mikli':
        case 'Apollo':
        case 'Brilleland':
        case 'David_Clulow_Opticians':
        case 'David_Clulow':
        case 'Ecolentes':
        case 'Econopticas':
        case 'ForEyes':
        case 'GrandOptical':
        case 'GrandVision':
        case 'Lafam':
        case 'Laubman_&_Pank':
        case 'LensCrafters':
        case 'MasVision':
        case 'MultiOpticas':
        case 'Oakley':
        case 'Oliver_Peoples':
        case 'GMO':
        case 'OPSM':
        case 'Optica_Lunettes':
        case 'Optical_Shop_of_Aspen':
        case 'Oticas_Carol':
        case 'Pearle':
        case 'Pearle_Vision':
        case 'Persol':
        case 'Ray-Ban':
        case 'Salmoiraghi_&_Viganò':
        case 'Spectacle_Hut':
        case 'Sunglass_Hut':
        case 'Target':
        case 'Vision_Express':
        case 'VistaSì':
            logo = banner ? `/logos/${fileName}.png` : ''
            break

        default:
            logo = '/image@3x.png'
            break
    }

    return (
        <div style={classes.logoContainer}>
            <img src={logo} alt="" />
        </div>
    )
}

export default injectIntl(LogoImage)