import { RegionFilters } from '../../models/region-filters'
import { SidebarMenuKind } from '../../models/sidebar-menu-kind'
import { FiltersState } from '../states/filtersState'
import { FiltersDispatchTypes } from '../dispatchers/filters'
import { FiltersSidebarSizeType } from '../../models/filters-sidebar-size-type'
import { isMobile } from 'react-device-detect'
import { HostedRetailFilters } from '../../models/hosted-retail-filters'
import { BannerFilters } from '../../models/banner-filters'
import { RemodelFilters } from '../../models/remodel-filters'
import { RelocationFilters } from '../../models/relocation-filters'
import { NewFilters } from '../../models/new-filters'
import { SurveyDateFilters } from '../../models/survey-date-filters'

/*
//// BANNER ////
- Banner Desc
- Channel of Trade
- Host
- Segment
- Store Design

//// REGION ////
- MacroRegions
- Countries
- Zone Descriptions
- Cities
- Business Units

//// REMODEL ////

//// RELOCATION ////

//// NEW ////

//// HOSTED RETAIL ////

//// SURVEY DATE ////

*/

let defaultstate: FiltersState = {
    selectedSidebarMenu: SidebarMenuKind.NONE,
    selectedSidebarSizeType: isMobile
        ? FiltersSidebarSizeType.LARGE
        : FiltersSidebarSizeType.DESKTOP,
    bannerFilters: new BannerFilters(),
    regionFilters: new RegionFilters(),
    remodelFilters: new RemodelFilters(),
    relocationFilters: new RelocationFilters(),
    newFilters: new NewFilters(),
    hostedRetailFilters: new HostedRetailFilters(),
    surveyDateFilters: new SurveyDateFilters(),
}

const filtersReducer = (state: FiltersState = defaultstate, action: any) => {
    switch (action.type) {
        case FiltersDispatchTypes.FILTERS_SET_SELECTED_SIDEBAR_MENU_DONE:
            return {
                ...state,
                selectedSidebarMenu: action.response.selectedSidebarMenu,
            }

        case FiltersDispatchTypes.FILTERS_SET_SELECTED_SIDEBAR_SIZE_TYPE_DONE:
            return {
                ...state,
                selectedSidebarSizeType:
                    action.response.selectedSidebarSizeType,
            }

        /////////////////////// BANNER - START ///////////////////////
        case FiltersDispatchTypes.FILTERS_CLEAR_ALL_BANNER_FILTERS_DONE:
            return {
                ...state,
                bannerFilters: {
                    ...state.bannerFilters,
                    bannerDescFilters: action.response.bannerDescFilters,
                },
            }

        /********************** BANNER DESC - START **********************/
        case FiltersDispatchTypes.FILTERS_GET_BANNER_DESC_SUCCESS:
        case FiltersDispatchTypes.FILTERS_SET_SELECTED_BANNER_DESC_DONE:
        case FiltersDispatchTypes.FILTERS_TOGGLE_BANNER_DESC_VIEW_ALL:
        case FiltersDispatchTypes.FILTERS_SET_SEARCH_BANNER_DESC_VALUE_DONE:
            return {
                ...state,
                bannerFilters: {
                    ...state.bannerFilters,
                    bannerDescFilters: action.response.bannerDescFilters,
                },
            }
        /********************** BANNER DESC - END **********************/

        /********************** CHANNEL OF TRADE - START **********************/
        case FiltersDispatchTypes.FILTERS_GET_CHANNEL_OF_TRADE_SUCCESS:
        case FiltersDispatchTypes.FILTERS_SET_SELECTED_CHANNEL_OF_TRADE_DONE:
        case FiltersDispatchTypes.FILTERS_TOGGLE_CHANNEL_OF_TRADE_VIEW_ALL:
        case FiltersDispatchTypes.FILTERS_SET_SEARCH_CHANNEL_OF_TRADE_VALUE_DONE:
            return {
                ...state,
                bannerFilters: {
                    ...state.bannerFilters,
                    channelOfTradeFilters:
                        action.response.channelOfTradeFilters,
                },
            }
        /********************** CHANNEL OF TRADE - END **********************/

        /********************** SEGMENT - START **********************/
        case FiltersDispatchTypes.FILTERS_GET_SEGMENT_SUCCESS:
        case FiltersDispatchTypes.FILTERS_SET_SELECTED_SEGMENT_DONE:
        case FiltersDispatchTypes.FILTERS_TOGGLE_SEGMENT_VIEW_ALL:
        case FiltersDispatchTypes.FILTERS_SET_SEARCH_SEGMENT_VALUE_DONE:
            return {
                ...state,
                bannerFilters: {
                    ...state.bannerFilters,
                    segmentFilters: action.response.segmentFilters,
                },
            }
        /********************** SEGMENT - END **********************/

        /********************** STORE DESIGN - START **********************/
        case FiltersDispatchTypes.FILTERS_GET_STORE_DESIGN_SUCCESS:
        case FiltersDispatchTypes.FILTERS_SET_SELECTED_STORE_DESIGN_DONE:
        case FiltersDispatchTypes.FILTERS_TOGGLE_STORE_DESIGN_VIEW_ALL:
        case FiltersDispatchTypes.FILTERS_TOGGLE_STORE_DESIGN_PANEL_COLLAPSED:
        case FiltersDispatchTypes.FILTERS_TOGGLE_STORE_DESIGN_SELECT_ALL_SELECTED:
        case FiltersDispatchTypes.FILTERS_TOGGLE_STORE_DESIGN_FIRST_LEVEL_PANEL_COLLAPSED:
        case FiltersDispatchTypes.FILTERS_SET_SEARCH_STORE_DESIGN_VALUE_DONE:
            return {
                ...state,
                bannerFilters: {
                    ...state.bannerFilters,
                    storeDesignFilters: action.response.storeDesignFilters,
                },
            }
        /********************** STORE DESIGN - END **********************/

        /********************** SELECTION BEST - START **********************/
        case FiltersDispatchTypes.FILTERS_GET_SELECTIONBEST_SUCCESS:
        case FiltersDispatchTypes.FILTERS_SET_SELECTED_IMAGES_QUALITY_DONE:
        case FiltersDispatchTypes.FILTERS_SET_SELECTED_MAIN_PROJECTS_DONE:
        case FiltersDispatchTypes.FILTERS_TOGGLE_MAIN_PROJECTS_VIEW_ALL:
        case FiltersDispatchTypes.FILTERS_SET_SEARCH_MAIN_PROJECTS_VALUE_DONE:
            return {
                ...state,
                bannerFilters: {
                    ...state.bannerFilters,
                    selectionBestFilters: action.response.selectionBestFilters,
                },
            }
        /********************** SELECTION BEST - END **********************/
        /////////////////////// BANNER - END ///////////////////////

        /////////////////////// REGION FILTER - START ///////////////////////
        /********************** MACROREGIONS - START **********************/
        case FiltersDispatchTypes.FILTERS_CLEAR_ALL_MACROREGIONS_DONE:
        case FiltersDispatchTypes.FILTERS_GET_MACROREGIONS_SUCCESS:
        case FiltersDispatchTypes.FILTERS_SET_SELECTED_MACROREGION_DONE:
            return {
                ...state,
                regionFilters: {
                    ...state.regionFilters,
                    macroregionsFilters: action.response.macroregionsFilters,
                },
            }
        /********************** MACROREGIONS - END **********************/

        /********************** COUNTRIES - START **********************/
        case FiltersDispatchTypes.FILTERS_CLEAR_ALL_COUNTRIES_DONE:
        case FiltersDispatchTypes.FILTERS_GET_COUNTRIES_SUCCESS:
        case FiltersDispatchTypes.FILTERS_SET_SELECTED_COUNTRY_DONE:
        case FiltersDispatchTypes.FILTERS_TOGGLE_COUNTRIES_VIEW_ALL:
        case FiltersDispatchTypes.FILTERS_SET_SEARCH_COUNTRY_VALUE_DONE:
            return {
                ...state,
                regionFilters: {
                    ...state.regionFilters,
                    countriesFilters: action.response.countriesFilters,
                },
            }
        /********************** COUNTRIES - END **********************/

        /********************** ZONE DESCRIPTIONS - START **********************/
        case FiltersDispatchTypes.FILTERS_CLEAR_ALL_ZONE_DESCS_DONE:
        case FiltersDispatchTypes.FILTERS_GET_ZONE_DESCS_SUCCESS:
        case FiltersDispatchTypes.FILTERS_SET_SELECTED_ZONE_DESC_DONE:
        case FiltersDispatchTypes.FILTERS_SET_SEARCH_ZONE_DESC_VALUE_DONE:
        case FiltersDispatchTypes.FILTERS_TOGGLE_ZONE_DESCS_VIEW_ALL:
            return {
                ...state,
                regionFilters: {
                    ...state.regionFilters,
                    zoneDescsFilters: action.response.zoneDescsFilters,
                },
            }
        /********************** ZONE DESCRIPTIONS - END **********************/

        /********************** CITIES - START **********************/
        case FiltersDispatchTypes.FILTERS_CLEAR_ALL_CITIES_DONE:
        case FiltersDispatchTypes.FILTERS_GET_CITIES_SUCCESS:
        case FiltersDispatchTypes.FILTERS_SET_SELECTED_CITY_DONE:
        case FiltersDispatchTypes.FILTERS_SET_SEARCH_CITY_VALUE_DONE:
        case FiltersDispatchTypes.FILTERS_TOGGLE_CITIES_VIEW_ALL:
            return {
                ...state,
                regionFilters: {
                    ...state.regionFilters,
                    citiesFilters: action.response.citiesFilters,
                },
            }
        /********************** CITIES- END **********************/

        /********************** BUSINESS UNIS - START **********************/
        case FiltersDispatchTypes.FILTERS_CLEAR_ALL_BUS_DONE:
        case FiltersDispatchTypes.FILTERS_GET_BUS_SUCCESS:
        case FiltersDispatchTypes.FILTERS_SET_SELECTED_BU_DONE:
        case FiltersDispatchTypes.FILTERS_SET_SEARCH_BU_VALUE_DONE:
        case FiltersDispatchTypes.FILTERS_TOGGLE_BUS_VIEW_ALL:
            return {
                ...state,
                regionFilters: {
                    ...state.regionFilters,
                    busFilters: action.response.busFilters,
                },
            }
        /********************** BUSINESS UNIS- END **********************/
        /////////////////////// REGION FILTER - END ///////////////////////

        /////////////////////// REMODEL FILTER - START ///////////////////////
        case FiltersDispatchTypes.FILTERS_CLEAR_ALL_REMODEL_FILTERS_DONE:
        case FiltersDispatchTypes.FILTERS_SET_SELECTED_REMODEL_MONTH_DONE:
        case FiltersDispatchTypes.FILTERS_SET_SELECTED_REMODEL_YEAR_DONE:
            return {
                ...state,
                remodelFilters: {
                    ...state.remodelFilters,
                    remodelFilters: action.response.remodelFilters,
                },
            }
        /////////////////////// REMODEL FILTER - END ///////////////////////

        /////////////////////// RELOCATION FILTER - START ///////////////////////
        case FiltersDispatchTypes.FILTERS_CLEAR_ALL_RELOCATION_FILTERS_DONE:
        case FiltersDispatchTypes.FILTERS_SET_SELECTED_RELOCATION_MONTH_DONE:
        case FiltersDispatchTypes.FILTERS_SET_SELECTED_RELOCATION_YEAR_DONE:
            return {
                ...state,
                relocationFilters: {
                    ...state.relocationFilters,
                    relocationFilters: action.response.relocationFilters,
                },
            }
        /////////////////////// RELOCATION FILTER - END ///////////////////////

        /////////////////////// NEW FILTER - START ///////////////////////
        case FiltersDispatchTypes.FILTERS_CLEAR_ALL_NEW_FILTERS_DONE:
        case FiltersDispatchTypes.FILTERS_SET_SELECTED_NEW_MONTH_DONE:
        case FiltersDispatchTypes.FILTERS_SET_SELECTED_NEW_YEAR_DONE:
            return {
                ...state,
                newFilters: {
                    ...state.newFilters,
                    newFilters: action.response.newFilters,
                },
            }
        /////////////////////// NEW FILTER - END ///////////////////////

        /////////////////////// HOSTED RETAIL FILTER - START ///////////////////////
        case FiltersDispatchTypes.FILTERS_CLEAR_ALL_HOSTED_RETAIL_FILTERS_DONE:
        case FiltersDispatchTypes.FILTERS_GET_HOSTED_RETAIL_SUCCESS:
        case FiltersDispatchTypes.FILTERS_SET_SELECTED_HOSTED_RETAIL_DONE:
        case FiltersDispatchTypes.FILTERS_SET_SEARCH_HOSTED_RETAIL_VALUE_DONE:
        case FiltersDispatchTypes.FILTERS_TOGGLE_HOSTED_RETAIL_VIEW_ALL:
            return {
                ...state,
                hostedRetailFilters: {
                    ...action.response.hostedRetailFilters,
                },
            }
        /////////////////////// HOSTED RETAIL FILTER - END ///////////////////////

        /////////////////////// SURVEY DATE FILTER - START ///////////////////////
        case FiltersDispatchTypes.FILTERS_CLEAR_ALL_SURVEY_DATE_FILTERS_DONE:
        case FiltersDispatchTypes.FILTERS_SET_SELECTED_SURVEY_DATE_FROM_DONE:
        case FiltersDispatchTypes.FILTERS_SET_SELECTED_SURVEY_DATE_TO_DONE:
            return {
                ...state,
                surveyDateFilters: {
                    ...state.surveyDateFilters,
                    surveyDateFilters: action.response.surveyDateFilters,
                },
            }
        /////////////////////// SURVEY DATE FILTER - END ///////////////////////

        default:
            return state
    }
}

export default filtersReducer

