export function clearError(type: any) {
  if (Array.isArray(type)) {
    const types = type.map((t) =>
      /(.*)_(REQUEST|FAILURE)/.exec(t) ? t : t + "_FAILURE"
    );
    return { type: types, error: false };
  } else {
    return {
      type: /(.*)_(REQUEST|FAILURE)/.exec(type) ? type : type + "_FAILURE",
      error: false,
    };
  }
}

export function clearAllError() {
  return { type: "__ALL_ERROR__" };
}

export function timeoutError() {
  return { type: "__TIMEOUT_ERROR__" };
}
