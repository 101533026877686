import { injectIntl } from 'react-intl'
import { StyledHeaders } from './StyledHeader'
import MonthDatePicker from '../../month-date-picker/MonthDatePicker'
import IconButton from '../../icon-button/IconButton'
import { useWindowResize } from '../../../utils/commonFunctions'
import { MainProjectPhoto } from '../../../models/main-projects'
import { useEffect, useRef, useState } from 'react'
import { StyledColumn } from '../general-info/StyledColumn'
import { Card } from 'primereact/card'
import { StyledBlueButtonContainer } from '../../filter/filter-searchmainprojects-clear-section/style'
import { ExportDeckType } from '../../../models/export-deck-type'
import { Moment } from 'moment'

type MomentDateWrapper = {original: Moment, from: number, to: number}

function isMonthFilter (date: MomentDateWrapper) {
    return date.to - date.from < 2629800000 * 2
}

const MainProjectHeader = (props: any) => {
    const {
        intl,
        dates,
        setDates,
        isApp,
        photoList,
        downloadPhotos,
        setShouldShowPhotosSelection,
        onExportDeck,
        loadingExportMainProjectDeck,
    } = props

    const [isDeckMenuShown, setIsDeckMenuShown] = useState<boolean>(false)

    const photoListUrls: Array<string> =
        photoList?.length > 0
            ? photoList.map((photo: MainProjectPhoto) => {
                  return photo?.path
              })
            : []

    const isSecondRelease = false

    const isMobileNow = useWindowResize()

    const monthDatePickerRef = useRef<any>();

    useEffect(() => {
        // Changes to the dates selections should disable the manual selection of pictures to export the photos deck
        setShouldShowPhotosSelection(false)
    }, [dates, setShouldShowPhotosSelection])

    useEffect(() => {
        if (isDeckMenuShown) {
            // If the export menu just opened, we should close the other menus
            monthDatePickerRef.current?.closeOpenCalendar()
        }
    }, [isDeckMenuShown])

    return (
        <StyledHeaders>
            <div className="p-grid p-align-center">
                <div className="p-col-9">
                    <span className="p-d-flex h2">
                        <h2 className="p-text-uppercase p-text-bold storeHeaderH2">
                            {photoList?.length ? photoList.length : 0}{' '}
                            {intl.formatMessage({ id: 'store_detail.images' })}
                        </h2>
                    </span>
                </div>
                {photoList?.length > 0 && (
                    <div className="p-col-3 p-d-flex p-jc-end">
                        <MonthDatePicker
                            ref={monthDatePickerRef}
                            dates={dates}
                            setDates={setDates}
                            photoList={photoList}
                            onCalendarOpenChange={((isOpen: boolean) => {
                                // If the calendar menu just opened, we should close the other menus
                                if (isOpen) {
                                    setIsDeckMenuShown(false)
                                }
                            })}
                        />
                        {!isMobileNow &&
                            (isApp ? (
                                <IconButton
                                    icon="download"
                                    tooltipIntl="store_detail.download_photos"
                                    onClick={() =>
                                        (
                                            window as any
                                        ).webkit.messageHandlers.multipleDownload.postMessage(
                                            photoListUrls
                                        )
                                    }
                                />
                            ) : (
                                <IconButton
                                    icon="download"
                                    tooltipIntl="store_detail.download_photos"
                                    onClick={downloadPhotos}
                                />
                        ))}
                        <div className="p-row p-d-flex p-jr-end">
                            <IconButton
                                icon="images"
                                tooltipIntl="button.export_photos.text"
                                onClick={() =>
                                    setIsDeckMenuShown((prev) => !prev)
                                }
                            />
                        </div>
                        {isDeckMenuShown && (
                            <StyledColumn
                                style={{
                                    position: 'absolute',
                                    zIndex: 1000,
                                    marginTop: 30,
                                    width: 365,
                                }}>
                                <Card className="card">
                                    <div className="p-grid">
                                        <StyledBlueButtonContainer
                                            disabled={
                                                loadingExportMainProjectDeck
                                            }
                                            style={{
                                                marginTop: 8,
                                                marginBottom: 8,
                                                fontWeight: 500,
                                            }}
                                            onClick={(e: any) => {
                                                //const storearea = macroareas.find((ma: any) => ma.className.includes("active"))?.value
                                                const formattedDates = dates.filter(isMonthFilter).map((date: MomentDateWrapper) => date.original.format("MM/YYYY"))

                                                onExportDeck(
                                                    ExportDeckType.LAST_20,
                                                    /*storearea !== 'All' ? storearea :*/ undefined,
                                                    formattedDates.length > 0 ? formattedDates: undefined
                                                )
                                            }
                                        }>
                                            {intl.formatMessage({
                                                id: 'button.export_20.text',
                                            })}
                                        </StyledBlueButtonContainer>
                                        <StyledBlueButtonContainer
                                            disabled={
                                                loadingExportMainProjectDeck
                                            }
                                            style={{
                                                marginTop: 8,
                                                marginBottom: 8,
                                                fontWeight: 500,
                                            }}
                                            onClick={(e: any) => {
                                                //const storearea = macroareas.find((ma: any) => ma.className.includes("active"))?.value
                                                const formattedDates = dates.filter(isMonthFilter).map((date: MomentDateWrapper) => date.original.format("MM/YYYY"))

                                                onExportDeck(
                                                    ExportDeckType.LAST_40,
                                                    /*storearea !== 'All' ? storearea :*/ undefined,
                                                    formattedDates.length > 0 ? formattedDates: undefined
                                                )
                                            }
                                        }>
                                            {intl.formatMessage({
                                                id: 'button.export_40.text',
                                            })}
                                        </StyledBlueButtonContainer>
                                        <StyledBlueButtonContainer
                                            disabled={
                                                loadingExportMainProjectDeck
                                            }
                                            style={{
                                                marginTop: 8,
                                                marginBottom: 8,
                                                fontWeight: 500,
                                            }}
                                            onClick={(e: any) => {
                                                setShouldShowPhotosSelection(true)
                                                setIsDeckMenuShown(false)
                                            }}>
                                            {intl.formatMessage({
                                                id: 'button.export_selected.text',
                                            })}
                                        </StyledBlueButtonContainer>
                                    </div>
                                </Card>
                            </StyledColumn>
                        )}
                    </div>
                )}
                {isSecondRelease && (
                    <span className="p-d-flex h3 p-col-12 p-py-0">
                        <h3 className="p-text-uppercase">
                            {intl.formatMessage({
                                id: 'store_detail.next_survey',
                            })}
                            :
                        </h3>
                        <h3 className="p-ml-1 p-text-bold"> GG/MM/AAAA</h3>
                    </span>
                )}
            </div>
        </StyledHeaders>
    )
}
export default injectIntl(MainProjectHeader)

