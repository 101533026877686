import { Colors } from '../../../colors'
import styled from 'styled-components'

export const StylesResetFiltersInToggleButton = styled.button<{
    size: string
    isMobileNow: boolean
    onClick: (e: any) => void
}>`
    position: absolute;
    width: ${(p) => (p.isMobileNow ? '30px;' : '16px;')}
    height: ${(p) => (p.isMobileNow ? '30px;' : '16px;')}
    z-index: 20;
    font-size: 11px;
    font-weight: 500;
    color: ${Colors.white};
    background-color: rgba(255, 255, 255, 0.2);
    border-radius: 19.2px;
    margin: ${(p) => (p.isMobileNow ? '2px 0px 0px 2px;' : '9px 0px 0px 6px;')}
    margin-left: 12px;
    outline: none !important;
    box-shadow: none !important;
`

export const StyledToggleButton = styled.div<{
    size: string
    isMobileNow: boolean
}>`
    /* hide offIcon in ToggleButtons */
    .p-button-icon.pi.pi-times {
        display: none;
    }

    /* change onIcon background color */
    .p-button-icon.pi.pi-times-circle {
        background-color: rgba(255, 255, 255, 0.2);
        border-radius: 19.2px;
        display: ${(p) => (p.size === 'small' ? 'none' : '')};
    }

    /* remove blue box when clicking a togglebutton and gray background when the togglebutton is selected */
    .p-togglebutton:focus {
        box-shadow: none !important;
    }

    /* styling toggle buttons */
    .p-togglebutton {
        ${(p) => (p.size === 'small' ? 'width: 100%;' : '')}
        height: 35px;
        margin: 0 7px 0 0;
        padding: 10px 17px 10px 18px;
        border-radius: 19.2px;
        border: solid 1px ${Colors.grey3};
        background-color: ${Colors.grey0};
        transition: background-color 0.3s, border-color 0.3s, box-shadow 0.3s,
            -webkit-box-shadow 0.3s;
    }

    /* change background of ToggleButton when selected */
    .p-togglebutton.p-button.p-highlight {
        background: ${(p) => (p.size === 'small' ? Colors.white : Colors.blue)};
        border-color: ${Colors.blue};
    }

    /* styling toggle buttons label */
    .p-button-label {
        font-family: AvenirLTPro;
        ${(p) =>
            p.size === 'small'
                ? `
            font-size: 11px;
            font-weight: 900;
            padding: 10px 0px 10px 0px;
        `
                : `
            font-size: 12px;
            font-weight: 500;
        `}
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: 0.3px;
        text-align: center;
        color: ${Colors.grey5};
    }

    /* change toggle button label color when selected */
    .p-togglebutton.p-button.p-highlight .p-button-label {
        color: ${(p) => (p.size === 'small' ? Colors.blue : Colors.white)};
        margin-left: ${(p) => (p.isMobileNow ? '26px;' : '16px;')};
    }

    /* change toggle button color when hovering over it */
    .p-togglebutton:hover {
        background-color: ${(p) =>
            p.size === 'small'
                ? 'rgba(255,255,255,0.5)'
                : Colors.blue} !important;
        transition: background-color 0.3s, border-color 0.3s, box-shadow 0.3s,
            -webkit-box-shadow 0.3s;
    }

    /* change toggle button label color when hovering over the button */
    .p-togglebutton:hover .p-button-label {
        color: ${(p) =>
            p.size === 'small' ? Colors.grey5 : Colors.white} !important;
    }

    @media (max-width: 991px) {
        .p-togglebutton {
            margin-right: 12px;
        }
    }

    @media (min-width: 992px) and (max-width: 1280px) {
        .selectFilter {
            margin-left: 10px;
            background-color: transparent;
        }
    }

    @media (min-width: 992px) and (max-width: 1199px) {
        .p-togglebutton {
            padding: 10px 20px;
        }
        .p-togglebutton .p-button-label {
            font-size: 14px;
        }
    }
`
