import {
    StyledSearchStoresClearSection,
    StyledLightGreyButtonContainer,
    StyledDarkGreyButtonContainer,
    StyledBlueButtonContainer,
} from './style'
import { injectIntl } from 'react-intl'
import { ProgressSpinner } from 'primereact/progressspinner'
import styled from 'styled-components'

export const StyledProgressSpinner = styled(ProgressSpinner)`
  /* center progress spinner */
  position: relative;
  top: 50%;
  left: 50%;
  z-index: 30;
  height: 60px;
  width: 60px;
  margin-right: 100px;
  margin-top: 10px;
}`

const FilterSearchStoresClearSection = (props: any) => {
    const { intl } = props

    const openSearchResultsSidebar = () => {
        props.onSearchResults()
        // props.setSelectedSidebarMenu(SidebarMenuKind.SEARCH_RESULTS)
    }

    return (
        <>
            <StyledSearchStoresClearSection>
                <div className="p-grid p-justify-between">
                    <div className="p-col-align-start">
                        <StyledLightGreyButtonContainer
                            onClick={props.onClearAllFilters}>
                            {intl.formatMessage({
                                id: 'button.clear_all.text',
                            })}
                        </StyledLightGreyButtonContainer>
                    </div>
                    <div className="p-col-align-end">
                        {props.isLoadingSearchResults ? (
                            <StyledProgressSpinner />
                        ) : (
                            <StyledBlueButtonContainer
                                disabled={
                                    props.isSearchResultsDisabled ||
                                    props.isLoadingSearchResults
                                }
                                onClick={(e: any) =>
                                    openSearchResultsSidebar()
                                }>
                                {intl.formatMessage({
                                    id: 'button.search_stores.text',
                                })}
                            </StyledBlueButtonContainer>
                        )}
                    </div>
                    <div className="children">{props.children}</div>
                </div>
                {props.hasMoreFilters && (
                    <div className="p-grid">
                        <div className="p-col-6">
                            <StyledDarkGreyButtonContainer>
                                {intl.formatMessage({
                                    id: 'button.more_filters.text',
                                })}
                            </StyledDarkGreyButtonContainer>
                        </div>
                    </div>
                )}
            </StyledSearchStoresClearSection>
        </>
    )
}

export default injectIntl(FilterSearchStoresClearSection)
