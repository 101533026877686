import React, { useEffect } from 'react'
import { injectIntl } from 'react-intl'
import { StyledInfoCard } from './StyledInfoCard'
import { Link } from 'react-router-dom'
import PinCarousel from '../../carousel/PinCarousel'
import { Store } from '../../../models/store'
import LogoImage from '../../logo-image/LogoImage'
import { getLastIntervalFromDateFilters } from '../../../utils/commonFunctions'
import { Photo } from '../../../models/photo-list'
import { Button } from 'primereact/button'

type InfoCardProps = {
    intl: any
    store: Store
    photoList: Photo[]
    getThumbnails: any
    dateFilters: any[]
    loading?: boolean
    setSelectedStore?: any
}

function InfoCard(props: InfoCardProps) {
    const { intl, dateFilters, store, photoList, getThumbnails, loading, setSelectedStore } = props

    const channelOfTradeLabel = store.channel_of_trade_string
        ? intl.formatMessage({
              id: `map.${store.channel_of_trade_string.toLowerCase()}`,
          })
        : ''

    let carouselImages =
        photoList.length > 0 ? photoList : [{ url: '/image@3x.png' }]

    // handle the time interval to show the thumbnails
    // default: current year
    // if date filters are active: the most recent interval
    const date = getLastIntervalFromDateFilters(dateFilters)

    useEffect(() => {
        getThumbnails([store.store_id_string], [date])
    }, [store.store_id_string])

    return (
        <StyledInfoCard>
            <Link to={'/store/' + store.store_id_string} className="info-card">
                <div className="box p-shadow-8">
                    <PinCarousel photoList={carouselImages} loading={loading}/>

                    <div className="p-p-2 p-grid">
                        <div className="logo p-pl-3 p-pt-3 p-pb-0 p-col-4 p-mt-2">
                            <LogoImage banner={store.banner_desc_string} />
                        </div>
                        <div className="store-id p-col-4 p-pl-0">{`#${store.id}`}</div>
                        <CloseCardButton setSelectedStore={setSelectedStore} />
                        <div className="p-px-3 p-pb-1 p-col-12 store-name">
                            {store.store_name_string || 'Store Name'}
                        </div>
                        <div className="p-px-3 p-pt-0 p-col-12 store-address">{`${store.address_string} - ${store.city_string}`}</div>
                        <div className="p-px-3 p-col-12">
                            <div
                                className={`bg-${(
                                    store.channel_of_trade_string || ''
                                ).toLowerCase()} channel-label`}>
                                {channelOfTradeLabel.toUpperCase()}
                            </div>
                        </div>
                    </div>
                </div>
                <div className="arrow-down " />
            </Link>
        </StyledInfoCard>
    )
}

export default injectIntl(InfoCard)


export const CloseCardButton = (props: { setSelectedStore: any }) => {
    const { setSelectedStore } = props
    return (
        <div className="p-pl-4 p-pt-3 p-pb-0 p-ml-5" style={{ marginTop: '-19em' }}>
            <Button
                icon="pi pi-times"
                severity="danger"
                aria-label="Cancel"
                style={{ color: 'black', backgroundColor: '#ff0000', borderColor: '#ff0000', borderRadius: '20px' }}
                onClick={(event) => {
                    event.preventDefault()
                    setSelectedStore(null)
                }}
            />
        </div>
    )
}
