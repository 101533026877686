import React from 'react'
import { Switch, Router } from 'react-router-dom'
import RouteConfig from './routeConfig'
import AppRoute from './appRoute'
import { createBrowserHistory } from 'history'

export const history = createBrowserHistory()

const AppRouter = (props: any) => {
    return (
        <Router history={history}>
            <Switch>
                {RouteConfig.map((route, i) => (
                    <AppRoute
                        key={i}
                        path={route.path}
                        component={route.component}
                        _private={route._private}
                        redirectTo={route.redirectTo}
                        exact={route.exact}
                    />
                ))}
            </Switch>
        </Router>
    )
}

export default AppRouter
