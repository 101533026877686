import { MainProjectsDispatchTypes } from '../dispatchers/mainprojects'
import { MainProjectsState } from '../states/mainprojectsState'

let defaultstate: MainProjectsState = {
    mainprojects: [],
    loadingMainProjects: true,
    mainproject: {
        value: '',
        label: '',
    },
    loadingMainProject: true,
    selectedDeckPhotos: [],
}

const mainProjectsReducer = (
    state: MainProjectsState = defaultstate,
    action: any
) => {
    switch (action.type) {
        case MainProjectsDispatchTypes.GET_MAIN_PROJECTS_REQUEST:
            return {
                ...state,
                loadingMainProjects: true,
            }
        case MainProjectsDispatchTypes.GET_MAIN_PROJECTS_SUCCESS:
            return {
                ...state,
                mainprojects: action.response,
                loadingMainProjects: false,
            }
        case MainProjectsDispatchTypes.GET_MAIN_PROJECTS_ERROR:
            return {
                ...state,
                loadingMainProjects: false,
            }
        case MainProjectsDispatchTypes.GET_MAIN_PROJECT_DETAIL_REQUEST:
            return {
                ...state,
                loadingMainProject: true,
            }
        case MainProjectsDispatchTypes.GET_MAIN_PROJECT_DETAIL_SUCCESS:
            return {
                ...state,
                mainproject: action.response,
                loadingMainProject: false,
            }
        case MainProjectsDispatchTypes.GET_MAIN_PROJECT_DETAIL_ERROR:
            return {
                ...state,
                loadingMainProject: false,
            }
        case MainProjectsDispatchTypes.SET_MAIN_PROJECT_SELECTED_DECK_PHOTOS_REQUEST:
            return {
                ...state,
            }
        case MainProjectsDispatchTypes.SET_MAIN_PROJECT_SELECTED_DECK_PHOTOS_SUCCESS:
            return {
                ...state,
                selectedDeckPhotos: action.response,
            }
        case MainProjectsDispatchTypes.SET_MAIN_PROJECT_SELECTED_DECK_PHOTOS_ERROR:
            return {
                ...state,
            }
        case MainProjectsDispatchTypes.EXPORT_MAIN_PROJECT_DECK_REQUEST:
            return {
                ...state,
            }
        case MainProjectsDispatchTypes.EXPORT_MAIN_PROJECT_DECK_SUCCESS:
            return {
                ...state,
            }
        case MainProjectsDispatchTypes.EXPORT_MAIN_PROJECT_DECK_ERROR:
            return {
                ...state,
            }
        default:
            return state
    }
}

export default mainProjectsReducer

