import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { injectIntl } from 'react-intl'
import { FormattedMessage } from 'react-intl'
import { AppState } from '../../redux/states/appState'
import { StoresSnapshot } from '../../models/stores-snapshot'
import { ReactComponent as SnapshotIcon } from './../../utils/icons/elements-icon-chart.svg'
import { Chart } from 'primereact/chart'
import 'primeicons/primeicons.css'
import 'primereact/resources/themes/saga-blue/theme.css'
import 'primereact/resources/primereact.css'
import 'primeflex/primeflex.css'
import { Dialog } from 'primereact/dialog'
import './map-side-legend.css'
import moment from 'moment'
import { useWindowResize } from '../../utils/commonFunctions'
import { getSearchResults } from '../../redux/actions/stores'
import { setSelectedSidebarMenu } from '../../redux/actions/filters'
import { SidebarMenuKind } from '../../models/sidebar-menu-kind'
import { createLoadingSelector } from '../../redux/selectors'
import { ProgressSpinner } from 'primereact/progressspinner'

const styles = {
    pointerCursor: {
        cursor: 'pointer',
    },
    snapshotButton: {
        width: 60,
        fontFamily: 'AvenirLTPro',
    },
    snapshotButtonDisabledOverride: {
        cursor: 'default',
        color: '#CCC',
    },
}

type MapSideLegendProps = {
    loadingStores: boolean
    loadingSearchResults: boolean
    getSnapshotRemodelingSearchResults: Function
    getSnapshotRelocationSearchResults: Function
    getSnapshotNewOpeningsSearchResults: Function
    className?: string
    snapshot: StoresSnapshot
    intl: any
}

function MapSideLegend(props: MapSideLegendProps) {
    const { intl } = props

    const [isLegendOpen, setIsLegendOpen] = useState(false)
    const isMobileNew = useWindowResize()
    const isLegendVisible = !isMobileNew || (isMobileNew && isLegendOpen)
    const handleOpenLegendMobileClick = () => setIsLegendOpen(true)
    const handleCloseLegendMobileClick = () => setIsLegendOpen(false)
    const [displayBasic, setDisplayBasic] = useState(false)
    const dialogFuncMap = { displayBasic: setDisplayBasic }
    const onClick = (name: any) => {
        dialogFuncMap['displayBasic'](true)
    }
    const onHide = (name: any) => {
        dialogFuncMap['displayBasic'](false)
    }
    const [
        isLoadingSnapshotRemodelingSearchResults,
        setIsLoadingSnapshotRemodelingSearchResults,
    ] = useState(false)
    const [
        isLoadingSnapshotRelocationSearchResults,
        setIsLoadingSnapshotRelocationSearchResults,
    ] = useState(false)
    const [
        isLoadingSnapshotNewOpeningsSearchResults,
        setIsLoadingSnapshotNewOpeningsSearchResults,
    ] = useState(false)

    useEffect(() => {
        if (!props.loadingSearchResults) {
            setIsLoadingSnapshotRemodelingSearchResults(false)
            setIsLoadingSnapshotRelocationSearchResults(false)
            setIsLoadingSnapshotNewOpeningsSearchResults(false)
        }
    }, [props.loadingSearchResults])

    const currentDateFormatted = () => {
        let today: any = new Date().getTime()
        let timestamp: any = moment(today, 'x')
        return timestamp.format('DD MMMM YYYY')
    }

    const DoughnutChart = (percentage: number, name: string, color: string) => {
        const chartData = {
            labels: [name, ''],
            datasets: [
                {
                    data: [percentage, 100 - percentage],
                    backgroundColor: [color, '#ccd1d6'],
                    hoverBackgroundColor: [color, '#ccd1d6'],
                },
            ],
        }
        const lightOptions = {
            aspectRatio: 1,
            cutoutPercentage: 80,
            legend: {
                display: false,
            },
            tooltips: {
                enabled: false,
            },
        }
        return (
            <>
                <div className="card">
                    <div className="p-label">{percentage}%</div>
                    <Chart
                        type="doughnut"
                        data={chartData}
                        options={lightOptions}
                    />
                </div>
            </>
        )
    }

    const DoughnutChartCustom = () => {
        const chartData = {
            labels: ['', ''],
            datasets: [
                {
                    data: [
                        props.snapshot.core_percentage,
                        100 - props.snapshot.core_percentage,
                    ],
                    backgroundColor: ['#36d4a6', '#FFFFFF'],
                    hoverBackgroundColor: ['#36d4a6', '#FFFFFF'],
                    borderColor: '#FFFFFF',
                    hoverBorderColor: '#FFFFFF',
                    borderWidth: 10,
                },
                {
                    data: [
                        props.snapshot.hosted_percentage,
                        100 - props.snapshot.hosted_percentage,
                    ],
                    backgroundColor: ['#fa7b83', '#FFFFFF'],
                    hoverBackgroundColor: ['#fa7b83', '#FFFFFF'],
                    borderColor: '#FFFFFF',
                    hoverBorderColor: '#FFFFFF',
                    borderWidth: 10,
                },
                {
                    data: [
                        props.snapshot.outlet_percentage,
                        100 - props.snapshot.outlet_percentage,
                    ],
                    backgroundColor: ['#fec400', '#FFFFFF'],
                    hoverBackgroundColor: ['#fec400', '#FFFFFF'],
                    borderColor: '#FFFFFF',
                    hoverBorderColor: '#FFFFFF',
                    borderWidth: 10,
                },
                {
                    data: [
                        props.snapshot.noChannel_percentage,
                        100 - props.snapshot.noChannel_percentage,
                    ],
                    backgroundColor: ['#e8ebed', '#FFFFFF'],
                    hoverBackgroundColor: ['#e8ebed', '#FFFFFF'],
                    borderColor: '#FFFFFF',
                    hoverBorderColor: '#FFFFFF',
                    borderWidth: 10,
                }
            ]
        }
        const lightOptions = {
            aspectRatio: 1,
            cutoutPercentage: 40,
            legend: {
                display: false,
                position: 'right',
            },
            tooltips: {
                enabled: false,
            },
        }
        return (
            <>
                <div className="card">
                    <Chart
                        type="doughnut"
                        data={chartData}
                        options={lightOptions}
                    />
                </div>
            </>
        )
    }

    const PieChart = () => {
        const opticalPlusSun =
            props.snapshot.division_facet?.Optical?.count +
                props.snapshot.division_facet?.Sun?.count || 1
        const opticalPercent =
            (props.snapshot.division_facet?.Optical?.count * 100) /
            opticalPlusSun
        const sunPercent =
            (props.snapshot.division_facet?.Sun?.count * 100) / opticalPlusSun

        const chartData = {
            labels: ['Optical', 'Sun'],
            datasets: [
                {
                    data: [opticalPercent, sunPercent],
                    backgroundColor: ['#db91fb', '#ffec00'],
                    // hoverBackgroundColor: ["#b558f6", "#fec400"],
                    hoverBackgroundColor: ['#db91fb', '#ffec00'],
                },
            ],
        }
        const lightOptions = {
            aspectRatio: 1,
            legend: {
                display: false,
                position: 'left',
            },
            tooltips: {
                enabled: false,
            },
        }
        return (
            <div className="card">
                <Chart type="pie" data={chartData} options={lightOptions} />
            </div>
        )
    }

    return (
        <>
            <div className="dialogMapSideLegend">
                <Dialog
                    visible={displayBasic}
                    header="Stores Snapshot"
                    footer=""
                    onHide={() => onHide('displayBasic')}>
                    {isMobileNew && (
                        <div className="modalHeader">
                            <div className="modalHeaderIcon">
                                <SnapshotIcon />
                            </div>
                            <div className="modalHeaderTitle">
                                {props.snapshot.stores_count}
                            </div>
                            <div className="modalHeaderSubtitle">
                                {intl.formatMessage({ id: 'stores' })}
                            </div>
                            <div className="modalHeaderDate">
                                {currentDateFormatted()}
                            </div>
                        </div>
                    )}
                    <div className="modalBody">
                        <div className="modalBodyFirstSection">
                            <div
                                style={{
                                    ...styles.pointerCursor,
                                    ...((props.loadingStores ||
                                        props.loadingSearchResults) &&
                                        styles.snapshotButtonDisabledOverride),
                                }}
                                onClick={() => {
                                    if (
                                        props.loadingStores ||
                                        props.loadingSearchResults
                                    )
                                        return

                                    onHide('displayBasic')

                                    setIsLoadingSnapshotRemodelingSearchResults(
                                        true
                                    )
                                    props.getSnapshotRemodelingSearchResults()
                                }}>
                                {DoughnutChart(
                                    parseFloat(
                                        `${props.snapshot.remodeling_percentage}`
                                    ),
                                    'Remodeling',
                                    '#4072ee'
                                )}
                                <div className="text">
                                    <div className="text-black-600">
                                        {props.snapshot.remodeling_count}
                                    </div>
                                    <div className="text-gray-400">
                                        {`${intl.formatMessage({
                                            id: 'map.stores_remodeling',
                                        })} ${props.snapshot.remodeling_year}`}
                                    </div>
                                </div>
                            </div>
                            <div
                                style={{
                                    ...styles.pointerCursor,
                                    ...((props.loadingStores ||
                                        props.loadingSearchResults) &&
                                        styles.snapshotButtonDisabledOverride),
                                }}
                                onClick={() => {
                                    if (
                                        props.loadingStores ||
                                        props.loadingSearchResults
                                    )
                                        return

                                    onHide('displayBasic')

                                    setIsLoadingSnapshotRelocationSearchResults(
                                        true
                                    )
                                    props.getSnapshotRelocationSearchResults()
                                }}>
                                {DoughnutChart(
                                    parseFloat(
                                        `${props.snapshot.relocation_percentage}`
                                    ),
                                    'Relocation',
                                    '#4072ee'
                                )}
                                <div className="text">
                                    <div className="text-black-600">
                                        {props.snapshot.relocation_count}
                                    </div>
                                    <div className="text-gray-400">
                                        {`${intl.formatMessage({
                                            id: 'map.stores_relocation',
                                        })} ${props.snapshot.relocation_year}`}
                                    </div>
                                </div>
                            </div>
                            <div
                                style={{
                                    ...styles.pointerCursor,
                                    ...((props.loadingStores ||
                                        props.loadingSearchResults) &&
                                        styles.snapshotButtonDisabledOverride),
                                }}
                                onClick={() => {
                                    if (
                                        props.loadingStores ||
                                        props.loadingSearchResults
                                    )
                                        return

                                    onHide('displayBasic')

                                    setIsLoadingSnapshotNewOpeningsSearchResults(
                                        true
                                    )
                                    props.getSnapshotNewOpeningsSearchResults()
                                }}>
                                {DoughnutChart(
                                    parseFloat(
                                        `${props.snapshot.new_opening_percentage}`
                                    ),
                                    'New Openings',
                                    '#fed100'
                                )}
                                <div className="text">
                                    <div className="text-black-600">
                                        {props.snapshot.new_opening_count}
                                    </div>
                                    <div className="text-gray-400">
                                        {`${intl.formatMessage({
                                            id: 'map.stores_new_open',
                                        })} ${props.snapshot.new_opening_year}`}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="modalBodySecondSection">
                            {!isMobileNew && (
                                <>
                                    <div className="modalBodySecondSectionTitle">
                                        {intl.formatMessage({
                                            id: 'map.stores_snapshot',
                                        })}
                                    </div>
                                    <div className="modalBodySecondSectionDate">
                                        {currentDateFormatted()}
                                    </div>
                                </>
                            )}
                            <div className="modalBodySecondSectionCharts">
                                <div className="gridChart">
                                    {!isMobileNew && (
                                        <ul>
                                            <li>
                                                <span
                                                    className="circle"
                                                    style={{
                                                        borderColor: '#b558f6',
                                                    }}></span>
                                                {intl.formatMessage({
                                                    id: 'map.optical',
                                                })}{' '}
                                                (
                                                {props.snapshot.division_facet
                                                    ?.Optical?.count || 0}
                                                )
                                            </li>
                                            <li>
                                                <span
                                                    className="circle"
                                                    style={{
                                                        borderColor: '#fec400',
                                                    }}></span>
                                                {intl.formatMessage({
                                                    id: 'map.sun',
                                                })}{' '}
                                                (
                                                {props.snapshot.division_facet
                                                    ?.Sun?.count || 0}
                                                )
                                            </li>
                                        </ul>
                                    )}
                                    {PieChart()}
                                    {isMobileNew && (
                                        <ul>
                                            <li>
                                                <span
                                                    className="circle"
                                                    style={{
                                                        borderColor: '#b558f6',
                                                    }}></span>
                                                {intl.formatMessage({
                                                    id: 'map.optical',
                                                })}{' '}
                                                (
                                                {props.snapshot.division_facet
                                                    ?.Optical?.count || 0}
                                                )
                                            </li>
                                            <li>
                                                <span
                                                    className="circle"
                                                    style={{
                                                        borderColor: '#fec400',
                                                    }}></span>
                                                {intl.formatMessage({
                                                    id: 'map.sun',
                                                })}{' '}
                                                (
                                                {props.snapshot.division_facet
                                                    ?.Sun?.count || 0}
                                                )
                                            </li>
                                        </ul>
                                    )}
                                </div>
                                <div className="gridChart">
                                    {DoughnutChartCustom()}
                                    <div>
                                        <ul>
                                            <li>
                                                <span className="circle" style={{borderColor: '#36d4a6',}}></span>
                                                {intl.formatMessage({id: 'map.core',})}{' '}
                                                ({props.snapshot.core_percentage}%)
                                            </li>
                                            <li>
                                                <span className="circle" style={{borderColor: '#fa7b83',}}></span>
                                                {intl.formatMessage({id: 'map.hosted',})}{' '}
                                                ({props.snapshot.hosted_percentage}%)
                                            </li>
                                            <li>
                                                <span className="circle" style={{borderColor: '#fec400',}}></span>
                                                {intl.formatMessage({id: 'map.outlet',})}{' '}
                                                ({props.snapshot.outlet_percentage}%)
                                            </li>
                                            <li>
                                                <span className="circle" style={{borderColor: 'e8ebed',}}></span>
                                                {intl.formatMessage({id: 'map.noChannelOfTrade',})}{' '}
                                                ({props.snapshot.noChannel_percentage}%)
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            {!isMobileNew && (
                                <div className="modalBodySecondSectionStores">
                                    <div className="modalBodySecondSectionStoresNumber">
                                        {props.snapshot.stores_count}
                                    </div>
                                    <div className="modalBodySecondSectionStoresText">
                                        {intl.formatMessage({ id: 'stores' })}
                                    </div>
                                </div>
                            )}
                        </div>
                    </div>
                </Dialog>
            </div>
            {isLegendVisible && (
                <div className="rounded-2xl w-80 p-4 mb-2 bg-white">
                    {isMobileNew && (
                        <div
                            onClick={handleCloseLegendMobileClick}
                            className="text-mainBlue flex justify-end mb-2 text-xs">
                            <FormattedMessage id={`map.close_snapshot`} />
                        </div>
                    )}
                    <div className="flex items-center justify-between">
                        <div>
                            <FormattedMessage id={`map.stores_snapshot`} />
                        </div>
                        <div
                            onClick={() => onClick('displayBasic')}
                            className="text-mainBlue viewAll text-sm">
                            <FormattedMessage id={`map.view_all`} />
                        </div>
                    </div>
                    <div className="mt-8">
                        <div
                            className="flex items-center justify-between mt-2 text-mainBlue"
                            onClick={() => {
                                if (
                                    props.loadingStores ||
                                    props.loadingSearchResults
                                )
                                    return

                                setIsLoadingSnapshotRemodelingSearchResults(
                                    true
                                )
                                props.getSnapshotRemodelingSearchResults()
                            }}>
                            <div
                                className="text-gray-400"
                                style={{
                                    ...styles.pointerCursor,
                                    ...((props.loadingStores ||
                                        props.loadingSearchResults) &&
                                        styles.snapshotButtonDisabledOverride),
                                }}>
                                <FormattedMessage
                                    id={`map.stores_remodeling`}
                                />
                                <span>{` ${props.snapshot.remodeling_year}`}</span>
                            </div>

                            <div
                                className="flex items-center justify-center p-2 text-sm"
                                style={{
                                    ...styles.pointerCursor,
                                    ...styles.snapshotButton,
                                    ...((props.loadingStores ||
                                        props.loadingSearchResults) &&
                                        styles.snapshotButtonDisabledOverride),
                                }}>
                                {isLoadingSnapshotRemodelingSearchResults ? (
                                    <ProgressSpinner style={{ height: 20 }} />
                                ) : (
                                    `${props.snapshot.remodeling_count}`
                                )}
                            </div>
                        </div>
                        <div
                            className="flex items-center justify-between mt-2 text-mainBlue"
                            onClick={() => {
                                if (
                                    props.loadingStores ||
                                    props.loadingSearchResults
                                )
                                    return

                                setIsLoadingSnapshotRelocationSearchResults(
                                    true
                                )
                                props.getSnapshotRelocationSearchResults()
                            }}>
                            <div
                                className="text-gray-400"
                                style={{
                                    ...styles.pointerCursor,
                                    ...((props.loadingStores ||
                                        props.loadingSearchResults) &&
                                        styles.snapshotButtonDisabledOverride),
                                }}>
                                <FormattedMessage
                                    id={`map.stores_relocation`}
                                />
                                <span>{` ${props.snapshot.relocation_year}`}</span>
                            </div>
                            <div
                                className="flex items-center justify-center p-2 text-sm"
                                style={{
                                    ...styles.pointerCursor,
                                    ...styles.snapshotButton,
                                    ...((props.loadingStores ||
                                        props.loadingSearchResults) &&
                                        styles.snapshotButtonDisabledOverride),
                                }}>
                                {isLoadingSnapshotRelocationSearchResults ? (
                                    <ProgressSpinner style={{ height: 20 }} />
                                ) : (
                                    `${props.snapshot.relocation_count}`
                                )}
                            </div>
                        </div>
                        <div
                            className="flex items-center justify-between mt-2 text-mainBlue"
                            onClick={() => {
                                if (
                                    props.loadingStores ||
                                    props.loadingSearchResults
                                )
                                    return

                                setIsLoadingSnapshotNewOpeningsSearchResults(
                                    true
                                )
                                props.getSnapshotNewOpeningsSearchResults()
                            }}>
                            <div
                                className="text-gray-400"
                                style={{
                                    ...styles.pointerCursor,
                                    ...((props.loadingStores ||
                                        props.loadingSearchResults) &&
                                        styles.snapshotButtonDisabledOverride),
                                }}>
                                <FormattedMessage id={`map.stores_new_open`} />
                                <span>{` ${props.snapshot.new_opening_year}`}</span>
                            </div>
                            <div
                                className="flex items-center justify-center p-2 text-sm"
                                style={{
                                    ...styles.pointerCursor,
                                    ...styles.snapshotButton,
                                    ...((props.loadingStores ||
                                        props.loadingSearchResults) &&
                                        styles.snapshotButtonDisabledOverride),
                                }}>
                                {isLoadingSnapshotNewOpeningsSearchResults ? (
                                    <ProgressSpinner style={{ height: 20 }} />
                                ) : (
                                    `${props.snapshot.new_opening_count}`
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            )}
            {isMobileNew && !isLegendOpen && (
                <div
                    onClick={handleOpenLegendMobileClick}
                    className="bg-mainBlue flex items-center justify-center p-4 mb-4 rounded-full">
                    <SnapshotIcon />
                </div>
            )}
        </>
    )
}

const loadingStores = createLoadingSelector(['STORES'])
const loadingSearchResults = createLoadingSelector(['SEARCH_RESULTS'])

const mapStateToProps = (state: AppState) => ({
    loadingStores: loadingStores(state),
    loadingSearchResults: loadingSearchResults(state),
    snapshot: state.snapshotReducer.snapshot,
})

const mapDispatchToProps = (dispatch: any) => ({
    getSnapshotRemodelingSearchResults: () =>
        dispatch(getSearchResults('snapshotRem')).then((e: any) =>
            dispatch(setSelectedSidebarMenu(SidebarMenuKind.SEARCH_RESULTS))
        ),
    getSnapshotRelocationSearchResults: () =>
        dispatch(getSearchResults('snapshotRel')).then((e: any) =>
            dispatch(setSelectedSidebarMenu(SidebarMenuKind.SEARCH_RESULTS))
        ),
    getSnapshotNewOpeningsSearchResults: () =>
        dispatch(getSearchResults('snapshotNew')).then((e: any) =>
            dispatch(setSelectedSidebarMenu(SidebarMenuKind.SEARCH_RESULTS))
        ),
})

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(injectIntl(MapSideLegend))
