import { injectIntl } from 'react-intl'
import { Colors } from '../../../colors'
import FavIcon from '../../../utils/icons/favourite'
import { StyledColumn } from './StyledColumn'

const FavSwitch = (props: any) => {
    const { isFavourite, setFavourite, intl } = props

    const colorCss = isFavourite ? 'is-fav' : ''

    return (
        <StyledColumn>
            <button
                className={'p-col favourite-tag p-text-bold ' + colorCss}
                onClick={setFavourite}>
                {intl.formatMessage({ id: 'store.favourite' })}{' '}
                <FavIcon color={isFavourite ? Colors.pink : Colors.grey5} />
            </button>
        </StyledColumn>
    )
}

export default injectIntl(FavSwitch)
