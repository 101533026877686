import styled from 'styled-components'
import { Colors } from '../../colors'
import { ProgressSpinner } from 'primereact/progressspinner'

export const StyledProgressSpinner = styled(ProgressSpinner)`
  /* center progress spinner */
  position: relative;
  top: 50%;
  left: 50%;
  z-index: 30;
  height: 60px;
  width: 60px;
  margin-left: -30px;
  margin-top: -30px;
}`

export const StyledTitle = styled.div`
    border-radius: 4px;
    background-color: ${Colors.grey2};
    font-family: AvenirLTPro;
    font-size: 16px;
    font-weight: 900;
    line-height: 40px;
    padding: 0px 0px 0px 13px;
    margin-top: 18px;
    margin-bottom: 13px;

    @media (max-width: 991px) {
        margin-top: 10px;
        margin-bottom: 10px;
    }
`

export const StyledButton = styled.button<{
    onClick: (e: any) => void
}>`
    color: ${Colors.blue};
    font-family: AvenirLTPro;
    font-size: 14px;
    font-weight: 900;
    letter-spacing: 0.27px;
    text-align: left;
    margin: -15px 0px 15px 0px;
    outline: none !important;
    box-shadow: none !important;
    padding-left: 16px;
`
