import Fuse from 'fuse.js'
import axios from 'axios'
import { BannerFilters } from '../../models/banner-filters'
import { HostedRetailFilters } from '../../models/hosted-retail-filters'
import { RegionFilters } from '../../models/region-filters'
import { Store } from '../../models/store'
import { StoresSnapshot } from '../../models/stores-snapshot'
import { RemodelFilters } from '../../models/remodel-filters'
import { SolrFields } from '../../solr-fields'
import { StoresApi } from '../../utils/api/storesApi'
import { ObsoleteError } from '../../utils/obsoleteError'
import { StoresDispatcher } from '../dispatchers/stores'
import { AppState } from '../states/appState'
import { StoresState } from '../states/storesState'
import { RelocationFilters } from '../../models/relocation-filters'
import { NewFilters } from '../../models/new-filters'
import { NRRFilters } from '../../models/nrr-filters'
import { SearchingFor } from '../../searchingfor'
import { IndexedDb, IndexedDbInfo } from '../../utils/indexedDB'
import { BaseIndexedDBSerializable } from '../../models/indexedDbSerializable'
import { UserDispatcher } from '../dispatchers/user'

export const refreshIDB = (
    idbTable: string,
    refreshFunction: Function,
    forceRefresh: boolean = false,
    refreshFunctionParameters?: any
) => {
    return async (dispatch: any, getState: any) => {
        try {
            const refreshDataFromBackend = async () => {
                return refreshFunctionParameters
                    ? await refreshFunction.apply(
                        refreshFunction,
                        refreshFunctionParameters
                    )
                    : await refreshFunction()
            }

            // If the browser doesn't support IndexedDB, then we just fetch data from the back-end and quit
            if (!window.indexedDB) {
                return dispatch(
                    StoresDispatcher.RefreshIDBDone({
                        data: await refreshDataFromBackend(),
                        shouldRefreshSoon: false,
                    })
                )
            }

            // Search on IndexedDB for data
            const iDb = new IndexedDb(IndexedDbInfo.appDB)
            await iDb.createObjectStore(IndexedDbInfo.allTables)
            let idbData = await iDb.getAllValues(idbTable)

            // If IndexedDB doesn't have the data, we need to fetch it from the back-end immediately.
            // Otherwise, we fetch data only if forceRefresh is passed as true
            const iDbWithoutData = !idbData || idbData.length < 1
            if (iDbWithoutData || forceRefresh) {
                idbData = await refreshDataFromBackend()

                // Update IndexedDB
                // Arrays are valid IndexedDB types, so we can store them as they are received
                if (Array.isArray(idbData)) {
                    await iDb.putBulkValues(idbTable, idbData)
                } else {
                    // If the back-end output is an object, we treat each property of the object as a table to store
                    // (make sure to check that it is defined inside the IndexedDb util class)
                    for (let table of Object.keys(idbData)) {
                        const itemsToPutToiDB = idbData[table]
                        // Put items in each table
                        // Arrays are valid IndexedDB types, so we can store them as they are received
                        // BaseIndexedDBSerializable classes needs to be converted to objects instead
                        await iDb.putBulkValues(
                            table,
                            Array.isArray(itemsToPutToiDB) ||
                                !(
                                    itemsToPutToiDB instanceof
                                    BaseIndexedDBSerializable
                                )
                                ? itemsToPutToiDB
                                : [itemsToPutToiDB.toObject()]
                        )
                    }
                }
            }

            return dispatch(
                StoresDispatcher.RefreshIDBDone({
                    data: idbData,
                    shouldRefreshSoon: !iDbWithoutData,
                })
            )
        } catch (error: any) {
            dispatch(UserDispatcher.UserError(error))

            dispatch(StoresDispatcher.StoresError(error))
        }
    }
}

export const getSingleStore = (storeId: string) => {
    return async (dispatch: any, getState: any) => {
        dispatch(StoresDispatcher.SingleStoreRequest())

        try {
            let store = await StoresApi.GetSingleStore(storeId)

            dispatch(
                StoresDispatcher.SingleStoreSuccess({
                    selectedStore: store,
                })
            )
        } catch (error: any) {
            dispatch(UserDispatcher.UserError(error))

            if (error instanceof ObsoleteError) {
                console.log('Discarded obsolete getStores')
            } else {
                dispatch(StoresDispatcher.StoresError(error))
            }
        }
    }
}

export const getSearchResults = (
    mode: 'search' | 'snapshotRem' | 'snapshotRel' | 'snapshotNew' = 'search'
) => {
    return async (dispatch: any, getState: any) => {
        dispatch(StoresDispatcher.SearchResultsRequest())

        try {
            let currentState: AppState = getState()
            const { stores, storesSnapshot } = currentState.storesReducer

            let searchResults =
                mode === 'search'
                    ? stores?.length
                        ? await StoresApi.GetSearchResults(
                            stores.map((store) => store.store_id_string),
                            currentState.filtersReducer
                        )
                        : []
                    : mode === 'snapshotRem'
                        ? await StoresApi.GetSearchResults(
                            storesSnapshot.remodeling_stores.map(
                                (store) => store.store_id_string
                            )
                        )
                        : mode === 'snapshotRel'
                            ? await StoresApi.GetSearchResults(
                                storesSnapshot.relocation_stores.map(
                                    (store) => store.store_id_string
                                )
                            )
                            : mode === 'snapshotNew'
                                ? await StoresApi.GetSearchResults(
                                    storesSnapshot.new_opening_stores.map(
                                        (store) => store.store_id_string
                                    )
                                )
                                : []

            // We need to return the Promise as other components need to trigger a SelectedMenuKind
            return dispatch(
                StoresDispatcher.SearchResultsSuccess({
                    searchResults: searchResults,
                })
            )
        } catch (error: any) {
            dispatch(UserDispatcher.UserError(error))

            dispatch(StoresDispatcher.StoresError(error))
        }
    }
}

export const setToolbarSearchValue = (searchValue: string) => {
    return async (dispatch: any, getState: any) => {
        dispatch(
            StoresDispatcher.SetToolbarSearchValueDone({
                toolbarSearchValue: searchValue,
            })
        )
    }
}

export const toolbarInitFuse = () => {
    return async (dispatch: any, getState: any) => {
        dispatch(StoresDispatcher.ToolbarInitFuseRequest())

        try {
            // Search for stores in the local DB.
            // If they are not there, we fallback to Solr
            const refreshIDBOutput = await dispatch(
                refreshIDB(
                    IndexedDbInfo.fuseStoresTable,
                    StoresApi.GetFuseStores,
                    true
                )
            )
            const stores = refreshIDBOutput.success.data
            const shouldRefreshSoon = refreshIDBOutput.success.shouldRefreshSoon

            const newToolbarFuseEngines = {
                global: new Fuse(stores, {
                    findAllMatches: true,
                    includeMatches: true,
                    threshold: 0.2,
                    location: 0,
                    keys: [
                        SolrFields.ID,
                        SolrFields.MacroRegion,
                        SolrFields.StoreName,
                        SolrFields.Division,
                        SolrFields.BannerDesc,
                        SolrFields.Market,
                        SolrFields.CountryDesc,
                        SolrFields.Region,
                        SolrFields.City,
                        SolrFields.Address,
                        SolrFields.BusinessModel,
                        SolrFields.StoreType,
                        SolrFields.ChannelOfTrade,
                        SolrFields.HostedRetail,
                        SolrFields.Segment,
                        SolrFields.ZoneDesc,
                        SolrFields.StoreDesignGroup,
                        SolrFields.StoreDesign,
                    ],
                }),
                id: new Fuse(stores, {
                    findAllMatches: true,
                    threshold: SearchingFor.searchThresholds.id,
                    location: 0,
                    keys: [SolrFields.ID],
                }),
                macroregion_desc_string: new Fuse(stores, {
                    findAllMatches: true,
                    threshold: SearchingFor.searchThresholds.macroregion,
                    location: 0,
                    keys: [SolrFields.MacroRegionDesc],
                }),
                store_name_string: new Fuse(stores, {
                    findAllMatches: true,
                    threshold: SearchingFor.searchThresholds.storeName,
                    location: 0,
                    keys: [SolrFields.StoreName],
                }),
                division_string: new Fuse(stores, {
                    findAllMatches: true,
                    threshold: SearchingFor.searchThresholds.division,
                    location: 0,
                    keys: [SolrFields.Division],
                }),
                banner_desc_string: new Fuse(stores, {
                    findAllMatches: true,
                    threshold: SearchingFor.searchThresholds.banner,
                    location: 0,
                    keys: [SolrFields.BannerDesc],
                }),
                market_string: new Fuse(stores, {
                    findAllMatches: true,
                    threshold: SearchingFor.searchThresholds.market,
                    location: 0,
                    keys: [SolrFields.Market],
                }),
                country_desc_string: new Fuse(stores, {
                    findAllMatches: true,
                    threshold: SearchingFor.searchThresholds.country,
                    location: 0,
                    keys: [SolrFields.CountryDesc],
                }),
                region_string: new Fuse(stores, {
                    findAllMatches: true,
                    threshold: SearchingFor.searchThresholds.region,
                    location: 0,
                    keys: [SolrFields.Region],
                }),
                city_string: new Fuse(stores, {
                    findAllMatches: true,
                    threshold: SearchingFor.searchThresholds.city,
                    location: 0,
                    keys: [SolrFields.City],
                }),
                address_string: new Fuse(stores, {
                    findAllMatches: true,
                    threshold: SearchingFor.searchThresholds.address,
                    location: 0,
                    keys: [SolrFields.Address],
                }),
                business_model_string: new Fuse(stores, {
                    findAllMatches: true,
                    threshold: SearchingFor.searchThresholds.businessModel,
                    location: 0,
                    keys: [SolrFields.BusinessModel],
                }),
                store_type_string: new Fuse(stores, {
                    findAllMatches: true,
                    threshold: SearchingFor.searchThresholds.storeType,
                    location: 0,
                    keys: [SolrFields.StoreType],
                }),
                channel_of_trade_string: new Fuse(stores, {
                    findAllMatches: true,
                    threshold: SearchingFor.searchThresholds.channelOfTrade,
                    location: 0,
                    keys: [SolrFields.ChannelOfTrade],
                }),
                store_host_name_string: new Fuse(stores, {
                    findAllMatches: true,
                    threshold: SearchingFor.searchThresholds.storeHostName,
                    location: 0,
                    keys: [SolrFields.HostedRetail],
                }),
                segment_string: new Fuse(stores, {
                    findAllMatches: true,
                    threshold: SearchingFor.searchThresholds.segment,
                    location: 0,
                    keys: [SolrFields.Segment],
                }),
                zone_desc_string: new Fuse(stores, {
                    findAllMatches: true,
                    threshold: SearchingFor.searchThresholds.zone,
                    location: 0,
                    keys: [SolrFields.ZoneDesc],
                }),
                store_design_group_string: new Fuse(stores, {
                    findAllMatches: true,
                    threshold: SearchingFor.searchThresholds.storeDesignGroup,
                    location: 0,
                    keys: [SolrFields.StoreDesignGroup],
                }),
                store_design_string: new Fuse(stores, {
                    findAllMatches: true,
                    threshold: SearchingFor.searchThresholds.storeDesign,
                    location: 0,
                    keys: [SolrFields.StoreDesign],
                }),
            }

            if (shouldRefreshSoon) {
                dispatch(
                    refreshIDB(
                        IndexedDbInfo.fuseStoresTable,
                        StoresApi.GetFuseStores,
                        true
                    )
                )
            }

            dispatch(
                StoresDispatcher.ToolbarInitFuseSuccess({
                    toolbarFuseEngines: newToolbarFuseEngines,
                })
            )
        } catch (error: any) {
            dispatch(UserDispatcher.UserError(error))

            dispatch(StoresDispatcher.StoresError(error))
        }
    }
}

export const getToolbarSearchResults = (searchFieldName?: string) => {
    return async (dispatch: any, getState: any) => {
        dispatch(StoresDispatcher.ToolbarSearchResultsRequest())

        try {
            let currentState: AppState = getState()
            const { toolbarSearchValue, toolbarFuseEngines } =
                currentState.storesReducer

            const fuseEngine = toolbarFuseEngines[searchFieldName || 'global']
            const fuseSearchResults = fuseEngine.search(toolbarSearchValue)

            const searchResults = fuseSearchResults.map(
                (result: {
                    item: Store
                    refIndex: number
                    matches: { key: string; value: string }[]
                }) => {
                    result.item.search_matched = result.matches
                    return result.item
                }
            )

            // We need to return the Promise as other components need to trigger a SelectedMenuKind
            return dispatch(
                StoresDispatcher.ToolbarSearchResultsSuccess({
                    searchResults: searchResults,
                })
            )
        } catch (error: any) {
            dispatch(UserDispatcher.UserError(error))

            dispatch(StoresDispatcher.StoresError(error))
        }
    }
}

export const getStores = () => {
    return async (dispatch: any, getState: any) => {
        let currentState: AppState = getState()

        // If CancelToken is set, then another getStores is still pending and we need to abort it
        const { cancelTokenSource } = currentState.storesReducer
        if (cancelTokenSource) {
            cancelTokenSource.cancel()
        }

        const CancelToken = axios.CancelToken
        const source = CancelToken.source()

        dispatch(StoresDispatcher.StoresRequest({ cancelTokenSource: source }))

        let {
            bannerFilters,
            regionFilters,
            remodelFilters,
            relocationFilters,
            newFilters,
            hostedRetailFilters,
            surveyDateFilters,
        } = currentState.filtersReducer
        let { selectedImagesQuality } =
            bannerFilters.selectionBestFilters.imagesQualityFilters
        let { selectedMainProject } =
            bannerFilters.selectionBestFilters.mainProjectsFilters
        let { selectedFrom, selectedTo } = surveyDateFilters

        try {
            ////////////// STORES SNAPSHOT - START //////////////
            const { selectedRemodelYears } = remodelFilters
            const { selectedRelocationYears } = relocationFilters
            const { selectedNewYears } = newFilters

            // Default year and time is the current year
            const dateNow = new Date()
            const defaultYear = dateNow.getFullYear()
            // Get range based upon the filters
            const remodelRange = NRRFilters.mostRecentYearSolrRange(
                selectedRemodelYears,
                defaultYear
            )
            const relocationRange = NRRFilters.mostRecentYearSolrRange(
                selectedRelocationYears,
                defaultYear
            )
            const newRange = NRRFilters.mostRecentYearSolrRange(
                selectedNewYears,
                defaultYear
            )

            const remodelSolrRange = `&facet.range=${SolrFields.RemodelDate}&f.${SolrFields.RemodelDate}.facet.range.start=${remodelRange.rangeStart}&f.${SolrFields.RemodelDate}.facet.range.end=${remodelRange.rangeEnd}&f.${SolrFields.RemodelDate}.facet.range.gap=${remodelRange.rangeGap}`
            const relocationSolrRange = `&facet.range=${SolrFields.RelocationDate}&f.${SolrFields.RelocationDate}.facet.range.start=${relocationRange.rangeStart}&f.${SolrFields.RelocationDate}.facet.range.end=${relocationRange.rangeEnd}&f.${SolrFields.RelocationDate}.facet.range.gap=${relocationRange.rangeGap}`
            const openSolrRange = `&facet.range=${SolrFields.OpenDate}&f.${SolrFields.OpenDate}.facet.range.start=${newRange.rangeStart}&f.${SolrFields.OpenDate}.facet.range.end=${newRange.rangeEnd}&f.${SolrFields.OpenDate}.facet.range.gap=${newRange.rangeGap}`
            const divisionFacet = `&facet.field=${SolrFields.Division}&facet.mincount=1`
            const channelOfTradeFacet = `&facet.field=${SolrFields.ChannelOfTrade}&facet.mincount=1`
            ////////////// STORES SNAPSHOT - END //////////////

            ////////////// FILTERS - START //////////////
            let solrFq = `${BannerFilters.getSolrFQFilter(
                bannerFilters
            )}${RegionFilters.getSolrFQFilter(
                regionFilters
            )}${HostedRetailFilters.getSolrFQFilter(
                hostedRetailFilters
            )}${RemodelFilters.getSolrFQFilter(
                remodelFilters
            )}${RelocationFilters.getSolrFQFilter(
                relocationFilters
            )}${NewFilters.getSolrFQFilter(
                newFilters
            )}&facet=on${remodelSolrRange}${relocationSolrRange}${openSolrRange}${divisionFacet}${channelOfTradeFacet}`
            ////////////// FILTERS - END //////////////

            if (
                selectedRemodelYears.size < 1 &&
                selectedRelocationYears.size < 1 &&
                selectedNewYears.size < 1
            ) {
                solrFq += `&fq=${SolrFields.OpenDate
                    }:([* TO ${dateNow.getTime()}])&fq=${SolrFields.CloseDate
                    }:([${dateNow.getTime()} TO *])`
            }

            let storesData = await StoresApi.GetStores(solrFq, source.token)
            let stores = storesData.stores as Store[]
            let storesSnapshot = storesData.storesSnapshot as StoresSnapshot

            storesSnapshot.remodeling_year = remodelRange.year.toString()
            storesSnapshot.relocation_year = relocationRange.year.toString()
            storesSnapshot.new_opening_year = newRange.year.toString()
            storesSnapshot.remodeling_stores = stores.filter(
                (s) =>
                    Number(s.remodel_date_long) > remodelRange.rangeStart &&
                    Number(s.remodel_date_long) < remodelRange.rangeEnd
            )
            storesSnapshot.relocation_stores = stores.filter(
                (s) =>
                    Number(s.relocation_date_long) >
                    relocationRange.rangeStart &&
                    Number(s.relocation_date_long) < relocationRange.rangeEnd
            )
            storesSnapshot.new_opening_stores = stores.filter(
                (s) =>
                    Number(s.open_date_long) > newRange.rangeStart &&
                    Number(s.open_date_long) < newRange.rangeEnd
            )
            ////////////// SELECTION BEST - START //////////////
            if (
                selectedImagesQuality.size > 0 ||
                selectedMainProject.size > 0
            ) {
                // POTENTIAL OPTIMIZATION: use Remodel, Relocation, New, Survey Date intervals to filter to speed up the back-end side
                // for this to be an optimization, computing the intersections of such interval should be done efficiently.
                // Arguably it might take longer to filter multiple times even with shorter intervals. I guess it depends on the data size.

                // If a MainProject filter is selected, we need filter the stores
                let storesBestSelection =
                    await StoresApi.GetStoresBestSelection(
                        new Date(0),
                        new Date(),
                        stores.map((store) => store.store_id_string)
                    )

                const hasLayoutOrExecution = (storeID: string) =>
                    (storesBestSelection[storeID].bestqualityimagelayout &&
                        selectedImagesQuality.has('layout')) ||
                    (storesBestSelection[storeID].bestqualityimageexecution &&
                        selectedImagesQuality.has('execution'))
                const hasMainProject = (storeID: string) =>
                    storesBestSelection[storeID].mainproject.some(
                        (mp: string) => selectedMainProject.has(mp)
                    )

                // We select a store only if the images quality or the main projects are in the corresponding selection from the user
                stores = stores.filter(
                    (store: Store) =>
                        (selectedImagesQuality.size < 1 ||
                            hasLayoutOrExecution(store.store_id_string)) &&
                        (selectedMainProject.size < 1 ||
                            hasMainProject(store.store_id_string))
                )
            }
            ////////////// SELECTION BEST - END //////////////

            ////////////// SURVEY DATE - START //////////////
            const storesArray = stores.map((store) => store.store_id_string)

            let storesBySurveyDate = await StoresApi.GetStoresBySurveyDate(
                selectedFrom,
                selectedTo,
                storesArray
            )

            const storesBySurveyDateSet = new Set(storesBySurveyDate)
            // We select a store only if they have at least a survey within the Survey Date filters
            stores = stores.filter((store: Store) =>
                storesBySurveyDateSet.has(store.store_id_string)
            )
            ////////////// SURVEY DATE - START //////////////
            dispatch(
                StoresDispatcher.StoresSuccess({
                    stores: stores as any,
                    storesSnapshot: storesSnapshot,
                    cancelTokenSource: null,
                } as StoresState)
            )
        } catch (error: any) {
            dispatch(UserDispatcher.UserError(error))

            if (error instanceof ObsoleteError) {
                console.log('Discarded obsolete getStores')
            } else {
                dispatch(StoresDispatcher.StoresError(error))
            }
        }
    }
}

