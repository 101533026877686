import { Colors } from '../../colors'
import styled from 'styled-components'

export const StyledDatePicker = styled.div`
    .p-inputtext {
        background: ${Colors.grey0};
        border-right: none;
        border-top-left-radius: 8px;
        border-bottom-left-radius: 8px;
        max-height: 44px;
        padding-left: 12px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }
    .p-calendar.p-calendar-w-btn .p-datepicker-trigger {
        background: ${Colors.grey0};
        color: ${Colors.grey4};
        border: 1px solid #ced4da;
        border-top-right-radius: 8px;
        border-bottom-right-radius: 8px;
        border-left: none;
        max-height: 44px;
    }
    .p-inputtext:focus,
    .p-inputtext:hover {
        -webkit-box-shadow: none;
        border: 1px solid #ced4da;
        border-right: none;
    }
    .p-calendar.p-calendar-w-btn .p-datepicker-trigger:focus {
        -webkit-box-shadow: none;
    }
    .p-datepicker-header .p-link {
        display: none;
    }
    .p-datepicker.p-datepicker-monthpicker {
        z-index: 2 !important;
    }
    .p-datepicker-inline {
        flex-direction: column;
        z-index: 2;
    }
    .mobile-button {
        background: ${Colors.grey0};
        color: ${Colors.grey4};
        border-color: ${Colors.grey4};
    }
    .mobile-calendar {
        position: absolute;
        right: -1px;
        width: max-content;
        max-width: calc(100vw - 2rem);
        top: 3rem;
    }
    .p-monthpicker-month {
        font-weight: bold;
    }
    .p-monthpicker-month-disabled {
        font-weight: normal;
        text-decoration: line-through;
        opacity: 0.4;
    }
    .p-monthpicker-month.p-highlight {
        background: #4072ee !important;
        color: white !important;
    }
`
