import { BaseDispatcher } from './base'

export enum PhotosDispatchTypes {
    GET_DEFAULT_THUMBNAILS_REQUEST = 'GET_DEFAULT_THUMBNAILS_REQUEST',
    GET_DEFAULT_THUMBNAILS_SUCCESS = 'GET_DEFAULT_THUMBNAILS_SUCCESS',
    GET_DEFAULT_THUMBNAILS_ERROR = 'GET_DEFAULT_THUMBNAILS_ERROR',
    GET_ALL_PHOTOS_REQUEST = 'GET_ALL_PHOTOS_REQUEST',
    GET_ALL_PHOTOS_SUCCESS = 'GET_ALL_PHOTOS_SUCCESS',
    GET_ALL_PHOTOS_ERROR = 'GET_ALL_PHOTOS_ERROR',
    GET_PHOTOS_BY_MAIN_PROJECT_REQUEST = 'GET_PHOTOS_BY_MAIN_PROJECT_REQUEST',
    GET_PHOTOS_BY_MAIN_PROJECT_SUCCESS = 'GET_PHOTOS_BY_MAIN_PROJECT_SUCCESS',
    GET_PHOTOS_BY_MAIN_PROJECT_ERROR = 'GET_PHOTOS_BY_MAIN_PROJECT_ERROR',
    SET_SELECTED_DECK_PHOTOS_REQUEST = 'SET_SELECTED_DECK_PHOTOS_REQUEST',
    SET_SELECTED_DECK_PHOTOS_SUCCESS = 'SET_SELECTED_DECK_PHOTOS_SUCCESS',
    SET_SELECTED_DECK_PHOTOS_ERROR = 'SET_SELECTED_DECK_PHOTOS_ERROR',
    EXPORT_STORE_DECK_REQUEST = 'EXPORT_STORE_DECK_REQUEST',
    EXPORT_STORE_DECK_SUCCESS = 'EXPORT_STORE_DECK_SUCCESS',
    EXPORT_STORE_DECK_ERROR = 'EXPORT_STORE_DECK_ERROR',
}

export class PhotosDispatcher extends BaseDispatcher {
    static GetDefaultThumbnailsRequest = (): any => {
        return new PhotosDispatcher(
            PhotosDispatchTypes.GET_DEFAULT_THUMBNAILS_REQUEST,
            {}
        ).onDispatchSuccess()
    }

    static GetDefaultThumbnailSuccess = (success: any): any => {
        return new PhotosDispatcher(
            PhotosDispatchTypes.GET_DEFAULT_THUMBNAILS_SUCCESS,
            success
        ).onDispatchSuccess()
    }

    static GetDefaultThumbnailError = (error: any): any => {
        return new PhotosDispatcher(
            PhotosDispatchTypes.GET_DEFAULT_THUMBNAILS_ERROR,
            error
        ).onDispatchError()
    }

    static GetAllPhotosRequest = (): any => {
        return new PhotosDispatcher(
            PhotosDispatchTypes.GET_ALL_PHOTOS_REQUEST,
            {}
        ).onDispatchSuccess()
    }

    static GetAllPhotosSuccess = (success: any): any => {
        return new PhotosDispatcher(
            PhotosDispatchTypes.GET_ALL_PHOTOS_SUCCESS,
            success
        ).onDispatchSuccess()
    }

    static GetAllPhotosError = (error: any): any => {
        return new PhotosDispatcher(
            PhotosDispatchTypes.GET_ALL_PHOTOS_ERROR,
            error
        ).onDispatchError()
    }

    static GetPhotosByMainProjectRequest = (): any => {
        return new PhotosDispatcher(
            PhotosDispatchTypes.GET_PHOTOS_BY_MAIN_PROJECT_REQUEST,
            {}
        ).onDispatchSuccess()
    }

    static GetPhotosByMainProjectSuccess = (success: any): any => {
        return new PhotosDispatcher(
            PhotosDispatchTypes.GET_PHOTOS_BY_MAIN_PROJECT_SUCCESS,
            success
        ).onDispatchSuccess()
    }

    static GetPhotosByMainProjectError = (error: any): any => {
        return new PhotosDispatcher(
            PhotosDispatchTypes.GET_PHOTOS_BY_MAIN_PROJECT_ERROR,
            error
        ).onDispatchError()
    }

    static SetSelectedDeckPhotosRequest = (): any => {
        return new PhotosDispatcher(
            PhotosDispatchTypes.SET_SELECTED_DECK_PHOTOS_REQUEST,
            {}
        ).onDispatchSuccess()
    }

    static SetSelectedDeckPhotosSuccess = (success: any): any => {
        return new PhotosDispatcher(
            PhotosDispatchTypes.SET_SELECTED_DECK_PHOTOS_SUCCESS,
            success
        ).onDispatchSuccess()
    }

    static SetSelectedDeckPhotosError = (error: any): any => {
        return new PhotosDispatcher(
            PhotosDispatchTypes.SET_SELECTED_DECK_PHOTOS_ERROR,
            error
        ).onDispatchError()
    }

    static ExportStoreDeckRequest = (): any => {
        return new PhotosDispatcher(
            PhotosDispatchTypes.EXPORT_STORE_DECK_REQUEST,
            {}
        ).onDispatchSuccess()
    }

    static ExportStoreDeckSuccess = (success: any): any => {
        return new PhotosDispatcher(
            PhotosDispatchTypes.EXPORT_STORE_DECK_SUCCESS,
            success
        ).onDispatchSuccess()
    }

    static ExportStoreDeckError = (error: any): any => {
        return new PhotosDispatcher(
            PhotosDispatchTypes.EXPORT_STORE_DECK_ERROR,
            error
        ).onDispatchError()
    }
}

