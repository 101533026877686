import React, { useContext } from 'react'
import { InputText } from 'primereact/inputtext'
import { RadioButton } from 'primereact/radiobutton'
import { ReactComponent as EmailIcon } from './../../assets/svgs/elements-icon-email.svg'
import { injectIntl } from 'react-intl'
import './style.css'
import { User } from '../../models/user'
import { i18nContext } from '../../utils/localization/i18nWrapper'
import moment from 'moment'

type ProfileSidebarContentProps = {
    intl: any
    currentUser: User
    setLanguage: Function
}

const ProfileSidebarContent = (props: ProfileSidebarContentProps) => {
    const { intl } = props

    const { switchLanguage } = useContext(i18nContext)

    return (
        <div className="profileSidebarContent">
            <div className="Name">{`${props.currentUser.given_name} ${props.currentUser.family_name}`}</div>
            <div className="Email">
                <div className="label">Email</div>
                <div className="p-inputgroup">
                    <span className="p-inputgroup-addon z-10">
                        <EmailIcon />
                    </span>
                    <InputText
                        placeholder="name.surname@topm.luxottica.com"
                        value={props.currentUser.mail}
                        className="z-5"
                        readOnly
                    />
                </div>
            </div>
            <div className="Languages">
                <div className="label">
                    {intl.formatMessage({
                        id: 'profile_and_settings.languages',
                    })}
                </div>
                <div>
                    <div className="p-field-radiobutton">
                        <RadioButton
                            inputId="languageIT"
                            name="language"
                            value="it"
                            onChange={(e) => {
                                localStorage.setItem(
                                    'LANGUAGE_just_changed',
                                    'true'
                                )
                                props.setLanguage(e.value)
                                switchLanguage('it-IT')
                                moment.locale('it-IT')
                            }}
                            checked={props.currentUser.curr_language === 'it'}
                        />
                        <label htmlFor="languageIT">
                            {intl.formatMessage({
                                id: 'profile_and_settings.languages.italian',
                            })}
                        </label>
                    </div>
                    <div className="p-field-radiobutton">
                        <RadioButton
                            inputId="languageEN"
                            name="language"
                            value="en"
                            onChange={(e) => {
                                localStorage.setItem(
                                    'LANGUAGE_just_changed',
                                    'true'
                                )
                                props.setLanguage(e.value)
                                switchLanguage('en-EN')
                                moment.locale('en-EN')
                            }}
                            checked={props.currentUser.curr_language === 'en'}
                        />
                        <label htmlFor="languageEN">
                            {intl.formatMessage({
                                id: 'profile_and_settings.languages.english',
                            })}
                        </label>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default injectIntl(ProfileSidebarContent)
