import { Colors } from '../../../colors'
import styled from 'styled-components'

export const StyledSearchStoresClearSection = styled.div`
    background-color: white;

    .p-grid {
        margin: 0;
    }
    .p-col-6 {
        padding-top: 0px;
        padding: 10px;
    }
    .children {
        width: 100%;
    }

    @media (max-width: 991px) {
        padding-top: 40px;
    }
`

export const StyledLightGreyButtonContainer = styled.button<{
    onClick: (e: any) => void
}>`
    font-family: AvenirLTPro;
    font-size: 16px;
    font-weight: 900;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 0.3px;
    color: ${Colors.lightGrey};
    border: none;
    background: none;
    outline: none;
    margin-top: 32px;
    margin-left: 16px;
    text-align: left;
    outline: none !important;
    box-shadow: none !important;
`

export const StyledBlueButtonContainer = styled.button<{
    disabled: boolean
    onClick: (e: any) => void
}>`
    font-family: AvenirLTPro;
    font-size: 14px;
    font-weight: 900;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 0.27px;
    color: ${(p) => (p.disabled ? Colors.grey5 : Colors.blue)};
    border: none;
    background: none;
    outline: none;
    margin-top: 24px;
    margin-right: 24px;
    outline: none !important;
    box-shadow: none !important;
`

export const StyledDarkGreyButtonContainer = styled.div`
    font-family: AvenirLTPro;
    font-size: 14px;
    font-weight: 900;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 0.27px;
    color: ${Colors.darkGrey};
    border: none;
    background: none;
    outline: none;
    margin-top: 16px;
    margin-bottom: 16px;
    margin-left: 6px;
    text-align: left;
`
