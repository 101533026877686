import { PhotosDispatchTypes } from '../dispatchers/photos'
import { PhotoState } from '../states/photosState'

let defaultstate: PhotoState = {
    thumbnailList: [],
    photoList: [],
    allExistingPhotos: [],
    photoLoading: true,
    mainprojectPhotoList: [],
    mainprojectPhotoLoading: true,
    selectedDeckPhotos: [],
}

const photoReducer = (state: PhotoState = defaultstate, action: any) => {
    switch (action.type) {
        case PhotosDispatchTypes.GET_DEFAULT_THUMBNAILS_REQUEST:
            return {
                ...state,
                photoLoading: true,
            }
        case PhotosDispatchTypes.GET_DEFAULT_THUMBNAILS_SUCCESS:
            return {
                ...state,
                photoLoading: false,
                thumbnailList: action.response, //TODO change
            }
        case PhotosDispatchTypes.GET_DEFAULT_THUMBNAILS_ERROR:
        case PhotosDispatchTypes.GET_ALL_PHOTOS_REQUEST:
            return {
                ...state,
                photoLoading: true,
            }
        case PhotosDispatchTypes.GET_ALL_PHOTOS_SUCCESS:
            return {
                ...state,
                photoLoading: false,
                photoList: action.response.photoList,
                allExistingPhotos:
                    action.response.allExistingPhotos ??
                    state.allExistingPhotos,
            }
        case PhotosDispatchTypes.GET_ALL_PHOTOS_ERROR:
        case PhotosDispatchTypes.GET_PHOTOS_BY_MAIN_PROJECT_REQUEST:
            return {
                ...state,
                mainprojectPhotoLoading: true,
                mainprojectPhotoList: [],
                allExistingPhotos: [],
            }
        case PhotosDispatchTypes.GET_PHOTOS_BY_MAIN_PROJECT_SUCCESS:
            return {
                ...state,
                mainprojectPhotoLoading: false,
                mainprojectPhotoList: action.response.mainprojectPhotoList,
                allExistingPhotos:
                    action.response.allExistingPhotos ??
                    state.allExistingPhotos,
            }
        case PhotosDispatchTypes.GET_PHOTOS_BY_MAIN_PROJECT_ERROR:
            return {
                ...state,
                mainprojectPhotoLoading: false,
            }
        case PhotosDispatchTypes.SET_SELECTED_DECK_PHOTOS_REQUEST:
            return {
                ...state,
            }
        case PhotosDispatchTypes.SET_SELECTED_DECK_PHOTOS_SUCCESS:
            return {
                ...state,
                selectedDeckPhotos: action.response.selectedDeckPhotos,
            }
        case PhotosDispatchTypes.SET_SELECTED_DECK_PHOTOS_ERROR:
            return {
                ...state,
            }
        case PhotosDispatchTypes.EXPORT_STORE_DECK_REQUEST:
            return {
                ...state,
            }

        case PhotosDispatchTypes.EXPORT_STORE_DECK_SUCCESS:
            return {
                ...state,
            }
        case PhotosDispatchTypes.EXPORT_STORE_DECK_ERROR:
            return {
                ...state,
            }
        default:
            return state
    }
}

export default photoReducer
