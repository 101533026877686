// api/selectors.js
import _ from "lodash";
export const createLoadingSelector = (actions: any) => (state: any) => {
  // returns true only when all actions is not loading
  return _(actions).some((action) => _.get(state, `loadingReducer.${action}`));
};

export const createErrorMessageSelector = (actions: any) => (state: any) => {
  const last = _.get(state, "errorsReducer.__LAST");
  const messages = _.compact(
    _.map(actions, (action) => _.get(state, `errorsReducer.${action}`))
  );
  const message = _.find(messages, (m) => m === last) || "";
  return message;
};

export const createErrorTimeoutSelector = () => (state: any) => {
  const last = _.get(state, "errorsReducer.__TIMEOUT");
  return last;
};

export const createSuccessMessageSelector = (actions: any) => (state: any) => {
  const last = _.get(state, "successReducer.__LAST");
  const messages = _.compact(
    _.map(actions, (action) => _.get(state, `successReducer.${action}`))
  );

  const message = _.find(messages, (m) => m === last) || "";
  return message;
};
