import { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { injectIntl } from 'react-intl'
import { useHistory } from 'react-router-dom'
import { AppState } from '../redux/states/appState'
import { getMainProjects } from '../redux/actions/mainprojects'
import MainProjectSearch from '../components/main-project/main-project-search/MainProjectSearch'
import FilterSearchMainProjectsClearSection from '../components/filter/filter-searchmainprojects-clear-section'
import MainProjectSearchResults from '../components/main-project/main-project-search-results/MainProjectSearchResults'
import { ScrollPanel } from 'primereact/scrollpanel'

const MainProjectFilter = (props: any) => {
    const { intl } = props

    // CONSTS

    const history = useHistory()
    const [searchValue, setSearchValue] = useState<any>('')
    const [isSearching, setIsSearching] = useState<boolean>(false)

    // FUNCTIONS

    const onClearAllFilters = () => {
        onChangeSearchValue('')
    }

    const onSearchResults = () => {
        props.getMainProjects(searchValue)
    }

    const onChangeSearchValue = (e: any) => {
        if (!isSearching) {
            setSearchValue(e)
            setIsSearching(true)
            setTimeout(() => {
                props.getMainProjects(e)
                setIsSearching(false)
            }, 2000)
        }
    }

    const onClearSearchValue = () => {
        setSearchValue('')
        props.getMainProjects('')
    }

    const goToDetail = (id: string) => {
        history.push(`/main-project/${id}`)
    }

    // USE EFFECTS

    useEffect(() => {
        props.getMainProjects(searchValue)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.selectedBannerDesc])

    return (
        <ScrollPanel
            className="pvscroll"
            style={{
                width: '100%',
                height: 'calc(100vh - 175px)',
            }}>
            <FilterSearchMainProjectsClearSection
                noGutter
                isLoadingSearchResults={false}
                isSearchResultsDisabled={
                    props.loadingMainProjects || isSearching
                }
                onSearchResults={() => onSearchResults()}
                onClearAllFilters={() => onClearAllFilters()}
            />
            <MainProjectSearch
                searchValue={searchValue}
                placeholder={intl.formatMessage({
                    id: 'search.placeholder.main_projects',
                    defaultMessage: 'Search Main Projects',
                })}
                isLoading={props.loadingMainProjects}
                isSearching={isSearching}
                changeSearchValue={onChangeSearchValue}
                clearSearchValue={onClearSearchValue}
            />
            <MainProjectSearchResults
                mainprojects={props.mainprojects}
                goToDetail={goToDetail}
                isLoading={props.loadingMainProjects}
                isSearching={isSearching}
            />
        </ScrollPanel>
    )
}

const mapStateToProps = (state: AppState) => ({
    mainprojects: state.mainprojectsReducer.mainprojects,
    loadingMainProjects: state.mainprojectsReducer.loadingMainProjects,
    selectedBannerDesc:
        state.filtersReducer.bannerFilters?.bannerDescFilters
            ?.selectedBannerDesc ?? [],
})

const mapDispatchToProps = (dispatch: any) => ({
    getMainProjects: (name: string) => dispatch(getMainProjects(name)),
})

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(injectIntl(MainProjectFilter))
