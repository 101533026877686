import { injectIntl } from 'react-intl'
import { StyledGallery } from './StyledGallery'
import { Button } from 'primereact/button'
import { useEffect, useState } from 'react'
import { Photo } from '../../../models/photo-list'
import { useWindowResize } from '../../../utils/commonFunctions'
import moment from 'moment'
import SquaredCheckbox from '../../squared-checkbox'
import { ToggleButton } from 'primereact/togglebutton'
import { Colors } from '../../../colors'
import { ExportDeckType } from '../../../models/export-deck-type'

// Photo gallery divided in sections and carousel

const fourMonthsAgo = moment().subtract(4, 'months')

const StoreGallery = (props: any) => {
    const {
        setCarouselState,
        photoInSections,
        openModal,
        intl,
        showPhotosSelection,
        setShouldShowPhotosSelection,
        exportStoreDeck,
        selectedDeckPhotos,
        setSelectedDeckPhotos,
        loadingExportStoreDeck,
        macroarea,
    } = props

    const isMobileNow = useWindowResize()


    useEffect(() => {
        if (
            !showPhotosSelection || (
            selectedDeckPhotos.length > 0 &&
            selectedDeckPhotos.some(
                (sdp: Photo) =>
                    !Object.values(photoInSections).some((p: any) =>
                        p.list.some((pl: Photo) => pl.photo_id === sdp.photo_id)
                    )
            ))
        ) {
            setSelectedDeckPhotos([])
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [setSelectedDeckPhotos])

    useEffect(() => {
        if(!showPhotosSelection) setSelectedDeckPhotos([])
    }, [showPhotosSelection, setSelectedDeckPhotos])

    // thumbnail resolution: 200 / 500 / 900
    const resolutionParameter = '?impolicy=900'

    const cssClass = (
        index: number,
        sectionLength: number,
        isOverview: boolean
    ) => {
        let cssClass = ''
        sectionLength === 1 ||
        (sectionLength % 2 !== 0 &&
            index === sectionLength - 1 &&
            !isOverview) ||
        (sectionLength % 2 === 0 && index === sectionLength - 1 && isOverview)
            ? (cssClass += ' p-sm-12')
            : (cssClass += ' p-md-6')
        if (isOverview && index === 0) cssClass += ' double-height'
        return cssClass
    }

    const ClickImageItem = (props: { cssClass: string; photo: Photo }) => {
        const { cssClass, photo } = props

        const onClickHandler = () => {
            const existingPhotoIndex = selectedDeckPhotos.findIndex(
                (p: Photo) => p.index === photo.index
            )
            if (existingPhotoIndex > -1) {
                selectedDeckPhotos.splice(existingPhotoIndex, 1)
            } else {
                // We do not allow selecting more than X photos at a time
                if (selectedDeckPhotos.length >= 40) return

                selectedDeckPhotos.push(photo)
            }
            setSelectedDeckPhotos([...selectedDeckPhotos])
        }

        return (
            <button
                className={'img-box p-col-12' + cssClass}
                onClick={(e) => {
                    if ((e.target as HTMLInputElement).nodeName !== 'IMG')
                        return

                    if (showPhotosSelection) {
                        onClickHandler()
                    } else {
                        openModal()
                        if (photo.macroarea && photo.index !== undefined)
                            setCarouselState({
                                tab: photo.macroarea,
                                page: photo.index,
                            })
                    }
                }}>
                <div className="img-container">
                    <img
                        className="img"
                        src={photo.url + resolutionParameter}
                        alt=""
                    />
                    {showPhotosSelection && (
                        <div
                            style={{
                                position: 'absolute',
                                right: 15,
                                bottom: 0,
                            }}>
                            <SquaredCheckbox
                                checked={selectedDeckPhotos.some(
                                    (p: Photo) => p.photo_id === photo.photo_id
                                )}
                                onChange={onClickHandler}
                            />
                        </div>
                    )}
                    {photo.label && (
                        <div className="img-label">{photo.label}</div>
                    )}
                </div>
            </button>
        )
    }

    const Section = (props: any) => {
        const [showMore, setShowMore] = useState(isMobileNow ? true : false)

        const title =
            props.photoList.list.length > 1
                ? props.photoList.sectionName +
                  ' (' +
                  props.photoList.list.length +
                  ')'
                : props.photoList.sectionName

        const morePhotos =
            (props.photoList.sectionName !== 'Overview' &&
                props.photoList.list.length > 4) ||
            (props.photoList.sectionName === 'Overview' &&
                props.photoList.list.length > 1)

        const sliceIndex = props.photoList.sectionName === 'Overview' ? 1 : 4

        return (
            <div className="p-col-12 p-px-0 p-pt-4">
                {props.photoList.list.length > 0 && (
                    <>
                        <div className="p-d-flex p-justify-between">
                            <h4 className="p-text-uppercase">{title}</h4>
                            {morePhotos &&
                                !isMobileNow &&
                                !showPhotosSelection && (
                                    <Button
                                        className="p-button-text view-more-button p-py-0"
                                        label={
                                            !showMore
                                                ? intl.formatMessage({
                                                      id: 'store_detail.view_all',
                                                  })
                                                : intl.formatMessage({
                                                      id: 'store_detail.view_less',
                                                  })
                                        }
                                        onClick={() => setShowMore(!showMore)}
                                    />
                                )}
                        </div>
                        <div className="p-grid">
                            {props.photoList.list &&
                                props.photoList.list
                                    .slice(0, sliceIndex)
                                    .map((ph: Photo, i: number) => (
                                        <ClickImageItem
                                            photo={ph}
                                            cssClass={cssClass(
                                                i,
                                                props.photoList.list.length,
                                                props.photoList.sectionName ===
                                                    'Overview'
                                            )}
                                            key={i}
                                        />
                                    ))}
                        </div>
                        {morePhotos && (
                            <div
                                className={
                                    showMore || showPhotosSelection
                                        ? 'p-grid'
                                        : 'p-d-none'
                                }>
                                {props.photoList.list
                                    .slice(
                                        sliceIndex,
                                        props.photoList.list.length
                                    )
                                    .map((ph: Photo, i: number) => (
                                        <ClickImageItem
                                            photo={ph}
                                            cssClass={cssClass(
                                                i + sliceIndex,
                                                props.photoList.list.length,
                                                props.photoList.sectionName ===
                                                    'Overview'
                                            )}
                                            key={i}
                                        />
                                    ))}
                            </div>
                        )}
                    </>
                )}
            </div>
        )
    }

    let hasSomeRecentPhotos = false

    return props?.photoList?.length > 0 ? (
        <StyledGallery style={{ marginBottom: 40 }}>
            {photoInSections &&
                Object.keys(photoInSections).map((section, i: number) => {
                    const macroAreaPhotoList =
                        macroarea && macroarea !== 'All'
                            ? photoInSections[section].list
                            : photoInSections[section].list.filter((p: Photo) =>
                                  moment(p.date).isAfter(fourMonthsAgo)
                              )
                    if (
                        section !== 'Floor Plan' &&
                        macroAreaPhotoList.length > 0
                    ) {
                        const macroAreaPhotosSection = {
                            ...photoInSections[section],
                            list: macroAreaPhotoList,
                        }
                        hasSomeRecentPhotos = true
                        return (
                            <Section
                                key={i}
                                photoList={macroAreaPhotosSection}
                            />
                        )
                    } else return <div key={i}></div>
                })}
            {macroarea && macroarea !== 'All' ? (
                <></>
            ) : (
                <Section
                    photoList={{
                        list: props.photoList.filter((p: Photo) =>
                            moment(p.date).isSameOrBefore(fourMonthsAgo)
                        ),
                        sectionName: `${
                            hasSomeRecentPhotos ? 'OLDER ' : ''
                        }PHOTOS`,
                    }}
                />
            )}
            {showPhotosSelection && (
                <div
                    style={{
                        width: '100%',
                        paddingTop: 15,
                        paddingBottom: 15,
                        position: 'fixed',
                        left: 0,
                        right: 0,
                        bottom: 0,
                        backgroundColor: 'white',
                        borderColor: Colors.grey5,
                        borderWidth: 1,
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        gap: 10,
                    }}>
                    <ToggleButton
                        style={{ borderRadius: 20 }}
                        onChange={() => {
                            setSelectedDeckPhotos([])
                            setShouldShowPhotosSelection(false)
                        }}
                        offLabel={intl.formatMessage({
                            id: 'button.export_cancel.text',
                        })}
                        onIcon="pi pi-times-circle"
                    />
                    <ToggleButton
                        checked={
                            !loadingExportStoreDeck &&
                            selectedDeckPhotos.length > 0
                        }
                        style={{ borderRadius: 20 }}
                        onChange={() => {
                            if (
                                !loadingExportStoreDeck &&
                                selectedDeckPhotos.length > 0
                            )
                                exportStoreDeck(ExportDeckType.CUSTOM)
                        }}
                        onLabel={`${intl.formatMessage({
                            id: 'button.export_confirm.text',
                        })} ${selectedDeckPhotos.length} ${intl
                            .formatMessage({
                                id: 'store_detail.photo',
                            })
                            .toLowerCase()}`}
                        offLabel={
                            selectedDeckPhotos.length > 0
                                ? intl.formatMessage({
                                      id: 'button.exporting.text',
                                  })
                                : intl.formatMessage({
                                      id: 'button.export_confirm_disabled.text',
                                  })
                        }
                        onIcon="pi pi-times-circle"
                    />
                </div>
            )}
        </StyledGallery>
    ) : (
        <div style={{ marginTop: 30 }}>
            {intl.formatMessage({
                id: 'no.images.found',
                default: 'No images found.',
            })}
        </div>
    )
}

export default injectIntl(StoreGallery)
