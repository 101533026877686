import { Colors } from '../../colors'
import styled from 'styled-components'

export const StyledFilterToolbar = styled.div`
    /* overall style of the filter toolbar */
    .p-toolbar {
        position: fixed;
        z-index: 10;
        top: 0;
        left: 0;
        width: 100%;
        height: 110px;
        background: white;
        padding-left: 32px;
        border: solid 1px ${Colors.grey2};
        border-radius: 0;
        box-shadow: 0 3px 5px -1px rgba(162, 171, 181, 0.2),
            0 1px 18px 0 rgba(162, 171, 181, 0.2),
            0 6px 10px 0 rgba(162, 171, 181, 0.2);
    }
    .p-toolbar-group-left {
        overflow: auto;
        -ms-overflow-style: none; /* IE and Edge */
        scrollbar-width: none; /* Firefox */
    }
    .p-toolbar-group-left::-webkit-scrollbar {
        display: none;
    }
    .p-toolbar .p-toolbar-group-left .p-togglebutton {
        width: max-content;
    }
    .searchTopbar {
        height: 76px;
        margin-right: 16px;
    }
    .searchTopbar > div {
        padding: 16px;
        border-radius: 8px;
        background-color: #fff;
    }
    .searchTopbar > div.onFocus {
        box-shadow: 0 2px 4px -1px rgb(204 209 214 / 20%),
            0 1px 10px 0 rgb(204 209 214 / 20%),
            0 4px 5px 0 rgb(162 171 181 / 20%);
    }
    .searchTopbar > div > div {
        margin: 0;
        width: 330px;
    }
    .searchTopbar > div > div input {
        padding: 0;
        margin: 0;
        margin-left: 28px;
        margin-right: 16px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }
    @media (max-width: 991px) {
        .p-toolbar.p-component {
            flex-direction: column-reverse;
            height: auto;
            margin-top: 0px;
            padding: 16px 0px;
            border-bottom-left-radius: 16px;
            border-bottom-right-radius: 16px;
        }
        .p-toolbar-group-left {
            width: 100%;
            padding-left: 16px;
            -ms-overflow-style: none;
            scrollbar-width: none;
        }
        .p-toolbar-group-left::-webkit-scrollbar {
            display: none;
        }
        .p-toolbar-group-right {
            width: 100%;
            margin-bottom: 16px;
            justify-content: flex-end;
            justify-content: space-between;
            padding: 0px 16px;
        }
        .searchTopbar {
            width: 100%;
            height: auto;
            margin-right: 9px;
        }
        .searchTopbar > div {
            padding: 0;
        }
        .searchTopbar > div.onFocus {
            box-shadow: inherit;
        }
        .searchTopbar > div > div {
            margin: 0;
            width: 100%;
            max-width: 330px;
        }
        .searchTopbar > div > div input {
            font-size: 14px;
            margin-top: 0;
        }
    }
    @media (min-width: 992px) and (max-width: 1199px) {
        .p-toolbar {
            flex-direction: column-reverse;
            height: auto;
            padding: 0px 16px 16px;
        }
        .searchTopbar > div {
            margin: 8px 0;
            padding: 8px 16px;
        }
    }
    @media (min-width: 1200px) and (max-width: 1399px) {
        .searchTopbar {
            margin-right: 16px;
        }
        .searchTopbar > div > div {
            width: 255px;
        }
    }
    @media (min-width: 1200px) and (max-width: 1300px) {
        .searchTopbar > div > div {
            width: 150px;
        }
    }
`
