import { Checkbox } from 'primereact/checkbox'
import styled from 'styled-components'
import { Colors } from '../colors'
import { useWindowResize } from '../utils/commonFunctions'

const StyledSquaredCheckbox = styled.div<{ checked: boolean; size: string }>`
    /* customize outer box of the checkbox */
    .p-checkbox {
        ${(p) =>
            p.size === 'extralarge'
                ? `
            width: 40px;
            height: 40px;
            padding: 7px;`
                : p.size === 'large'
                ? `
            width: 32px;
            height: 32px;
            padding: 5px;`
                : (p) =>
                      p.size === 'medium'
                          ? `
            width: 24px;
            height: 24px;
            padding: 5px;`
                          : `
            width: 16px;
            height: 16px;
            padding: 3px;`}
        border-radius: 4px;
        border: solid 1px ${Colors.grey3};
        background-color: ${Colors.grey0};
    }

    /* customize inner box of the checkbox */
    .p-checkbox-box {
        display: ${(p) => (p.checked ? 'block' : 'none')};
        ${(p) =>
            p.size === 'extralarge'
                ? `
            width: 24px;
            height: 24px;`
                : p.size === 'large'
                ? `
            width: 20px;
            height: 20px;`
                : (p) =>
                      p.size === 'medium'
                          ? `
            width: 12px;
            height: 12px;`
                          : `
            width: 8px;
            height: 8px;`}
        border-radius: 2px;
        background-color: ${Colors.blue};
        border: solid 2px ${Colors.blue};
    }

    /* remove the "V" icon */
    .pi-check:before {
        content: '';
    }

    /* remove blue box when focusing on the checkbox */
    .p-checkbox-box.p-focus {
        box-shadow: none !important;
    }
`

const StyledContent = styled.div`
    width: 100%;
`

const StyledLabel = styled.p`
    font-family: AvenirLTPro;
    font-size: 14px;
    font-weight: 500;
    letter-spacing: 0.27px;
    color: ${Colors.grey5};
    margin-left: 8px;
`

const SquaredCheckbox = (props: any) => {
    const isMobileNow = useWindowResize()

    return (
        <StyledSquaredCheckbox
            checked={props.checked}
            size={props.size || 'medium'}>
            <div className="p-field-checkbox">
                {isMobileNow ? (
                    <StyledContent className="p-grid p-justify-between">
                        <div className="p-col-align-start">
                            <StyledLabel>{props.label}</StyledLabel>
                        </div>
                        <div className="p-col-align-end">
                            <Checkbox
                                onChange={props.onChange}
                                checked={props.checked}
                            />
                        </div>
                    </StyledContent>
                ) : (
                    <>
                        <Checkbox
                            onChange={props.onChange}
                            checked={props.checked}
                        />
                        <StyledLabel>{props.label}</StyledLabel>
                    </>
                )}
            </div>
        </StyledSquaredCheckbox>
    )
}

export default SquaredCheckbox
