import { DefaultSet } from "./default";

export class i18n extends DefaultSet {
  locale: string;
  defaultLocale: string;

  constructor(data?: any) {
    super();
    this.locale = "en-EN";
    this.defaultLocale = "en-EN";
    if (data) {
      this._set(data);
    }
  }
}
