import React, { useEffect } from 'react'
import 'primereact/resources/themes/bootstrap4-light-blue/theme.css'
import 'primeicons/primeicons.css'
import 'primeflex/primeflex.css'
import 'primereact/resources/primereact.min.css'
import './App.css'
import AppRouter from './routing/appRouter'
import Toaster from './components/toaster'

function App() {
    useEffect(() => {
        const params: URLSearchParams = new URLSearchParams(
            window.location.search
        )
        const from = params.get('from')
        if (from && from === 'app') {
            sessionStorage.setItem('from', 'app')
        }
    }, [])
    return (
        <div className="App">
            <Toaster />
            <header className="App-header">
                <AppRouter />
            </header>
        </div>
    )
}

export default App
