import { SearchableFilter } from './searchable-filter'

export type BDElement = {
    count: number
    selected: boolean
}

export type BannerDescDict = Record<string, BDElement>

export class BannerDescFilters extends SearchableFilter {
    selectedBannerDesc: Set<string>
    id: string
    title: string
    fuseEngine: any
    viewAllVisible: boolean
    searchableFilter: BannerDescDict
    searchedFilter: BannerDescDict
    searchValue: string
    getSearchableFilter = () =>
        Object.keys(this.searchedFilter).length > 0
            ? this.searchedFilter
            : this.searchableFilter
    isPanelCollapsed: boolean
    selectAllSelected: boolean

    constructor(data?: any) {
        super()

        this.selectedBannerDesc = new Set()
        this.id = 'banner_desc'
        this.title = ''
        this.fuseEngine = null
        this.viewAllVisible = true
        this.searchableFilter = data
        this.searchedFilter = data
        this.searchValue = ''
        this.isPanelCollapsed = true
        this.selectAllSelected = false
    }
}
