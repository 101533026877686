import { Colors } from '../../../colors'
import styled from 'styled-components'

export const StyledColumn = styled.div`
    .favourite-tag {
        color: ${Colors.grey5};
        text-transform: uppercase;
        font-size: 12px;
        margin: 24px 24px 8px auto;
        display: flex;
        justify-content: flex-end;
        letter-spacing: 0.83px;
    }
    .favourite-tag svg {
        margin-left: 2px;
        position: relative;
        top: -1px;
    }

    .favourite-tag:focus,
    .favourite-tag:active {
        border: none;
        outline: none;
        box-shadow: none;
    }
    .is-fav {
        color: ${Colors.pink};
        background-color: transparent;
        text-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.08);
    }
    .logo-img {
        min-height: 115px;
        padding: 5rem 5rem 1rem;
    }
    .card {
        box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.08);
        margin: 8px 17px 8px 15px;
        border-radius: 8px;
    }
    .p-card-body {
        padding: 0;
    }
    .p-card-content {
        padding: 17px 24px 16px 32px;
    }
    .floor-plan-container {
        margin: 0 32px;
        font-size: 18px;
        font-weight: 700;
        color: ${Colors.darkGrey};
    }
    .floor-plan-container button {
        overflow: hidden;
    }
    .floor-plan-img {
        object-fit: cover;
        width: 100%;
        max-height: 300px;
        border-radius: 0 !important;
        border-radius: 4px;
    }
    .img-container {
        min-width: 92px;
        width: 90px;
    }
    .img-stat {
        width: 100%;
        object-fit: contain;
    }
    .card-content {
        // width: 70%;
    }
    .card-number {
        font-size: 42px;
        color: ${Colors.grey7};
    }
    .card-name {
        font-size: 14px;
        color: ${Colors.grey5};
    }
    .card-button button {
        font-size: 14px;
    }
    .details-paragraph {
        border-bottom: 1px solid ${Colors.grey1};
        margin: 0;
    }
    .title {
        width: 40%;
        min-width: 110px;
        font-size: 16px;
        line-height: 1.5;
        // padding: 8px 32px;
    }
    .field {
        width: 60%;
        font-size: 16px;
        line-height: 1.5;
        // padding: 8px 32px;
    }
    .title {
        color: ${Colors.grey5};
    }
    .field {
        color: ${Colors.grey7};
    }
    .accordion-container {
        padding: 10px 8px 10px 32px;

        .child-accordion {
            padding-bottom: 10px;

            &:last-child {
                padding-bottom: 0;
            }
        }
    }
    @media (max-width: 767px) {
        .favourite-tag {
            margin: 1rem auto 0px;
        }
    }
`
