import styled from 'styled-components'
import { Colors } from '../../../colors'

export const StyledCarousel = styled.div<{
    isFullScreen: boolean
    isMobileNow: boolean
}>`

    .p-dialog .p-dialog-header {
      display: ${(p) => (p.isFullScreen ? 'none' : '')};
    }
  
    .p-carousel-indicators {
        display: none;
    }
  
    .carousel-item {
        width: 100%;
    }
  
    .carousel-item > div {
        align-items: center;
    }
    .carousel-img {
        width: ${(p) => (p.isFullScreen ? '90vw' : '90vw')};
        height: ${(p) => (p.isFullScreen ? 'calc(100vh - 108px)' : '95vh')};
        margin: 0 auto;
        object-fit: cover;
        border-radius: 4px;
    }
    .pin-carousel {
        max-width: 325px;
        max-height: 203px;
    }
    .pin-carousel .p-carousel-content button {
        position: absolute;
        background-color: ${Colors.white};
        color: ${Colors.darkGrey};
        opacity: 0.4;
    }
    .pin-carousel .p-carousel-content button .pi {
        opacity: 1;
    }
    .pin-carousel .p-carousel-content .p-carousel-prev {
        left: 0;
        z-index: 3;
    }
    .pin-carousel .p-carousel-content .p-carousel-next {
        right: 0;
        z-index: 3;
    }

    .tab-footer {
        margin-left: auto;
        margin-right: auto;
        position: relative;
        border-bottom: 4px solid ${Colors.grey1};
        margin-bottom: -4px;
        width: fit-content;
    }
    .tab-button {
        position: relative;
        bottom: -4px;
        color: ${Colors.grey5};
        border-bottom: 4px solid ${Colors.grey1};
        // background-color: ${Colors.white};
        border-radius: 0;
        text-transform: uppercase;
    }
    .active-tab {
        outline: none !important;
        box-shadow: none !important;
        color: ${Colors.blue};
        border-bottom: 4px solid ${Colors.blue} !important;
    }
    .mobile-macroarea {
        background-color: ${Colors.grey0};
        padding: 9px 16px;
        text-align: left;
        text-align: center;w
        width: 100%;
        letter-spacing: 0.3px;
        text-transform: uppercase;
        color: ${Colors.grey5};
    }
    .carousel-item.small {
        width: 325px;
        max-height: 203px;
        height: 100%;
        border-radius: 12px;
    }
    .carousel-item.small .carousel-img {
        object-fit: cover;
        border-top-left-radius: 12px;
        border-top-right-radius: 12px;
        width: 100%;
        height: 100%;
    }
    .pin-carousel button.p-link {
        display: flex;
    }
    @media (max-width: 991px) {
        .carousel-img {
            padding-left: 0;
            padding-right: 0;
            ${(p) => (p.isMobileNow ? '' : 'height: 100vh;')}
            height: auto;
        }
        .p-carousel-item > div {
            display: flex;
            align-items: center;
            overflow: hidden;
            height: 100vh;
            margin-top: -65px;
        }
        .p-carousel-item > div.carousel-item.small {
            margin-top: 0px;
        }
        .p-carousel .p-carousel-content .p-carousel-prev,
        .p-carousel .p-carousel-content .p-carousel-next {
            position: absolute;
            background: #fff;
            z-index: 25;
            bottom: 35px;
        }
        .p-carousel.pin-carousel .p-carousel-content .p-carousel-prev,
        .p-carousel.pin-carousel .p-carousel-content .p-carousel-next {
            bottom: auto;
        }
        .p-carousel .p-carousel-content .p-carousel-prev {
            left: 0;
        }
        .p-carousel .p-carousel-content .p-carousel-next {
            right: 0;
        }
    }
    @media ( max-width: 767px ) {
        .p-carousel-item > div {
            margin-top: -61px;
        }
        .p-carousel-item > div.carousel-item.small {
            margin-top: 0px;
        }
        .p-carousel .p-carousel-content .p-carousel-prev,
        .p-carousel .p-carousel-content .p-carousel-next {
            bottom: 35px;
        }
        .p-carousel.pin-carousel .p-carousel-content .p-carousel-prev,
        .p-carousel.pin-carousel .p-carousel-content .p-carousel-next {
            bottom: auto;
        }
    }
`
