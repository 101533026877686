import { Store } from './../../models/store'
import SearchResult from './../search-result'
import { Photo } from '../../models/photo-list'
import { createLoadingSelector } from '../../redux/selectors'
import { connect } from 'react-redux'
import { AppState } from '../../redux/states/appState'
import { set } from 'lodash'
import { useEffect } from 'react'

type SearchResultsProps = {
    intl: any
    stores: Store[]
    favouriteStores: Store[]
    isList: boolean
    imageList: Photo[]
    isLoading: boolean
    isLoadingImages: boolean
    setCounter: any
}

const SearchResults = (props: SearchResultsProps) => {
    const {
        stores,
        favouriteStores,
        isList,
        imageList,
        isLoading,
        isLoadingImages,
        intl,
        setCounter
    } = props

    var defaultPhotos: Photo[] = [
        {
            url: isLoading || isLoadingImages ? '/loadingthumb.gif' : '',
            store_id: '',
            index: 0,
            date_execution: '',
        },
    ]

    const favouriteStoresSet = favouriteStores
        ? favouriteStores.reduce((accumulator: Set<string>, store: Store) => {
              accumulator.add(store.store_id_string)

              return accumulator
          }, new Set<string>())
        : new Set()


    useEffect(() => {
        console.log(stores, imageList)
        stores.map((store: Store) => {
            console.log(defaultPhotos = imageList.filter(
                (ph) => ph.store_id === store.store_id_string
            ))
        })
    }, [stores])

    return (
        <>
            {stores.length > 0 ? (
                stores.map((store: Store, index: number) => {
                    if (imageList?.length) {
                        defaultPhotos = imageList.filter(
                            (ph) => ph.store_id === store.store_id_string
                        )
                        if (defaultPhotos.length < 1) {
                            defaultPhotos = [
                                {
                                    url:
                                        isLoading || isLoadingImages
                                            ? '/loadingthumb.gif'
                                            : '',
                                    store_id: '',
                                    index: 0,
                                    date_execution: '',
                                },
                            ]
                        }
                    }


                    return (
                        <SearchResult
                            key={index}
                            isList={isList}
                            store={store}
                            imageUrl={defaultPhotos[0].url}
                            isFav={favouriteStoresSet.has(
                                store.store_id_string
                            )}
                            setCounter={setCounter}
                        />
                    )
                })
            ) : (
                <p style={{ color: '#7b8a9a', marginTop: 15 }}>
                    {intl.formatMessage({
                        id: 'search_results.no_stores_found',
                    })}
                    .
                </p>
            )}
        </>
    )
}

const loadingSelector = createLoadingSelector(['GET_DEFAULT_THUMBNAILS'])

const mapStateToProps = (state: AppState) => ({
    isLoadingImages: loadingSelector(state),
})

export default connect(mapStateToProps)(SearchResults)
