import { Button } from 'primereact/button'
import { useEffect, useState } from 'react'
import { injectIntl } from 'react-intl'
import { useWindowResize } from '../../../utils/commonFunctions'
import { MainProjectPhoto } from '../../../models/main-projects'
import './MainProjectSections.css'
import SquaredCheckbox from '../../squared-checkbox'
import { ToggleButton } from 'primereact/togglebutton'
import { Colors } from '../../../colors'
import { ExportDeckType } from '../../../models/export-deck-type'
import store from '../../../redux/store'

const MainProjectSections = (props: any) => {
    const {
        intl,
        mainproject,
        photoList,
        openModal,
        showPhotosSelection,
        setShouldShowPhotosSelection,
        onExportDeck,
        selectedDeckPhotos,
        setSelectedDeckPhotos,
        loadingExportMainProjectDeck,
        setCarouselState,
    } = props

    const isMobileNow = useWindowResize()

    const resolutionParameter = '?impolicy=900'

    useEffect(() => {
        if (!showPhotosSelection ||
            (selectedDeckPhotos.length > 0 &&
                selectedDeckPhotos.some(
                    (sdp: MainProjectPhoto) =>
                        !photoList.some((p: MainProjectPhoto) => p.id === sdp.id)
                ))
        ) {
            setSelectedDeckPhotos([])
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [setSelectedDeckPhotos])

    useEffect(() => {
        if (!showPhotosSelection) setSelectedDeckPhotos([])
    }, [showPhotosSelection, setSelectedDeckPhotos])

    const cssClass = (
        index: number,
        sectionLength: number,
        isOverview: boolean
    ) => {
        let cssClass = ''
                sectionLength === 1 ? (cssClass += ' p-sm-12') 
                : (cssClass += ' p-md-6')
    /*            ||
        (sectionLength % 2 !== 0 &&
            index === sectionLength - 1 &&
            !isOverview) ||
        (sectionLength % 2 === 0 && index === sectionLength - 1 && isOverview)
            ? (cssClass += ' p-sm-12')
            : (cssClass += ' p-md-6')*/
        if (isOverview && index === 0) cssClass += ' double-height'
        return cssClass
    }

    const ClickImageItem = (props: {
        key: number
        cssClass: string
        photo: MainProjectPhoto
        banner?: string
        storeName?: string
        threeImgStore?: boolean
    }) => {
        const { cssClass, photo, banner, storeName, threeImgStore, key } = props

        console.log(key, threeImgStore)

        const onClickHandler = () => {
            const existingPhotoIndex = selectedDeckPhotos.findIndex(
                (p: MainProjectPhoto) => p.id === photo.id
            )
            if (existingPhotoIndex > -1) {
                selectedDeckPhotos.splice(existingPhotoIndex, 1)
            } else {
                // We do not allow selecting more than X photos at a time
                if (selectedDeckPhotos.length >= 40) return
                selectedDeckPhotos.push(photo)
            }
            setSelectedDeckPhotos([...selectedDeckPhotos])
        }

        const setCarouselPhoto = () => {
            const index = photoList.findIndex(
                (p: MainProjectPhoto) => p.id === photo.id
            )
            setCarouselState({
                page: index,
                tab: 'overview',
            })
        }


        return (
            <button
                className={'img-box p-col-12' + cssClass}
                onClick={(e) => {
                    if ((e.target as HTMLInputElement).nodeName !== 'IMG')
                        return
                    if (showPhotosSelection) {
                        onClickHandler()
                    } else {
                        setCarouselPhoto()
                        openModal(banner, storeName)
                    }
                }}>
                <div className="img-container" style={threeImgStore && key === 2 ? { maxWidth: '50%' } : {}}>
                    <img
                        className="img"
                        src={photo.path + resolutionParameter}
                        alt={photo.id}
                    />
                    {showPhotosSelection && (
                        <div
                            style={{
                                position: 'absolute',
                                right: 15,
                                bottom: 0,
                            }}>
                            <SquaredCheckbox
                                onChange={onClickHandler}
                                checked={selectedDeckPhotos.some(
                                    (p: MainProjectPhoto) => p.id === photo.id
                                )}
                            />
                        </div>
                    )}
                </div>
            </button>
        )
    }

    const Section = (props: {
        banner: string
        store_id: string
        store_name: string
    }) => {
        const { banner, store_id, store_name } = props



        let store_photos: MainProjectPhoto[] = []

        for (let i = 0; i < photoList.length; i++) {
            let photo = photoList[i]
            if (photo.store_id === '500-000000' + store_id) {
                store_photos.push(photo)
            }
        }

        let store_photos_count = store_photos.length

        let morePhotos = store_photos_count > 3

        let [showMore, setShowMore] = useState(isMobileNow ? true : false)

        return (
            <div className="p-col-12 p-pt-4 store-thumbnail" style={store_photos_count > 1 ? {} : { maxWidth: '50%' }}>
                <>
                    <div className="p-d-flex p-justify-between" style={{ height: '37px' }}>
                        <h4 className="p-text-uppercase">
                            {banner} #{store_id} {store_name} (
                            {store_photos_count > 0 ? store_photos_count : 0})
                        </h4>
                        {morePhotos && !isMobileNow && !showPhotosSelection && (
                            <Button
                                onClick={() => setShowMore(!showMore)}
                                className="p-button-text view-more-button p-py-0"
                                label={
                                    !showMore
                                        ? intl.formatMessage({
                                            id: 'store_detail.view_all',
                                        })
                                        : intl.formatMessage({
                                            id: 'store_detail.view_less',
                                        })
                                }
                            />
                        )}
                    </div>
                    {store_photos_count > 0 ? (
                        <div className="p-grid">
                            {store_photos.length > 0 &&
                                store_photos
                                    .slice(0, 4)
                                    .map((ph: MainProjectPhoto, i: number) => (
                                        <ClickImageItem
                                            key={i}
                                            photo={ph}
                                            cssClass={cssClass(
                                                i,
                                                store_photos?.length,
                                                false
                                            )}
                                            banner={banner}
                                            storeName={store_name}
                                            threeImgStore={store_photos_count === 3}
                                        />
                                    ))}
                        </div>
                    ) : (
                        <div className="p-d-flex p-justify-between p-pt-2">
                            {intl.formatMessage({
                                id: `mainproject.detail.no_associated_photos`,
                                default: 'No associated photos to this store',
                            })}
                        </div>
                    )}
                    {morePhotos && (
                        <div
                            className={
                                showMore || showPhotosSelection
                                    ? 'p-grid'
                                    : 'p-d-none'
                            }>
                            {store_photos
                                .slice(4, store_photos.length)
                                .map((ph: MainProjectPhoto, i: number) => (
                                    <ClickImageItem
                                        key={i}
                                        photo={ph}
                                        cssClass={cssClass(
                                            i,
                                            store_photos?.length,
                                            false
                                        )}
                                        threeImgStore={store_photos_count === 3}
                                    />
                                ))}
                        </div>
                    )}
                </>
            </div>
        )
    }


    return (
        <div className="mainprojectSections" style={{ marginBottom: 40 }}>
            {mainproject?.store_list?.length > 0 ? (
                mainproject?.store_list?.map(
                    (
                        store: {
                            banner: string
                            store_id: string
                            store_name: string
                        },
                        i: number
                    ) => {
                        return (
                            <Section
                                banner={store.banner}
                                store_id={store.store_id}
                                store_name={store.store_name}
                                key={i}
                            />
                        )
                    }
                )
            ) : (
                <div className="p-col-12 p-pt-4 store-thumbnail">
                    <div className="p-d-flex p-justify-between p-pt-2">
                        {intl.formatMessage({
                            id: `mainproject.detail.no_associated_stores`,
                            default:
                                'No associated stores to this main project',
                        })}
                    </div>
                </div>
            )}
            {showPhotosSelection && (
                <div
                    style={{
                        width: '100%',
                        paddingTop: 15,
                        paddingBottom: 15,
                        position: 'fixed',
                        left: 0,
                        right: 0,
                        bottom: 0,
                        backgroundColor: 'white',
                        borderColor: Colors.grey5,
                        borderWidth: 1,
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        gap: 10,
                    }}>
                    <ToggleButton
                        style={{ borderRadius: 20 }}
                        onChange={() => {
                            setSelectedDeckPhotos([])
                            setShouldShowPhotosSelection(false)
                        }}
                        offLabel={intl.formatMessage({
                            id: 'button.export_cancel.text',
                        })}
                        onIcon="pi pi-times-circle"
                    />
                    <ToggleButton
                        checked={
                            !loadingExportMainProjectDeck &&
                            selectedDeckPhotos.length > 0
                        }
                        style={{ borderRadius: 20 }}
                        onChange={() => {
                            if (
                                !loadingExportMainProjectDeck &&
                                selectedDeckPhotos.length > 0
                            )
                                onExportDeck(ExportDeckType.CUSTOM)
                        }}
                        onLabel={`${intl.formatMessage({
                            id: 'button.export_confirm.text',
                        })} ${selectedDeckPhotos.length} ${intl
                            .formatMessage({
                                id: 'store_detail.photo',
                            })
                            .toLowerCase()}`}
                        offLabel={
                            selectedDeckPhotos.length > 0
                                ? intl.formatMessage({
                                    id: 'button.exporting.text',
                                })
                                : intl.formatMessage({
                                    id: 'button.export_confirm_disabled.text',
                                })
                        }
                        onIcon="pi pi-times-circle"
                    />
                </div>
            )}
        </div>
    )
}

export default injectIntl(MainProjectSections)
