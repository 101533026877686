import { DefaultSet } from './default'

export class Store extends DefaultSet {
    store_key_string: string
    store_id_string: string
    id: string
    store_name_string: string
    division_string: string
    macroregion_string: string
    macroregion_desc_string: string
    business_unit_string: string
    banner_string: string
    banner_desc_string: string
    market_string: string
    country_string: string
    region_string: string
    region_code_string: string
    city_string: string
    address_string: string
    store_coordinate: string
    business_model_string: string
    store_type_string: string
    sap_planning_channel_string: string
    channel_of_trade_string: string
    store_location_string: string
    store_host_name_string: string
    segment_string: string
    store_segment_1_string: string
    store_rent_end_date_long: string
    lab_in_store_no_lab_string: string
    digital_window_string: string
    store_comments_string: string
    sop_site_string: string
    store_commercial_sqm_double: string
    store_total_sqm_double: string
    state_province_string: string
    sap_store_segment_string: string
    remodel_date_long: string
    relocation_date_long: string
    open_date_long: string
    close_date_long: string
    zone_desc_string: string
    zone_regional_area_manager_string: string
    store_design_group_string: string
    store_design_string: string
    search_matched?: { key: string; value: string }[]

    constructor(data?: any) {
        super()
        this.store_key_string = ''
        this.store_id_string = ''
        this.id = ''
        this.store_name_string = ''
        this.division_string = ''
        this.macroregion_string = ''
        this.macroregion_desc_string = ''
        this.business_unit_string = ''
        this.banner_string = ''
        this.banner_desc_string = ''
        this.market_string = ''
        this.country_string = ''
        this.region_string = ''
        this.region_code_string = ''
        this.city_string = ''
        this.address_string = ''
        this.store_coordinate = ''
        this.business_model_string = ''
        this.store_type_string = ''
        this.sap_planning_channel_string = ''
        this.channel_of_trade_string = ''
        this.store_location_string = ''
        this.store_host_name_string = ''
        this.segment_string = ''
        this.store_segment_1_string = ''
        this.store_rent_end_date_long = ''
        this.lab_in_store_no_lab_string = ''
        this.digital_window_string = ''
        this.store_comments_string = ''
        this.sop_site_string = ''
        this.store_commercial_sqm_double = ''
        this.store_total_sqm_double = ''
        this.state_province_string = ''
        this.sap_store_segment_string = ''
        this.remodel_date_long = ''
        this.relocation_date_long = ''
        this.open_date_long = ''
        this.close_date_long = ''
        this.zone_desc_string = ''
        this.zone_regional_area_manager_string = ''
        this.store_design_group_string = ''
        this.store_design_string = ''

        if (data) {
            this._set(data)
        }
    }
}
