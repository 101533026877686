import { injectIntl } from 'react-intl'
import { ProgressSpinner } from 'primereact/progressspinner'
import './MainProjectSearchResults.css'

const MainProjectSearchResults = (props: {
    intl: any
    mainprojects: { value: string; label: string }[]
    goToDetail: (id: string) => void
    isLoading: boolean
    isSearching: boolean
}) => {
    const { intl, mainprojects, goToDetail, isLoading, isSearching } = props

    return isLoading || isSearching ? (
        <div className="searchResultsLoader">
            <ProgressSpinner />
        </div>
    ) : (
        <div className="searchResults">
            {mainprojects?.length > 0 ? (
                mainprojects.map(
                    (p: { value: string; label: string }, i: number) => {
                        return (
                            <div
                                key={i}
                                className="col-p-12 result"
                                onClick={() => goToDetail(p.value)}>
                                {p.label}
                            </div>
                        )
                    }
                )
            ) : (
                <div className="noresults">
                    {intl.formatMessage({
                        id: 'mainprojects.filter.noresults',
                        defaultMessage: 'No results found',
                    })}
                </div>
            )}
        </div>
    )
}

export default injectIntl(MainProjectSearchResults)

