import { injectIntl } from 'react-intl'
import { Button } from 'primereact/button'

// navigation tabs for gallery modal
const FooterTabs = (props: any) => {
    const { activeTab, photoInSections, onTabChange } = props

    return (
        <div className="tab-footer p-d-none p-d-lg-block">
            {Object.keys(photoInSections).map((key, i) => (
                <Button
                    label={
                        photoInSections[key].list.length > 1
                            ? key +
                              ' (' +
                              photoInSections[key].list.length +
                              ')'
                            : key
                    }
                    onClick={() => {
                        onTabChange(key)
                        console.log(
                            'inside footer',
                            photoInSections[key].list[0].index
                        )
                    }}
                    className={
                        'p-button-text p-text-bold tab-button' +
                        (activeTab === key ? ' active-tab' : '')
                    }
                    key={i}
                />
            ))}
        </div>
    )
}

export default injectIntl(FooterTabs)
