export interface IndexedDBSerializable {
    toObject: () => void
}

export abstract class BaseIndexedDBSerializable
    implements IndexedDBSerializable {
    toObject() {
        throw new Error()
    }
}
