import { DefaultSet } from './default'

export type SearchableFilterElement = {
    count: number
    selected: boolean
}

export type SearchableFiltersDict = Record<string, SearchableFilterElement>
export type SearchableFiltersArray = [string, SearchableFilterElement][]

export interface SearchableFilterInterface {
    id: string
    title: string
    fuseEngine: any
    viewAllVisible: boolean
    searchableFilter: SearchableFiltersDict
    searchedFilter: SearchableFiltersDict
    searchValue: string
    isPanelCollapsed: boolean
    selectAllSelected: boolean
}

export abstract class SearchableFilter
    extends DefaultSet
    implements SearchableFilterInterface {
    abstract id: string
    abstract title: string
    abstract fuseEngine: any
    abstract viewAllVisible: boolean
    abstract searchableFilter: SearchableFiltersDict
    abstract searchedFilter: SearchableFiltersDict
    abstract searchValue: string
    abstract isPanelCollapsed: boolean
    abstract selectAllSelected: boolean

    static getSearchableFilterByInstance(
        filterObj: SearchableFilter,
        showEmptySearchResults: boolean
    ) {
        return showEmptySearchResults ||
            Object.keys(filterObj.searchedFilter).length > 0
            ? filterObj.searchedFilter
            : filterObj.searchableFilter
    }

    getSolrFQFilter = (selectedValues: Set<string>, field: string): string => {
        var solrFq: string = ''
        if (selectedValues.size > 0) {
            solrFq = Array.from(selectedValues).reduce(
                (accumulator: string, val: string) => {
                    if (val) {
                        if (accumulator.length > 0) {
                            accumulator += ' OR '
                        }
                        accumulator += `${field}:"${encodeURIComponent(val)}"`
                    }

                    return accumulator
                },
                ''
            )
        }
        return solrFq
    }

    getSolrFQChannelFilter = (selectedValues: Set<string>, field: string): string => {
        var solrFq: string = ''
        if (selectedValues.size > 0) {
            solrFq = Array.from(selectedValues).reduce(
                (accumulator: string, val: string) => {
                    if (val) {
                        if (accumulator.length > 0) {
                            accumulator += ' OR '
                        }
                        accumulator += val === 'No Channel' ? `(*:* NOT ${field}:*)` : `${field}:"${encodeURIComponent(val)}"`
                    }

                    return accumulator
                },
                ''
            )
        }
        return solrFq
    }
}
