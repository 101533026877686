import { connect } from 'react-redux'
import { FilterCalendarComponent } from '../components/filter/filter-calendar/filter-calendar.component'
import FilterSearchStoresClearSection from '../components/filter/filter-searchstores-clear-section'
import { SidebarMenuKind } from '../models/sidebar-menu-kind'
import {
    clearAllFilters,
    setSelectedNewMonth,
    setSelectedNewYear,
    setSelectedSidebarMenu,
} from '../redux/actions/filters'
import { getSearchResults } from '../redux/actions/stores'
import { createLoadingSelector } from '../redux/selectors'
import { AppState } from '../redux/states/appState'

const NewFilter = (props: any) => {
    return (
        <>
            <FilterSearchStoresClearSection
                noGutter
                onClearAllFilters={(e: any) => props.clearAllFilters()}
                isLoadingSearchResults={props.loadingSearchResults}
                isSearchResultsDisabled={props.loadingStores}
                onSearchResults={() => props.getSearchResults()}
            />
            <FilterCalendarComponent
                selectedMonths={props.newFilters.selectedNewMonths}
                selectedYears={props.newFilters.selectedNewYears}
                onMonthChange={props.setSelectedNewMonth}
                onYearChange={props.setSelectedNewYear}
            />
        </>
    )
}

const loadingStores = createLoadingSelector(['STORES'])
const loadingSearchResults = createLoadingSelector(['SEARCH_RESULTS'])

const mapStateToProps = (state: AppState) => ({
    loadingStores: loadingStores(state),
    loadingSearchResults: loadingSearchResults(state),
    newFilters: state.filtersReducer.newFilters,
})

const mapDispatchToProps = (dispatch: any) => ({
    clearAllFilters: () => dispatch(clearAllFilters()),
    setSelectedNewMonth: (month: number) =>
        dispatch(setSelectedNewMonth(month)),
    setSelectedNewYear: (year: number) => dispatch(setSelectedNewYear(year)),
    getSearchResults: () =>
        dispatch(getSearchResults()).then((e: any) =>
            dispatch(setSelectedSidebarMenu(SidebarMenuKind.SEARCH_RESULTS))
        ),
})

export default connect(mapStateToProps, mapDispatchToProps)(NewFilter)
