import { SearchableFilter } from './searchable-filter'

export type IQlement = {
    count: number
    selected: boolean
}

export type ImagesQualityDict = Record<string, IQlement>

export class ImagesQualityFilters extends SearchableFilter {
    selectedImagesQuality: Set<string>
    id: string
    title: string
    fuseEngine: any
    viewAllVisible: boolean
    searchableFilter: ImagesQualityDict
    searchedFilter: ImagesQualityDict
    searchValue: string
    getSearchableFilter = () =>
        Object.keys(this.searchedFilter).length > 0
            ? this.searchedFilter
            : this.searchableFilter
    isPanelCollapsed: boolean
    selectAllSelected: boolean

    constructor(data?: any) {
        super()

        this.selectedImagesQuality = new Set()
        this.id = 'images_quality'
        this.title = 'banner.images_quality.title'
        this.fuseEngine = null
        this.viewAllVisible = true
        this.searchableFilter = data
        this.searchedFilter = data
        this.searchValue = ''
        this.isPanelCollapsed = true
        this.selectAllSelected = false
    }
}

export type MPlement = {
    count: number
    selected: boolean
}

export type MainProjectDict = Record<string, MPlement>

export class MainProjectsFilters extends SearchableFilter {
    selectedMainProject: Set<string>
    id: string
    title: string
    fuseEngine: any
    viewAllVisible: boolean
    searchableFilter: MainProjectDict
    searchedFilter: MainProjectDict
    searchValue: string
    getSearchableFilter = () =>
        Object.keys(this.searchedFilter).length > 0
            ? this.searchedFilter
            : this.searchableFilter
    isPanelCollapsed: boolean
    selectAllSelected: boolean

    constructor(data?: any) {
        super()

        this.selectedMainProject = new Set()
        this.id = 'main_project'
        this.title = 'banner.main_projects.title'
        this.fuseEngine = null
        this.viewAllVisible = true
        this.searchableFilter = data
        this.searchedFilter = data
        this.searchValue = ''
        this.isPanelCollapsed = true
        this.selectAllSelected = false
    }
}

export class SelectionBestFilters {
    imagesQualityFilters: ImagesQualityFilters
    mainProjectsFilters: MainProjectsFilters

    constructor(data?: any) {
        this.imagesQualityFilters =
            data?.imagesQualityFilters || new ImagesQualityFilters()
        this.mainProjectsFilters =
            data?.mainProjectsFilters || new MainProjectsFilters()
    }

    getSelectedSelectionBestCount() {
        return (
            this.imagesQualityFilters.selectedImagesQuality.size +
            this.mainProjectsFilters.selectedMainProject.size
        )
    }
}
