import { Dialog } from 'primereact/dialog'
import { injectIntl } from 'react-intl'
import { StyledModal } from './StyledModal'
import 'primeicons/primeicons.css'

const ModalFullScreen = (props: any) => {
    const { open, onHide, children, header } = props

    return (
        <StyledModal>
            <Dialog
                maximized
                visible={open}
                header={header}
                // footer=""
                onHide={() => onHide('displayBasic')}>
                {children}
            </Dialog>
        </StyledModal>
    )
}

export default injectIntl(ModalFullScreen)
