import React, { Component, createContext } from 'react'
import { IntlProvider, addLocaleData } from 'react-intl'
import { i18nCalendars, i18nDictionaries } from './i18nHelper'
import { Storage } from '../storage/storage'
import { i18n } from '../../models/i18n'
import en from 'react-intl/locale-data/en'
import es from 'react-intl/locale-data/es'
import it from 'react-intl/locale-data/it'
import { i18nState } from '../../redux/states/i18nState'
import moment from 'moment'

addLocaleData([...en, ...it, ...es])

const Context = createContext<any>({
    switchLanguage: (locale: string) => {},
    locale: 'en-EN',
    langInited: false,
})

class I18nWrapper extends Component<{}, i18nState> {
    switchLanguage: any
    langInited: boolean

    constructor(props: any) {
        super(props)

        this.switchLanguage = (locale: string) => {
            const ls = Storage.geti18n()
            let _i18n: i18n = ls ? JSON.parse(ls) : new i18n()
            _i18n.locale = locale
            let messages = i18nDictionaries[locale]
            let calendar = i18nCalendars[locale]
            Storage.seti18n({ ..._i18n })
            this.langInited = true
            this.setState({
                locale: _i18n.locale,
                messages: messages,
                calendar: calendar,
                langInited: this.langInited,
            })
        }

        this.langInited = false

        const ls = Storage.geti18n()
        let _i18n: i18n = ls ? JSON.parse(ls) : new i18n()
        let messages = i18nDictionaries[_i18n.locale]
        let calendar = i18nCalendars[_i18n.locale]

        this.state = {
            locale: _i18n.locale,
            messages: messages,
            switchLanguage: this.switchLanguage,
            langInited: this.langInited,
            calendar: calendar,
        }
    }

    render() {
        const { children } = this.props
        const { locale, messages } = this.state
        moment.locale(locale)
        return (
            <Context.Provider value={this.state}>
                <IntlProvider
                    key={locale}
                    locale={locale}
                    messages={messages}
                    defaultLocale="en">
                    {children}
                </IntlProvider>
            </Context.Provider>
        )
    }
}

export { I18nWrapper, Context as i18nContext }
