import { UserApi } from '../../utils/api/userApi'
import { User, UserData } from '../../models/user'
import { UserDispatcher } from '../dispatchers/user'
import { Storage } from '../../utils/storage/storage'
import { UserState } from '../states/userState'
import { AppState } from '../states/appState'
import { Store } from '../../models/store'
import { SidebarMenuKind } from '../../models/sidebar-menu-kind'

export const loggedIn = (accessToken: string) => {
    return async (dispatch: any, getState: any) => {
        dispatch(UserDispatcher.UserRequest())

        try {
            Storage.setAccessToken(accessToken)

            const user: User = (await UserApi.WhoAmI()).data?.user as User

            const userData: UserData = (await UserApi.UserData()).data
                ?.user_data as UserData
            user.curr_language = userData.lang

            Storage.setUser(user)
            dispatch(
                UserDispatcher.UserSuccess({
                    user: user,
                    accessToken: accessToken,
                } as UserState)
            )
        } catch (error: any) {
            // dispatch(UserDispatcher.UserError(error));
            const user: User = new User()
            Storage.setUser(user)
            Storage.setAccessToken(accessToken)
            dispatch(
                UserDispatcher.UserSuccess({
                    user: user,
                    accessToken: accessToken,
                } as UserState)
            )
        }
    }
}

export const logout = () => {
    return async (dispatch: any, getState: any) => {
        dispatch(UserDispatcher.UserRequest())
        dispatch(UserDispatcher.UserLogout())
    }
}

export const getFavouriteStoresList = () => {
    return (dispatch: any, getState: any) => {
        dispatch(UserDispatcher.GetFavouriteStoresListRequest())
        UserApi.GetFavouriteStoresList()
            .then((res: any) => {
                if (res && res.data && res.data.favourite_store_list) {
                    const list = res.data.favourite_store_list.map(
                        (s: any) => Object.keys(s).map((key, i) => s[key])[0]
                    )
                    dispatch(UserDispatcher.GetFavouriteStoresListSuccess(list))
                }
            })
            .catch((error: Error) => {
                dispatch(UserDispatcher.UserError(error))

                dispatch(UserDispatcher.GetFavouriteStoresListError(error))
            })
    }
}

export const setFavouriteStore = (store: Store) => {
    const storeId = store.store_id_string
    return (dispatch: any, getState: any) => {
        let favList = getState().userReducer.favouriteStoresList
        dispatch(UserDispatcher.SetFavouriteStoreRequest())
        UserApi.SetFavouriteStore(storeId)
            .then((response: any) => {
                if (response && response.data) {
                    var newFavList = [...favList]
                    newFavList.unshift(store)
                    dispatch(
                        UserDispatcher.SetFavouriteStoreSuccess(newFavList)
                    )
                } else {
                    dispatch(UserDispatcher.SetFavouriteStoreError({}))
                }
            })
            .catch((error: any) => {
                dispatch(UserDispatcher.UserError(error))

                dispatch(UserDispatcher.SetFavouriteStoreError(error))
            })
    }
}

export const removeStoreFromFavourites = (storeId: string) => {
    return (dispatch: any, getState: any) => {
        let favList = getState().userReducer.favouriteStoresList
        dispatch(UserDispatcher.RemoveFavouriteStoreRequest())
        UserApi.RemoveStoreFromFavourites(storeId)
            .then((response: any) => {
                if (response && response.data) {
                    var filtered = favList.filter(
                        (st: Store) => st.store_id_string !== storeId
                    )
                    dispatch(
                        UserDispatcher.RemoveFavouriteStoreSuccess(filtered)
                    )
                } else {
                    dispatch(UserDispatcher.RemoveFavouriteStoreError({}))
                }
            })
            .catch((error: any) => {
                dispatch(UserDispatcher.UserError(error))

                dispatch(UserDispatcher.RemoveFavouriteStoreError(error))
            })
    }
}

export const setUserLanguage = (langLocaleShort: string) => {
    return async (dispatch: any, getState: any) => {
        let currentState: AppState = getState()

        let { user } = currentState.userReducer

        try {
            user.curr_language = langLocaleShort
            Storage.setUser(user)

            dispatch(setUserLanguageUpdateDB(langLocaleShort))

            dispatch(
                UserDispatcher.SetUserLanguageSuccess({
                    curr_language: langLocaleShort,
                })
            )
        } catch (error: any) {
            dispatch(UserDispatcher.UserError(error))
        }
    }
}

export const setUserLanguageUpdateDB = (langLocaleShort: string) => {
    return async (dispatch: any, getState: any) => {
        try {
            await UserApi.SetUserLanguage(langLocaleShort)

            dispatch(UserDispatcher.SetUserLanguageUpdateDBSuccess())
        } catch (error: any) {
            dispatch(UserDispatcher.UserError(error))
        }
    }
}

export const setReopenSidebarMemo = (
    shouldReopen: string,
    sidebarMenuKindToReopen: SidebarMenuKind
) => {
    return async (dispatch: any, getState: any) => {
        try {
            dispatch(
                UserDispatcher.SetReopenSidebarMemoSuccess({
                    shouldReopen: shouldReopen,
                    sidebarMenuKindToReopen: sidebarMenuKindToReopen,
                })
            )
        } catch (error: any) {
            dispatch(UserDispatcher.UserError(error))
        }
    }
}

