import { SearchableFilter } from './searchable-filter'

export type SDElement = {
    count: number
    selected: boolean
    groupFilter: StoreDesignGroupFilters
}

export type StoreDesignDict = Record<string, SDElement>

export class StoreDesignGroupFilters extends SearchableFilter {
    selectedStoreDesigns: Set<string>
    id: string
    title: string
    fuseEngine: any
    viewAllVisible: boolean
    searchableFilter: StoreDesignDict
    searchedFilter: StoreDesignDict
    searchValue: string
    isPanelCollapsed: boolean
    selectAllSelected: boolean
    getSearchableFilter = () =>
        Object.keys(this.searchedFilter).length > 0
            ? this.searchedFilter
            : this.searchableFilter

    constructor(data?: any) {
        super()

        this.selectedStoreDesigns = new Set()
        this.id = 'store_design_string'
        this.title = ''
        this.fuseEngine = null
        this.viewAllVisible = true
        this.searchableFilter = data
        this.searchedFilter = data
        this.searchValue = ''
        this.isPanelCollapsed = true
        this.selectAllSelected = false
    }
}

export class StoreDesignFilters {
    fuseEngine: any
    searchableFilters: StoreDesignGroupFilters[]
    searchableFiltersByDict: Record<string, StoreDesignGroupFilters>
    firstLevelPanelsCollapsed: Record<string, boolean>
    searchValue: string

    constructor(data?: any) {
        this.fuseEngine = null
        this.searchValue = ''
        this.searchableFilters = data
        this.firstLevelPanelsCollapsed = {
            LC: true,
            SG: true,
            TO: true,
            OP: true,
            LP: true,
            RB: true,
            OO: true,
            SV: true,
            DC: true,
            AL: true,
        }

        if (data) {
            this.searchableFiltersByDict = data.reduce(
                (
                    accumulator: Record<string, StoreDesignGroupFilters>,
                    elem: StoreDesignGroupFilters
                ) => {
                    accumulator[elem.id] = elem
                    return accumulator
                },
                {}
            )
        } else {
            this.searchableFiltersByDict = {}
        }
    }

    getSelectedStoreDesignsCount() {
        return this.searchableFilters
            ? this.searchableFilters.reduce(
                  (accumulator: number, sd: StoreDesignGroupFilters) => {
                      accumulator += sd.selectedStoreDesigns.size

                      return accumulator
                  },
                  0
              )
            : 0
    }
}
