import { SolrFields } from '../solr-fields'
import { NRRFilters } from './nrr-filters'

export class RemodelFilters extends NRRFilters {
    selectedRemodelMonths: Set<number>
    selectedRemodelYears: Set<number>

    constructor(data?: any) {
        super()

        this.selectedRemodelMonths = data?.months || new Set()
        this.selectedRemodelYears = data?.years || new Set()
    }

    static getSolrFQFilter = (remodelFilters?: RemodelFilters) => {
        let remodelSolrFq = ''

        if (remodelFilters && remodelFilters.selectedRemodelYears.size) {
            const { selectedRemodelYears, selectedRemodelMonths } =
                remodelFilters

            const solrFilterData = NRRFilters.prepareSolrFQFilter(
                selectedRemodelYears,
                selectedRemodelMonths
            )

            remodelSolrFq = `&fq=${SolrFields.RemodelDate}:(${solrFilterData.nrr})&fq=${SolrFields.OpenDate}:(${solrFilterData.openDate})&fq=${SolrFields.CloseDate}:(${solrFilterData.closeDate})`
        }

        return remodelSolrFq
    }
}
