import { StoresState } from '../states/storesState'
import { StoresDispatchTypes } from '../dispatchers/stores'
import { Store } from '../../models/store'
import { StoresSnapshot } from '../../models/stores-snapshot'

let defaultState = {
    stores: [],
    searchResults: [],
    storesSnapshot: new StoresSnapshot(),
    selectedStore: new Store(),
    cancelTokenSource: null,
    toolbarFuseEngines: null,
    toolbarSearchValue: '',
}

const storesReducer = (state: StoresState = defaultState, action: any) => {
    switch (action.type) {
        case StoresDispatchTypes.SINGLE_STORE_SUCCESS:
            return {
                ...state,
                selectedStore: action.response.selectedStore,
            }

        case StoresDispatchTypes.SEARCH_RESULTS_SUCCESS:
        case StoresDispatchTypes.TOOLBAR_SEARCH_RESULTS_SUCCESS:
            return {
                ...state,
                searchResults: action.response.searchResults,
            }

        case StoresDispatchTypes.SET_TOOLBAR_SEARCH_VALUE_DONE:
            return {
                ...state,
                toolbarSearchValue: action.response.toolbarSearchValue,
            }

        case StoresDispatchTypes.TOOLBAR_INIT_FUSE_SUCCESS:
            return {
                ...state,
                toolbarFuseEngines: action.response.toolbarFuseEngines,
            }

        case StoresDispatchTypes.STORES_REQUEST:
            return {
                ...state,
                cancelTokenSource: action.response.cancelTokenSource,
            }

        case StoresDispatchTypes.STORES_SUCCESS:
            return {
                ...state,
                stores: action.response.stores,
                storesSnapshot: action.response.storesSnapshot,
                cancelTokenSource: action.response.cancelTokenSource,
            }

        case StoresDispatchTypes.STORES_ERROR:
        default:
            return state
    }
}

export default storesReducer
