import { http } from './http'
import { SolrFields } from '../../solr-fields'
import { MacroRegionsDict } from '../../models/macroregions-filters'
import { CountriesDict } from '../../models/countries-filters'
import { ZoneDescsDict } from '../../models/zonedesc-filters'
import { SearchableFiltersDict } from '../../models/searchable-filter'
import { CitiesDict } from '../../models/cities-filters'
import { RegionFilters } from '../../models/region-filters'
import { BUsDict } from '../../models/business-unit-filters'
import {
    HostedRetailDict,
    HostedRetailFilters,
} from '../../models/hosted-retail-filters'
import { FiltersState } from '../../redux/states/filtersState'
import { BannerDescDict } from '../../models/banner-desc-filters'
import { BannerFilters } from '../../models/banner-filters'
import { ChannelOfTradeDict } from '../../models/channel-of-trade-filters'
import { SegmentDict } from '../../models/segment-filters'
import { StoreDesignGroup } from '../../models/store-design-group'
import { RelocationFilters } from '../../models/relocation-filters'
import { NewFilters } from '../../models/new-filters'
import { RemodelFilters } from '../../models/remodel-filters'

/**
 * @export FiltersApi - Just an api interface for claiming stores data with the filters and reducing the code needed for axios http functions
 * @class FiltersApi
 */
export class FiltersApi {
    /**
     * Base Url for the Filters Api
     * @static
     * @type {string}
     * @memberof FiltersApi
     */
    static FILTERS_BASE_URL: string = process.env.REACT_APP_BASE_URL_BE || ''

    /**
     * Endpoint for getting Stores data from the Solr machine
     * @static
     * @type {string}
     * @memberof FiltersApi
     */
    static STORES_FROM_SOLR_ENDP: string = `${FiltersApi.FILTERS_BASE_URL}solr`

    /**
     * Endpoint for getting the banner desc filter
     * @static
     * @type {string}
     * @memberof FiltersApi
     */
    static FILTERS_BASE_QUERY_BD_FROM_SOLR: string = `select?q=*:*&facet=on&facet.limit=-1&facet.sort=count&facet.field=${SolrFields.BannerDesc}&facet.mincount=1&rows=0`

    /**
     * This functions gets all the banner desc available for the current stores
     * @returns Promise of type AxiosPromise
     */
    static GetBannerDesc = async (
        filtersReducer: FiltersState
    ): Promise<BannerDescDict> => {
        try {
            const { bannerFilters, regionFilters, hostedRetailFilters } =
                filtersReducer
            let bannerSolrFq = BannerFilters.getSolrFQFilter(
                bannerFilters,
                new Set(['bd'])
            )
            let regionSolrFq = RegionFilters.getSolrFQFilter(regionFilters)
            let hostedRetailSolrFq =
                HostedRetailFilters.getSolrFQFilter(hostedRetailFilters)

            const nrrFilters = FiltersApi.nrrFiltersbyDate(filtersReducer)

            // Fetch data from Solr
            let response = await http.post(FiltersApi.STORES_FROM_SOLR_ENDP, {
                solr_query: `lux_stores/${FiltersApi.FILTERS_BASE_QUERY_BD_FROM_SOLR}${bannerSolrFq}${regionSolrFq}${hostedRetailSolrFq}${nrrFilters}`,
            })

            // Reduce response to normalize facets in a manageable way
            let bannerDesc = FiltersApi.normalizeFacets(
                response.data?.facet_counts.facet_fields.banner_desc_string
            )
            return bannerDesc
        } catch (error: any) {
            throw error
        }
    }

    /**
     * Endpoint for getting the channel of trade filter
     * @static
     * @type {string}
     * @memberof FiltersApi
     */
    static FILTERS_BASE_QUERY_COT_FROM_SOLR: string = `select?q=*:*&facet=on&facet.limit=-1&facet.sort=count&facet.field=${SolrFields.ChannelOfTrade}&facet.mincount=1&rows=0`

    /**
     * This functions gets all the channel of trade available for the current stores
     * @returns Promise of type AxiosPromise
     */
    static GetChannelOfTrade = async (
        filtersReducer: FiltersState
    ): Promise<ChannelOfTradeDict> => {
        try {
            const { bannerFilters, regionFilters, hostedRetailFilters } =
                filtersReducer
            let bannerSolrFq = BannerFilters.getSolrFQFilter(
                bannerFilters,
                new Set(['cot'])
            )
            let regionSolrFq = RegionFilters.getSolrFQFilter(regionFilters)
            let hostedRetailSolrFq =
                HostedRetailFilters.getSolrFQFilter(hostedRetailFilters)

            const nrrFilters = FiltersApi.nrrFiltersbyDate(filtersReducer)

            // Fetch data from Solr
            let response = await http.post(FiltersApi.STORES_FROM_SOLR_ENDP, {
                solr_query: `lux_stores/${FiltersApi.FILTERS_BASE_QUERY_COT_FROM_SOLR}${regionSolrFq}${hostedRetailSolrFq}${bannerSolrFq}${nrrFilters}&fq=${SolrFields.ChannelOfTrade}:(CORE OR HOSTED OR OUTLET OR \"NO%20CHANNEL%20OF%20TRADE\")`,
            })

            // Reduce response to normalize facets in a manageable way
            const facets = response.data?.facet_counts.facet_fields.channel_of_trade_string.map((el: any) => el === null ? 'No Channel' : el) || []
            // Reduce response to normalize facets in a manageable way
            let channelOfTrade = FiltersApi.normalizeFacets(facets)
            return channelOfTrade
        } catch (error: any) {
            throw error
        }
    }

    /**
     * Endpoint for getting the segment filter
     * @static
     * @type {string}
     * @memberof FiltersApi
     */
    static FILTERS_BASE_QUERY_S_FROM_SOLR: string = `select?q=*:*&facet=on&facet.limit=-1&facet.sort=count&facet.field=${SolrFields.Segment}&facet.mincount=1&rows=0`

    /**
     * This functions gets all the segments available for the current stores
     * @returns Promise of type AxiosPromise
     */
    static GetSegment = async (
        filtersReducer: FiltersState
    ): Promise<SegmentDict> => {
        try {
            const { bannerFilters, regionFilters, hostedRetailFilters } =
                filtersReducer
            let bannerSolrFq = BannerFilters.getSolrFQFilter(
                bannerFilters,
                new Set(['s'])
            )
            let regionSolrFq = RegionFilters.getSolrFQFilter(regionFilters)
            let hostedRetailSolrFq =
                HostedRetailFilters.getSolrFQFilter(hostedRetailFilters)

            const nrrFilters = FiltersApi.nrrFiltersbyDate(filtersReducer)

            // Fetch data from Solr
            let response = await http.post(FiltersApi.STORES_FROM_SOLR_ENDP, {
                solr_query: `lux_stores/${FiltersApi.FILTERS_BASE_QUERY_S_FROM_SOLR}${regionSolrFq}${hostedRetailSolrFq}${bannerSolrFq}${nrrFilters}`,
            })

            // Reduce response to normalize facets in a manageable way
            let segment = FiltersApi.normalizeFacets(
                response.data?.facet_counts.facet_fields.segment_string
            )
            return segment
        } catch (error: any) {
            throw error
        }
    }

    /**
     * Endpoint for getting the Store Design filter
     * @static
     * @type {string}
     * @memberof FiltersApi
     */
    static FILTERS_BASE_QUERY_STD_FROM_SOLR: string = `select?q=*:*&facet=on&facet.limit=-1&facet.sort=count&facet.pivot=${SolrFields.StoreDesignGroup},${SolrFields.StoreDesign}&facet.mincount=1&rows=0`

    /**
     * This functions gets all the stores design available for the current stores
     * @returns Promise of type AxiosPromise
     */
    static GetStoreDesign = async (
        filtersReducer: FiltersState
    ): Promise<StoreDesignGroup[]> => {
        try {
            const { bannerFilters, regionFilters, hostedRetailFilters } =
                filtersReducer
            let bannerSolrFq = BannerFilters.getSolrFQFilter(
                bannerFilters,
                new Set(['sd'])
            )
            let regionSolrFq = RegionFilters.getSolrFQFilter(regionFilters)
            let hostedRetailSolrFq =
                HostedRetailFilters.getSolrFQFilter(hostedRetailFilters)

            const nrrFilters = FiltersApi.nrrFiltersbyDate(filtersReducer)

            // Fetch data from Solr
            let response = await http.post(FiltersApi.STORES_FROM_SOLR_ENDP, {
                solr_query: `lux_stores/${FiltersApi.FILTERS_BASE_QUERY_STD_FROM_SOLR}${regionSolrFq}${hostedRetailSolrFq}${bannerSolrFq}${nrrFilters}`,
            })

            // Reduce response to normalize facets in a manageable way
            let storeDesign = response.data?.facet_counts.facet_pivot[
                `${SolrFields.StoreDesignGroup},${SolrFields.StoreDesign}`
            ] as StoreDesignGroup[]
            return storeDesign
        } catch (error: any) {
            throw error
        }
    }

    /**
     * Endpoint for getting the Main Projects
     * @static
     * @type {string}
     * @memberof FiltersApi
     */
    static FILTERS_MAINPROJECT_ENDP: string =
        FiltersApi.FILTERS_BASE_URL + 'app/mainproject'

    /**
     * Get the Main Projects
     * @returns Promise of type AxiosPromise
     */
    static GetFiltersMainProjects = async (): Promise<string[]> => {
        try {
            let response = await http.post(FiltersApi.FILTERS_MAINPROJECT_ENDP)
            return response.data.mainprojects
        } catch (error: any) {
            throw error
        }
    }

    /**
     * Endpoint for getting the macroareas
     * @static
     * @type {string}
     * @memberof FiltersApi
     */
    static FILTERS_BASE_QUERY_MACROAREAS_FROM_SOLR: string = `select?q=*:*&facet=on&facet.limit=-1&facet.sort=count&facet.field=${SolrFields.MacroRegion}&facet.field=${SolrFields.MacroRegionDesc}&facet.mincount=1&rows=0`

    /**
     * This function gets all the macroareas of the regions available for the current stores
     * @returns Promise of type AxiosPromise
     */
    static GetMacroRegions = async (
        filtersReducer: FiltersState
    ): Promise<MacroRegionsDict> => {
        try {
            const { bannerFilters, hostedRetailFilters } = filtersReducer
            let bannerSolrFq = BannerFilters.getSolrFQFilter(bannerFilters)
            let hostedRetailSolrFq =
                HostedRetailFilters.getSolrFQFilter(hostedRetailFilters)

            const nrrFilters = FiltersApi.nrrFiltersbyDate(filtersReducer)

            let response = await http.post(
                `${FiltersApi.STORES_FROM_SOLR_ENDP}`,
                {
                    solr_query: `lux_stores/${FiltersApi.FILTERS_BASE_QUERY_MACROAREAS_FROM_SOLR}${bannerSolrFq}${hostedRetailSolrFq}${nrrFilters}`,
                }
            )

            // Reduce response to normalize facets in a manageable way
            let mrFacetFields = response.data?.facet_counts.facet_fields
            let macroRegions = mrFacetFields?.macroregion_string.reduce(
                (
                    accumulator: MacroRegionsDict,
                    val: string | number,
                    idx: number
                ) => {
                    const isMRCode: boolean = typeof val === 'string'
                    const previousVal =
                        idx > 0
                            ? mrFacetFields?.macroregion_string[idx - 1]
                            : ''
                    if (isMRCode) {
                        accumulator[val] = {
                            desc: mrFacetFields?.macroregion_desc_string[idx],
                            count: 0,
                            selected: false,
                        }
                    } else {
                        accumulator[previousVal].count = val as number
                    }
                    return accumulator
                },
                {}
            )

            return macroRegions
        } catch (error: any) {
            throw error
        }
    }

    /**
     * Endpoint for getting the countries
     * @static
     * @type {string}
     * @memberof FiltersApi
     */
    static FILTERS_BASE_QUERY_COUNTRIES_FROM_SOLR: string = `select?q=*:*&facet=on&facet.limit=-1&facet.sort=count&facet.field=${SolrFields.CountryDesc}&facet.mincount=1&rows=0`

    /**
     * This function gets all the Countries of the regions available for the current stores.
     * If MacroRegions are selected, then returned Countries will be only related to those MacroRegions.
     * @returns Promise of type AxiosPromise
     */
    static GetCountries = async (
        filtersReducer: FiltersState
    ): Promise<CountriesDict> => {
        try {
            const { bannerFilters, regionFilters, hostedRetailFilters } =
                filtersReducer

            let bannerSolrFq = BannerFilters.getSolrFQFilter(bannerFilters)
            let hostedRetailSolrFq =
                HostedRetailFilters.getSolrFQFilter(hostedRetailFilters)
            // Reduce selectedMacroRegions to construct an "fq" filter for Solr
            var countriesSolrFq =
                '&fq=' +
                regionFilters.macroregionsFilters.getSolrFQFilter(
                    regionFilters.macroregionsFilters.selectedMacroRegions,
                    SolrFields.MacroRegion
                )

            const nrrFilters = FiltersApi.nrrFiltersbyDate(filtersReducer)

            // Fetch data from Solr
            let response = await http.post(
                `${FiltersApi.STORES_FROM_SOLR_ENDP}`,
                {
                    solr_query: `lux_stores/${FiltersApi.FILTERS_BASE_QUERY_COUNTRIES_FROM_SOLR}${bannerSolrFq}${hostedRetailSolrFq}${countriesSolrFq}${nrrFilters}`,
                }
            )

            // Reduce response to normalize facets in a manageable way
            let countries = FiltersApi.normalizeFacets(
                response.data?.facet_counts.facet_fields.country_desc_string
            )
            return countries
        } catch (error: any) {
            throw error
        }
    }

    /**
     * Endpoint for getting the zone descriptions
     * @static
     * @type {string}
     * @memberof FiltersApi
     */
    static FILTERS_BASE_QUERY_ZONE_DESCS_FROM_SOLR: string = `select?q=*:*&facet=on&facet.limit=-1&facet.sort=count&facet.field=${SolrFields.ZoneDesc}&facet.mincount=1&rows=0`

    /**
     * This functions gets all the zone descriptions of the regions available for the current stores
     * If Countries are selected, then returned ZoneDesc will be only related to those Countries.
     * @returns Promise of type AxiosPromise
     */
    static GetZoneDescs = async (
        filtersReducer: FiltersState
    ): Promise<ZoneDescsDict> => {
        try {
            const { bannerFilters, regionFilters, hostedRetailFilters } =
                filtersReducer

            let bannerSolrFq = BannerFilters.getSolrFQFilter(bannerFilters)
            let hostedRetailSolrFq =
                HostedRetailFilters.getSolrFQFilter(hostedRetailFilters)
            // Reduce selectedCountries to construct an "fq" filter for Solr
            const zoneDescsSolrFq =
                '&fq=' +
                regionFilters.macroregionsFilters.getSolrFQFilter(
                    regionFilters.macroregionsFilters.selectedMacroRegions,
                    SolrFields.MacroRegion
                ) +
                '&fq=' +
                regionFilters.countriesFilters.getSolrFQFilter(
                    regionFilters.countriesFilters.selectedCountries,
                    SolrFields.CountryDesc
                )

            const nrrFilters = FiltersApi.nrrFiltersbyDate(filtersReducer)

            // Fetch data from Solr
            let response = await http.post(
                `${FiltersApi.STORES_FROM_SOLR_ENDP}`,
                {
                    solr_query: `lux_stores/${FiltersApi.FILTERS_BASE_QUERY_ZONE_DESCS_FROM_SOLR}${bannerSolrFq}${hostedRetailSolrFq}${zoneDescsSolrFq}${nrrFilters}`,
                }
            )

            // Reduce response to normalize facets in a manageable way
            let zoneDescs = FiltersApi.normalizeFacets(
                response.data?.facet_counts.facet_fields.zone_desc_string
            )
            return zoneDescs
        } catch (error: any) {
            throw error
        }
    }

    /**
     * Endpoint for getting the cities
     * @static
     * @type {string}
     * @memberof FiltersApi
     */
    static FILTERS_BASE_QUERY_CITIES_FROM_SOLR: string = `select?q=*:*&facet=on&facet.limit=-1&facet.sort=count&facet.field=${SolrFields.City}&facet.mincount=1&rows=0`

    /**
     * This functions gets all the cities of the regions available for the current stores
     * If Zones are selected, then returned City will be only related to those Zones.
     * @returns Promise of type AxiosPromise
     */
    static GetCities = async (
        filtersReducer: FiltersState
    ): Promise<CitiesDict> => {
        try {
            const { bannerFilters, regionFilters, hostedRetailFilters } =
                filtersReducer

            let bannerSolrFq = BannerFilters.getSolrFQFilter(bannerFilters)
            let hostedRetailSolrFq =
                HostedRetailFilters.getSolrFQFilter(hostedRetailFilters)
            // Reduce selectedZoneDescs to construct an "fq" filter for Solr
            const citiesSolrFq =
                '&fq=' +
                regionFilters.macroregionsFilters.getSolrFQFilter(
                    regionFilters.macroregionsFilters.selectedMacroRegions,
                    SolrFields.MacroRegion
                ) +
                '&fq=' +
                regionFilters.countriesFilters.getSolrFQFilter(
                    regionFilters.countriesFilters.selectedCountries,
                    SolrFields.CountryDesc
                ) +
                '&fq=' +
                regionFilters.zoneDescsFilters.getSolrFQFilter(
                    regionFilters.zoneDescsFilters.selectedZoneDescs,
                    SolrFields.ZoneDesc
                )

            const nrrFilters = FiltersApi.nrrFiltersbyDate(filtersReducer)

            // Fetch data from Solr
            let response = await http.post(
                `${FiltersApi.STORES_FROM_SOLR_ENDP}`,
                {
                    solr_query: `lux_stores/${FiltersApi.FILTERS_BASE_QUERY_CITIES_FROM_SOLR}${bannerSolrFq}${hostedRetailSolrFq}${citiesSolrFq}${nrrFilters}`,
                }
            )

            // Reduce response to normalize facets in a manageable way
            let cities = FiltersApi.normalizeFacets(
                response.data?.facet_counts.facet_fields.city_string
            )
            return cities
        } catch (error: any) {
            throw error
        }
    }

    /**
     * Endpoint for getting the business units
     * @static
     * @type {string}
     * @memberof FiltersApi
     */
    static FILTERS_BASE_QUERY_BU_FROM_SOLR: string = `select?q=*:*&facet=on&facet.limit=-1&facet.sort=count&facet.field=${SolrFields.BusinessUnit}&facet.mincount=1&rows=0`

    /**
     * This functions gets all the business units of the regions available for the current stores
     * @returns Promise of type AxiosPromise
     */
    static GetBUs = async (filtersReducer: FiltersState): Promise<BUsDict> => {
        try {
            const { bannerFilters, regionFilters, hostedRetailFilters } =
                filtersReducer

            let bannerSolrFq = BannerFilters.getSolrFQFilter(bannerFilters)
            let regionSolrFq = RegionFilters.getSolrFQFilter(regionFilters)
            let hostedRetailSolrFq =
                HostedRetailFilters.getSolrFQFilter(hostedRetailFilters)

            const nrrFilters = FiltersApi.nrrFiltersbyDate(filtersReducer)

            // Fetch data from Solr
            let response = await http.post(
                `${FiltersApi.STORES_FROM_SOLR_ENDP}`,
                {
                    solr_query: `lux_stores/${FiltersApi.FILTERS_BASE_QUERY_BU_FROM_SOLR}${bannerSolrFq}${hostedRetailSolrFq}${regionSolrFq}${nrrFilters}`,
                }
            )

            // Reduce response to normalize facets in a manageable way
            let businessUnits = FiltersApi.normalizeFacets(
                response.data?.facet_counts.facet_fields.business_unit_string
            )
            return businessUnits
        } catch (error: any) {
            throw error
        }
    }

    /**
     * Endpoint for getting the hosted retail filter
     * @static
     * @type {string}
     * @memberof FiltersApi
     */
    static FILTERS_BASE_QUERY_HR_FROM_SOLR: string = `select?q=*:*&facet=on&facet.limit=-1&facet.sort=count&facet.field=${SolrFields.HostedRetail}&facet.mincount=1&rows=0`

    /**
     * This functions gets all the hosted retail available for the current stores
     * @returns Promise of type AxiosPromise
     */
    static GetHostedRetail = async (
        filtersReducer: FiltersState
    ): Promise<HostedRetailDict> => {
        try {
            const { bannerFilters, regionFilters } = filtersReducer
            let bannerDescSolrFq = BannerFilters.getSolrFQFilter(bannerFilters)
            let regionSolrFq = RegionFilters.getSolrFQFilter(regionFilters)

            const nrrFilters = FiltersApi.nrrFiltersbyDate(filtersReducer)

            // Fetch data from Solr
            let response = await http.post(
                `${FiltersApi.STORES_FROM_SOLR_ENDP}`,
                {
                    solr_query: `lux_stores/${FiltersApi.FILTERS_BASE_QUERY_HR_FROM_SOLR}${bannerDescSolrFq}${regionSolrFq}${nrrFilters}`,
                }
            )

            // Reduce response to normalize facets in a manageable way
            let hostedRetail = FiltersApi.normalizeFacets(
                response.data?.facet_counts.facet_fields.store_host_name_string
            )
            return hostedRetail
        } catch (error: any) {
            throw error
        }
    }

    /**
     * This functions gets the facets received by a Solr query and normalize it to be used within the Picture Viewer
     * @returns object containing of the normalized facets
     */
    public static normalizeFacets = (facets: any): {} => {
        // Reduce response to normalize facets in a manageable way
        let normalizedFacets = facets.reduce(
            (
                accumulator: SearchableFiltersDict,
                val: string | number,
                idx: number
            ) => {
                const isCode: boolean = typeof val === 'string' || !val
                const previousVal = idx > 0 ? facets[idx - 1] : ''

                const isAnEmptyFacet = isCode ? !val : !previousVal
                if (isAnEmptyFacet) return accumulator

                if (isCode) {
                    accumulator[val] = { count: 0, selected: false }
                } else {
                    accumulator[previousVal].count = val as number
                }
                return accumulator
            },
            {}
        )

        return normalizedFacets
    }

    private static nrrFiltersbyDate(filtersReducer: FiltersState) {
        const { remodelFilters, relocationFilters, newFilters } = filtersReducer
        const { selectedRemodelYears } = remodelFilters
        const { selectedRelocationYears } = relocationFilters
        const { selectedNewYears } = newFilters

        const dateNow = new Date()

        let dateFqFilter = ''
        if (
            selectedRemodelYears.size < 1 &&
            selectedRelocationYears.size < 1 &&
            selectedNewYears.size < 1
        ) {
            dateFqFilter += `&fq=${SolrFields.OpenDate
                }:([* TO ${dateNow.getTime()}])&fq=${SolrFields.CloseDate
                }:([${dateNow.getTime()} TO *])`
        }

        // Fetch data from Solr
        return `${RemodelFilters.getSolrFQFilter(
            remodelFilters
        )}${RelocationFilters.getSolrFQFilter(
            relocationFilters
        )}${NewFilters.getSolrFQFilter(newFilters)}${dateFqFilter}`
    }
}
