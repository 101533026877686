import { connect } from 'react-redux'
import { injectIntl } from 'react-intl'
import { AppState } from '../../redux/states/appState'
import { SidebarMenuKind } from '../../models/sidebar-menu-kind'
import {
    clearAllBannerFilters,
    clearAllHostedRetailFilters,
    clearAllNewFilters,
    clearAllRegionFilters,
    clearAllRelocationFilters,
    clearAllRemodelFilters,
    clearAllSurveyDateFilters,
    setSelectedSidebarMenu,
} from '../../redux/actions/filters'
import FilterToggleButton from '../../components/filter/filter-toggle-button'

const Filters = (props: any) => {
    const { intl } = props

    const {
        bannerFilters,
        regionFilters,
        remodelFilters,
        relocationFilters,
        newFilters,
        hostedRetailFilters,
        surveyDateFilters,
    } = props

    const {
        bannerDescFilters,
        channelOfTradeFilters,
        segmentFilters,
        storeDesignFilters,
        selectionBestFilters,
    } = bannerFilters

    const {
        macroregionsFilters,
        countriesFilters,
        zoneDescsFilters,
        citiesFilters,
        busFilters,
    } = regionFilters

    const selectedBannerFiltersCount =
        bannerDescFilters.selectedBannerDesc.size +
        channelOfTradeFilters.selectedChannelOfTrade.size +
        hostedRetailFilters.selectedHostedRetail.size +
        segmentFilters.selectedSegment.size +
        storeDesignFilters.getSelectedStoreDesignsCount() +
        selectionBestFilters.getSelectedSelectionBestCount()

    const selectedRegionFiltersCount =
        macroregionsFilters.selectedMacroRegions.size +
        countriesFilters.selectedCountries.size +
        zoneDescsFilters.selectedZoneDescs.size +
        citiesFilters.selectedCities.size +
        busFilters.selectedBUs.size

    const selectedRemodelFiltersCount =
        remodelFilters.selectedRemodelMonths.size +
        remodelFilters.selectedRemodelYears.size

    const selectedRelocationFiltersCount =
        relocationFilters.selectedRelocationMonths.size +
        relocationFilters.selectedRelocationYears.size

    const selectedNewFiltersCount =
        newFilters.selectedNewMonths.size + newFilters.selectedNewYears.size

    const selectedHostedRetailFiltersCount =
        hostedRetailFilters.selectedHostedRetail.size

    const selectedSurveyDateFiltersCount =
        (surveyDateFilters.selectedFrom != null ? 1 : 0) +
        (surveyDateFilters.selectedTo != null ? 1 : 0)

    const isCountGreaterThanZero = (value: number) =>
        value && value > 0 ? true : false

    const isBannerFilterChecked: boolean =
        props.selectedSidebarMenu === SidebarMenuKind.BANNER ||
        isCountGreaterThanZero(selectedBannerFiltersCount)
    const isRegionFilterChecked: boolean =
        props.selectedSidebarMenu === SidebarMenuKind.REGION ||
        isCountGreaterThanZero(selectedRegionFiltersCount)
    const isRemodelFilterChecked: boolean =
        props.selectedSidebarMenu === SidebarMenuKind.REMODEL ||
        isCountGreaterThanZero(selectedRemodelFiltersCount)
    const isRelocationFilterChecked: boolean =
        props.selectedSidebarMenu === SidebarMenuKind.RELOCATION ||
        isCountGreaterThanZero(selectedRelocationFiltersCount)
    const isNewFilterChecked: boolean =
        props.selectedSidebarMenu === SidebarMenuKind.NEW ||
        isCountGreaterThanZero(selectedNewFiltersCount)
    const isHostedRetailFilterChecked: boolean =
        props.selectedSidebarMenu === SidebarMenuKind.HOSTED_RETAIL ||
        isCountGreaterThanZero(selectedHostedRetailFiltersCount)
    const isSurveyDateFilterChecked: boolean =
        props.selectedSidebarMenu === SidebarMenuKind.SURVEY_DATE ||
        isCountGreaterThanZero(selectedSurveyDateFiltersCount)
    const isMainProjectFilterChecked: boolean =
        props.selectedSidebarMenu === SidebarMenuKind.MAIN_PROJECT

    return (
        <>
            <FilterToggleButton
                checked={isBannerFilterChecked}
                onChange={() =>
                    props.setSelectedSidebarMenu(SidebarMenuKind.BANNER)
                }
                onClearAll={(e: any) => props.clearAllBannerFilters()}
                label={intl.formatMessage({ id: 'toolbar.banner.button' })}
                labelSuffix={
                    selectedBannerFiltersCount
                        ? ` (${selectedBannerFiltersCount})`
                        : ''
                }
            />

            <FilterToggleButton
                checked={isRegionFilterChecked}
                onChange={() =>
                    props.setSelectedSidebarMenu(SidebarMenuKind.REGION)
                }
                onClearAll={(e: any) => props.clearAllRegionFilters()}
                label={intl.formatMessage({ id: 'toolbar.region.button' })}
                labelSuffix={
                    selectedRegionFiltersCount
                        ? ` (${selectedRegionFiltersCount})`
                        : ''
                }
            />

            <FilterToggleButton
                checked={isRemodelFilterChecked}
                onChange={() =>
                    props.setSelectedSidebarMenu(SidebarMenuKind.REMODEL)
                }
                onClearAll={(e: any) => props.clearAllRemodelFilters()}
                label={intl.formatMessage({ id: 'toolbar.remodel.button' })}
                labelSuffix={
                    selectedRemodelFiltersCount
                        ? ` (${selectedRemodelFiltersCount})`
                        : ''
                }
            />

            <FilterToggleButton
                checked={isRelocationFilterChecked}
                onChange={() =>
                    props.setSelectedSidebarMenu(SidebarMenuKind.RELOCATION)
                }
                onClearAll={(e: any) => props.clearAllRelocationFilters()}
                label={intl.formatMessage({ id: 'toolbar.relocation.button' })}
                labelSuffix={
                    selectedRelocationFiltersCount
                        ? ` (${selectedRelocationFiltersCount})`
                        : ''
                }
            />

            <FilterToggleButton
                checked={isNewFilterChecked}
                onChange={() =>
                    props.setSelectedSidebarMenu(SidebarMenuKind.NEW)
                }
                onClearAll={(e: any) => props.clearAllNewFilters()}
                label={intl.formatMessage({ id: 'toolbar.new.button' })}
                labelSuffix={
                    selectedNewFiltersCount
                        ? ` (${selectedNewFiltersCount})`
                        : ''
                }
            />

            <FilterToggleButton
                checked={isHostedRetailFilterChecked}
                onChange={() =>
                    props.setSelectedSidebarMenu(SidebarMenuKind.HOSTED_RETAIL)
                }
                onClearAll={(e: any) => props.clearAllHostedRetailFilters()}
                label={intl.formatMessage({
                    id: 'toolbar.hosted_retail.button',
                })}
                labelSuffix={
                    selectedHostedRetailFiltersCount
                        ? ` (${selectedHostedRetailFiltersCount})`
                        : ''
                }
            />

            <FilterToggleButton
                checked={isSurveyDateFilterChecked}
                onChange={() =>
                    props.setSelectedSidebarMenu(SidebarMenuKind.SURVEY_DATE)
                }
                onClearAll={(e: any) => props.clearAllSurveyDateFilters()}
                label={intl.formatMessage({ id: 'toolbar.survey_date.button' })}
                labelSuffix={
                    selectedSurveyDateFiltersCount
                        ? ` (${selectedSurveyDateFiltersCount})`
                        : ''
                }
            />

            <FilterToggleButton
                checked={isMainProjectFilterChecked}
                onChange={() =>
                    props.setSelectedSidebarMenu(SidebarMenuKind.MAIN_PROJECT)
                }
                onClearAll={() =>
                    props.setSelectedSidebarMenu(SidebarMenuKind.NONE)
                }
                label={intl.formatMessage({
                    id: 'toolbar.main_project.button',
                })}
                labelSuffix={''}
            />
        </>
    )
}

const mapStateToProps = (state: AppState) => ({
    selectedSidebarMenu: state.filtersReducer.selectedSidebarMenu,
    bannerFilters: state.filtersReducer.bannerFilters,
    regionFilters: state.filtersReducer.regionFilters,
    remodelFilters: state.filtersReducer.remodelFilters,
    relocationFilters: state.filtersReducer.relocationFilters,
    newFilters: state.filtersReducer.newFilters,
    hostedRetailFilters: state.filtersReducer.hostedRetailFilters,
    surveyDateFilters: state.filtersReducer.surveyDateFilters,
})

const mapDispatchToProps = (dispatch: any) => ({
    setSelectedSidebarMenu: (newSelectedFiltersMenu: SidebarMenuKind) =>
        dispatch(setSelectedSidebarMenu(newSelectedFiltersMenu)),
    clearAllBannerFilters: () => dispatch(clearAllBannerFilters()),
    clearAllRegionFilters: () => dispatch(clearAllRegionFilters()),
    clearAllRemodelFilters: () => dispatch(clearAllRemodelFilters()),
    clearAllRelocationFilters: () => dispatch(clearAllRelocationFilters()),
    clearAllNewFilters: () => dispatch(clearAllNewFilters()),
    clearAllHostedRetailFilters: () => dispatch(clearAllHostedRetailFilters()),
    clearAllSurveyDateFilters: () => dispatch(clearAllSurveyDateFilters()),
})

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(Filters))
