import { SolrFields } from '../solr-fields'
import { NRRFilters } from './nrr-filters'

export class RelocationFilters extends NRRFilters {
    selectedRelocationMonths: Set<number>
    selectedRelocationYears: Set<number>

    constructor(data?: any) {
        super()

        this.selectedRelocationMonths = data?.months || new Set()
        this.selectedRelocationYears = data?.years || new Set()
    }

    static getSolrFQFilter = (relocationFilters?: RelocationFilters) => {
        let relocationSolrFq = ''

        if (
            relocationFilters &&
            relocationFilters.selectedRelocationYears.size
        ) {
            const {
                selectedRelocationYears,
                selectedRelocationMonths,
            } = relocationFilters

            const solrFilterData = NRRFilters.prepareSolrFQFilter(
                selectedRelocationYears,
                selectedRelocationMonths
            )

            relocationSolrFq = `&fq=${SolrFields.RelocationDate}:(${solrFilterData.nrr})&fq=${SolrFields.OpenDate}:(${solrFilterData.openDate})&fq=${SolrFields.CloseDate}:(${solrFilterData.closeDate})`
        }

        return relocationSolrFq
    }
}
