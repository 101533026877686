import { injectIntl } from 'react-intl'
//import moment from 'moment'
import { StyledHeaders } from './StyledHeader'
import MonthDatePicker from '../../month-date-picker/MonthDatePicker'
import IconButton from '../../icon-button/IconButton'
import { useWindowResize } from '../../../utils/commonFunctions'
import { Photo } from '../../../models/photo-list'
import React, { useEffect, useRef, useState } from 'react'
import { StyledBlueButtonContainer } from '../../filter/filter-searchstores-clear-section/style'
import { Card } from 'primereact/card'
import { StyledColumn } from '../general-info/StyledColumn'
import { ExportDeckType } from '../../../models/export-deck-type'
import { Menu } from 'primereact/menu'
import { Moment } from 'moment'

type MomentDateWrapper = { original: Moment; from: number; to: number }

function isMonthFilter(date: MomentDateWrapper) {
    return date.to - date.from < 2629800000 * 2
}

const StoreHeader = (props: any) => {
    const {
        dates,
        downloadPhotos,
        setDates,
        images,
        intl,
        isApp,
        photoList,
        setShouldShowPhotosSelection,
        exportStoreDeck,
        loadingExportStoreDeck,
        macroareas,
    } = props

    const [isDeckMenuShown, setIsDeckMenuShown] = useState<boolean>(false)

    const photoListUrls: Array<string> = photoList.map((photo: Photo) => {
        return photo.url
    })

    // hide second release components (to remove in second release)
    const isSecondRelease = false

    // convert date to local time zone and show month and year
    //const galleryDate = moment(date.original).format('MMMM YYYY')

    const isMobileNow = useWindowResize()

    const menu = useRef<any>(null)
    const monthDatePickerRef = useRef<any>();

    const [isMenuOpen, setIsMenuOpen] = useState<boolean>(false)
    const [isMacroareaSelected, setIsMacroareaSelected] = useState<boolean>(false)

    useEffect(() => {
        // Changes to the dates or macroareas selections should disable the manual selection of pictures to export the photos deck
        setShouldShowPhotosSelection(false)
    }, [dates, macroareas, setShouldShowPhotosSelection])

    useEffect(() => {
        if (isMenuOpen) {
            // If the macroarea menu just opened, we should close the other menus
            monthDatePickerRef.current?.closeOpenCalendar()
            setIsDeckMenuShown(false)
        }
    }, [isMenuOpen])

    useEffect(() => {
        let _macroareas = [...macroareas].slice(1)
        if (_macroareas.length) {
            let result = _macroareas.find(obj => {
                return obj.className === 'active'
            })
            if (result) {setIsMacroareaSelected(true)}
            else {setIsMacroareaSelected(false)}
        } else {
            setIsMacroareaSelected(false)
        }
    }, [macroareas])

    useEffect(() => {
        if (isDeckMenuShown) {
            // If the export menu just opened, we should close the other menus
            monthDatePickerRef.current?.closeOpenCalendar()
            setIsMenuOpen(false)
        }
    }, [isDeckMenuShown])

    return (
        <StyledHeaders>
            <div className="p-grid p-align-center">
                <div className="p-col-9">
                    <span className="p-d-flex h2">
                        {/*<h2 className="p-text-uppercase p-text-bold storeHeaderH2">
                            {galleryDate}
                        </h2>
                        <h2 className="p-ml-1 storeHeaderH2">
                            {' '}
                            ({images.count}{' '}
                            {intl.formatMessage({ id: 'store_detail.images' })})
                        </h2>*/}
                        <h2 className="p-text-uppercase p-text-bold storeHeaderH2">
                            {images.count}{' '}
                            {intl.formatMessage({
                                id: 'store_detail.images',
                            })}
                        </h2>
                    </span>
                </div>
                <div className="p-col-3 p-d-flex p-jc-end">
                    <div className="p-row p-d-flex p-jr-end">
                        <IconButton
                            icon="sliders-h"
                            tooltipIntl={
                                isMenuOpen
                                    ? 'button.filter.macroarea.close'
                                    : 'button.filter.macroarea'
                            }
                            onClick={(event: any) => menu.current.toggle(event)}
                            style={{
                                backgroundColor: `${isMacroareaSelected ? 'rgb(64, 114, 238)' : ''}`,
                                borderColor: `${isMacroareaSelected ? 'rgb(64, 114, 238)' : ''}`,
                                borderRadius: `${isMacroareaSelected ? '45%' : ''}`,
                                color: `${isMacroareaSelected ? 'white' : ''}`
                            }}
                        />
                        <Menu
                            popup
                            ref={menu}
                            model={macroareas}
                            className={'p-menu-macroareas'}
                            onShow={() => setIsMenuOpen(true)}
                            onHide={() => setIsMenuOpen(false)}
                        />
                    </div>
                    <MonthDatePicker
                        ref={monthDatePickerRef}
                        dates={dates}
                        setDates={setDates}
                        photoList={photoList}
                        onCalendarOpenChange={((isOpen: boolean) => {
                            // If the calendar menu just opened, we should close the other menus
                            if (isOpen) {
                                setIsDeckMenuShown(false)
                                setIsMenuOpen(false)
                            }
                        })}
                    />
                    {!isMobileNow &&
                        (isApp ? (
                            <IconButton
                                icon="download"
                                tooltipIntl="store_detail.download_photos"
                                onClick={() =>
                                    (
                                        window as any
                                    ).webkit.messageHandlers.multipleDownload.postMessage(
                                        photoListUrls
                                    )
                                }
                            />
                        ) : (
                            <IconButton
                                icon="download"
                                tooltipIntl="store_detail.download_photos"
                                onClick={downloadPhotos}
                            />
                        ))}
                    <div className="p-row p-d-flex p-jr-end">
                        <IconButton
                            icon="images"
                            tooltipIntl="button.export_photos.text"
                            onClick={() => setIsDeckMenuShown((prev) => !prev)}
                        />
                    </div>
                    {isDeckMenuShown && (
                        <StyledColumn
                            style={{
                                position: 'absolute',
                                zIndex: 1000,
                                marginTop: 30,
                                width: 365,
                            }}>
                            <Card className="card">
                                <div className="p-grid">
                                    <StyledBlueButtonContainer
                                        disabled={loadingExportStoreDeck}
                                        style={{
                                            marginTop: 8,
                                            marginBottom: 8,
                                            fontWeight: 500,
                                            textAlign: 'left',
                                        }}
                                        onClick={(e: any) => {
                                            const storearea = macroareas.find(
                                                (ma: any) =>
                                                    ma.className.includes(
                                                        'active'
                                                    )
                                            )?.value
                                            const formattedDates = dates
                                                .filter(isMonthFilter)
                                                .map(
                                                    (date: MomentDateWrapper) =>
                                                        date.original.format(
                                                            'MM/YYYY'
                                                        )
                                                )

                                            exportStoreDeck(
                                                ExportDeckType.LAST_20,
                                                storearea !== 'All'
                                                    ? storearea
                                                    : undefined,
                                                formattedDates.length > 0
                                                    ? formattedDates
                                                    : undefined
                                            )
                                        }}>
                                        {intl.formatMessage({
                                            id: 'button.export_20.text',
                                        })}
                                    </StyledBlueButtonContainer>
                                    <StyledBlueButtonContainer
                                        disabled={loadingExportStoreDeck}
                                        style={{
                                            marginTop: 8,
                                            marginBottom: 8,
                                            fontWeight: 500,
                                            textAlign: 'left',
                                        }}
                                        onClick={(e: any) => {
                                            const storearea = macroareas.find(
                                                (ma: any) =>
                                                    ma.className.includes(
                                                        'active'
                                                    )
                                            )?.value
                                            const formattedDates = dates
                                                .filter(isMonthFilter)
                                                .map(
                                                    (date: MomentDateWrapper) =>
                                                        date.original.format(
                                                            'MM/YYYY'
                                                        )
                                                )

                                            exportStoreDeck(
                                                ExportDeckType.LAST_40,
                                                storearea !== 'All'
                                                    ? storearea
                                                    : undefined,
                                                formattedDates.length > 0
                                                    ? formattedDates
                                                    : undefined
                                            )
                                        }}>
                                        {intl.formatMessage({
                                            id: 'button.export_40.text',
                                        })}
                                    </StyledBlueButtonContainer>
                                    <StyledBlueButtonContainer
                                        disabled={loadingExportStoreDeck}
                                        style={{
                                            marginTop: 8,
                                            marginBottom: 8,
                                            fontWeight: 500,
                                            textAlign: 'left',
                                        }}
                                        onClick={(e: any) => {
                                            setShouldShowPhotosSelection(true)
                                            setIsDeckMenuShown(false)
                                        }}>
                                        {intl.formatMessage({
                                            id: 'button.export_selected.text',
                                        })}
                                    </StyledBlueButtonContainer>
                                </div>
                            </Card>
                        </StyledColumn>
                    )}
                </div>
                {isSecondRelease && (
                    <span className="p-d-flex h3 p-col-12 p-py-0">
                        <h3 className="p-text-uppercase">
                            {intl.formatMessage({
                                id: 'store_detail.next_survey',
                            })}
                            :
                        </h3>
                        <h3 className="p-ml-1 p-text-bold"> GG/MM/AAAA</h3>
                    </span>
                )}
            </div>
        </StyledHeaders>
    )
}
export default React.memo(injectIntl(StoreHeader))
