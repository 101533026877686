import React from 'react'
import MarkerIcon from '../marker-icon'
import { Colors } from '../../../colors'
import { StyledSingleMarkerContainer } from './style'

function SingleMarker({
    lat,
    lng,
    onClick,
    type,
}: {
    lat: number
    lng: number
    onClick: (event: React.MouseEvent<SVGSVGElement>) => void
    type: string
}) {
    let iconColor
    switch (type) {
        case 'HOSTED':
            iconColor = Colors.hostedChannelColor
            break
        case 'OUTLET':
            iconColor = Colors.outletChannelColor
            break
        case 'CORE':
            iconColor = Colors.coreChannelColor
            break
        case 'NO CHANNEL OF TRADE':
            iconColor = Colors.grey3
            break
        default:
            iconColor = Colors.grey0
            break
    }
    return (
        <StyledSingleMarkerContainer>
            <MarkerIcon onClick={onClick} color={iconColor} />
        </StyledSingleMarkerContainer>
    )
}

export default SingleMarker
