import { useEffect, useState } from 'react'
import React from 'react'
import { FormattedMessage } from 'react-intl'
import {
    SearchContainer,
    SearchInput,
    StyledSearchIcon,
    SearchResults,
    SearchingForContainer,
    SearchingForTitle,
} from './style'
import { ProgressSpinner } from 'primereact/progressspinner'
import { Interweave } from 'interweave'
import styled from 'styled-components'
import { ReactComponent as CloseIcon } from '../../utils/icons/elements-icon-close.svg'
import SquaredCheckbox from '../squared-checkbox'
import { Store } from '../../models/store'
import { SolrFields } from '../../solr-fields'

export const StyledProgressSpinner = styled(ProgressSpinner)`
  /* center progress spinner */
  position: absolute;
  margin-top: -28px !important;
}`

let currentThread: NodeJS.Timeout | null = null

type AutoCompletionResult = Record<string, string>

type SearchProps = {
    loading?: boolean
    placeholder: string
    searchValue: string
    setSearchValue: (searchValue: string) => any
    toolbarFuseEngines?: any
    toolbarInitFuse?: Function
    searchingForDataSource?: { key: string }[]
    searchHandler?: Function
    searchHandlerNoSidebar?: Function
}

const Search = (props: SearchProps) => {
    var searchInputRef: HTMLInputElement

    const [showSearchResults, setShowSearchResults] = useState(false)
    const [showAdvancedSearch, setShowAdvancedSearch] = useState(false)
    const [nonAdvancedSearchResultsStores, setNonAdvancedSearchResultsStores] =
        useState<Store[]>([])

    useEffect(() => {
        if (searchInputRef) {
            searchInputRef.value = props.searchValue
        }

        if (props.searchingForDataSource) {
            if (!props.toolbarFuseEngines && props.toolbarInitFuse) {
                props.toolbarInitFuse()
            }
        }

        if (props.searchValue) searchInputRef.focus({ preventScroll: true })

        return () => {
            if (currentThread != null) clearTimeout(currentThread)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const resetSearchValue = () => {
        searchInputRef.value = ''
        props.setSearchValue('')
    }

    const onKeyUp = (e: any) => {
        props.setSearchValue(e.target.value)
        setShowSearchResults(true)
        if (!showAdvancedSearch) {
            if (currentThread != null) clearTimeout(currentThread)

            currentThread = props?.searchHandlerNoSidebar
                ? setTimeout(async () => {
                      const result = await props.searchHandlerNoSidebar?.()
                      if (result?.success?.searchResults)
                          setNonAdvancedSearchResultsStores(
                              result.success.searchResults
                          )
                  }, 250)
                : null
        }
    }

    const onFocus = (e: any) => {
        setShowSearchResults(true)
    }

    const onKeyPress = (e: any) => {
        if (e.key === 'Enter') {
            props.searchHandler?.()
        }
    }

    return (
        <>
            <div
                className={showSearchResults ? 'onFocus' : ''}
                style={{ transition: '0.25s' }}>
                {/*props.loading && <StyledProgressSpinner />*/}
                <SearchContainer>
                    <StyledSearchIcon />
                    <SearchInput
                        // disabled={props.loading}
                        placeholder={props.placeholder}
                        ref={(input) =>
                            (searchInputRef = input as HTMLInputElement)
                        }
                        onKeyUp={onKeyUp}
                        onFocus={onFocus}
                        onKeyPress={onKeyPress}
                    />
                    {props.searchValue && (
                        <CloseIcon
                            className="closeSearchbar"
                            onClick={(e: any) => resetSearchValue()}
                        />
                    )}
                </SearchContainer>
                {props.searchingForDataSource?.length &&
                    showSearchResults &&
                    props.searchValue.length > 0 && (
                        <SearchResults>
                            <SearchingForContainer className="p-grid searchingForContainer">
                                <div
                                    style={{
                                        display: 'flex',
                                        flexDirection: 'row',
                                        columnGap: '0.875rem',
                                    }}>
                                    <SearchingForTitle>
                                        <FormattedMessage
                                            id={
                                                'search.search_mode.by_attribute.text'
                                            }
                                        />
                                    </SearchingForTitle>
                                    <div style={{ marginTop: -4 }}>
                                        <SquaredCheckbox
                                            checked={showAdvancedSearch}
                                            onChange={() =>
                                                setShowAdvancedSearch(
                                                    !showAdvancedSearch
                                                )
                                            }
                                        />
                                    </div>
                                </div>

                                {showAdvancedSearch
                                    ? props.searchingForDataSource.map(
                                          (elem: any, idx: number) => (
                                              <div
                                                  className="p-col-12"
                                                  key={idx}
                                                  onClick={(e: any) => {
                                                      props.searchHandler?.(
                                                          elem.solrProp
                                                      )
                                                      setShowSearchResults(
                                                          false
                                                      )
                                                  }}>
                                                  <Interweave
                                                      content={elem.key.replace(
                                                          'REPLWITH',
                                                          props.searchValue
                                                      )}
                                                      noHtml
                                                  />
                                              </div>
                                          )
                                      )
                                    : Object.entries(
                                          nonAdvancedSearchResultsStores
                                              // We only need the autocompletion part here, not the stores, so we flatMap to use only that
                                              .flatMap(
                                                  (store: Store) =>
                                                      store.search_matched ?? []
                                              )
                                              // Remove duplicated solrProps by using a dictionary
                                              .reduce((acc, sm) => {
                                                  acc[sm.value] = sm.key
                                                  return acc
                                              }, {} as AutoCompletionResult)
                                      ).map(
                                          ([autoCompletionString, solrProp]: [
                                              string,
                                              string
                                          ]) => (
                                              <div
                                                  className="p-col-12"
                                                  key={autoCompletionString}
                                                  onClick={(e: any) => {
                                                      // If the autocompletion result is a store id, we trim the 5- prefix to avoid breaking the search below
                                                      if (
                                                          solrProp ===
                                                          SolrFields.ID
                                                      )
                                                          autoCompletionString =
                                                              autoCompletionString.substr(
                                                                  2
                                                              )
                                                      // Set Search value with whatever was clicked in the autocompletition box to actually autocomplete
                                                      searchInputRef.value =
                                                          autoCompletionString
                                                      props.setSearchValue(
                                                          autoCompletionString
                                                      )

                                                      // Search using the autocompleted item
                                                      props.searchHandler?.(
                                                          solrProp
                                                      )
                                                      setShowSearchResults(
                                                          false
                                                      )
                                                  }}>
                                                  <p>{autoCompletionString}</p>
                                              </div>
                                          )
                                      )}
                            </SearchingForContainer>
                        </SearchResults>
                    )}
            </div>
        </>
    )
}

export default React.memo(
    Search,
    (prev, next) => prev.searchValue === next.searchValue
)

