import { useEffect, useState } from 'react'
import { injectIntl } from 'react-intl'
import { StyledWrapper } from './StyledWrapper'
import MainProjectHeader from '../main-project-header/MainProjectHeader'
import DetailsColumn from '../general-info/GeneralInfoColumn'
import { TabView, TabPanel } from 'primereact/tabview'
import { useWindowResize } from '../../../utils/commonFunctions'
import GalleryModal from '../gallery-modal/GalleryModal'
import MainProjectSections from '../main-project-sections/MainProjectSections'
import { ProgressSpinner } from 'primereact/progressspinner'
import { Moment } from 'moment'
import { MainProjectPhoto } from '../../../models/main-projects'
import store from '../../../redux/store'

const ResponsiveWrapper = (props: {
    intl: any
    dates: string[]
    isApp: boolean
    mainproject: any
    mainprojectId: string
    loadingMainProject: boolean
    photoList: MainProjectPhoto[]
    totalPhotosCount: number
    photosLength: number
    photoLoading: boolean
    onChangeDates: (newDates: Moment[], shouldResetToYear: boolean) => void
    downloadPhotos: () => void
    onExportDeck: () => void
    selectedDeckPhotos: MainProjectPhoto[]
    setSelectedDeckPhotos: (selectedDeckPhotos: MainProjectPhoto[]) => void
    loadingExportMainProjectDeck: boolean
}) => {
    const {
        intl,
        dates,
        isApp,
        mainproject,
        mainprojectId,
        loadingMainProject,
        photoList,
        photosLength,
        totalPhotosCount,
        photoLoading,
        onChangeDates,
        downloadPhotos,
        onExportDeck,
        selectedDeckPhotos,
        setSelectedDeckPhotos,
        loadingExportMainProjectDeck,
    } = props

    const [banner, setBanner] = useState<string>('')
    const [storeName, setStoreName] = useState<string>('')

    const [activeTab, setActiveTab] = useState<number>(0)
    const [isModalOpen, setIsModalOpen] = useState<boolean>(false)
    const [shouldShowPhotosSelection, setShouldShowPhotosSelection] =
        useState<boolean>(false)
    const [carouselState, setCarouselState] = useState({
        page: 0,
        tab: 'overview',
    })

    useEffect(() => {
        isModalOpen
            ? (document.body.style.overflow = 'hidden')
            : (document.body.style.overflow = 'unset')
    }, [isModalOpen])

    const isMobileNow = useWindowResize()

    const details = (
        <DetailsColumn
            photoList={photoList}
            mainproject={mainproject}
            onPhotoNumberClick={() => setActiveTab(0)}
            photosLength={photosLength}
        />
    )

    return (
        <StyledWrapper>
            {isMobileNow ? (
                loadingMainProject || (photoList.length < 1 && photoLoading) ? (
                    <div className="detail-page-loader">
                        <ProgressSpinner />
                    </div>
                ) : (
                    <TabView
                        activeIndex={activeTab}
                        onTabChange={(e) => setActiveTab(e.index)}
                        className="tab-view">
                        <TabPanel
                            header={intl.formatMessage({
                                id: 'store_detail.photo',
                            })}
                            headerClassName="mobile-tab">
                            <div className="p-col-12 p-lg-8 photo-container p-p-3 p-p-lg-6 p-pl-lg-5">
                                <MainProjectHeader
                                    dates={dates}
                                    isApp={isApp}
                                    photoList={photoList}
                                    setDates={onChangeDates}
                                    downloadPhotos={downloadPhotos}
                                    setShouldShowPhotosSelection={setShouldShowPhotosSelection}
                                    onExportDeck={onExportDeck}
                                    loadingExportMainProjectDeck={loadingExportMainProjectDeck}
                                />
                                <MainProjectSections
                                    mainproject={mainproject}
                                    photoList={photoList}
                                    openModal={(banner: string, storeName: string) => {
                                        setBanner(banner)
                                        setStoreName(storeName)
                                        setIsModalOpen(true)
                                    }}
                                    setCarouselState={setCarouselState}
                                    showPhotosSelection={shouldShowPhotosSelection}
                                    setShouldShowPhotosSelection={setShouldShowPhotosSelection}
                                    onExportDeck={onExportDeck}
                                    selectedDeckPhotos={selectedDeckPhotos}
                                    setSelectedDeckPhotos={setSelectedDeckPhotos}
                                    loadingExportMainProjectDeck={loadingExportMainProjectDeck}
                                />
                            </div>
                        </TabPanel>
                        <TabPanel
                            header={intl.formatMessage({
                                id: 'store_detail.general_info',
                            })}
                            headerClassName="mobile-tab">
                            {details}
                        </TabPanel>
                    </TabView>
                )
            ) : (
                <div className="p-grid detail-page">
                    {loadingMainProject || (photoList?.length < 1 && photoLoading) ? (
                        <div className="detail-page-loader">
                            <ProgressSpinner />
                        </div>
                    ) : (
                        <>
                            <div className="p-col-12 p-lg-8 photo-container p-p-3 p-p-lg-6 p-pl-lg-5">
                                <MainProjectHeader
                                    dates={dates}
                                    isApp={isApp}
                                    photoList={photoList}
                                    setDates={onChangeDates}
                                    downloadPhotos={downloadPhotos}
                                    setShouldShowPhotosSelection={setShouldShowPhotosSelection}
                                    onExportDeck={onExportDeck}
                                    loadingExportMainProjectDeck={loadingExportMainProjectDeck}
                                />
                                <MainProjectSections
                                    mainproject={mainproject}
                                    photoList={photoList}
                                    openModal={(banner: string, storeName: string) => {
                                        setBanner(banner)
                                        setStoreName(storeName)
                                        setIsModalOpen(true)
                                    }}
                                    setCarouselState={setCarouselState}
                                    showPhotosSelection={shouldShowPhotosSelection}
                                    setShouldShowPhotosSelection={setShouldShowPhotosSelection}
                                    onExportDeck={onExportDeck}
                                    selectedDeckPhotos={selectedDeckPhotos}
                                    setSelectedDeckPhotos={setSelectedDeckPhotos}
                                    loadingExportMainProjectDeck={loadingExportMainProjectDeck}
                                />
                            </div>
                            <div className="p-col-4 details-container">{details}</div>
                        </>
                    )}
                </div>
            )}
            <GalleryModal
                isApp={isApp}
                open={isModalOpen}
                photoList={photoList}
                mainproject={mainproject}
                mainprojectId={mainprojectId}
                carouselState={carouselState}
                close={() => setIsModalOpen(false)}
                setCarouselState={setCarouselState}
                banner={banner}
                storeName={storeName}
            />
        </StyledWrapper>
    )
}

export default injectIntl(ResponsiveWrapper)

