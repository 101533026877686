import { DefaultSet } from '../../models/default'

class DefaultDispatcher extends DefaultSet {
    toJSON(proto?: any) {
        let jsoned: any = {}
        let toConvert = proto || this
        Object.getOwnPropertyNames(toConvert).forEach((prop) => {
            const val = toConvert[prop]
            // don't include those
            if (prop === 'toJSON' || prop === 'constructor') {
                return
            }
            if (typeof val === 'function') {
                jsoned[prop] = val.bind(jsoned)
                return
            }
            jsoned[prop] = val
        })

        const inherited = Object.getPrototypeOf(toConvert)
        if (inherited !== null) {
            Object.keys(this.toJSON(inherited)).forEach((key) => {
                if (!!jsoned[key] || key === 'constructor' || key === 'toJSON')
                    return
                if (typeof inherited[key] === 'function') {
                    jsoned[key] = inherited[key].bind(jsoned)
                    return
                }
                jsoned[key] = inherited[key]
            })
        }
        return jsoned
    }
}

export class BaseDispatcher extends DefaultDispatcher {
    type: string
    response: any
    success: any
    error: any
    constructor(type: string, response: any) {
        super()
        this.type = type
        this.response = response
    }

    onDispatch() {
        return this.toJSON()
    }

    onDispatchSuccess() {
        this.success = this.response
        return this.toJSON()
    }

    onDispatchError() {
        this.error = this.response
        return this.toJSON()
    }
}
