const apply = (state: any, type: any, error: any) => {
  const matches = /(.*)_(REQUEST|FAILURE)/.exec(type);

  // not a *_REQUEST / *_FAILURE actions, so we ignore them
  if (!matches) return state;

  const [, requestName, requestState] = matches;

  let _error = undefined;

  if (
    error &&
    error.response &&
    error.response.data &&
    error.response.data.message
  ) {
    _error = error.response.data.message;
  }

  if (!_error) {
    _error = error
      ? error.message
        ? error.message
        : "commons.error.generic"
      : undefined;
  }

  const value = requestState === "FAILURE" && _error;

  return {
    ...state,
    // Store errorMessage
    // e.g. stores errorMessage when receiving GET_TODOS_FAILURE
    //      else clear errorMessage when receiving GET_TODOS_REQUEST
    [requestName]: value,
    __LAST: value,
  };
};

const errorsReducer = (state = {}, action: any) => {
  const { type, error } = action;
  switch (type) {
    case "__ALL_ERROR__":
      return {};
    case "__TIMEOUT_ERROR__":
      return {
        ...state,
        __TIMEOUT: true,
      };
    default: {
      if (Array.isArray(type)) {
        const nextState = type.reduce((nState, t) => {
          return apply(nState, t, error);
        }, state);
        return nextState;
      } else {
        return apply(state, type, error);
      }
    }
  }
};

export default errorsReducer;
