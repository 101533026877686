import { SearchableFilter } from './searchable-filter'

export type SElement = {
    count: number
    selected: boolean
}

export type SegmentDict = Record<string, SElement>

export class SegmentFilters extends SearchableFilter {
    selectedSegment: Set<string>
    id: string
    title: string
    fuseEngine: any
    viewAllVisible: boolean
    searchableFilter: SegmentDict
    searchedFilter: SegmentDict
    searchValue: string
    getSearchableFilter = () =>
        Object.keys(this.searchedFilter).length > 0
            ? this.searchedFilter
            : this.searchableFilter
    isPanelCollapsed: boolean
    selectAllSelected: boolean

    constructor(data?: any) {
        super()

        this.selectedSegment = new Set()
        this.id = 'segment'
        this.title = ''
        this.fuseEngine = null
        this.viewAllVisible = true
        this.searchableFilter = data
        this.searchedFilter = data
        this.searchValue = ''
        this.isPanelCollapsed = true
        this.selectAllSelected = false
    }
}
