import { Carousel } from 'primereact/components/carousel/Carousel'
import { injectIntl } from 'react-intl'
import './GeneralInfo.css'

const Banners = (props: { banners: string[] }) => {
    const { banners } = props

    const classes = {
        logoContainer: {
            width: '100%',
            height: '100%',
            padding: '1rem 5rem',
        },
    }

    const bannerTemplate = (banner: string) => {
        let logo: string
        if (banner === 'SunglassHut' || banner === 'Sunglass Hut - white label') {
            banner = 'Sunglass Hut'
        } else if (banner === 'Optical Prescription Spectacle Makers') {
            banner = 'OPSM'
        } else if (banner === 'David Clulow Opt' || banner === 'David Clulow Opt - white label') {
            banner = 'David_Clulow_Opticians'
        } else if (banner === 'David Clulow - white label') {
            banner = 'David_Clulow'
        } else if (banner === 'Oakley Vault') {
            banner = 'Oakley'
        }
        const fileName = banner.split(' ').join('_')
        switch (fileName) {
            case 'Alain_Mikli':
            case 'David_Clulow_Opticians':
            case 'David_Clulow':
            case 'Econopticas':
            case 'Laubman_&_Pank':
            case 'LensCrafters':
            case 'Oakley':
            case 'Oliver_Peoples':
            case 'GMO':
            case 'OPSM':
            case 'Optica_Lunettes':
            case 'Optical_Shop_of_Aspen':
            case 'Oticas_Carol':
            case 'Pearle_Vision':
            case 'Persol':
            case 'Ray-Ban':
            case 'Salmoiraghi_&_Viganò':
            case 'Spectacle_Hut':
            case 'Sunglass_Hut':
            case 'Target':
            case 'VistaSì':
                logo = banner ? `/logos/${fileName}.png` : ''
                break
            default:
                logo = '/image@3x.png'
                break
        }
        return <img style={{ margin: '0 0 1.5em 0' }} src={logo} alt="banner-name" />
    }

    return banners?.length > 0 ? (
        <div className="generalInfoBanners">
            <Carousel
                numScroll={1}
                numVisible={1}
                value={banners}
                itemTemplate={bannerTemplate}
            />
        </div>
    ) : (
        <div style={classes.logoContainer}>
            <img src={'/image@3x.png'} alt="banner" />
        </div>
    )
}

export default injectIntl(Banners)

