import { Panel } from 'primereact/panel';
import { StyledFilterTitle } from './style';

const FilterTitle = (props: any) => {
    return (
        <StyledFilterTitle>
            <Panel header={props.label} className={props.className}>
                {props.children}
            </Panel>
        </StyledFilterTitle>
    )
}

export default FilterTitle;
