import { Calendar } from 'primereact/calendar'
import { StyledDatePicker } from './StyledDatePicker'
import React, { useCallback, useEffect, useImperativeHandle, useRef, useState } from 'react'
import IconButton from '../icon-button/IconButton'
import { createLoadingSelector } from '../../redux/selectors'
import { connect } from 'react-redux'
import { AppState } from '../../redux/states/appState'
import ReactDOM from 'react-dom'
import moment, { Moment } from 'moment'

type MomentDateWrapper = { original: Moment; from: number; to: number }

function isMonthFilter(date: MomentDateWrapper) {
    return date.to - date.from < 2629800000 * 2
}

const loadingSelector = createLoadingSelector(['GET_ALL_PHOTOS'])

const mapStateToProps = (state: AppState) => ({
    isLoadingImages: loadingSelector(state),
    allExistingPhotos: state.photosReducer.allExistingPhotos,
})

const MonthDatePicker = (props: any, ref: any) => {
    const { dates, setDates, allExistingPhotos, onCalendarOpenChange } = props
    const calendarRef = useRef<Calendar | null>(null)
    const [openCalendar, setOpenCalendar] = useState(dates.find(isMonthFilter) != null)
    const [viewDate, setViewDate] = useState(dates[dates.length - 1].original.toDate())

    const createMonthsInPhotoList = useCallback(() => {
        const monthsInPhotoList: Record<number, Set<number>> = {}

        for (const photo of allExistingPhotos) {
            const monthsOfYear =
                monthsInPhotoList[photo.date.getFullYear()] ?? new Set([])
            monthsOfYear.add(photo.date.getMonth())

            monthsInPhotoList[photo.date.getFullYear()] = monthsOfYear
        }

        return monthsInPhotoList
    }, [allExistingPhotos])

    const monthsInPhotoListRef = useRef(createMonthsInPhotoList())

    const closeOpenCalendar = () => {
        setImmediate(() => setOpenCalendar(false))
    };

    // Expose the methods via the ref object
    // so that it can be called from the parent component
    useImperativeHandle(ref, () => ({
        closeOpenCalendar,
    }));

    function getDOMMonths() {
        const calendarDOMNode = ReactDOM.findDOMNode(calendarRef.current)

        if (calendarDOMNode == null) return []

        const calendarMonthSpans = (calendarDOMNode as Element).children[0]
            .children[1]

        return calendarMonthSpans?.children ?? []
    }

    const disableCalendarMonth = useCallback(
        (currentViewDate: Date) => {
            const domMonths = getDOMMonths()

            for (let i = 0; i < domMonths.length; i++) {
                domMonths[i].classList.remove('p-monthpicker-month-disabled')
            }

            for (let i = 0; i < domMonths.length; i++) {
                if (
                    monthsInPhotoListRef.current[
                        currentViewDate.getFullYear()
                    ]?.has(i)
                )
                    continue

                domMonths[i].classList.add('p-monthpicker-month-disabled')
            }
        },
        [monthsInPhotoListRef]
    )

    const highlightSelectedMonths = useCallback(
        (dates: MomentDateWrapper[] | null, currentViewDate: Date) => {
            if (!dates) return

            const selectionsSet = new Set<string>(
                dates
                    .filter(isMonthFilter)
                    .map((d) => `${d.original.year()}_${d.original.month()}`)
            )

            const domMonths = getDOMMonths()

            for (let i = 0; i < domMonths.length; i++) {
                if (
                    monthsInPhotoListRef.current[
                        currentViewDate.getFullYear()
                    ]?.has(i) &&
                    selectionsSet.has(`${currentViewDate.getFullYear()}_${i}`)
                )
                    domMonths[i].classList.add('p-highlight')
                else domMonths[i].classList.remove('p-highlight')
            }
        },
        [monthsInPhotoListRef]
    )

    useEffect(() => {
        onCalendarOpenChange(openCalendar)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [openCalendar])

    useEffect(() => {
        if (openCalendar) {
            monthsInPhotoListRef.current = createMonthsInPhotoList()

            disableCalendarMonth(viewDate)
            highlightSelectedMonths(dates, viewDate)
        }
    }, [
        dates,
        viewDate,
        openCalendar,
        allExistingPhotos,
        disableCalendarMonth,
        highlightSelectedMonths,
        createMonthsInPhotoList
    ])

    return (
        <StyledDatePicker>
            <div className="p-d-flex" style={{ position: 'relative' }}>
                <IconButton
                    icon="calendar"
                    onClick={() => {
                        setOpenCalendar(!openCalendar)
                    }}
                    tooltipIntl={
                        openCalendar
                            ? 'store_detail.select_dates.close'
                            : 'store_detail.select_dates'
                    }
                    style={{ position: 'relative',
                        backgroundColor: `${dates.some(isMonthFilter) ? 'rgb(64, 114, 238)' : ''}`,
                        borderColor: `${dates.some(isMonthFilter) ? 'rgb(64, 114, 238)' : ''}`,
                        borderRadius: `${dates.some(isMonthFilter) ? '45%' : ''}`,
                        color: `${dates.some(isMonthFilter) ? 'white' : ''}`
                    }}
                />
                {dates.some(isMonthFilter) && (
                    <IconButton
                        icon="times"
                        onClick={() =>
                            setDates({
                                ...dates,
                                shouldResetToYear: true,
                            })
                        }
                        tooltipIntl="button.clear_filter.text"
                        style={{ position: 'relative', color: 'black' }}
                    />
                )}
                <Calendar
                    ref={calendarRef}
                    inline
                    selectionMode="multiple"
                    className={openCalendar ? 'mobile-calendar' : 'p-d-none'}
                    value={dates ?? undefined}
                    view="month"
                    dateFormat="mm/yy"
                    yearNavigator
                    yearRange="1970:2030"
                    onChange={(e) => {
                        const newDates = e.value as (Date | MomentDateWrapper)[]
                        let preparedDates = newDates
                            .map((date) =>
                                date instanceof Date
                                    ? {
                                          original: moment(date),
                                          from: moment(date)
                                              .startOf('month')
                                              .utc()
                                              .valueOf(),
                                          to: moment(date)
                                              .endOf('month')
                                              .utc()
                                              .valueOf(),
                                      }
                                    : date
                            )
                            .reduce((acc, date) => {
                                const existingMonthFilterIndex = acc.findIndex(
                                    (ae) =>
                                        ae.from === date.from &&
                                        ae.to === date.to
                                )

                                if (existingMonthFilterIndex > -1)
                                    acc.splice(existingMonthFilterIndex, 1)
                                else acc.push(date)

                                return acc
                            }, [] as MomentDateWrapper[])
                            .filter(isMonthFilter)
                        setDates({
                            newDates: preparedDates,
                            shouldResetToYear: preparedDates.length < 1,
                        })
                    }}
                    disabledDates={[new Date()]}
                    viewDate={viewDate}
                    onViewDateChange={(e: any) => {
                        monthsInPhotoListRef.current = createMonthsInPhotoList()
                        disableCalendarMonth(e.value)
                        highlightSelectedMonths(dates, e.value)
                        setViewDate(e.value)
                    }}
                />
            </div>
        </StyledDatePicker>
    )
}

export default connect(
    mapStateToProps,
    null,
    null,
    { forwardRef: true } // pass forwardRef as an option
)(React.forwardRef(MonthDatePicker))
