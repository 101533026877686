import { MainProjectPhoto } from '../../models/main-projects'
import { BaseDispatcher } from './base'

export enum MainProjectsDispatchTypes {
    GET_MAIN_PROJECTS_REQUEST = 'GET_MAIN_PROJECTS_REQUEST',
    GET_MAIN_PROJECTS_SUCCESS = 'GET_MAIN_PROJECTS_SUCCESS',
    GET_MAIN_PROJECTS_ERROR = 'GET_MAIN_PROJECTS_ERROR',
    GET_MAIN_PROJECT_DETAIL_REQUEST = 'GET_MAIN_PROJECT_DETAIL_REQUEST',
    GET_MAIN_PROJECT_DETAIL_SUCCESS = 'GET_MAIN_PROJECT_DETAIL_SUCCESS',
    GET_MAIN_PROJECT_DETAIL_ERROR = 'GET_MAIN_PROJECT_DETAIL_ERROR',
    SET_MAIN_PROJECT_SELECTED_DECK_PHOTOS_REQUEST = 'SET_MAIN_PROJECT_SELECTED_DECK_PHOTOS_REQUEST',
    SET_MAIN_PROJECT_SELECTED_DECK_PHOTOS_SUCCESS = 'SET_MAIN_PROJECT_SELECTED_DECK_PHOTOS_SUCCESS',
    SET_MAIN_PROJECT_SELECTED_DECK_PHOTOS_ERROR = 'SET_MAIN_PROJECT_SELECTED_DECK_PHOTOS_ERROR',
    EXPORT_MAIN_PROJECT_DECK_REQUEST = 'EXPORT_MAIN_PROJECT_DECK_REQUEST',
    EXPORT_MAIN_PROJECT_DECK_SUCCESS = 'EXPORT_MAIN_PROJECT_DECK_SUCCESS',
    EXPORT_MAIN_PROJECT_DECK_ERROR = 'EXPORT_MAIN_PROJECT_DECK_ERROR',
}

export class MainProjectsDispatcher extends BaseDispatcher {
    static GetMainProjectsRequest = () => {
        return new MainProjectsDispatcher(
            MainProjectsDispatchTypes.GET_MAIN_PROJECTS_REQUEST,
            {}
        ).onDispatchSuccess()
    }

    static GetMainProjectsSuccess = (mainprojects: any) => {
        return new MainProjectsDispatcher(
            MainProjectsDispatchTypes.GET_MAIN_PROJECTS_SUCCESS,
            mainprojects
        ).onDispatchSuccess()
    }

    static GetMainProjectsError = (error: any) => {
        return new MainProjectsDispatcher(
            MainProjectsDispatchTypes.GET_MAIN_PROJECTS_ERROR,
            error
        ).onDispatchError()
    }

    static GetMainProjectDetailRequest = () => {
        return new MainProjectsDispatcher(
            MainProjectsDispatchTypes.GET_MAIN_PROJECT_DETAIL_REQUEST,
            {}
        ).onDispatchSuccess()
    }

    static GetMainProjectDetailSuccess = (mainproject: any) => {
        return new MainProjectsDispatcher(
            MainProjectsDispatchTypes.GET_MAIN_PROJECT_DETAIL_SUCCESS,
            mainproject
        ).onDispatchSuccess()
    }

    static GetMainProjectDetailError = (error: any) => {
        return new MainProjectsDispatcher(
            MainProjectsDispatchTypes.GET_MAIN_PROJECT_DETAIL_ERROR,
            error
        ).onDispatchError()
    }

    static SetMainProjectSelectedDeckPhotosRequest = () => {
        return new MainProjectsDispatcher(
            MainProjectsDispatchTypes.SET_MAIN_PROJECT_SELECTED_DECK_PHOTOS_REQUEST,
            {}
        ).onDispatchError()
    }

    static SetMainProjectSelectedDeckPhotosSuccess = (
        selectedDeckPhotos: MainProjectPhoto[]
    ) => {
        return new MainProjectsDispatcher(
            MainProjectsDispatchTypes.SET_MAIN_PROJECT_SELECTED_DECK_PHOTOS_SUCCESS,
            selectedDeckPhotos
        ).onDispatchSuccess()
    }

    static SetMainProjectSelectedDeckPhotosError = (error: any) => {
        return new MainProjectsDispatcher(
            MainProjectsDispatchTypes.SET_MAIN_PROJECT_SELECTED_DECK_PHOTOS_ERROR,
            error
        ).onDispatchError()
    }

    static ExportMainProjectDeckRequest = () => {
        return new MainProjectsDispatcher(
            MainProjectsDispatchTypes.EXPORT_MAIN_PROJECT_DECK_REQUEST,
            {}
        ).onDispatchError()
    }

    static ExportMainProjectDeckSuccess = () => {
        return new MainProjectsDispatcher(
            MainProjectsDispatchTypes.EXPORT_MAIN_PROJECT_DECK_SUCCESS,
            {}
        ).onDispatchSuccess()
    }

    static ExportMainProjectDeckError = (error: any) => {
        return new MainProjectsDispatcher(
            MainProjectsDispatchTypes.EXPORT_MAIN_PROJECT_DECK_ERROR,
            error
        ).onDispatchError()
    }
}

