import { connect } from 'react-redux'
import { injectIntl } from 'react-intl'
import { AppState } from '../redux/states/appState'
import FilterSearchStoresClearSection from '../components/filter/filter-searchstores-clear-section'
import FilterToggleButton from '../components/filter/filter-toggle-button'
import { getSearchResults } from '../redux/actions/stores'
import {
    clearAllFilters,
    getHostedRetail,
    setSearchHostedRetailValue,
    setSelectedHostedRetail,
    setSelectedSidebarMenu,
    toggleHostedRetailViewAll,
} from '../redux/actions/filters'
import { createLoadingSelector } from '../redux/selectors'
import SmartSearchableFilter from '../components/smart-searchable-filter'
import { SearchableFilterElement } from '../models/searchable-filter'
import { useEffect } from 'react'
import { SidebarMenuKind } from '../models/sidebar-menu-kind'
import { ScrollPanel } from 'primereact/scrollpanel'

const HostedRetailFilter = (props: any) => {
    const { intl } = props
    const { hostedRetailFilters } = props

    useEffect(() => {
        props.getHostedRetail()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return (
        <ScrollPanel
            style={{
                width: '100%',
                height: 'calc(100vh - 175px)',
            }}
            className="pvscroll">
            <FilterSearchStoresClearSection
                onClearAllFilters={(e: any) => props.clearAllFilters()}
                isLoadingSearchResults={props.loadingSearchResults}
                isSearchResultsDisabled={props.loadingStores}
                onSearchResults={() => props.getSearchResults()}>
                <SmartSearchableFilter
                    render={(
                        searchableFilterItem: [string, SearchableFilterElement]
                    ) => (
                        <FilterToggleButton
                            size="small"
                            checked={searchableFilterItem[1].selected}
                            onChange={(e: any) =>
                                props.setSelectedHostedRetail(
                                    searchableFilterItem[0],
                                    !searchableFilterItem[1].selected
                                )
                            }
                            label={`${searchableFilterItem[0].toUpperCase()} (${
                                searchableFilterItem[1].count
                            })`}
                        />
                    )}
                    loading={props.loading}
                    placeholderSearch={intl.formatMessage({
                        id: 'hosted_retail.search.placeholder',
                    })}
                    searchValue={hostedRetailFilters.searchValue}
                    setSearchValue={props.setSearchHostedRetailValue}
                    searchableFilters={[hostedRetailFilters]}
                    toggleViewAllVisible={props.toggleHostedRetailViewAll}
                    setFilterSelection={props.setSelectedHostedRetail}
                />
            </FilterSearchStoresClearSection>
        </ScrollPanel>
    )
}

const loadingSelector = createLoadingSelector(['FILTERS_GET_HOSTED_RETAIL'])
const loadingStores = createLoadingSelector(['STORES'])
const loadingSearchResults = createLoadingSelector(['SEARCH_RESULTS'])

const mapStateToProps = (state: AppState) => ({
    loading: loadingSelector(state),
    loadingStores: loadingStores(state),
    loadingSearchResults: loadingSearchResults(state),
    hostedRetailFilters: state.filtersReducer.hostedRetailFilters,
})

const mapDispatchToProps = (dispatch: any) => ({
    clearAllFilters: () => dispatch(clearAllFilters()),
    getHostedRetail: () => dispatch(getHostedRetail()),
    toggleHostedRetailViewAll: () => dispatch(toggleHostedRetailViewAll()),
    setSelectedHostedRetail: (hostedRetail: string, selection: boolean) =>
        dispatch(setSelectedHostedRetail(hostedRetail, selection)),
    setSearchHostedRetailValue: (searchValue: string) =>
        dispatch(setSearchHostedRetailValue(searchValue)),
    getSearchResults: () =>
        dispatch(getSearchResults()).then((e: any) =>
            dispatch(setSelectedSidebarMenu(SidebarMenuKind.SEARCH_RESULTS))
        ),
})

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(injectIntl(HostedRetailFilter))
