import moment from 'moment'
import { sortPhotosByMacroarea } from '../../utils/commonFunctions'
import { PhotosApi } from '../../utils/api/photosApi'
import { PhotosDispatcher } from '../dispatchers/photos'
import { MainProjectPhoto } from '../../models/main-projects'
import { Photo } from '../../models/photo-list'
import { AppState } from '../states/appState'
import { PhotoState } from '../states/photosState'
import { ExportDeckType } from '../../models/export-deck-type'
import { UserDispatcher } from '../dispatchers/user'
import axios, { CancelTokenSource } from 'axios'

export const getDefaultThumbnails = (
    storeIds: string[],
    dates: {
        from: string
        to: string
    }[]
) => {
    // const queryParameters =
    //     '?sv=2019-12-12&st=2021-02-09T14%3A29%3A06Z&se=2021-04-10T14%3A29%3A00Z&sr=c&sp=r&sig=5Me1CUXWf8xlqztqJf4RKvNgpzvSEBItAeIsg4RlHME%3D'
    return async (dispatch: any, getState: any) => {
        dispatch(PhotosDispatcher.GetDefaultThumbnailsRequest())

        try {
            const promises = dates.map(({ from, to }) =>
                PhotosApi.GetDefaultThumbnails(storeIds, from, to)
            )

            const result = []

            for (const thumbnailsResults of await Promise.all(promises)) {
                if (
                    thumbnailsResults &&
                    thumbnailsResults.data &&
                    thumbnailsResults.data.items
                ) {
                    const list = thumbnailsResults.data.items
                        /*.sort(
                            (t1: any, t2: any) =>
                                t2.capture_time_ms - t1.capture_time_ms
                        )*/
                        .map((s: any) => ({
                            url:
                                process.env.REACT_APP_DAM_IMAGES_PREFIX +
                                s.path,
                            macroarea: s.store_area,
                            store_id: s.store_id.slice(10, 14),
                        }))

                    result.push(...list)
                }
            }
            dispatch(PhotosDispatcher.GetDefaultThumbnailSuccess(result))
        } catch (error: any) {
            dispatch(UserDispatcher.UserError(error))

            dispatch(PhotosDispatcher.GetDefaultThumbnailError(error))
        }
    }
}

export const getAllPhotos = (
    storeIds: string[],
    dates: {
        from: string
        to: string
    }[],
    macroarea?: string
) => {
    return async (dispatch: any, getState: any) => {
        dispatch(PhotosDispatcher.GetAllPhotosRequest())

        try {
            const promises = dates.map(({ from, to }) =>
                PhotosApi.GetAllPhotos(storeIds, from, to)
            )

            const result = []

            for (const allPhotosResults of await Promise.all(promises)) {
                if (allPhotosResults?.data?.items) {
                    let list = allPhotosResults.data.items
                        /*.sort(
                        (t1: any, t2: any) =>
                            t2.capture_time_ms - t1.capture_time_ms
                    )*/
                        .map((s: any) => ({
                            url:
                                process.env.REACT_APP_DAM_IMAGES_PREFIX +
                                s.path,
                            macroarea: s.store_area,
                            // store_id: s.store_id.slice(10, 14),
                            store_id: s.store_id,
                            date_execution: moment(
                                s.capture_time_ms,
                                'x'
                            ).format('YYYY/MM/DD'),
                            date: new Date(s.capture_time_ms),
                            photo_id: s.photo_id,
                        }))
                        .filter(
                            (s: any) =>
                                macroarea == null ||
                                macroarea === 'All' ||
                                macroarea === s.macroarea
                        )
                    if (storeIds.length === 1)
                        list = sortPhotosByMacroarea(list)

                    result.push(...list)
                }
            }

            dispatch(
                PhotosDispatcher.GetAllPhotosSuccess({
                    photoList: result,
                    allExistingPhotos:
                        dates.length === 1 &&
                            Number(dates[0].to) - Number(dates[0].from) >
                            2629800000 * 2
                            ? result
                            : undefined,
                })
            )
        } catch (error: any) {
            dispatch(UserDispatcher.UserError(error))

            dispatch(PhotosDispatcher.GetAllPhotosError(error))
        }
    }
}

export const getPhotosByMainProject = (
    store_id_list: string[],
    dates: {
        from: string
        to: string
    }[],
    main_project: string,
    cancelTokenSource: CancelTokenSource
) => {
    return async (dispatch: any) => {
        dispatch(PhotosDispatcher.GetPhotosByMainProjectRequest())
        try {
            const promises = dates.map(({ from, to }) =>
                PhotosApi.GetPhotosByMainProject(
                    store_id_list,
                    from,
                    to,
                    main_project,
                    cancelTokenSource
                )
            )

            const result = []
            for (const resItems of await Promise.all(promises)) {
                if (resItems) {
                    let items = resItems.map(
                        (item: MainProjectPhoto, i: number) => ({
                            ...item,
                            index: i,
                            date: new Date(item.capture_time_ms),
                            path:
                                (process.env.REACT_APP_DAM_IMAGES_PREFIX ||
                                    '') + item.path,
                        })
                    )

                    result.push(...items)
                }
            }

            dispatch(
                PhotosDispatcher.GetPhotosByMainProjectSuccess({
                    mainprojectPhotoList: result,
                    allExistingPhotos:
                        dates.length === 1 &&
                            Number(dates[0].to) - Number(dates[0].from) >
                            2629800000 * 2
                            ? result
                            : undefined,
                })
            )
        } catch (error: any) {
            if (!axios.isCancel(error)) {
                dispatch(UserDispatcher.UserError(error))

                dispatch(PhotosDispatcher.GetPhotosByMainProjectError(error))
            }
        }
    }
}

export const setSelectedDeckPhotos = (selectedDeckPhotos: Photo[]) => {
    return async (dispatch: any) => {
        dispatch(PhotosDispatcher.SetSelectedDeckPhotosRequest())

        try {
            dispatch(
                PhotosDispatcher.SetSelectedDeckPhotosSuccess({
                    selectedDeckPhotos,
                } as PhotoState)
            )
        } catch (error: any) {
            dispatch(PhotosDispatcher.SetSelectedDeckPhotosError(error))
        }
    }
}

export const exportStoreDeck = (
    storeID: string,
    exportDeckType: ExportDeckType,
    intl: any,
    storearea?: string,
    dates?: string[]
) => {
    return async (dispatch: any, getState: any) => {
        dispatch(PhotosDispatcher.ExportStoreDeckRequest())
        let currentState: AppState = getState()

        let responseTitle = ''
        let responseDescription = ''
        try {
            const res = await PhotosApi.GetStorePhotosDeck(
                storeID,
                exportDeckType,
                exportDeckType === ExportDeckType.CUSTOM
                    ? currentState.photosReducer.selectedDeckPhotos
                        .filter((dp) => dp.photo_id != null)
                        .map((dp) => dp.photo_id || '0')
                    : [],
                storearea,
                dates
            )
            if (res.status === 200) {
                responseTitle = intl.formatMessage({
                    id: 'store_detail.download_deck.confirmed_title',
                })
                responseDescription = intl.formatMessage({
                    id: 'store_detail.download_deck.confirmed_description',
                })
            } else {
                responseTitle = intl.formatMessage({
                    id: 'generic.oops',
                })
                responseDescription = intl.formatMessage({
                    id: 'store_detail.download_deck.error',
                })
            }
        } catch (error: any) {
            dispatch(UserDispatcher.UserError(error))

            responseTitle = intl.formatMessage({
                id: 'generic.oops',
            })
            responseDescription = intl.formatMessage({
                id: 'store_detail.download_deck.error',
            })
        }
        dispatch(PhotosDispatcher.ExportStoreDeckSuccess({}))

        return { responseTitle, responseDescription }
    }
}
