import { connect } from 'react-redux'
import moment from 'moment'
import { injectIntl } from 'react-intl'
import { AppState } from '../../redux/states/appState'
import AppToolbar from '../app-toolbar/toolbar'
import { StyledMainProjectDetail } from './StyledMainProjectDetailContainer'
import { Photo } from '../../models/photo-list'
import ResponsiveWrapper from '../../components/main-project/responsive-wrapper/ResponsiveWrapper'
import { useEffect, useRef, useState } from 'react'
import { getSingleStore, getSearchResults } from '../../redux/actions/stores'
import { useLocation } from 'react-router-dom'
import {
    getAllPhotos,
    getDefaultThumbnails,
    getPhotosByMainProject,
} from '../../redux/actions/photos'
import {
    setUserLanguage,
    setReopenSidebarMemo,
    setFavouriteStore,
    getFavouriteStoresList,
    removeStoreFromFavourites,
} from '../../redux/actions/user'
import { Store } from '../../models/store'
import PVSidebar from '../../components/pv-sidebar'
import { SidebarMenuKind } from '../../models/sidebar-menu-kind'
import {
    setSelectedSidebarMenu,
    setSelectedSidebarSizeType,
} from '../../redux/actions/filters'
import { FiltersSidebarSizeType } from '../../models/filters-sidebar-size-type'
import { PhotosApi } from '../../utils/api/photosApi'
import MainProjectNavigation from '../../components/main-project/main-project-navigation/MainProjectNavigation'
import {
    exportMainProjectDeck,
    getMainProjectDetail,
    setMainProjectSelectedDeckPhotos,
} from '../../redux/actions/mainprojects'
import { MainProjectPhoto } from '../../models/main-projects'
import { ExportDeckType } from '../../models/export-deck-type'
import { createLoadingSelector } from '../../redux/selectors'
import { Dialog } from 'primereact/dialog'
import { Button } from 'primereact/button'
import axios, { CancelTokenSource } from 'axios'

const MainProjectDetail = (props: any) => {
    const isFilterBarOpen = props.selectedSidebarMenu !== SidebarMenuKind.NONE

    // CONSTS

    const path = useLocation().pathname.split('/')

    const mainprojectId = path[path.length - 1]

    const [isDialogVisible, setIsDialogVisible] = useState<boolean>(false)
    const [dialogTitle, setDialogTitle] = useState<string>('')
    const [dialogDescription, setDialogDescription] = useState<string>('')

    const [isApp, setIsApp] = useState<boolean>(false)

    const [dates, setDates] = useState([
        {
            original: moment(),
            from: 1561878000000,
            to: moment().utc().endOf('month').valueOf(),
        },
    ])

    const totalUploadedImages = useRef(0)
    const [photosLength, setPhotosLength] = useState(0)

    const [name, setName] = useState('')
    const [storeListId, setStoreListId] = useState([])

    const photoList: Photo[] = props?.mainprojectPhotoList

    const breadcrumb = {
        leftContents: (
            <MainProjectNavigation
                mainprojectCode={mainprojectId}
                mainprojectName={
                    props?.loadingMainProject ? '' : props?.mainproject?.name
                }
            />
        ),
    }
    const getPhotosSourceRef = useRef<CancelTokenSource | null>(null)

    // USE EFFECTS

    useEffect(() => {
        setName(props?.mainproject?.name)
        setStoreListId(props?.mainproject?.store_list)
    }, [props.mainproject])

    useEffect(() => {
        setPhotosLength(props.mainprojectPhotoList.length)
    }, [props.mainprojectPhotoList])

    useEffect(() => {
        // Workaround to keep the total photos count without doing a specific query
        if (totalUploadedImages.current === 0 && props?.mainprojectPhotoList)
            totalUploadedImages.current = props.mainprojectPhotoList.length
    }, [props?.mainprojectPhotoList])

    useEffect(() => {
        // Reset total on mount
        totalUploadedImages.current = 0

        const from = sessionStorage.getItem('from')
        if (from && from === 'app') {
            setIsApp(true)
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        props.getMainProjectDetail(parseInt(mainprojectId))
        props.setReopenSidebarMemo(true, props.selectedSidebarMenu)
        props.setSelectedSidebarMenu(SidebarMenuKind.NONE)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [mainprojectId, props.selectedBannerDesc])

    useEffect(() => {
        if (name?.length > 0 && storeListId?.length > 0) {
            if (getPhotosSourceRef.current) {
                getPhotosSourceRef.current.cancel()
            }
            const cancelTokenSource = axios.CancelToken.source()
            getPhotosSourceRef.current = cancelTokenSource
            let list = storeListId.map((s: any) => s.store_id)

            props.getPhotosByMainProject(list, dates, name, cancelTokenSource)
            // eslint-disable-next-line react-hooks/exhaustive-deps
            return () => {
                cancelTokenSource.cancel()
            }
        }
    }, [name, storeListId, dates])

    const downloadPhotos = () => {
        PhotosApi.zipMainProjectPhotos(
            photoList,
            mainprojectId,
            dates.map((date) => date.original)
        )
    }

    return (
        <StyledMainProjectDetail>
            <div className="p-d-md-flex p-flex-column z-20">
                <div className="p-mb-12 storeDetailContainerAppToolbar">
                    <AppToolbar {...breadcrumb} />
                </div>
                <ResponsiveWrapper
                    dates={dates}
                    isApp={isApp}
                    downloadPhotos={downloadPhotos}
                    mainproject={props.mainproject}
                    mainprojectId={mainprojectId}
                    loadingMainProject={props.loadingMainProject}
                    photoList={props.mainprojectPhotoList}
                    totalPhotosCount={totalUploadedImages.current}
                    photoLoading={props.mainprojectPhotoLoading}
                    photosLength={photosLength}
                    onChangeDates={(value: any) => {
                        if (value.shouldResetToYear)
                            setDates([
                                {
                                    original: moment(),
                                    from: 1561878000000,
                                    to: moment().utc().endOf('month').valueOf(),
                                },
                            ])
                        else setDates(value.newDates)
                    }}
                    onExportDeck={(
                        exportDeckType: ExportDeckType,
                        storearea?: string,
                        dates?: string[]
                    ) =>
                        props
                            .exportMainProjectDeck(
                                mainprojectId,
                                exportDeckType,
                                props.intl,
                                storearea,
                                dates
                            )
                            .then(
                                ({
                                    responseTitle,
                                    responseDescription,
                                }: {
                                    responseTitle: string
                                    responseDescription: string
                                }) => {
                                    setIsDialogVisible(true)
                                    setDialogTitle(responseTitle)
                                    setDialogDescription(responseDescription)
                                }
                            )
                    }
                    selectedDeckPhotos={props.selectedDeckPhotos}
                    setSelectedDeckPhotos={
                        props.setMainProjectSelectedDeckPhotos
                    }
                    loadingExportMainProjectDeck={
                        props.loadingExportMainProjectDeck
                    }
                />
                <PVSidebar
                    setSelectedSidebarMenu={props.setSelectedSidebarMenu}
                    setSelectedSidebarSizeType={
                        props.setSelectedSidebarSizeType
                    }
                    isOpen={isFilterBarOpen}
                    sizeType={props.sidebarSizeType}
                    selectedSidebarMenu={props.selectedSidebarMenu}
                    currentUser={props.currentUser}
                    setLanguage={props.setLanguage}
                    stores={props.stores}
                    searchResults={props.searchResults}
                    favouriteStoresList={props.favouriteStoresList}
                    thumbnails={props.thumbnails}
                    getThumbnails={(ids: string[]) =>
                        props.getThumbnails(ids, dates)
                    }
                    dateFilters={dates.map((date) => [
                        {
                            selectedDateMonth: [
                                moment(date.original).month() + 1,
                            ],
                            selectedDateYear: [moment(date.original).year()],
                        },
                    ])}
                />
                <Dialog
                    header={dialogTitle}
                    visible={isDialogVisible}
                    style={{ width: '50vw' }}
                    footer={
                        <div className="flex justify-center">
                            <Button
                                style={{ marginTop: 8 }}
                                label="OK"
                                icon="pi pi-check"
                                onClick={() => setIsDialogVisible(false)}
                                autoFocus
                            />
                        </div>
                    }
                    onHide={() => setIsDialogVisible(false)}>
                    <p style={{ marginTop: 8 }}>{dialogDescription}</p>
                </Dialog>
            </div>
        </StyledMainProjectDetail>
    )
}

const loadingExportMainProjectDeck = createLoadingSelector([
    'EXPORT_MAIN_PROJECT_DECK',
])

const mapStateToProps = (state: AppState) => ({
    currentUser: state.userReducer.user,
    sidebarSizeType: state.filtersReducer.selectedSidebarSizeType,
    selectedSidebarMenu: state.filtersReducer.selectedSidebarMenu,
    searchResults: state.storesReducer.searchResults,
    favouriteStoresList: state.userReducer.favouriteStoresList,
    mainproject: state.mainprojectsReducer.mainproject,
    loadingMainProject: state.mainprojectsReducer.loadingMainProject,
    mainprojectPhotoList: state.photosReducer.mainprojectPhotoList,
    mainprojectPhotoLoading: state.photosReducer.mainprojectPhotoLoading,
    selectedDeckPhotos: state.mainprojectsReducer.selectedDeckPhotos,
    loadingExportMainProjectDeck: loadingExportMainProjectDeck(state),
    selectedBannerDesc:
        state.filtersReducer.bannerFilters?.bannerDescFilters
            ?.selectedBannerDesc ?? [],
})

const mapDispatchToProps = (dispatch: any) => ({
    setSelectedSidebarMenu: (newSelectedSidebarMenu: SidebarMenuKind) =>
        dispatch(setSelectedSidebarMenu(newSelectedSidebarMenu)),
    setSelectedSidebarSizeType: (
        newSelectedSidebarSizeType: FiltersSidebarSizeType
    ) => dispatch(setSelectedSidebarSizeType(newSelectedSidebarSizeType)),
    setLanguage: (langLocaleShort: string) =>
        dispatch(setUserLanguage(langLocaleShort)),
    getSelectedStore: (storeId: string) => dispatch(getSingleStore(storeId)),
    getAllPhotos: (
        storeIds: string[],
        dates: {
            from: string
            to: string
        }[],
        macroarea: string
    ) => dispatch(getAllPhotos(storeIds, dates, macroarea)),
    getThumbnails: (
        storeIds: string[],
        dates: { from: string; to: string }[]
    ) => dispatch(getDefaultThumbnails(storeIds, dates)),
    getFavouriteStoresList: () => dispatch(getFavouriteStoresList()),
    setFavouriteStore: (store: Store) => dispatch(setFavouriteStore(store)),
    removeFavouriteStore: (storeId: string) =>
        dispatch(removeStoreFromFavourites(storeId)),
    getSearchResults: () => dispatch(getSearchResults()),
    setReopenSidebarMemo: (
        shouldReopen: string,
        sidebarMenuKindToReopen: SidebarMenuKind
    ) => dispatch(setReopenSidebarMemo(shouldReopen, sidebarMenuKindToReopen)),
    getMainProjectDetail: (id: number) => dispatch(getMainProjectDetail(id)),
    getPhotosByMainProject: (
        store_id_list: string[],
        dates: {
            from: string
            to: string
        }[],
        main_project: string,
        cancelTokenSource: CancelTokenSource
    ) =>
        dispatch(
            getPhotosByMainProject(
                store_id_list,
                dates,
                main_project,
                cancelTokenSource
            )
        ),
    setMainProjectSelectedDeckPhotos: (
        selectedDeckPhotos: MainProjectPhoto[]
    ) => dispatch(setMainProjectSelectedDeckPhotos(selectedDeckPhotos)),
    exportMainProjectDeck: (
        mainProjectID: string,
        exportDeckType: ExportDeckType,
        intl: any,
        storearea?: string,
        dates?: string[]
    ) =>
        dispatch(
            exportMainProjectDeck(
                mainProjectID,
                exportDeckType,
                intl,
                storearea,
                dates
            )
        ),
})

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(injectIntl(MainProjectDetail))
