import { injectIntl } from 'react-intl'
import { StyledModal } from './StyledModal'
import { useEffect, useState } from 'react'
import ModalFullScreen from '../../modal-full-screen/ModalFullScreen'
import MainProjectNavigation from '../main-project-navigation/MainProjectNavigation'
import GalleryCarousel from '../gallery-carousel/GalleryCarousel'

// carousel gallery divided in sections

const GalleryModal = (props: any) => {
    const [isFullScreen, setIsFullScreen] = useState(false)

    const {
        open,
        close,
        mainproject,
        mainprojectId,
        photoList,
        carouselState,
        setCarouselState,
        isApp,
        banner,
        storeName
    } = props

    const tabControl = (pageNumber: number) => {
        setCarouselState({ ...carouselState, page: pageNumber })
    }

    useEffect(() => {
        if (photoList) {
            if (photoList[carouselState.page]) {
                const newSection = photoList[carouselState.page].macroarea
                if (newSection !== carouselState.tab)
                    setCarouselState({ ...carouselState, tab: newSection })
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [carouselState.page])

    return (
        <StyledModal
            isFullScreen={isFullScreen}
            onKeyDown={(e) => {
                if (e.key === 'ArrowLeft') {
                    if (carouselState.page > 0)
                        tabControl(carouselState.page - 1)
                } else if (e.key === 'ArrowRight') {
                    if (carouselState.page < photoList?.length - 1)
                        tabControl(carouselState.page + 1)
                }
            }}
            tabIndex={0}>
            <ModalFullScreen
                open={open}
                onHide={close}
            // header={
            //     <MainProjectNavigation
            //         isModalHeader
            //         mainprojectName={mainproject?.name}
            //         mainprojectCode={mainprojectId}
            //     />
            // }
            >
                <GalleryCarousel
                    isApp={isApp}
                    isFullScreen={false}
                    setIsFullScreen={setIsFullScreen}
                    page={carouselState.page}
                    photoList={photoList}
                    tabControl={tabControl}
                    banner={banner}
                    storeName={storeName}
                    close={close}
                />
            </ModalFullScreen>
        </StyledModal>
    )
}

export default injectIntl(GalleryModal)

