import { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import {
    createLoadingSelector,
    createSuccessMessageSelector,
    createErrorMessageSelector,
    createErrorTimeoutSelector,
} from '../redux/selectors'
import { clearAllSuccess } from '../redux/actions/success'
import { clearAllError } from '../redux/actions/errors'
import { injectIntl } from 'react-intl'
import { Toast } from 'primereact/toast'

const Toaster = (props: any) => {
    const { intl } = props
    const [toast, setToast] = useState<any>(false)

    // REMOVE THIS CALL TO ENABLE TOASTER!!
    const blacklistToaster = (element: any) => {
        return true
    }

    useEffect(() => {
        if (props.success && !blacklistToaster(props.success)) {
            let title = intl.formatMessage({
                id: 'commons.success.title',
                defaultMessage: 'Success',
            })
            let message = intl.formatMessage({
                id: 'commons.success.message',
                defaultMessage: 'Operation succesfully completed',
            })
            if (props.success.message) {
                message = intl.formatMessage({
                    id: props.success.message.toString(),
                    defaultMessage: props.success.message.toString(),
                })
            }
            toast?.show({
                severity: 'success',
                summary: title,
                detail: message,
            })
            console.log('MESSAGE SNACKBAR', 'SUCCESS SNACKBAR OPEN')
        }
    }, [intl, toast, props.success])

    useEffect(() => {
        if (props.error && props.error !== '') {
            let title = intl.formatMessage({
                id: 'commons.error.title',
                defaultMessage: 'Error',
            })
            let message = intl.formatMessage({
                id: 'commons.error.message',
                defaultMessage: 'There was an error during the operation',
            })
            if (props.error.message) {
                message = intl.formatMessage({
                    id: props.error.message.toString(),
                    defaultMessage: props.error.message.toString(),
                })
            }
            toast?.show({ severity: 'error', summary: title, detail: message })
            console.log('MESSAGE SNACKBAR', 'ERROR SNACKBAR OPEN', props.error)
        }
    }, [intl, toast, props.error])

    useEffect(() => {
        if (props.timeout) {
            let title = intl.formatMessage({
                id: 'commons.timeout.title',
                defaultMessage: 'Timeout',
            })
            let message = intl.formatMessage({
                id: 'commons.timeout.message',
                defaultMessage: 'Timeout error during the operation',
            })
            if (props.error.message) {
                message = intl.formatMessage({
                    id: props.error.message.toString(),
                    defaultMessage: props.error.message.toString(),
                })
            }
            toast?.show({ severity: 'warn', summary: title, detail: message })
            console.log('MESSAGE SNACKBAR', 'TIMEOUT SNACKBAR OPEN')
        }
    }, [intl, toast, props.error, props.timeout])

    const handleClose = () => {
        // toast?.clear();
        console.log('MESSAGE SNACKBAR', 'SNACKBAR CLOSED')
        props.clearError()
        props.clearSuccess()
    }

    return <Toast ref={(el) => setToast(el)} onRemove={handleClose} />
}

const loadingAction = createLoadingSelector(['__LAST'])
const successAction = createSuccessMessageSelector(['__LAST'])
const errorAction = createErrorMessageSelector(['__LAST'])
const timeoutAction = createErrorTimeoutSelector()

const mapStateToProps = (state: any) => ({
    loading: loadingAction(state),
    success: successAction(state),
    error: errorAction(state),
    timeout: timeoutAction(state),
})

const mapDispatchToProps = (dispatch: any) => ({
    clearSuccess: () => dispatch(clearAllSuccess()),
    clearError: () => dispatch(clearAllError()),
})

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(Toaster))
