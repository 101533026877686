import React from 'react'
import MapTopLegendItem from './map-top-legend-item'
import './map-top-legend.css'

function MapTopLegend() {
    return (
        <div className="mapTopLegend inline-flex items-center justify-between px-4 py-2 bg-white rounded-full">
            <MapTopLegendItem className="mr-2" name="core" />
            <MapTopLegendItem className="mr-2" name="hosted" />
            <MapTopLegendItem className="mr-2" name="outlet" />
            <MapTopLegendItem name="noChannelOfTrade" />
        </div>
    )
}

export default MapTopLegend
