const apply = (state: any, type: any, success: any) => {
    const matches = /(.*)_(REQUEST|SUCCESS)/.exec(type)

    // not a *_REQUEST / *_SUCCESS actions, so we ignore them
    if (!matches) return state

    const [, requestName, requestState] = matches

    const value = requestState === 'SUCCESS' && success ? success : undefined
    return {
        ...state,
        [requestName]: value,
        __LAST: value,
    }
}

const successReducer = (state = {}, action: any) => {
    const { type, success } = action
    switch (type) {
        case '__ALL_SUCCESS__':
            return {}
        default: {
            if (Array.isArray(type)) {
                const nextState = type.reduce((nState, t) => {
                    return apply(nState, t, success)
                }, state)
                return nextState
            } else {
                return apply(state, type, success)
            }
        }
    }
}

export default successReducer
