import { Colors } from '../../../colors'
import styled from 'styled-components'

export const StyledHeaders = styled.div`
    .h2 h2 {
        font-size: 21px;
        color: ${Colors.darkGrey};
    }
    .h3 h3 {
        font-size: 14px;
        color: ${Colors.grey5};
    }
    @media (max-width: 767px) {
        .h2 h2.storeHeaderH2 {
            font-size: 14px;
            color: #4e5b69;
            line-height: 1;
            letter-spacing: normal;
        }
    }
`
