import { injectIntl } from 'react-intl'
import { Link } from 'react-router-dom'
import { useWindowResize } from '../../../utils/commonFunctions'
import { StyledNavigation } from './StyledNavigation'
import IconButton from '../../icon-button/IconButton'
import { PhotosApi } from '../../../utils/api/photosApi'
import React from 'react'

const MainProjectNavigation = (props: any) => {
    const { mainprojectName, isModalHeader, banner, isFullScreen, photo, storeName, photoList, isApp, shouldFullScreen, intl } = props
    const isMobileNow = useWindowResize()

    return (
        <StyledNavigation style={{ width: '100%' }}>
            <Link
                className={
                    'navigation-link' +
                    (isMobileNow || isModalHeader ? ' mobile' : '')
                }
                to="/home">
                {!isModalHeader && <i className="pi pi-angle-left"></i>}
                <div className="p-d-inline name-container" style={ { display: 'flex', flexDirection: 'column', alignItems: 'flex-start' } }>
                    {' '}
                    <div className="mainproject-name"> {mainprojectName}</div>
                </div>
            </Link>
        </StyledNavigation>
    )
}

export default injectIntl(MainProjectNavigation)
