import React from 'react'
import { injectIntl } from 'react-intl'
import { Carousel } from 'primereact/carousel'
import { StyledCarousel } from './StyledCarousel'
import 'primeicons/primeicons.css'
import { useWindowResize } from '../../utils/commonFunctions'
import { ProgressSpinner } from 'primereact/progressspinner'

const GalleryCarousel = (props: any) => {
    const { photoList, loading } = props
    const isMobileNow = useWindowResize()
    const resolutionParameter = '?impolicy=500'
    const maxTenPhotos =
        photoList.length > 10 ? photoList.slice(0, 10) : photoList
    const photoTemplate = (photo: {
        url: string
        label?: string
        index: number
    }) => {
        return (
            <div className="carousel-item small ">
                <img
                    className="carousel-img "
                    src={photo.url + resolutionParameter}
                    alt={photo.label || ''}
                />
            </div>
        )
    }
    return (
        loading
            ? <ProgressSpinner/>
            : (<StyledCarousel isFullScreen={false} isMobileNow={isMobileNow}>
                <Carousel
                    className="pin-carousel"
                    // circular
                    value={maxTenPhotos}
                    itemTemplate={photoTemplate}
                    numVisible={1}
                    numScroll={1}
                />
        </StyledCarousel>)
    )
}
export default injectIntl(GalleryCarousel)
