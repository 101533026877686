import { http } from './http'
import axios, { CancelTokenSource } from 'axios'
import JSZip from 'jszip'
import { saveAs } from 'file-saver'
import moment, { Moment } from 'moment'
import { chunk } from 'lodash'
import { Photo } from '../../models/photo-list'
import { MainProjectPhoto } from '../../models/main-projects'
import { ExportDeckType } from '../../models/export-deck-type'

/**
 * @export PhotosApi - Just an api interface for claiming photo data and reducing the code needed for axios http functions
 * @class PhotosApi
 */
export class PhotosApi {
    /**
     * Base Url for Photos Api
     * @static
     * @type {string}
     * @memberof PhotosApi
     */
    static PHOTOS_BASE_URL: string = process.env.REACT_APP_BASE_URL_DAM || ''

    /**
     * Endpoint for photo info
     * @static
     * @type {string}
     * @memberof PhotosApi
     */
    static PHOTOS_ENDP: string = 'VmStoreImages/api/v1/pictures'

    /**
     * Number of store ids for mainproject detail
     * @static
     * @type {string}
     * @memberof PhotosApi
     */
    static IDS_NUMBER: string = process.env.STORE_IDS_NUMBER || '110'

    // get preview photos (main store areas)
    // used for showing search results and favourite stores list in the sidebar
    static GetDefaultThumbnails = async (
        storeIds: string[],
        dateFrom: string,
        dateTo: string
    ) => {
        const storeIdsToString = storeIds
            .map((store) => `'500-000000${store}'`)
            .join(',')

        let response = await axios.get(
            PhotosApi.PHOTOS_BASE_URL +
            PhotosApi.PHOTOS_ENDP +
            `?store_id=[${storeIdsToString}]&from=${dateFrom}&to=${dateTo}&default=true`,
            {
                timeout: 60000,
                maxContentLength: Infinity,
                maxBodyLength: Infinity,
                headers: {
                    'Ocp-Apim-Subscription-Key':
                        process.env.REACT_APP_DAM_API_SUBSCRIPTION_KEY || '',
                },
            }
        )

        return response
    }

    // get all the photos for selected stores
    // used in the gallery of the store detail and for the pin carousel on the map
    static GetAllPhotos = async (
        storeIds: string[],
        dateFrom: string,
        dateTo: string
    ) => {
        const storeIdsToString = storeIds
            .map((store) => `'500-000000${store}'`)
            .join(',')

        let response = await axios.get(
            PhotosApi.PHOTOS_BASE_URL +
            PhotosApi.PHOTOS_ENDP +
            `?store_id=[${storeIdsToString}]&from=${dateFrom}&to=${dateTo}&default=false`,
            {
                timeout: 60000,
                maxContentLength: Infinity,
                maxBodyLength: Infinity,
                headers: {
                    'Ocp-Apim-Subscription-Key':
                        process.env.REACT_APP_DAM_API_SUBSCRIPTION_KEY || '',
                },
            }
        )
        return response
    }

    // get all photos by main project
    // used in the main project detail
    static GetPhotosByMainProject = async (
        store_id_list: string[],
        dateFrom: string,
        dateTo: string,
        main_project: string,
        cancelTokenSource: CancelTokenSource
    ): Promise<any[]> => {
        let chunkedStoreIds = chunk(store_id_list, Number(PhotosApi.IDS_NUMBER))

        //utilizzo Promise.all per fare le chiamate in parallelo
        let responseList = await Promise.all(
            chunkedStoreIds.map(async (storeIds) => {
                let store_ids = storeIds
                    .map((store_id: string) => `'500-000000${store_id}'`)
                    .join(',')
                let main_projects = `'${encodeURIComponent(main_project)}'`

                return await axios.get(
                    PhotosApi.PHOTOS_BASE_URL +
                    PhotosApi.PHOTOS_ENDP +
                    `?store_id=[${store_ids}]&from=${dateFrom}&to=${dateTo}&main_project=[${main_projects}]`,
                    {
                        timeout: 60000,
                        maxContentLength: Infinity,
                        maxBodyLength: Infinity,
                        cancelToken: cancelTokenSource.token,
                        headers: {
                            'Ocp-Apim-Subscription-Key':
                                process.env
                                    .REACT_APP_DAM_API_SUBSCRIPTION_KEY || '',
                        },
                    }
                )
            })
        )

        let response: any[] = []

        responseList.forEach((res) => {
            if (res.data?.items?.length > 0) {
                response = response.concat(res.data?.items)
            }
        })

        return response
    }

    static zipPhotos = async (
        photoList: Photo[],
        storeId: string,
        storearea: string,
        dates: Moment[]
    ) => {
        var zip = new JSZip()

        const zipName =
            storeId +
            '_' +
            storearea +
            '_' +
            dates.map((date) => moment(date).format('MMYYYY')).join('_')

        let idx = 1
        for (const ph of photoList) {
            // Unless we want all macroareas, just consider photos from the passed macroarea
            if (storearea !== 'All' && ph.macroarea !== storearea) continue

            // maybe use shoot date instead of array index
            const fileName = `${idx}_${ph.macroarea
                }_${ph.date_execution.replaceAll('/', '-')}.jpg`
            let r = await axios.get(ph.url, {
                responseType: 'blob',
            })

            let blob = r.data

            zip.file(fileName, blob, { binary: true })

            idx++
        }

        const type = JSZip.support.blob ? 'blob' : 'string'
        zip.generateAsync({
            type: type,
            // compression: 'DEFLATE',
            // compressionOptions: {
            //     level: 7,
            // },
        }).then((blob) => {
            saveAs(blob, zipName + '.zip')
        })
    }

    static zipMainProjectPhotos = async (
        photoList: MainProjectPhoto[],
        mainprojectId: string,
        //storearea: string,
        dates: Moment[]
    ) => {
        var zip = new JSZip()
        //const zipName = mainprojectId + '_' + storearea + '_' + dates.map(date => moment(date).format('MMYYYY')).join('_')
        const zipName =
            mainprojectId +
            '_' +
            dates.map((date) => moment(date).format('MMYYYY')).join('_')
        for (const ph of photoList) {
            // Unless we want all macroareas, just consider photos from the passed macroarea
            //if (storearea !== 'All' && ph.macro_area !== storearea) continue;

            const fileName = `${ph.photo_id}.jpg`
            let r: any =
                ph.path &&
                (await axios.get(ph.path, {
                    responseType: 'blob',
                }))
            let blob = r.data
            zip.file(fileName, blob, { binary: true })
        }
        const type = JSZip.support.blob ? 'blob' : 'string'
        zip.generateAsync({
            type: type,
        }).then((blob) => {
            saveAs(blob, zipName + '.zip')
        })
    }

    static downloadSinglePhoto = async (photo: Photo, intl: any) => {
        const response = await fetch(photo.url)

        if (response.status === 200) {
            const blob = await response.blob()
            const url = URL.createObjectURL(blob)
            const link = document.createElement('a')
            link.href = url
            link.download =
                photo.store_id +
                '_' +
                photo.date_execution.replaceAll('/', '-') +
                '.jpeg'
            document.body.appendChild(link)
            link.click()
            link.remove()
            return { success: true }
        } else {
            alert(
                intl.formatMessage({
                    id: 'store_detail.download_photo_alert',
                })
            )
        }
    }

    static downloadSingleMainProjectPhoto = async (
        photo: MainProjectPhoto,
        intl: any
    ) => {
        if (photo?.path) {
            const response = await fetch(photo.path)

            if (response.status === 200) {
                const blob = await response.blob()
                const url = URL.createObjectURL(blob)
                const link = document.createElement('a')
                link.href = url
                link.download =
                    photo.store_id +
                    '_' +
                    (photo?.execution_status
                        ? photo.execution_status.replaceAll('/', '-')
                        : '') +
                    '.jpeg'
                document.body.appendChild(link)
                link.click()
                link.remove()
                return { success: true }
            } else {
                alert(
                    intl.formatMessage({
                        id: 'store_detail.download_photo_alert',
                    })
                )
            }
        }
    }

    static BASE_URL: string = process.env.REACT_APP_BASE_URL_BE || ''

    static GetStorePhotosDeck = (
        store_id: string,
        selection_type: ExportDeckType,
        photo_id_list: string[],
        storearea?: string,
        dates?: string[]
    ) => {
        return http.post(
            PhotosApi.BASE_URL + 'picstore/deck/store',
            // `https://apistore360-dev.luxottica.com/picturemgmtapi/picstore/deck/store`,
            {
                store_id,
                selection_type,
                photo_id_list,
                filters: {
                    storearea: storearea ?? undefined,
                    dates: dates ?? undefined,
                },
            }
        )
    }
}
