import { ExportDeckType } from '../../models/export-deck-type'
import { http } from './http'

/**
 * @export MainProjectsApi - Just an api interface for claiming main projects data and reducing the code needed for axios http functions
 * @class MainProjectsApi
 */
export class MainProjectsApi {
    /**
     * Base Url for Main Projects Api
     * @static
     * @type {string}
     * @memberof MainProjectsApi
     */
    static BASE_URL: string = process.env.REACT_APP_BASE_URL_BE || ''

    static GetMainProjects = (
        name: string,
        store_id_list: string[],
        banners?: string[]
    ) => {
        return http.post<any>(
            MainProjectsApi.BASE_URL + `picstore/mainproject`,
            // `https://apistore360-dev.luxottica.com/picturemgmtapi/picstore/mainproject`,
            {
                name: name,
                store_id_list,
                banners,
            }
        )
    }

    static GetMainProjectDetail = (id: number, banners?: string[]) => {
        return http.post<any>(
            MainProjectsApi.BASE_URL + `picstore/mainproject/detail`,
            // `https://apistore360-dev.luxottica.com/picturemgmtapi/picstore/mainproject/detail`,
            {
                id: id,
                banners,
            }
        )
    }

    static GetMainProjectPhotosDeck = (
        mainproject_id: number,
        selection_type: ExportDeckType,
        photo_id_list: string[],
        storearea?: string,
        dates?: string[],
        banners?: string[]
    ) => {
        return http.post(
            MainProjectsApi.BASE_URL + 'picstore/deck/mainproject',
            // `https://apistore360-dev.luxottica.com/picturemgmtapi/picstore/deck/mainproject`,
            {
                mainproject_id,
                selection_type,
                photo_id_list,
                filters: {
                    storearea: storearea ?? undefined,
                    dates: dates ?? undefined,
                },
                banners,
            }
        )
    }
}
