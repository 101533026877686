import { DefaultSet } from './default'

export class UserData extends DefaultSet {
    lang: string

    constructor(data?: any) {
        super()

        this.lang = ''
    }
}

export class User extends DefaultSet {
    user_id: string
    sub: string
    mail: string
    name: string
    given_name: string
    family_name: string
    role: string
    role_name: string
    local_iat: string
    iat: string
    curr_language: string

    constructor(data?: any) {
        super()

        this.user_id = ''
        this.sub = ''
        this.mail = ''
        this.name = ''
        this.given_name = ''
        this.family_name = ''
        this.role = ''
        this.role_name = ''
        this.local_iat = ''
        this.iat = ''
        this.curr_language = ''

        if (data) {
            this._set(data)
        }
    }
}
