import { i18n } from '../../models/i18n'
import { User } from '../../models/user'

export class Storage {
    private static ACCESS_TOKEN_KEY = 'PICTURE-VIEWER_access_token'
    private static I18N_KEY = 'PICTURE-VIEWER_i18n'
    private static USER_KEY = 'PICTURE-VIEWER_user'

    public static getAccessToken = (): any => {
        return localStorage.getItem(Storage.ACCESS_TOKEN_KEY) || undefined
    }
    public static setAccessToken = (accessToken: string): any => {
        if (accessToken) {
            return localStorage.setItem(Storage.ACCESS_TOKEN_KEY, accessToken)
        }
    }
    public static geti18n = (): any => {
        return localStorage.getItem(Storage.I18N_KEY) || undefined
    }
    public static getUser = (): any => {
        return localStorage.getItem(Storage.USER_KEY) || undefined
    }
    public static setUser = (user: User): any => {
        if (user) {
            return localStorage.setItem(Storage.USER_KEY, JSON.stringify(user))
        }
    }
    public static seti18n = (i18n: i18n) => {
        if (i18n) {
            localStorage.setItem(Storage.I18N_KEY, JSON.stringify(i18n))
        }
    }
    public static clear = () => {
        localStorage.clear()
    }
}
