import React from 'react'
import { Colors } from '../../../colors'
import { Doughnut } from 'react-chartjs-2'
import { Leave } from '../../../models/leave'
import { Store } from '../../../models/store'

function buildData(leaves: Leave[]) {
    const channelOfTradeArray: string[] = []
    leaves.forEach((leave: Leave) => {
        const store: Store = leave.properties
        if (store.channel_of_trade_string) {
            channelOfTradeArray.push(store.channel_of_trade_string === 'NO CHANNEL OF TRADE'
                ? 'nochanneloftrade'
                :store.channel_of_trade_string.toLowerCase()
            )
        }
    })
    var counts: any = { hosted: 0, outlet: 0, core: 0, nochanneloftrade: 0 }

    channelOfTradeArray.forEach((x) => {
        counts[x] = (counts[x] || 0) + 1
    })

    const countChannelsOfTrade: {
        hosted: number
        core: number
        outlet: number
        nochanneloftrade: number
    } = counts
    const { core, outlet, hosted, nochanneloftrade } = countChannelsOfTrade

    return {
        datasets: [
            {
                data: [core, outlet, hosted, nochanneloftrade],
                backgroundColor: [
                    Colors.coreChannelColor,
                    Colors.outletChannelColor,
                    Colors.hostedChannelColor,
                    Colors.grey3,
                ],
            },
        ],
    }
}

function ClusterMarker({
    lat,
    lng,
    pointCount,
    leaves,
    isCluster,
    onClick,
}: {
    lat: number
    lng: number
    pointCount: number
    leaves: Leave[]
    isCluster: boolean
    onClick: (event: React.MouseEvent<HTMLDivElement>) => void
}) {
    return (
        <div style={{ position: 'relative' }} onClick={onClick}>
            <div
                style={{
                    width: 100,
                    transform: 'translate(-50%,-50%)',
                    position: 'relative',
                }}>
                <Doughnut
                    data={() => buildData(leaves)}
                    width={220}
                    height={320}
                    options={{
                        tooltips: {
                            callbacks: {
                                title: function (tooltipItem: any, data: any) {
                                    return tooltipItem[0]['index'] === 0
                                        ? 'CORE'
                                        : tooltipItem[0]['index'] === 1
                                            ? 'OUTLET'
                                            : tooltipItem[0]['index'] === 2
                                                ? 'HOSTED'
                                                : 'NO CHANNEL OF TRADE'
                                },
                                label: function (tooltipItem: any, data: any) {
                                    return `${
                                        data['datasets'][0]['data'][
                                            tooltipItem['index']
                                        ]
                                    } stores`
                                },
                            },
                            backgroundColor: '#FFF',
                            titleFontSize: 16,
                            titleFontColor: '#0066ff',
                            bodyFontColor: '#000',
                            bodyFontSize: 14,
                            borderWidth: 1,
                            borderColor: '#000',
                        },
                    }}
                />
                <div
                    className="text-xl"
                    style={{
                        top: '54%',
                        left: '50%',
                        transform: 'translate(-50%,-50%)',
                        position: 'absolute',
                        zIndex: -1,
                    }}>
                    {pointCount}
                </div>
            </div>
        </div>
    )
}

export default ClusterMarker
