import moment from 'moment'
import { Calendar } from 'primereact/calendar'
import { injectIntl } from 'react-intl'
import { connect } from 'react-redux'
import FilterSearchStoresClearSection from '../components/filter/filter-searchstores-clear-section'
import { SidebarMenuKind } from '../models/sidebar-menu-kind'
import {
    clearAllFilters,
    setSelectedSidebarMenu,
    setSelectedSurveyDateFrom,
    setSelectedSurveyDateTo,
} from '../redux/actions/filters'
import { getSearchResults } from '../redux/actions/stores'
import { createLoadingSelector } from '../redux/selectors'
import { AppState } from '../redux/states/appState'

const SurveyDateFilter = (props: any) => {
    const { intl } = props
    return (
        <>
            <FilterSearchStoresClearSection
                noGutter
                onClearAllFilters={(e: any) => props.clearAllFilters()}
                isLoadingSearchResults={props.loadingSearchResults}
                isSearchResultsDisabled={props.loadingStores}
                onSearchResults={() => props.getSearchResults()}
            />
            <div
                style={{
                    display: 'flex',
                    flexDirection: 'column',
                    margin: '1em',
                }}>
                <Calendar
                    placeholder={intl.formatMessage({
                        id: 'survey_date.from_date.placeholder',
                    })}
                    value={props.surveyDateFilters.selectedFrom}
                    onChange={(e) => {
                        const newFrom = moment(e.value as Date)
                            .endOf('day')
                            .toDate()
                        props.setSelectedSurveyDateFrom(newFrom)
                    }}
                    maxDate={
                        props.surveyDateFilters.selectedTo != null
                            ? moment(
                                  props.surveyDateFilters.selectedTo.getTime() -
                                      86400000,
                                  'x'
                              ).toDate()
                            : new Date()
                    }
                    monthNavigator
                    yearNavigator
                    yearRange={`1960:${new Date().getFullYear()}`}
                />
                <Calendar
                    placeholder={intl.formatMessage({
                        id: 'survey_date.to_date.placeholder',
                    })}
                    value={props.surveyDateFilters.selectedTo}
                    onChange={(e) => {
                        const toFrom = moment(e.value as Date)
                            .endOf('day')
                            .toDate()
                        props.setSelectedSurveyDateTo(toFrom)
                    }}
                    minDate={
                        props.surveyDateFilters.selectedFrom != null
                            ? moment(
                                  props.surveyDateFilters.selectedFrom.getTime() +
                                      86400000,
                                  'x'
                              ).toDate()
                            : new Date('1970-01-01T00:00:00')
                    }
                    maxDate={new Date()}
                    monthNavigator
                    yearNavigator
                    yearRange={`1960:${new Date().getFullYear()}`}
                    style={{ marginTop: 20 }}
                />
            </div>
        </>
    )
}

const loadingStores = createLoadingSelector(['STORES'])
const loadingSearchResults = createLoadingSelector(['SEARCH_RESULTS'])

const mapStateToProps = (state: AppState) => ({
    loadingStores: loadingStores(state),
    loadingSearchResults: loadingSearchResults(state),
    surveyDateFilters: state.filtersReducer.surveyDateFilters,
})

const mapDispatchToProps = (dispatch: any) => ({
    clearAllFilters: () => dispatch(clearAllFilters()),
    setSelectedSurveyDateFrom: (from: Date | null) =>
        dispatch(setSelectedSurveyDateFrom(from)),
    setSelectedSurveyDateTo: (to: Date | null) =>
        dispatch(setSelectedSurveyDateTo(to)),
    getSearchResults: () =>
        dispatch(getSearchResults()).then((e: any) =>
            dispatch(setSelectedSidebarMenu(SidebarMenuKind.SEARCH_RESULTS))
        ),
})

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(injectIntl(SurveyDateFilter))
