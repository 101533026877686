// import { useState } from 'react'
import { injectIntl } from 'react-intl'
import { Button } from 'primereact/button'
import { StyledIconButton } from './StyledIconButton'

type IconButtonProps = {
    icon: string
    tooltipIntl?: string
    onClick: any
    style: any
    intl: any
    label: any
}

const IconButton = (props: IconButtonProps) => {
    const { icon, tooltipIntl, onClick, style, intl, label } = props

    return (
        <StyledIconButton>
            <Button
                className="p-button-text p-p-1 p-mx-1 font-size"
                onClick={onClick}
                icon={`pi pi-${icon}`}
                tooltip={
                    tooltipIntl
                        ? intl.formatMessage({ id: tooltipIntl })
                        : undefined
                }
                tooltipOptions={{ position: 'left' }}
                style={style}
                label={label}
            />
        </StyledIconButton>
    )
}

export default injectIntl(IconButton)

