import styled from 'styled-components'

export const StyledMainProjectDetail = styled.div`
    .detail-page {
        margin-top: 110px;
        width: 100%;
    }
    @media (max-width: 991px) {
        .p-toolbar-group-right {
            display: none;
        }
    }
    @media (max-width: 767px) {
        .storeDetailContainerAppToolbar .p-toolbar.p-component {
            padding: 12px 0px 8px;
        }
        .storeDetailContainerAppToolbar
            .p-toolbar.p-component
            .p-toolbar-group-left {
            padding-left: 8px;
        }
        .storeDetailContainerAppToolbar .navigation-link.mobile i {
            top: calc(50% - 2px);
        }
        .storeDetailContainerAppToolbar .mobile .name-container {
            max-width: 80%;
        }
    }
`
