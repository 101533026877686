import { injectIntl } from 'react-intl'
import Divider from '../../Divider'
import { StyledColumn } from './StyledColumn'

const DetailsParagraph = (props: any) => {
    const { content } = props
    return (
        <StyledColumn>
            <div
                className="p-grid p-py-3 details-paragraph "
                style={{ backgroundColor: content.backgroundColor }}>
                {content.info &&
                    Object.keys(content.info).map((f, i) => (
                        <div
                            className="p-d-flex p-col-12 p-px-1 p-py-2 "
                            key={i}>
                            <div className="title p-px-2 p-pl-md-5">
                                {' '}
                                {content.info[f].title}
                            </div>
                            <div className="field p-text-bold p-px-2 p-pr-md-5">
                                {' '}
                                {content.info[f].field}
                            </div>
                        </div>
                    ))}
            </div>
            <Divider />
        </StyledColumn>
    )
}

export default injectIntl(DetailsParagraph)
