import { Route } from './route'

const RouteConfig = [
    Route.AuthRoute(),
    Route.StoreDetailRoute(),
    Route.DefaultRoute(),
    Route.NotFoundRoute(),
    Route.MainProjectDetailRoute(),
]

export default RouteConfig
