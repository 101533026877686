import { injectIntl } from 'react-intl'
import { Colors } from '../../../colors'
import DetailsParagraph from './GeneralInfoParagraph'
import StatisticsCard from './StatisticsCard'
import { StyledColumn } from './StyledColumn'
import { dateFormatter, useWindowResize } from '../../../utils/commonFunctions'
import FavSwitch from './FavSwitch'
import LogoImage from '../../logo-image/LogoImage'

const GeneralInfoColumn = (props: any) => {
    const {
        onPhotoNumberClick,
        storeDetails,
        floorPlan,
        imageCount,
        setFavourite,
        isFavourite,
        openModal,
        setCarouselState,
        intl,
    } = props

    const isMobileNow = useWindowResize()

    // hide second release components (to remove in second release)
    const isSecondRelease = false

    const statistics = [
        {
            icon: 'glasses',
            number: 1780,
            name: intl.formatMessage({
                id: 'store_detail.visible_capacity',
            }),
            button: intl.formatMessage({
                id: 'store_detail.view_assortment',
            }),
        },
        {
            icon: 'image',
            number: imageCount || 0,
            name: intl.formatMessage({
                id: 'store_detail.uploaded_images',
            }),
            button: undefined,
            label: intl.formatMessage({
                id: 'store_detail.few_images',
            }),
        },
    ]

    var details
    if (storeDetails) {
        details = [
            {
                backgroundColor: Colors.grey1,
                info: {
                    globalStoreID: {
                        title: intl.formatMessage({
                            id: 'store_detail.details.global_id',
                        }),
                        field: '#' + storeDetails.id,
                    },
                    banner: {
                        title: intl.formatMessage({
                            id: 'store_detail.details.banner',
                        }),
                        field: storeDetails.banner_desc_string,
                    },
                    storeName: {
                        title: intl.formatMessage({
                            id: 'store_detail.details.store_name',
                        }),
                        field: storeDetails.store_name_string,
                    },
                    channelOfTrade: {
                        title: intl.formatMessage({
                            id: 'store_detail.details.trade_channel',
                        }),
                        field: storeDetails.channel_of_trade_string,
                    },
                    storeSegment: {
                        title: intl.formatMessage({
                            id: 'store_detail.details.segment',
                        }),
                        field: storeDetails.segment_string,
                    },
                    storeDesignFamily: {
                        title: intl.formatMessage({
                            id: 'store_detail.details.design_family',
                        }),
                        field: storeDetails.store_design_group_string,
                    },
                    storeDesign: {
                        title: intl.formatMessage({
                            id: 'store_detail.details.design',
                        }),
                        field: storeDetails.store_design_string,
                    },
                },
            },
            {
                backgroundColor: Colors.white,
                info: {
                    macroRegion: {
                        title: intl.formatMessage({
                            id: 'store_detail.details.macroregion',
                        }),
                        field: storeDetails.macroregion_desc_string,
                    },
                    country: {
                        title: intl.formatMessage({
                            id: 'store_detail.details.country',
                        }),
                        field: storeDetails.country_string,
                    },
                    region: {
                        title: intl.formatMessage({
                            id: 'store_detail.details.region',
                        }),
                        field: storeDetails.region_string,
                    },
                    city: {
                        title: intl.formatMessage({
                            id: 'store_detail.details.city',
                        }),
                        field: storeDetails.city_string,
                    },
                    streetAddress: {
                        title: intl.formatMessage({
                            id: 'store_detail.details.address',
                        }),
                        field: storeDetails.address_string,
                    },
                },
            },
            {
                backgroundColor: Colors.white,
                info: {
                    openDate: {
                        title: intl.formatMessage({
                            id: 'store_detail.details.open',
                        }),
                        field: dateFormatter(storeDetails.open_date_long),
                    },
                    closeDate: {
                        title: intl.formatMessage({
                            id: 'store_detail.details.close',
                        }),
                        field: dateFormatter(storeDetails.close_date_long),
                    },
                    remodelingDate: {
                        title: intl.formatMessage({
                            id: 'store_detail.details.remodeling',
                        }),
                        field: dateFormatter(storeDetails.remodel_date_long),
                    },
                    relocationDate: {
                        title: intl.formatMessage({
                            id: 'store_detail.details.relocation',
                        }),
                        field: dateFormatter(storeDetails.relocation_date_long),
                    },
                    comment: {
                        title: intl.formatMessage({
                            id: 'store_detail.details.comment',
                        }),
                        field: storeDetails.store_comments_string,
                    },
                },
            },
        ]
    }
    return (
        <StyledColumn>
            <div className="">
                <FavSwitch
                    isFavourite={isFavourite}
                    setFavourite={setFavourite}
                />
                {!isMobileNow && (
                    <div className="logo-img">
                        <LogoImage banner={storeDetails.banner_desc_string} />
                    </div>
                )}
                {isSecondRelease && (
                    <div className="p-col">
                        <StatisticsCard cardContent={statistics[0]} />
                    </div>
                )}
                <div className="p-col">
                    <StatisticsCard
                        cardContent={statistics[1]}
                        onClick={onPhotoNumberClick}
                    />
                </div>
                <div className="p-d-flex p-flex-column p-flex-lg-column-reverse">
                    {floorPlan && (
                        <div className="p-col floor-plan-container">
                            {intl.formatMessage({
                                id: 'store_detail.floor_plan',
                            })}
                            <button
                                className="p-col-12 p-p-0"
                                onClick={() => {
                                    openModal()
                                    setCarouselState({
                                        tab: floorPlan.macroarea,
                                        page: floorPlan.index,
                                    })
                                }}>
                                <div
                                    className="p-mt-2 p-mt-lg-3"
                                    style={{
                                        overflow: 'hidden',
                                        borderRadius: '4px',
                                    }}>
                                    <img
                                        src={floorPlan.url}
                                        alt=""
                                        className="img floor-plan-img"
                                    />
                                </div>
                            </button>
                        </div>
                    )}
                    <div className="p-col">
                        {details &&
                            details.map((d, idx) => (
                                <div className="p-col p-p-0 " key={idx}>
                                    <DetailsParagraph content={d} />
                                </div>
                            ))}
                    </div>
                </div>
            </div>
        </StyledColumn>
    )
}
export default injectIntl(GeneralInfoColumn)
