import styled from 'styled-components'

export const StyledIconButton = styled.div`
    .p-button.p-button-icon-only .pi {
        font-size: 1.5rem;
    }
    @media (max-width: 767px) {
        .p-button.p-button-icon-only {
            width: auto;
        }
        .p-button.p-button-icon-only .pi {
            font-size: 1.25rem;
        }
    }
`
