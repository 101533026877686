import { connect } from 'react-redux'
import moment from 'moment'
import { injectIntl } from 'react-intl'
import { AppState } from '../../redux/states/appState'
import AppToolbar from '../app-toolbar/toolbar'
import StoreNavigation from '../../components/store-details/store-navigation/StoreNavigation'
import { StyledStoreDetail } from './StyledStoreDetailContainer'
import { Photo } from '../../models/photo-list'
import ResponsiveWrapper from '../../components/store-details/responsive-wrapper/ResponsiveWrapper'
import { useEffect, useRef, useState } from 'react'
import { getSingleStore, getSearchResults } from '../../redux/actions/stores'
import { useLocation } from 'react-router-dom'
import {
    getAllPhotos,
    getDefaultThumbnails,
    exportStoreDeck,
    setSelectedDeckPhotos,
} from '../../redux/actions/photos'
import {
    setUserLanguage,
    getFavouriteStoresList,
    removeStoreFromFavourites,
    setFavouriteStore,
    setReopenSidebarMemo,
} from '../../redux/actions/user'
import { Store } from '../../models/store'
import PVSidebar from '../../components/pv-sidebar'
import { SidebarMenuKind } from '../../models/sidebar-menu-kind'
import {
    setSelectedSidebarMenu,
    setSelectedSidebarSizeType,
} from '../../redux/actions/filters'
import { FiltersSidebarSizeType } from '../../models/filters-sidebar-size-type'
import { PhotosApi } from '../../utils/api/photosApi'
import { ExportDeckType } from '../../models/export-deck-type'
import { createLoadingSelector } from '../../redux/selectors'
import { Dialog } from 'primereact/dialog'
import { Button } from 'primereact/button'

const StoreDetail = (props: any) => {
    const isFilterBarOpen = props.selectedSidebarMenu !== SidebarMenuKind.NONE

    // get store name from url
    const path = useLocation().pathname.split('/')
    const storeId = path[path.length - 1]

    const [isDialogVisible, setIsDialogVisible] = useState<boolean>(false)
    const [dialogTitle, setDialogTitle] = useState<string>('')
    const [dialogDescription, setDialogDescription] = useState<string>('')

    // set current month as initial date to filter photos
    // convert to utc, then to unix (seconds)
    const [dates, setDates] = useState([{
        original: moment(),
        from: 1561878000000,
        to: moment().utc().endOf('month').valueOf(),
    }])

    const [isFavourite, setIsFavourite] = useState(false)

    const totalUploadedImages = useRef(0);

    /* MacroArea Filter */

    const [macroarea, setMacroarea] = useState<string>('All')

    const [macroareas, setMacroareas] = useState<any[]>([
        {
            value: 'All',
            label: 'All',
            className: 'active',
            command: (e: any) => setMacroarea(e.item.value),
        },
    ])

    useEffect(() => {
        if (macroarea) {
            let tmpPhotoList = [...photoList]
            setPhotoListFiltered(
                tmpPhotoList.filter((ph: Photo) => {
                    if (macroarea !== 'All') {
                        return ph.macroarea === macroarea
                    }
                    return ph
                })
            )
            let tmpMacroareas = [...macroareas]
            tmpMacroareas.forEach((m) => {
                m.className = ''
                if (m.value === macroarea) {
                    m.className = 'active'
                }
            })
            setMacroareas(tmpMacroareas)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [macroarea])

    /* end MacroArea Filter */

    useEffect(() => {
        // Workaround to keep the total photos count without doing a specific query
        if (totalUploadedImages.current === 0) totalUploadedImages.current = props.photos.length
    }, [props.photos])

    useEffect(() => {
        // Reset total on mount
        totalUploadedImages.current = 0
    }, [])

    // when storeId is found, get store details and the photos from the current month
    useEffect(() => {
        props.getSelectedStore(storeId)
        props.getFavouriteStoresList()
        props.getAllPhotos([storeId], dates, macroarea)

        props.setReopenSidebarMemo(true, props.selectedSidebarMenu)
        props.setSelectedSidebarMenu(SidebarMenuKind.NONE)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [storeId])

    // when favourite list is loaded, check if the selected store is included
    useEffect(() => {
        // to check if the selected store is a favourite
        // scroll through the array of favourite stores
        setIsFavourite(
            props.favouriteStoresList.filter((st: Store) => {
                return st.store_id_string === storeId
            }).length > 0
        )
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.favouriteStoresList])

    // when a new date is selected, update the photo list with photos from the selected date
    useEffect(() => {
        props.getAllPhotos([storeId], dates, macroarea)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dates, macroarea])

    const breadcrumb = {
        leftContents: (
            <StoreNavigation
                storeName={props.selectedStore.store_name_string}
                storeCode={props.selectedStore.store_id_string}
            />
        ),
    }

    // thumbnails or placeholders
    const photoList: Photo[] = props.photos

    const [photoListFiltered, setPhotoListFiltered] =
        useState<Photo[]>(photoList)

    useEffect(() => {
        if (props.photos) {
            setPhotoListFiltered(props.photos)
        }
    }, [props.photos])

    const downloadPhotos = () => {
        PhotosApi.zipPhotos(photoList, storeId, macroarea, dates.map(date => date.original))
    }

    const setFavourite = () => {
        isFavourite
            ? props.removeFavouriteStore(storeId)
            : props.setFavouriteStore(props.selectedStore)
        setIsFavourite(!isFavourite)
    }

    const [isApp, setIsApp] = useState<boolean>(false)

    useEffect(() => {
        const from = sessionStorage.getItem('from')
        if (from && from === 'app') {
            setIsApp(true)
        }
    }, [])

    return (
        <StyledStoreDetail>
            <div className="p-d-md-flex p-flex-column z-20">
                <div className="p-mb-12 storeDetailContainerAppToolbar">
                    <AppToolbar {...breadcrumb} />
                </div>
                <ResponsiveWrapper
                    storeDetails={props.selectedStore}
                    photoList={photoListFiltered}
                    totalPhotosCount={totalUploadedImages.current}
                    onChangeDates={(value: any) => {
                        if (value.shouldResetToYear) setDates([{
                            original: moment(),
                            from: 1561878000000,
                            to: moment().utc().endOf('month').valueOf(),
                        }])
                        else
                            setDates(value.newDates)
                        }
                    }
                    dates={dates}
                    isFavourite={isFavourite}
                    setFavourite={setFavourite}
                    downloadPhotos={downloadPhotos}
                    isApp={isApp}
                    exportStoreDeck={(exportDeckType: ExportDeckType, storearea?: string, dates?: string[]) =>
                        {
                            props
                            .exportStoreDeck(
                                storeId,
                                exportDeckType,
                                props.intl,
                                storearea,
                                dates
                            )
                            .then(
                                ({
                                    responseTitle,
                                    responseDescription,
                                }: {
                                    responseTitle: string
                                    responseDescription: string
                                }) => {
                                    setIsDialogVisible(true)
                                    setDialogTitle(responseTitle)
                                    setDialogDescription(responseDescription)
                                }
                            )
                        }
                    }
                    selectedDeckPhotos={props.selectedDeckPhotos}
                    setSelectedDeckPhotos={props.setSelectedDeckPhotos}
                    loadingExportStoreDeck={props.loadingExportStoreDeck}
                    macroarea={macroarea}
                    setMacroarea={setMacroarea}
                    macroareas={macroareas}
                    setMacroareas={setMacroareas}
                />
                <PVSidebar
                    setSelectedSidebarMenu={props.setSelectedSidebarMenu}
                    setSelectedSidebarSizeType={
                        props.setSelectedSidebarSizeType
                    }
                    isOpen={isFilterBarOpen}
                    sizeType={props.sidebarSizeType}
                    selectedSidebarMenu={props.selectedSidebarMenu}
                    currentUser={props.currentUser}
                    setLanguage={props.setLanguage}
                    stores={props.stores}
                    searchResults={props.searchResults}
                    favouriteStoresList={props.favouriteStoresList}
                    thumbnails={props.thumbnails}
                    getThumbnails={(ids: string[]) =>
                        props.getThumbnails(ids, dates)
                    }
                    dateFilters={dates.map(date => [
                        {
                            selectedDateMonth: [
                                moment(date.original).month() + 1,
                            ],
                            selectedDateYear: [moment(date.original).year()],
                        },
                    ])}
                />
                <Dialog
                    header={dialogTitle}
                    visible={isDialogVisible}
                    style={{ width: '50vw' }}
                    footer={
                        <div className="flex justify-center">
                            <Button
                                style={{ marginTop: 8 }}
                                label="OK"
                                icon="pi pi-check"
                                onClick={() => setIsDialogVisible(false)}
                                autoFocus
                            />
                        </div>
                    }
                    onHide={() => setIsDialogVisible(false)}>
                    <p style={{ marginTop: 8 }}>{dialogDescription}</p>
                </Dialog>
            </div>
        </StyledStoreDetail>
    )
}

const loadingExportStoreDeck = createLoadingSelector(['EXPORT_STORE_DECK'])

const mapStateToProps = (state: AppState) => ({
    currentUser: state.userReducer.user,
    sidebarSizeType: state.filtersReducer.selectedSidebarSizeType,
    selectedSidebarMenu: state.filtersReducer.selectedSidebarMenu,
    selectedStore: state.storesReducer.selectedStore,
    photos: state.photosReducer.photoList,
    thumbnails: state.photosReducer.thumbnailList,
    searchResults: state.storesReducer.searchResults,
    favouriteStoresList: state.userReducer.favouriteStoresList,
    selectedDeckPhotos: state.photosReducer.selectedDeckPhotos,
    loadingExportStoreDeck: loadingExportStoreDeck(state),
})

const mapDispatchToProps = (dispatch: any) => ({
    setSelectedSidebarMenu: (newSelectedSidebarMenu: SidebarMenuKind) =>
        dispatch(setSelectedSidebarMenu(newSelectedSidebarMenu)),
    setSelectedSidebarSizeType: (
        newSelectedSidebarSizeType: FiltersSidebarSizeType
    ) => dispatch(setSelectedSidebarSizeType(newSelectedSidebarSizeType)),
    setLanguage: (langLocaleShort: string) =>
        dispatch(setUserLanguage(langLocaleShort)),
    getSelectedStore: (storeId: string) => dispatch(getSingleStore(storeId)),
    getAllPhotos: (storeIds: string[], 
        dates: {
            from: string,
            to: string
        }[], macroarea: string) => dispatch(getAllPhotos(storeIds, dates, macroarea)),
    getThumbnails: (storeIds: string[], dates: { from: string, to: string }[]) =>
        dispatch(getDefaultThumbnails(storeIds, dates)),
    getFavouriteStoresList: () => dispatch(getFavouriteStoresList()),
    setFavouriteStore: (store: Store) => dispatch(setFavouriteStore(store)),
    removeFavouriteStore: (storeId: string) =>
        dispatch(removeStoreFromFavourites(storeId)),
    getSearchResults: () => dispatch(getSearchResults()),
    setReopenSidebarMemo: (
        shouldReopen: string,
        sidebarMenuKindToReopen: SidebarMenuKind
    ) => dispatch(setReopenSidebarMemo(shouldReopen, sidebarMenuKindToReopen)),
    setSelectedDeckPhotos: (selectedDeckPhotos: Photo[]) =>
        dispatch(setSelectedDeckPhotos(selectedDeckPhotos)),
    exportStoreDeck: (
        storeID: string,
        exportDeckType: ExportDeckType,
        intl: any,
        storearea?: string,
        dates?: string[]
    ) => dispatch(exportStoreDeck(storeID, exportDeckType, intl, storearea, dates)),
})

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(injectIntl(StoreDetail))
