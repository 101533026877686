import React from "react";

function MarkerIcon({
  color,
  onClick,
}: {
  color: string;
  onClick: (event: React.MouseEvent<SVGSVGElement>) => void;
}) {
  return (
    <svg
      onClick={onClick}
      xmlns="http://www.w3.org/2000/svg"
      width="32"
      height="45"
      viewBox="0 0 32 45"
    >
      <g fill="none" fillRule="evenodd">
        <g fill={color}>
          <g>
            <g>
              <path
                d="M16 45l4-4.62c2.667-3.148 5.333-7.166 8-12.054 2.667-4.889 4-9.074 4-12.556 0-4.353-1.567-8.07-4.7-11.15S20.4 0 16 0 7.833 1.54 4.7 4.62C1.567 7.7 0 11.418 0 15.77c0 2.21.583 4.755 1.75 7.634 1.167 2.88 2.45 5.458 3.85 7.734 1.4 2.277 3.017 4.62 4.85 7.032 1.833 2.41 3.1 4.034 3.8 4.871.7.837 1.283 1.49 1.75 1.959zm0-23.605c-1.6 0-2.967-.536-4.1-1.607-1.067-1.138-1.6-2.478-1.6-4.018 0-1.54.55-2.863 1.65-3.968 1.1-1.104 2.45-1.657 4.05-1.657 1.6 0 2.95.553 4.05 1.657 1.1 1.105 1.65 2.428 1.65 3.968s-.55 2.863-1.65 3.968c-1.1 1.105-2.45 1.657-4.05 1.657z"
                transform="translate(-821 -628) translate(550 407) translate(271 221)"
              />
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
}

export default MarkerIcon;
