import { isEmpty } from 'lodash'
import { User } from '../../models/user'
import { UserState } from '../states/userState'
import { Storage } from '../../utils/storage/storage'
import { UserDispatchTypes } from '../dispatchers/user'
import { SidebarMenuKind } from '../../models/sidebar-menu-kind'

let defaultstate: UserState = {
    isUserAuthenticated: false,
    user: new User(),
    accessToken: '',
    favouriteStoresList: [],
    reopenSidebarMemo: {
        shouldReopen: false,
        sidebarMenuKindToReopen: SidebarMenuKind.NONE,
    },
}

const ls = Storage.getUser()
const user: User = ls ? JSON.parse(ls) : null
const accessToken: string = Storage.getAccessToken()

if (user && accessToken) {
    defaultstate = {
        isUserAuthenticated: !isEmpty(accessToken) ? true : false,
        user: user || new User(),
        accessToken: accessToken,
        favouriteStoresList: [],
        reopenSidebarMemo: {
            shouldReopen: false,
            sidebarMenuKindToReopen: SidebarMenuKind.NONE,
        },
    }
}

const userReducer = (state: UserState = defaultstate, action: any) => {
    switch (action.type) {
        case UserDispatchTypes.USER_SUCCESS:
            return {
                ...state,
                isUserAuthenticated: true,
                user: action.response.user,
                accessToken: action.response.accessToken,
            }
        case UserDispatchTypes.USER_LOGOUT:
            // Clear persistent data
            Storage.clear()
            window.location.href = process.env.REACT_APP_NETIQ_LOGOUT || ''
            return {
                ...state,
                // isUserAuthenticated: false,
            }
        case UserDispatchTypes.USER_ERROR:
            let isUserAuthenticated: boolean = state.isUserAuthenticated
            //CHECK FOR ERROR 401 and change isUserAuthenticated accordingly

            if (action && action.response && action.response.response) {
                isUserAuthenticated = action.response.response.status !== 401

                if (!isUserAuthenticated) {
                    // Clear persistent data
                    Storage.clear()
                    window.location.href =
                        process.env.REACT_APP_NETIQ_LOGIN || ''
                }
            }

            return {
                ...state,
                isUserAuthenticated,
            }
        case UserDispatchTypes.FAVOURITE_STORES_LIST_SUCCESS:
            return {
                ...state,
                favouriteStoresList: action.response,
            }
        case UserDispatchTypes.SET_FAVOURITE_STORE_SUCCESS: {
            return {
                ...state,
                favouriteStoresList: action.response,
            }
        }
        case UserDispatchTypes.REMOVE_FAVOURITE_STORE_SUCCESS: {
            return {
                ...state,
                favouriteStoresList: action.response,
            }
        }
        case UserDispatchTypes.SET_USER_LANGUAGE:
            return {
                ...state,
                user: {
                    ...state.user,
                    curr_language: action.response.curr_language,
                },
            }
        case UserDispatchTypes.REOPEN_SIDEBAR_MEMO_SUCCESS:
            return {
                ...state,
                reopenSidebarMemo: action.response,
            }
        case UserDispatchTypes.USER_REQUEST:
        default:
            return state
    }
}

export default userReducer
