import React from 'react'
import { injectIntl } from 'react-intl'
import './style.css'
import FavIcon from './../../utils/icons/favourite'
import { Colors } from './../../colors'
import { Link } from 'react-router-dom'
import LogoImage from '../logo-image/LogoImage'
import { Store } from '../../models/store'

const SearchResult = (props: {
    intl: any
    store: Store
    isList: boolean
    isFav: boolean
    imageUrl: string
}) => {
    const { intl, store, isList, isFav, imageUrl } = props

    const colorsCss = isFav ? 'is-fav' : ''

    let searchResultsDetailsBodyLabel: string = ''
    let searchResultsDetailsBodyLabelColor: string = ''
    switch (store.channel_of_trade_string) {
        case 'CORE':
            searchResultsDetailsBodyLabel = intl.formatMessage({
                id: 'map.core',
            })
            searchResultsDetailsBodyLabelColor = '#36d4a6'
            break
        case 'HOSTED':
            searchResultsDetailsBodyLabel = intl.formatMessage({
                id: 'map.hosted',
            })
            searchResultsDetailsBodyLabelColor = '#fa7b83'
            break
        case 'OUTLET':
            searchResultsDetailsBodyLabel = intl.formatMessage({
                id: 'map.outlet',
            })
            searchResultsDetailsBodyLabelColor = '#fec400'
            break
        case 'NO CHANNEL OF TRADE':
            searchResultsDetailsBodyLabel = intl.formatMessage({
                id: 'map.noChannelOfTrade',
            })
            searchResultsDetailsBodyLabelColor = '#6b6b6b'
            break
        default:
            searchResultsDetailsBodyLabel = intl.formatMessage({
                id: 'map.core',
            })
            searchResultsDetailsBodyLabelColor = '#36d4a6'
            break
    }

    // thumbnail resolution: 200 / 500 / 900
    const resolutionParameter = isList ? '?impolicy=200' : '?impolicy=500'

    const thumbnailSrc = imageUrl
        ? imageUrl + resolutionParameter
        : '/image@3x.png'

    return (
        <>
            <Link to={'/store/' + store.store_id_string}>
                <div
                    className={
                        isList ? 'searchResult' : 'searchResult thumbnails'
                    }>
                    <div className="searchResultDetails">
                        <div className="searchResultsDetailsHeader">
                            {!isList && (
                                <div className="searchResultsDetailsHeaderFullImage">
                                    <img
                                        src={thumbnailSrc}
                                        width="100%"
                                        height="auto"
                                        alt=""
                                    />
                                </div>
                            )}
                            <div className="searchResultsDetailsHeaderImage">
                                <LogoImage banner={store.banner_desc_string} />
                            </div>
                            {isList && (
                                <div className="searchResultsDetailsHeaderID">
                                    #{store.id}
                                </div>
                            )}
                        </div>
                        <div className="searchResultsDetailsBody">
                            <div>
                                <div className="searchResultsDetailsBodyTitle">
                                    {store.store_name_string}
                                </div>
                                <div className="searchResultsDetailsBodySubtitle">
                                    {store.address_string} - {store.city_string}
                                </div>
                                <div
                                    className="searchResultsDetailsBodyLabel"
                                    style={{
                                        backgroundColor: searchResultsDetailsBodyLabelColor,
                                    }}>
                                    {searchResultsDetailsBodyLabel}
                                </div>
                                {store.store_host_name_string && (
                                    <div className="searchResultsDetailsBodyOwner">
                                        {store.store_host_name_string}
                                    </div>
                                )}
                            </div>
                            {!isList && (
                                <div className="searchResultsDetailsBodyID">
                                    #{store.id}
                                </div>
                            )}
                        </div>
                    </div>
                    {isList && (
                        <div className="searchResultThumbnail">
                            <div className="searchResultThumbnailImage">
                                <img
                                    src={thumbnailSrc}
                                    width="120"
                                    height="80"
                                    alt=""
                                />
                            </div>
                            <div
                                className={
                                    'searchResultThumbnailFavourite ' +
                                    colorsCss
                                }>
                                <span>
                                    {intl.formatMessage({
                                        id: 'store.favourite',
                                    })}
                                </span>
                                <FavIcon
                                    color={isFav ? Colors.pink : '#a2abb5'}
                                />
                            </div>
                        </div>
                    )}
                </div>
            </Link>
        </>
    )
}

export default injectIntl(SearchResult)
