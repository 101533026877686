export abstract class SolrFields {
    public static StoreKey = 'store_key_string'
    public static StoreID = 'store_id_string'
    public static ID = 'id'
    public static Address = 'address_string'
    public static Coordinate = 'store_coordinate'
    public static StoreName = 'store_name_string'
    public static Division = 'division_string'
    public static MacroRegion = 'macroregion_string'
    public static MacroRegionDesc = 'macroregion_desc_string'
    public static Country = 'country_string'
    public static CountryDesc = 'country_desc_string'
    public static ZoneDesc = 'zone_desc_string'
    public static City = 'city_string'
    public static BusinessUnit = 'business_unit_string'
    public static HostedRetail = 'store_host_name_string'
    public static ChannelOfTrade = 'channel_of_trade_string'
    public static Banner = 'banner_string'
    public static BannerDesc = 'banner_desc_string'
    public static Market = 'market_string'
    public static Region = 'region_string'
    public static RegionCode = 'region_code_string'
    public static BusinessModel = 'business_model_string'
    public static StoreType = 'store_type_string'
    public static SapPlanningChannel = 'sap_planning_channel_string'
    public static StoreLocation = 'store_location_string'
    public static Segment = 'segment_string'
    public static StoreSegment1 = 'store_segment_1_string'
    public static StoreRentEndDate = 'store_rent_end_date_long'
    public static LabInStoreNoLab = 'lab_in_store_no_lab_string'
    public static DigitalWindow = 'digital_window_string'
    public static StoreComments = 'store_comments_string'
    public static SopSite = 'sop_site_string'
    public static StoreCommercialSqm = 'store_commercial_sqm_double'
    public static StoreTotalSqm = 'store_total_sqm_double'
    public static StateProvince = 'state_province_string'
    public static SapStoreSegment = 'sap_store_segment_string'
    public static OpenDate = 'open_date_long'
    public static CloseDate = 'close_date_long'
    public static ZoneRegionalAreaManager = 'zone_regional_area_manager_string'
    public static StoreDesignGroup = 'store_design_group_string'
    public static StoreDesign = 'store_design_string'
    public static RemodelDate = 'remodel_date_long'
    public static RelocationDate = 'relocation_date_long'
}
