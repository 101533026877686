export enum SidebarMenuKind {
    NONE,
    BANNER,
    REGION,
    REMODEL,
    RELOCATION,
    NEW,
    HOSTED_RETAIL,
    SURVEY_DATE,
    PROFILE_AND_SETTINGS,
    SEARCH_RESULTS,
    FAVOURITES,
    MAIN_PROJECT,
}
