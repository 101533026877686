import React, { useEffect, useState } from 'react'
import { injectIntl } from 'react-intl'
import './style.css'
import { ReactComponent as IconList } from './../../utils/icons/elements-icon-list.svg'
import { ReactComponent as IconPictures } from './../../utils/icons/elements-icon-pictures.svg'
import { ProgressBar } from 'primereact/progressbar'
import {
    getLastIntervalFromDateFilters,
    useWindowResize,
} from '../../utils/commonFunctions'
import SearchResults from './../search-results'
import { Store } from '../../models/store'
import { Photo } from '../../models/photo-list'

type ResultsProps = {
    intl: any
    storesResults: Store[]
    favouriteStoresList: Store[]
    thumbnails: Photo[]
    dateFilters: any[]
    getThumbnails: (storeIds: string[],
        dates: {
            from: string,
            to: string
        }[]) => (dispatch: any, getState: any) => Promise<void>
    sizeType: number
}

const ResultsSidebarContent = (props: ResultsProps) => {
    const {
        storesResults,
        favouriteStoresList,
        getThumbnails,
        dateFilters,
        intl,
        sizeType,
    } = props

    const isMobileNow = useWindowResize()

    // state used to switch the store list view
    // list with small thumbnail (true) or big thumbnails (false)
    const [isList, setIsList] = useState(true)

    useEffect(() => {
        if (isMobileNow && isList) {
            setIsList(false)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isMobileNow])

    // handle the time interval to show the thumbnails
    // default: current year
    // if date filters are active: the most recent interval
    const [date, setDate] = useState(
        getLastIntervalFromDateFilters(dateFilters)
    )

    useEffect(() => {
        const newDate = getLastIntervalFromDateFilters(dateFilters)
        setDate(newDate)
    }, [dateFilters])

    // lazy loading handlers
    const [visibleStoresCounter, setVisibleStoresCounter] = useState(10)
    const [visibleStores, setVisibleStores] = useState(
        storesResults.slice(0, visibleStoresCounter)
    )
    const [loading, setLoading] = useState(false)
    const [thumbnailList, setThumbnailList] = useState(props.thumbnails)

    useEffect(() => {
        setVisibleStoresCounter(10)
        setVisibleStores(storesResults.slice(0, visibleStoresCounter))
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [storesResults])

    useEffect(() => {
        setVisibleStores(storesResults.slice(0, visibleStoresCounter))
        getLastTenStoresPhotos()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [visibleStoresCounter])

    useEffect(() => {
        if (props.thumbnails?.length) {
            setThumbnailList(thumbnailList.concat(props.thumbnails))
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.thumbnails])

    const getLastTenStoresPhotos = () => {
        const lastTenIds = storesResults
            .slice(visibleStoresCounter - 10, visibleStoresCounter)
            .map((st) => st.store_id_string)

        let from =  new Date(dateFilters[0].selectedFrom).getTime()
        let to = dateFilters[0].selectedTo ? new Date(dateFilters[0].selectedTo).getTime() : new Date().getTime()

        getThumbnails(lastTenIds, [{from: from.toString(), to: to.toString()}])
    }

    const handleScroll = (e: any) => {
        const bottom =
            Math.floor(e.target.scrollHeight - e.target.scrollTop) -
                Math.floor(e.target.clientHeight) <=
            1
        if (bottom && visibleStoresCounter < storesResults.length) {
            setLoading(true)
            setVisibleStoresCounter(visibleStoresCounter + 10)
        } else {
            setLoading(false)
        }
    }

    const [counter, setCounter] = useState(storesResults.length)

    const [checkId, setCheckId] = useState([])

    useEffect(() => {
        console.log(thumbnailList, storesResults)
        let filters: any = []

        storesResults.map((store: Store, index) => {
          thumbnailList.map((item) => {
              if(store.store_id_string === item.store_id) {
                  filters.push({ index: index, id: true})
                  }
              })
          })

        setCheckId(filters)
        console.log(filters)
    }, [storesResults, thumbnailList])

    const comparePhotos = (item_a: any, item_b: any) => {
        return (
           item_a?.index === item_b?.index
        );
    };

    const removeDuplicate = (array: []) => {
        let _newArray = []
    }


    return (
        <div className="resultsSidebarContent" data-size={sizeType}>
            <div className="resultsHeader">
                <div className="resultsHeaderTitle">
                    {storesResults.length}{' '}
                    {intl.formatMessage({ id: 'Stores' })}
                </div>
                <div className="resultsHeaderFilters">
                    <div className="resultsHeaderFiltersToggle">
                        <div
                            className={isList ? '' : 'active'}
                            onClick={() => setIsList(false)}>
                            <IconPictures />
                        </div>
                        <div
                            className={isList ? 'active' : ''}
                            onClick={() => setIsList(true)}>
                            <IconList />
                        </div>
                    </div>
                </div>
            </div>
            <div className="resultsBody" onScroll={(e) => handleScroll(e)}>
                <SearchResults
                    intl={intl}
                    stores={visibleStores}
                    favouriteStores={favouriteStoresList}
                    isList={isList}
                    imageList={thumbnailList}
                    isLoading={loading}
                    setCounter={setCounter}
                />
                {loading && visibleStores.length > 0 && (
                    <ProgressBar
                        mode="indeterminate"
                        style={{ height: '6px' }}
                    />
                )}
            </div>
        </div>
    )
}

export default injectIntl(ResultsSidebarContent)
