import { SolrFields } from '../solr-fields'
import { NRRFilters } from './nrr-filters'

export class NewFilters extends NRRFilters {
    selectedNewMonths: Set<number>
    selectedNewYears: Set<number>

    constructor(data?: any) {
        super()

        this.selectedNewMonths = data?.months || new Set()
        this.selectedNewYears = data?.years || new Set()
    }

    static getSolrFQFilter = (newFilters?: NewFilters) => {
        let newSolrFq = ''

        if (newFilters && newFilters.selectedNewYears.size) {
            const { selectedNewYears, selectedNewMonths } = newFilters

            const solrFilterData = NRRFilters.prepareSolrFQFilter(
                selectedNewYears,
                selectedNewMonths
            )

            newSolrFq = `&fq=${SolrFields.OpenDate}:(${solrFilterData.nrr})&fq=${SolrFields.OpenDate}:(${solrFilterData.openDate})&fq=${SolrFields.CloseDate}:(${solrFilterData.closeDate})`
        }

        return newSolrFq
    }
}
