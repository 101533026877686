import { SearchableFilter } from './searchable-filter'

export type BUElement = {
    count: number
    selected: boolean
}

export type BUsDict = Record<string, BUElement>

export class BUsFilters extends SearchableFilter {
    selectedBUs: Set<string>
    id: string
    title: string
    fuseEngine: any
    viewAllVisible: boolean
    searchableFilter: BUsDict
    searchedFilter: BUsDict
    searchValue: string
    isPanelCollapsed: boolean
    selectAllSelected: boolean

    constructor(data?: any) {
        super()

        this.selectedBUs = new Set()
        this.id = 'bu'
        this.title = ''
        this.fuseEngine = null
        this.viewAllVisible = true
        this.searchableFilter = data
        this.searchedFilter = data
        this.searchValue = ''
        this.isPanelCollapsed = true
        this.selectAllSelected = false
    }
}
