import { useEffect, useState } from 'react'
import { injectIntl } from 'react-intl'
import { StyledWrapper } from './StyledWrapper'
import StoreHeader from '../store-header/StoreHeader'
import GeneralInfoColumn from '../general-info/GeneralInfoColumn'
import StoreGallery from '../store-gallery/StoreGallery'
import { Photo } from '../../../models/photo-list'
import { TabView, TabPanel } from 'primereact/tabview'
import {
    dividePhotosIntoSections,
    useWindowResize,
} from '../../../utils/commonFunctions'
import GalleryModal from '../gallery-modal/GalleryModal'

const ResponsiveWrapper = (props: any) => {
    const {
        storeDetails,
        isFavourite,
        setFavourite,
        photoList,
        totalPhotosCount,
        dates,
        downloadPhotos,
        onChangeDates,
        intl,
        isApp,
        exportStoreDeck,
        selectedDeckPhotos,
        setSelectedDeckPhotos,
        loadingExportStoreDeck,
        macroarea,
        setMacroarea,
        macroareas,
        setMacroareas,
    } = props

    const [activeTab, setActiveTab] = useState<number>(0)
    const [isModalOpen, setIsModalOpen] = useState<boolean>(false)
    const [shouldShowPhotosSelection, setShouldShowPhotosSelection] =
        useState<boolean>(false)
    const [carouselState, setCarouselState] = useState({
        page: 0,
        tab: 'overview',
    })


    const [banner, setBanner] = useState<string>('')
    const [storeName, setStoreName] = useState<string>('')


    useEffect(() => {
       setBanner(props.storeDetails.banner_desc_string)
        setStoreName(props.storeDetails.store_name_string)
    }, [props.storeDetails])

    useEffect(() => {
        isModalOpen
            ? (document.body.style.overflow = 'hidden')
            : (document.body.style.overflow = 'unset')
    }, [isModalOpen])

    const isMobileNow = useWindowResize()

    var photoInSections = dividePhotosIntoSections(photoList, macroarea)

    const details = (
        <GeneralInfoColumn
            onPhotoNumberClick={() => setActiveTab(0)}
            openModal={() => setIsModalOpen(true)}
            setCarouselState={setCarouselState}
            floorPlan={
                photoList.filter(
                    (ph: Photo) => ph.macroarea === 'Floor Plan'
                )[0]
            }
            imageCount={totalPhotosCount}
            isFavourite={isFavourite}
            setFavourite={setFavourite}
            storeDetails={storeDetails}
        />
    )

    useEffect(() => {
        if (photoInSections && macroarea && macroareas) {
            let sections = Object.keys(photoInSections)
            if (sections?.length > 0) {
                let tmpMacroareas = [...macroareas]
                sections.forEach((s: any) => {
                    tmpMacroareas.push({
                        value: s,
                        label: s,
                        className: '',
                        command: (e: any) => setMacroarea(e.item.value),
                    })
                })
                if (macroareas?.length === 1) {
                    setMacroareas(tmpMacroareas)
                }
            }
        }
    }, [photoInSections, macroarea, macroareas, setMacroarea, setMacroareas])

    return (
        <StyledWrapper>
            {isMobileNow ? (
                <TabView
                    activeIndex={activeTab}
                    onTabChange={(e) => setActiveTab(e.index)}
                    className="tab-view">
                    <TabPanel
                        header={intl.formatMessage({
                            id: 'store_detail.photo',
                        })}
                        headerClassName="mobile-tab">
                        <div className="p-col-12 p-lg-8 photo-container p-p-3 p-p-lg-6 p-pl-lg-5 ">
                            <StoreHeader
                                dates={dates}
                                setDates={onChangeDates}
                                downloadPhotos={downloadPhotos}
                                images={{ count: photoList.length, list: [] }}
                                isApp={isApp}
                                photoList={photoList}
                                setShouldShowPhotosSelection={setShouldShowPhotosSelection}
                                exportStoreDeck={exportStoreDeck}
                                loadingExportStoreDeck={loadingExportStoreDeck}
                                photoInSections={photoInSections}
                                storeDetails={storeDetails}
                                macroareas={macroareas}
                            />
                            <StoreGallery
                                photoList={photoList}
                                photoInSections={photoInSections}
                                openModal={() =>
                                    setIsModalOpen(true)
                                }
                                setCarouselState={setCarouselState}
                                showPhotosSelection={shouldShowPhotosSelection}
                                setShouldShowPhotosSelection={setShouldShowPhotosSelection}
                                exportStoreDeck={exportStoreDeck}
                                selectedDeckPhotos={selectedDeckPhotos}
                                setSelectedDeckPhotos={setSelectedDeckPhotos}
                                loadingExportStoreDeck={loadingExportStoreDeck}
                                macroarea={macroarea}
                            />
                        </div>
                    </TabPanel>
                    <TabPanel
                        header={intl.formatMessage({
                            id: 'store_detail.general_info',
                        })}
                        headerClassName="mobile-tab">
                        {details}
                    </TabPanel>
                </TabView>
            ) : (
                <div className="p-grid detail-page">
                    <div className="p-col-12 p-lg-8 photo-container p-p-3 p-p-lg-6 p-pl-lg-5 ">
                        <StoreHeader
                            dates={dates}
                            setDates={onChangeDates}
                            downloadPhotos={downloadPhotos}
                            images={{ count: photoList.length, list: [] }}
                            isApp={isApp}
                            photoList={photoList}
                            setShouldShowPhotosSelection={setShouldShowPhotosSelection}
                            exportStoreDeck={exportStoreDeck}
                            loadingExportStoreDeck={loadingExportStoreDeck}
                            photoInSections={photoInSections}
                            storeDetails={storeDetails}
                            macroareas={macroareas}
                        />
                        <StoreGallery
                            photoList={photoList}
                            photoInSections={photoInSections}
                            openModal={() =>
                                setIsModalOpen(true)
                            }
                            setCarouselState={setCarouselState}
                            showPhotosSelection={shouldShowPhotosSelection}
                            setShouldShowPhotosSelection={setShouldShowPhotosSelection}
                            exportStoreDeck={exportStoreDeck}
                            selectedDeckPhotos={selectedDeckPhotos}
                            setSelectedDeckPhotos={setSelectedDeckPhotos}
                            loadingExportStoreDeck={loadingExportStoreDeck}
                            macroarea={macroarea}
                        />
                    </div>
                    <div className="p-col-4 details-container">{details}</div>
                </div>
            )}
            <GalleryModal
                open={isModalOpen}
                close={() => setIsModalOpen(false)}
                photoInSections={photoInSections}
                photoList={photoList}
                store={{
                    name: storeDetails.store_name_string,
                    id: storeDetails.id,
                }}
                carouselState={carouselState}
                setCarouselState={setCarouselState}
                isApp={isApp}
                banner={banner}
                storeName={storeName}
            />
        </StyledWrapper>
    )
}

export default injectIntl(ResponsiveWrapper)
