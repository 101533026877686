import { BaseDispatcher } from './base'

export enum StoresDispatchTypes {
    REFRESH_IDB_DONE = 'REFRESH_IDB_DONE',
    SINGLE_STORE_REQUEST = 'SINGLE_STORE_REQUEST',
    SINGLE_STORE_SUCCESS = 'SINGLE_STORE_SUCCESS',
    SEARCH_RESULTS_REQUEST = 'SEARCH_RESULTS_REQUEST',
    SEARCH_RESULTS_SUCCESS = 'SEARCH_RESULTS_SUCCESS',
    SET_TOOLBAR_SEARCH_VALUE_DONE = 'SET_TOOLBAR_SEARCH_VALUE_DONE',
    TOOLBAR_INIT_FUSE_REQUEST = 'TOOLBAR_INIT_FUSE_REQUEST',
    TOOLBAR_INIT_FUSE_SUCCESS = 'TOOLBAR_INIT_FUSE_SUCCESS',
    TOOLBAR_SEARCH_RESULTS_REQUEST = 'TOOLBAR_SEARCH_RESULTS_REQUEST',
    TOOLBAR_SEARCH_RESULTS_SUCCESS = 'TOOLBAR_SEARCH_RESULTS_SUCCESS',
    STORES_REQUEST = 'STORES_REQUEST',
    STORES_SUCCESS = 'STORES_SUCCESS',
    STORES_ERROR = 'STORES_ERROR',
}

export class StoresDispatcher extends BaseDispatcher {
    static RefreshIDBDone = (success: any): any => {
        return new StoresDispatcher(
            StoresDispatchTypes.REFRESH_IDB_DONE,
            success
        ).onDispatchSuccess()
    }

    static SingleStoreRequest = (): any => {
        return new StoresDispatcher(
            StoresDispatchTypes.SINGLE_STORE_REQUEST,
            {}
        ).onDispatchSuccess()
    }

    static SingleStoreSuccess = (success: any): any => {
        return new StoresDispatcher(
            StoresDispatchTypes.SINGLE_STORE_SUCCESS,
            success
        ).onDispatchSuccess()
    }

    static SearchResultsRequest = (): any => {
        return new StoresDispatcher(
            StoresDispatchTypes.SEARCH_RESULTS_REQUEST,
            {}
        ).onDispatchSuccess()
    }

    static SearchResultsSuccess = (success: any): any => {
        return new StoresDispatcher(
            StoresDispatchTypes.SEARCH_RESULTS_SUCCESS,
            success
        ).onDispatchSuccess()
    }

    static SetToolbarSearchValueDone = (success: any): any => {
        return new StoresDispatcher(
            StoresDispatchTypes.SET_TOOLBAR_SEARCH_VALUE_DONE,
            success
        ).onDispatchSuccess()
    }

    static ToolbarInitFuseRequest = (): any => {
        return new StoresDispatcher(
            StoresDispatchTypes.TOOLBAR_INIT_FUSE_REQUEST,
            {}
        ).onDispatchSuccess()
    }

    static ToolbarInitFuseSuccess = (success: any): any => {
        return new StoresDispatcher(
            StoresDispatchTypes.TOOLBAR_INIT_FUSE_SUCCESS,
            success
        ).onDispatchSuccess()
    }

    static ToolbarSearchResultsRequest = (): any => {
        return new StoresDispatcher(
            StoresDispatchTypes.TOOLBAR_SEARCH_RESULTS_REQUEST,
            {}
        ).onDispatchSuccess()
    }

    static ToolbarSearchResultsSuccess = (success: any): any => {
        return new StoresDispatcher(
            StoresDispatchTypes.TOOLBAR_SEARCH_RESULTS_SUCCESS,
            success
        ).onDispatchSuccess()
    }

    static StoresRequest = (request: any): any => {
        return new StoresDispatcher(
            StoresDispatchTypes.STORES_REQUEST,
            request
        ).onDispatchSuccess()
    }

    static StoresSuccess = (success: any): any => {
        return new StoresDispatcher(
            StoresDispatchTypes.STORES_SUCCESS,
            success
        ).onDispatchSuccess()
    }

    static StoresError = (error: any): any => {
        return new StoresDispatcher(
            StoresDispatchTypes.STORES_ERROR,
            error
        ).onDispatchError()
    }
}
