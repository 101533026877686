import { Colors } from '../../../colors'
import styled from 'styled-components'

export const StyledModal = styled.div<{ isFullScreen: boolean }>`
    .p-dialog .p-dialog-header {
        display: ${(p) => (p.isFullScreen ? 'none' : '')};
        height: 0;
    }
    .p-dialog-header-close-icon{
        display: none;
    }
    .p-dialog-content {
        padding-top: 20px;
    }
    h4 {
        font-size: 20px;
        color: ${Colors.lightGrey};
        letter-spacing: 1.52px;
    }
    
    .img-box {
        height: 240px;
        margin: 8px 0 0;
    }
    .img-container {
        position: relative;
        height: 100%;
        overflow: hidden;
        border-radius: 4px;
        overflow: hidden;
    }
    .double-height {
        height: 480px;
    }
    .img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        border-radius: 4px;
        cursor: pointer;
        transition: 0.3s;
    }
    .img-label {
        position: absolute;
        left: 16px;
        bottom: 16px;
        padding: 4px 13px;
        opacity: 0.9;
        border-radius: 2px;
        -webkit-backdrop-filter: blur(6px);
        backdrop-filter: blur(6px);
        background-color: ${Colors.white};
        font-size: 12px;
        letter-spacing: 0.17px;
        color: ${Colors.grey6};
    }
    .view-more-button {
        text-transform: uppercase;
        font-weight: 500;
    }
    @media (max-width: 767px) {
        h4 {
            font-size: 12px;
            font-weight: 500;
            font-stretch: normal;
            font-style: normal;
            line-height: normal;
            letter-spacing: 0.3px;
            color: #7b8a9a;
        }
    }
`

