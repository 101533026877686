import { SearchableFilter } from './searchable-filter'

export type MRElement = {
    desc: string
    count: number
    selected: boolean
}

export type MacroRegionsDict = Record<string, MRElement>

export class MacroRegionsFilters extends SearchableFilter {
    selectedMacroRegions: Set<string>
    macroregions: MacroRegionsDict

    id: string
    title: string
    fuseEngine: any
    viewAllVisible: boolean
    searchableFilter: MacroRegionsDict
    searchedFilter: MacroRegionsDict
    searchValue: string
    isPanelCollapsed: boolean
    selectAllSelected: boolean

    constructor(data?: any) {
        super()

        this.selectedMacroRegions = new Set()
        this.id = 'mr'
        this.title = ''
        this.fuseEngine = null
        this.viewAllVisible = true
        this.searchableFilter = data
        this.searchedFilter = data
        this.searchValue = ''
        this.isPanelCollapsed = true
        this.selectAllSelected = false

        this.selectedMacroRegions = new Set()
        this.macroregions = data
    }
}
