import { Toolbar } from 'primereact/toolbar'
import { connect } from 'react-redux'
import { StyledFilterToolbar } from './style'
import { injectIntl } from 'react-intl'
import { AppState } from '../../redux/states/appState'
import { SidebarMenuKind } from '../../models/sidebar-menu-kind'
import { setSelectedSidebarMenu } from '../../redux/actions/filters'
import UserProfile from '../../components/user-profile'
import Search from '../../components/search'
import { setUserLanguage } from '../../redux/actions/user'
import {
    getToolbarSearchResults,
    setToolbarSearchValue,
    toolbarInitFuse,
} from '../../redux/actions/stores'
import { SearchingFor } from '../../searchingfor'
import { createLoadingSelector } from '../../redux/selectors'

const AppToolbar = (props: any) => {
    const { intl, leftContents } = props

    const rightContents: any = (
        <>
            <div className="searchTopbar">
                <Search
                    placeholder={
                        /*
                        props.loadingInitFuse
                            ? intl.formatMessage({
                                  id: 'search.searching_for.loading.text',
                              })
                            : */ intl.formatMessage({
                            id: 'topbar.search.placeholder',
                        })
                    }
                    searchValue={props.toolbarSearchValue}
                    setSearchValue={props.setToolbarSearchValue}
                    toolbarFuseEngines={props.toolbarFuseEngines}
                    toolbarInitFuse={props.toolbarInitFuse}
                    searchingForDataSource={SearchingFor.dataSource(intl)}
                    searchHandler={props.getToolbarSearchResults}
                    searchHandlerNoSidebar={
                        props.getToolbarSearchResultsNoSidebar
                    }
                    loading={props.loadingInitFuse}
                />
            </div>
            <UserProfile />
        </>
    )
    return (
        <StyledFilterToolbar>
            <Toolbar left={leftContents} right={rightContents} />
        </StyledFilterToolbar>
    )
}

const loadingInitFuseSelector = createLoadingSelector(['TOOLBAR_INIT_FUSE'])

const mapStateToProps = (state: AppState) => ({
    selectedSidebarMenu: state.filtersReducer.selectedSidebarMenu,
    toolbarSearchValue: state.storesReducer.toolbarSearchValue,
    loadingInitFuse: loadingInitFuseSelector(state),
    toolbarFuseEngines: state.storesReducer.toolbarFuseEngines,
})

const mapDispatchToProps = (dispatch: any) => ({
    setSelectedSidebarMenu: (newSelectedFilter: SidebarMenuKind) =>
        dispatch(setSelectedSidebarMenu(newSelectedFilter)),
    setLanguage: (langLocaleShort: string) =>
        dispatch(setUserLanguage(langLocaleShort)),
    setToolbarSearchValue: (searchValue: string) =>
        dispatch(setToolbarSearchValue(searchValue)),
    getToolbarSearchResults: (searchFieldName: string) =>
        dispatch(getToolbarSearchResults(searchFieldName)).then((e: any) =>
            dispatch(setSelectedSidebarMenu(SidebarMenuKind.SEARCH_RESULTS))
        ),
    getToolbarSearchResultsNoSidebar: (searchFieldName: string) =>
        dispatch(getToolbarSearchResults(searchFieldName)),
    toolbarInitFuse: () => dispatch(toolbarInitFuse()),
})

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(injectIntl(AppToolbar))
