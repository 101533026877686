import { connect } from 'react-redux'
import { FilterCalendarComponent } from '../components/filter/filter-calendar/filter-calendar.component'
import FilterSearchStoresClearSection from '../components/filter/filter-searchstores-clear-section'
import { SidebarMenuKind } from '../models/sidebar-menu-kind'
import {
    clearAllFilters,
    setSelectedRemodelMonth,
    setSelectedRemodelYear,
    setSelectedSidebarMenu,
} from '../redux/actions/filters'
import { getSearchResults } from '../redux/actions/stores'
import { createLoadingSelector } from '../redux/selectors'
import { AppState } from '../redux/states/appState'

const RemodelFilter = (props: any) => {
    return (
        <>
            <FilterSearchStoresClearSection
                noGutter
                onClearAllFilters={(e: any) => props.clearAllFilters()}
                isLoadingSearchResults={props.loadingSearchResults}
                isSearchResultsDisabled={props.loadingStores}
                onSearchResults={() => props.getSearchResults()}
            />
            <FilterCalendarComponent
                selectedMonths={props.remodelFilters.selectedRemodelMonths}
                selectedYears={props.remodelFilters.selectedRemodelYears}
                onMonthChange={props.setSelectedRemodelMonth}
                onYearChange={props.setSelectedRemodelYear}
            />
        </>
    )
}

const loadingStores = createLoadingSelector(['STORES'])
const loadingSearchResults = createLoadingSelector(['SEARCH_RESULTS'])

const mapStateToProps = (state: AppState) => ({
    loadingStores: loadingStores(state),
    loadingSearchResults: loadingSearchResults(state),
    remodelFilters: state.filtersReducer.remodelFilters,
})

const mapDispatchToProps = (dispatch: any) => ({
    clearAllFilters: () => dispatch(clearAllFilters()),
    setSelectedRemodelMonth: (month: number) =>
        dispatch(setSelectedRemodelMonth(month)),
    setSelectedRemodelYear: (year: number) =>
        dispatch(setSelectedRemodelYear(year)),
    getSearchResults: () =>
        dispatch(getSearchResults()).then((e: any) =>
            dispatch(setSelectedSidebarMenu(SidebarMenuKind.SEARCH_RESULTS))
        ),
})

export default connect(mapStateToProps, mapDispatchToProps)(RemodelFilter)
