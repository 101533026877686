import { Colors } from './colors'
import { SolrFields } from './solr-fields'

export abstract class SearchingFor {
    private static replWithStyle = `
        font-family: AvenirLTPro;
        font-size: 15px;
        font-weight: 500;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
        color: ${Colors.grey5};
    `

    private static dataSourceStyle = `
        font-family: AvenirLTPro;
        font-size: 15px;
        font-weight: 900;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
        color: #4e5b69;
        margin-left: 6px;
    `

    // 0 very hard to match -> 1 everything matches
    public static searchThresholds = {
        id: 0.2,
        macroregion: 0.2,
        storeName: 0.2,
        division: 0.2,
        banner: 0.2,
        market: 0.4,
        country: 0.7,
        region: 0.2,
        city: 0.2,
        address: 0.4,
        businessModel: 0.2,
        storeType: 0.2,
        channelOfTrade: 0.2,
        storeHostName: 0.2,
        segment: 0.2,
        zone: 0.2,
        storeDesignGroup: 0.2,
        storeDesign: 0.2,
    }

    public static dataSource = (intl: any) => [
        {
            key: `<p style="${SearchingFor.dataSourceStyle} margin-left: 0px;">REPLWITH</p>`,
            solrProp: SolrFields.ID,
        },
        {
            key: `<div style="display: flex;"><p style="${SearchingFor.replWithStyle
                }">REPLWITH</p><p style="${SearchingFor.dataSourceStyle
                }">${intl.formatMessage({
                    id: `topbar.searching_for.macroregion.label`,
                })}</p></div>`,
            solrProp: SolrFields.MacroRegionDesc,
        },
        {
            key: `<div style="display: flex;"><p style="${SearchingFor.replWithStyle
                }">REPLWITH</p><p style="${SearchingFor.dataSourceStyle
                }">${intl.formatMessage({
                    id: `topbar.searching_for.store_name.label`,
                })}</p></div>`,
            solrProp: SolrFields.StoreName,
        },
        {
            key: `<div style="display: flex;"><p style="${SearchingFor.replWithStyle
                }">REPLWITH</p><p style="${SearchingFor.dataSourceStyle
                }">${intl.formatMessage({
                    id: `topbar.searching_for.division.label`,
                })}</p></div>`,
            solrProp: SolrFields.Division,
        },
        {
            key: `<div style="display: flex;"><p style="${SearchingFor.replWithStyle
                }">REPLWITH</p><p style="${SearchingFor.dataSourceStyle
                }">${intl.formatMessage({
                    id: `topbar.searching_for.banner.label`,
                })}</p></div>`,
            solrProp: SolrFields.BannerDesc,
        },
        {
            key: `<div style="display: flex;"><p style="${SearchingFor.replWithStyle
                }">REPLWITH</p><p style="${SearchingFor.dataSourceStyle
                }">${intl.formatMessage({
                    id: `topbar.searching_for.market.label`,
                })}</p></div>`,
            solrProp: SolrFields.Market,
        },
        {
            key: `<div style="display: flex;"><p style="${SearchingFor.replWithStyle
                }">REPLWITH</p><p style="${SearchingFor.dataSourceStyle
                }">${intl.formatMessage({
                    id: `topbar.searching_for.country.label`,
                })}</p></div>`,
            solrProp: SolrFields.CountryDesc,
        },
        {
            key: `<div style="display: flex;"><p style="${SearchingFor.replWithStyle
                }">REPLWITH</p><p style="${SearchingFor.dataSourceStyle
                }">${intl.formatMessage({
                    id: `topbar.searching_for.region.label`,
                })}</p></div>`,
            solrProp: SolrFields.Region,
        },
        {
            key: `<div style="display: flex;"><p style="${SearchingFor.replWithStyle
                }">REPLWITH</p><p style="${SearchingFor.dataSourceStyle
                }">${intl.formatMessage({
                    id: `topbar.searching_for.city.label`,
                })}</p></div>`,
            solrProp: SolrFields.City,
        },
        {
            key: `<div style="display: flex;"><p style="${SearchingFor.replWithStyle
                }">REPLWITH</p><p style="${SearchingFor.dataSourceStyle
                }">${intl.formatMessage({
                    id: `topbar.searching_for.address.label`,
                })}</p></div>`,
            solrProp: SolrFields.Address,
        },
        {
            key: `<div style="display: flex;"><p style="${SearchingFor.replWithStyle
                }">REPLWITH</p><p style="${SearchingFor.dataSourceStyle
                }">${intl.formatMessage({
                    id: `topbar.searching_for.business_model.label`,
                })}</p></div>`,
            solrProp: SolrFields.BusinessModel,
        },
        {
            key: `<div style="display: flex;"><p style="${SearchingFor.replWithStyle
                }">REPLWITH</p><p style="${SearchingFor.dataSourceStyle
                }">${intl.formatMessage({
                    id: `topbar.searching_for.store_type.label`,
                })}</p></div>`,
            solrProp: SolrFields.StoreType,
        },
        {
            key: `<div style="display: flex;"><p style="${SearchingFor.replWithStyle
                }">REPLWITH</p><p style="${SearchingFor.dataSourceStyle
                }">${intl.formatMessage({
                    id: `topbar.searching_for.channel_of_trade.label`,
                })}</p></div>`,
            solrProp: SolrFields.ChannelOfTrade,
        },
        {
            key: `<div style="display: flex;"><p style="${SearchingFor.replWithStyle
                }">REPLWITH</p><p style="${SearchingFor.dataSourceStyle
                }">${intl.formatMessage({
                    id: `topbar.searching_for.store_host_name.label`,
                })}</p></div>`,
            solrProp: SolrFields.HostedRetail,
        },
        {
            key: `<div style="display: flex;"><p style="${SearchingFor.replWithStyle
                }">REPLWITH</p><p style="${SearchingFor.dataSourceStyle
                }">${intl.formatMessage({
                    id: `topbar.searching_for.segment.label`,
                })}</p></div>`,
            solrProp: SolrFields.Segment,
        },
        {
            key: `<div style="display: flex;"><p style="${SearchingFor.replWithStyle
                }">REPLWITH</p><p style="${SearchingFor.dataSourceStyle
                }">${intl.formatMessage({
                    id: `topbar.searching_for.zone.label`,
                })}</p></div>`,
            solrProp: SolrFields.ZoneDesc,
        },
        {
            key: `<div style="display: flex;"><p style="${SearchingFor.replWithStyle
                }">REPLWITH</p><p style="${SearchingFor.dataSourceStyle
                }">${intl.formatMessage({
                    id: `topbar.searching_for.store_design_group.label`,
                })}</p></div>`,
            solrProp: SolrFields.StoreDesignGroup,
        },
        {
            key: `<div style="display: flex;"><p style="${SearchingFor.replWithStyle
                }">REPLWITH</p><p style="${SearchingFor.dataSourceStyle
                }">${intl.formatMessage({
                    id: `topbar.searching_for.store_design.label`,
                })}</p></div>`,
            solrProp: SolrFields.StoreDesign,
        },
    ]
}
