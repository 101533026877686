import React from 'react'
import ReactDOM from 'react-dom'
import './index.css'
import App from './App'
import reportWebVitals from './reportWebVitals'
import { Provider } from 'react-redux'
import store from './redux/store'
import { I18nWrapper } from './utils/localization/i18nWrapper'
import packageJson from '../package.json'

console.debug(
    '%c' +
        packageJson.name +
        ' v' +
        packageJson.version +
        ' - ENVIRONMENT: ' +
        process.env.REACT_APP_ENV +
        ' - BUILD_TYPE: ' +
        process.env.NODE_ENV,
    'background: #C4538F; color: black; font-size:12px; padding: 5px;'
)

if (process.env.NODE_ENV === 'production') {
    console.log = function () {}
}

ReactDOM.render(
    <Provider store={store}>
        <I18nWrapper>
            <App />
        </I18nWrapper>
    </Provider>,
    document.getElementById('root')
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
