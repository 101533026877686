import { injectIntl } from 'react-intl'
import { StyledColumn } from './StyledColumn'
import { Card } from 'primereact/card'
import glasses from '../../../utils/icons/glasses/glasses.png'
import image from '../../../utils/icons/image/image.png'
import { Button } from 'primereact/button'

const StatisticsCard = (props: any) => {
    const { cardContent, onClick } = props

    let icon
    switch (cardContent.icon) {
        case 'glasses':
            icon = glasses
            break
        case 'image':
            icon = image
            break
        default:
            break
    }

    return (
        <StyledColumn>
            <Card className="card">
                <div className="p-d-flex ">
                    <div className="img-container px-0">
                        <img
                            src={icon}
                            alt={cardContent.icon}
                            className="img-stat"
                        />
                    </div>
                    <div className="p-grid p-pl-2 card-content">
                        <h4
                            className="p-col-12 card-number p-pb-0"
                            onClick={onClick}>
                            {cardContent.number}
                        </h4>
                        <span className="p-col card-name p-py-0">
                            {cardContent.name}
                        </span>
                        {cardContent.button && (
                            <span className="p-col card-button p-p-0 p-d-none">
                                <Button
                                    label={cardContent.button}
                                    className="p-button-text p-text-bold p-p-0"
                                />
                            </span>
                        )}
                    </div>
                </div>
            </Card>
        </StyledColumn>
    )
}

export default injectIntl(StatisticsCard)
