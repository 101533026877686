import { useContext, useEffect, useRef } from 'react'
import { BannerFilters } from '../../models/banner-filters'
import { SidebarMenuKind } from '../../models/sidebar-menu-kind'
import { HostedRetailFilters } from '../../models/hosted-retail-filters'
import { RegionFilters } from '../../models/region-filters'
import { Store } from '../../models/store'
import { i18nContext } from '../../utils/localization/i18nWrapper'
import { User } from '../../models/user'
import { RemodelFilters } from '../../models/remodel-filters'
import { RelocationFilters } from '../../models/relocation-filters'
import { NewFilters } from '../../models/new-filters'
import { SurveyDateFilters } from '../../models/survey-date-filters'

export const usePrevious = <T>(value: T): T | undefined => {
    const ref = useRef<T>()
    useEffect(() => {
        ref.current = value
    })
    return ref.current
}

const refreshInnerRegionFilters = (
    regionFilters: RegionFilters,
    getCountries: Function,
    getZoneDescs: Function,
    getCities: Function,
    getBUs: Function,
    clearAllCountriesFilters: Function,
    clearAllZoneDescsFilters: Function,
    clearAllCitiesFilters: Function
) => {
    const { macroregionsFilters, countriesFilters, zoneDescsFilters } =
        regionFilters

    // Fetch the other filters (only if there's at least a related element that is selected)
    if (macroregionsFilters.selectedMacroRegions.size > 0) {
        getCountries()
        getBUs()

        if (countriesFilters.selectedCountries.size) {
            getZoneDescs()

            if (zoneDescsFilters.selectedZoneDescs.size) {
                getCities()
            } else {
                clearAllCitiesFilters()
            }
        } else {
            clearAllZoneDescsFilters()
        }
    } else {
        clearAllCountriesFilters()
    }
}

export const useRefreshApplicationData = (
    currentUser: User,
    stores: Store[],
    bannerFilters: BannerFilters,
    regionFilters: RegionFilters,
    remodelFilters: RemodelFilters,
    relocationFilters: RelocationFilters,
    newFilters: NewFilters,
    hostedRetailFilters: HostedRetailFilters,
    surveyDateFilters: SurveyDateFilters,
    getStores: Function,
    getFavouriteStoresList: Function,
    getBannerDesc: Function,
    getChannelOfTrade: Function,
    getSegment: Function,
    getStoreDesign: Function,
    getSelectionBest: Function,
    getMacroRegions: Function,
    getCountries: Function,
    getZoneDescs: Function,
    getCities: Function,
    getBUs: Function,
    getHostedRetail: Function,
    selectedSidebarMenu: SidebarMenuKind,
    clearAllCountriesFilters: Function,
    clearAllZoneDescsFilters: Function,
    clearAllCitiesFilters: Function,
    fibonacciNumber: {
        first: number
        second: number
    }
): void => {
    const {
        bannerDescFilters,
        channelOfTradeFilters,
        segmentFilters,
        storeDesignFilters,
        selectionBestFilters,
    } = bannerFilters

    const {
        macroregionsFilters,
        countriesFilters,
        zoneDescsFilters,
        citiesFilters,
        busFilters,
    } = regionFilters

    const sdCount = storeDesignFilters.getSelectedStoreDesignsCount()

    const prevSize = usePrevious({
        bannerdesc: bannerDescFilters.selectedBannerDesc.size,
        channeloftrade: channelOfTradeFilters.selectedChannelOfTrade.size,
        segment: segmentFilters.selectedSegment.size,
        storedesign: sdCount,
        remodelyears: remodelFilters.selectedRemodelYears.size,
        remodelmonths: remodelFilters.selectedRemodelMonths.size,
        relocationyears: relocationFilters.selectedRelocationYears.size,
        relocationmonths: relocationFilters.selectedRelocationMonths.size,
        newyears: newFilters.selectedNewYears.size,
        newmonths: newFilters.selectedNewMonths.size,
        imagesquality:
            selectionBestFilters.imagesQualityFilters.selectedImagesQuality
                .size,
        mainprojects:
            selectionBestFilters.mainProjectsFilters.selectedMainProject.size,
        macroregions: macroregionsFilters.selectedMacroRegions.size,
        countries: countriesFilters.selectedCountries.size,
        zonedescs: zoneDescsFilters.selectedZoneDescs.size,
        cities: citiesFilters.selectedCities.size,
        bus: busFilters.selectedBUs.size,
        hostedretail: hostedRetailFilters.selectedHostedRetail.size,
        surveydatefrom: surveyDateFilters.selectedFrom,
        surveydateto: surveyDateFilters.selectedTo,
    })

    const { langInited, locale, switchLanguage } = useContext(i18nContext)

    useEffect(() => {
        // Fibonacci number check
        if (
            stores?.length < 1 &&
            fibonacciNumber.first > 0 &&
            fibonacciNumber.second < 9
        ) {
            getStores()
            getSelectionBest()
            getFavouriteStoresList()
        }

        // This occurs on the first render
        if (!prevSize) {
            const shortLocale = locale.substr(0, 2)
            if (shortLocale !== currentUser.curr_language && !langInited) {
                switchLanguage(
                    currentUser.curr_language === 'en' ? 'en-EN' : 'it-IT'
                )
            } else {
                // We fetch some information on the first render only
                if (stores?.length < 1) {
                    getStores()
                    getSelectionBest()
                    getFavouriteStoresList()
                }
            }
            return
        }

        const currentSDCount = storeDesignFilters.getSelectedStoreDesignsCount()

        const allSizes = [
            bannerDescFilters.selectedBannerDesc.size,
            channelOfTradeFilters.selectedChannelOfTrade.size,
            segmentFilters.selectedSegment.size,
            currentSDCount,
            selectionBestFilters.imagesQualityFilters.selectedImagesQuality
                .size,
            selectionBestFilters.mainProjectsFilters.selectedMainProject.size,
            macroregionsFilters.selectedMacroRegions.size,
            countriesFilters.selectedCountries.size,
            zoneDescsFilters.selectedZoneDescs.size,
            citiesFilters.selectedCities.size,
            busFilters.selectedBUs.size,
            hostedRetailFilters.selectedHostedRetail.size,
        ]

        // If every size is 0, than "Clear All" was clicked and we need to refresh the current displayed main filter
        if (allSizes.every((size: number) => size === 0)) {
            switch (selectedSidebarMenu) {
                case SidebarMenuKind.BANNER:
                    getBannerDesc()
                    getChannelOfTrade()
                    getHostedRetail()
                    getSegment()
                    getStoreDesign()
                    break

                case SidebarMenuKind.REGION:
                    getMacroRegions()
                    break

                case SidebarMenuKind.HOSTED_RETAIL:
                    getHostedRetail()
                    break
            }
        }

        // In any new selection/deselection of filters we refresh the stores with a getStores
        if (
            prevSize?.bannerdesc !==
                bannerDescFilters.selectedBannerDesc.size ||
            prevSize?.channeloftrade !==
                channelOfTradeFilters.selectedChannelOfTrade.size ||
            prevSize?.segment !== segmentFilters.selectedSegment.size ||
            prevSize?.storedesign !== currentSDCount ||
            prevSize?.imagesquality !==
                selectionBestFilters.imagesQualityFilters.selectedImagesQuality
                    .size ||
            prevSize?.mainprojects !==
                selectionBestFilters.mainProjectsFilters.selectedMainProject
                    .size ||
            prevSize?.macroregions !==
                macroregionsFilters.selectedMacroRegions.size ||
            prevSize?.countries !== countriesFilters.selectedCountries.size ||
            prevSize?.zonedescs !== zoneDescsFilters.selectedZoneDescs.size ||
            prevSize?.cities !== citiesFilters.selectedCities.size ||
            prevSize?.bus !== busFilters.selectedBUs.size ||
            prevSize?.remodelyears !==
                remodelFilters.selectedRemodelYears.size ||
            (prevSize?.remodelmonths !==
                remodelFilters.selectedRemodelMonths.size &&
                remodelFilters.selectedRemodelYears.size) ||
            prevSize?.relocationyears !==
                relocationFilters.selectedRelocationYears.size ||
            (prevSize?.relocationmonths !==
                relocationFilters.selectedRelocationMonths.size &&
                relocationFilters.selectedRelocationYears.size) ||
            prevSize?.newyears !== newFilters.selectedNewYears.size ||
            (prevSize?.newmonths !== newFilters.selectedNewMonths.size &&
                newFilters.selectedNewYears.size) ||
            prevSize?.hostedretail !==
                hostedRetailFilters.selectedHostedRetail.size ||
            prevSize?.surveydatefrom !== surveyDateFilters.selectedFrom ||
            prevSize?.surveydateto !== surveyDateFilters.selectedTo
        ) {
            getStores()
        }

        ////////////// BannerDesc selection //////////////
        if (
            prevSize?.bannerdesc !== bannerDescFilters.selectedBannerDesc.size
        ) {
            getChannelOfTrade()
            getHostedRetail()
            getSegment()
            getStoreDesign()

            refreshInnerRegionFilters(
                regionFilters,
                getCountries,
                getZoneDescs,
                getCities,
                getBUs,
                clearAllCountriesFilters,
                clearAllZoneDescsFilters,
                clearAllCitiesFilters
            )
        }

        ////////////// ChannelOfTrade selection //////////////
        if (
            prevSize?.channeloftrade !==
            channelOfTradeFilters.selectedChannelOfTrade.size
        ) {
            getBannerDesc()
            getHostedRetail()
            getSegment()
            getStoreDesign()

            refreshInnerRegionFilters(
                regionFilters,
                getCountries,
                getZoneDescs,
                getCities,
                getBUs,
                clearAllCountriesFilters,
                clearAllZoneDescsFilters,
                clearAllCitiesFilters
            )
        }

        ////////////// Segment selection //////////////
        if (prevSize?.segment !== segmentFilters.selectedSegment.size) {
            getBannerDesc()
            getChannelOfTrade()
            getHostedRetail()
            getStoreDesign()

            refreshInnerRegionFilters(
                regionFilters,
                getCountries,
                getZoneDescs,
                getCities,
                getBUs,
                clearAllCountriesFilters,
                clearAllZoneDescsFilters,
                clearAllCitiesFilters
            )
        }

        ////////////// Store Design selection //////////////
        if (prevSize?.storedesign !== currentSDCount) {
            getBannerDesc()
            getChannelOfTrade()
            getHostedRetail()
            getSegment()

            refreshInnerRegionFilters(
                regionFilters,
                getCountries,
                getZoneDescs,
                getCities,
                getBUs,
                clearAllCountriesFilters,
                clearAllZoneDescsFilters,
                clearAllCitiesFilters
            )
        }

        ////////////// MacroRegion selection //////////////
        if (
            prevSize?.macroregions !==
            macroregionsFilters.selectedMacroRegions.size
        ) {
            // Fetch the Countries and the Business Units again (only if there's at least a selected MacroRegion available)
            if (macroregionsFilters.selectedMacroRegions.size > 0) {
                getCountries()
                getBUs()
            } else {
                clearAllCountriesFilters()
            }
        }

        ////////////// Country selection //////////////
        if (prevSize?.countries !== countriesFilters.selectedCountries.size) {
            // Fetch the Zone Descriptions (only if there's at least a selected Country available)
            if (countriesFilters.selectedCountries.size) {
                getZoneDescs()
            } else {
                clearAllZoneDescsFilters()
            }

            // and the Business Units again
            getBUs()
        }

        ////////////// Zone Description selection //////////////
        if (prevSize?.zonedescs !== zoneDescsFilters.selectedZoneDescs.size) {
            // Fetch the Cities (only if there's at least a selected Country available)
            if (zoneDescsFilters.selectedZoneDescs.size) {
                getCities()
            } else {
                clearAllCitiesFilters()
            }

            // and the Business Units again
            getBUs()
        }

        ////////////// City selection //////////////
        if (prevSize?.cities !== citiesFilters.selectedCities.size) {
            // Fetch the Business Units again
            getBUs()
        }

        ////////////// Hosted Retail selection //////////////
        if (
            prevSize?.hostedretail !==
            hostedRetailFilters.selectedHostedRetail.size
        ) {
            // Fetch the Banner filters again
            getBannerDesc()
            getChannelOfTrade()
            getSegment()
            getStoreDesign()
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [
        bannerDescFilters.selectedBannerDesc.size,
        channelOfTradeFilters.selectedChannelOfTrade.size,
        segmentFilters.selectedSegment.size,
        sdCount,
        selectionBestFilters.imagesQualityFilters.selectedImagesQuality.size,
        selectionBestFilters.mainProjectsFilters.selectedMainProject.size,
        macroregionsFilters.selectedMacroRegions.size,
        countriesFilters.selectedCountries.size,
        zoneDescsFilters.selectedZoneDescs.size,
        citiesFilters.selectedCities.size,
        busFilters.selectedBUs.size,
        remodelFilters.selectedRemodelYears.size,
        remodelFilters.selectedRemodelMonths.size,
        relocationFilters.selectedRelocationYears.size,
        relocationFilters.selectedRelocationMonths.size,
        newFilters.selectedNewYears.size,
        newFilters.selectedNewMonths.size,
        hostedRetailFilters.selectedHostedRetail.size,
        surveyDateFilters.selectedFrom,
        surveyDateFilters.selectedTo,
        fibonacciNumber,
    ])
}
