import { ReactComponent as StoreVisualizerLogo } from '../../utils/icons/elements-desktop-brand.svg'

function MapLogo() {
    return (
        <div className="rounded-3xl inline-flex bg-white">
            <StoreVisualizerLogo style={{ width: 185, height: 'auto' }} />
        </div>
    )
}

export default MapLogo
