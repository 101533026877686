import { log } from 'console'
import { BaseIndexedDBSerializable } from './indexedDbSerializable'
import { SearchableFiltersDict } from './searchable-filter'
import { Store } from './store'

export class StoresSnapshot extends BaseIndexedDBSerializable {
    id: number
    new_opening_count: number
    new_opening_percentage: number
    new_opening_year: string
    remodeling_stores: Store[]
    remodeling_count: number
    remodeling_percentage: number
    remodeling_year: string
    relocation_stores: Store[]
    relocation_count: number
    relocation_percentage: number
    relocation_year: string
    new_opening_stores: Store[]
    division_facet: SearchableFiltersDict
    cot_facet: SearchableFiltersDict
    core_percentage: number
    hosted_percentage: number
    outlet_percentage: number
    noChannel_percentage: number
    stores_count: number

    constructor(data?: any) {
        super()

        this.id = 1
        this.stores_count = data?.stores_count

        this.new_opening_count = data?.new_opening_count || 0
        // Create percentage with only two digits
        this.new_opening_percentage = data?.stores_count
            ? Number(
                ((this.new_opening_count * 100) / data?.stores_count).toFixed(
                    2
                )
            )
            : 0
        this.new_opening_stores = data?.new_opening_stores ?? []
        this.remodeling_stores = data?.remodeling_stores ?? []
        this.remodeling_count = data?.remodeling_count || 0
        this.relocation_stores = data?.relocation_stores ?? []
        this.relocation_count = data?.relocation_count || 0
        // Create percentage with only two digits
        this.remodeling_percentage = data?.stores_count
            ? Number(
                ((this.remodeling_count * 100) / data?.stores_count).toFixed(
                    2
                )
            )
            : 0
        this.relocation_percentage = data?.stores_count
            ? Number(
                ((this.relocation_count * 100) / data?.stores_count).toFixed(
                    2
                )
            )
            : 0
        this.division_facet = data?.division_facet || {}
        this.cot_facet = data?.cot_facet || {}
        // Create percentage by rounding to the nearest number
        this.core_percentage =
            'CORE' in this.cot_facet && data?.stores_count
                ? Math.round(
                    (this.cot_facet.CORE.count * 100) / data?.stores_count
                )
                : 0
        // Create percentage by rounding to the nearest number
        this.hosted_percentage =
            'HOSTED' in this.cot_facet && data?.stores_count
                ? Math.round(
                    (this.cot_facet.HOSTED.count * 100) / data?.stores_count
                )
                : 0
        // Create percentage by rounding to the nearest number
        this.outlet_percentage =
            'OUTLET' in this.cot_facet && data?.stores_count
                ? Math.round(
                    (this.cot_facet.OUTLET.count * 100) / data?.stores_count
                )
                : 0
        // Create percentage by rounding to the nearest number
        this.noChannel_percentage =
            'NO CHANNEL OF TRADE' in this.cot_facet && data?.stores_count
                ? Math.round(
                    (this.cot_facet["NO CHANNEL OF TRADE"].count * 100) / data?.stores_count
                )
                : 0

        this.new_opening_year = data?.new_opening_year
        this.remodeling_year = data?.remodeling_year
        this.relocation_year = data?.relocation_year
    }

    toObject() {
        return {
            id: this.id,
            new_opening_count: this.new_opening_count,
            new_opening_percentage: this.new_opening_percentage,
            new_opening_year: this.new_opening_year,
            remodeling_count: this.remodeling_count,
            remodeling_percentage: this.remodeling_percentage,
            remodeling_year: this.remodeling_year,
            relocation_count: this.relocation_count,
            relocation_percentage: this.relocation_percentage,
            relocation_year: this.relocation_year,
            division_facet: this.division_facet,
            cot_facet: this.cot_facet,
            core_percentage: this.core_percentage,
            hosted_percentage: this.hosted_percentage,
            outlet_percentage: this.outlet_percentage,
            stores_count: this.stores_count,
        }
    }
}
