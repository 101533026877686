import { Colors } from '../../colors'
import styled from 'styled-components'
import { FiltersSidebarSizeType } from '../../models/filters-sidebar-size-type'

export const StyledFilterSidebar = styled.div<{
    sizeType: FiltersSidebarSizeType
}>`
    /* styling the sidebar itself */
    .p-sidebar {
        padding: 0;
        width: 496px;
        height: calc(100% - 110px) !important;
        max-width: 100vw;
        margin-top: 110px;
        position: fixed;
        bottom: 0px;
        overflow: scroll;
    }

    /* Hide scrollbar for Chrome, Safari and Opera */
    .p-sidebar::-webkit-scrollbar {
        display: none;
    }

    /* Hide scrollbar for IE, Edge and Firefox */
    .p-sidebar {
        -ms-overflow-style: none; /* IE and Edge */
        scrollbar-width: none; /* Firefox */
    }

    /* overlay icons to other elements */
    .p-sidebar-icons {
        position: absolute;
        z-index: 100;
        top: 16px;
        right: 16px;
    }

    /* remove blue borders to close button */
    .p-sidebar-icon {
        box-shadow: none !important;
    }

    /* size of the sizebar when on mobile */
    .p-sidebar-bottom {
        height: ${(p) =>
            p.sizeType === FiltersSidebarSizeType.EXTRALARGE
                ? '100%'
                : p.sizeType === FiltersSidebarSizeType.LARGE
                ? '60%'
                : p.sizeType === FiltersSidebarSizeType.MEDIUM
                ? '45%'
                : '30%'} !important;
        transition: height 0.3s;
    }

    /* move accordion tabs icon to the right */
    .p-accordion-toggle-icon {
        margin-top: -8px;
        position: absolute;
        right: 0.5em;
        top: 50%;
    }

    /* remove blue box when clicking accordion tabs and gray background when accordion tab is selected */
    .p-accordion .p-accordion-header-link:focus {
        box-shadow: none !important;
        background: white !important;
    }

    .p-accordion .p-accordion-header-link:hover {
        background: white !important;
    }

    /* styling accordion tabs */
    .p-accordion .p-accordion-header .p-accordion-header-link {
        width: 496px;
        width: 100%;
        max-width: 100vw;
        height: 58px;
        margin: 0px 223px 0 0;
        padding: 17px 16px 17px 17px;
        background-color: white;
        font-family: AvenirLTPro;
        font-size: 14px;
        font-weight: 500;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: 0.8px;
        color: ${Colors.grey6};
    }

    /* styling accordion tab icon */
    .pi {
        color: ${Colors.grey4};
        font-size: 1.1em;
    }

    /* styling panel header of profile & settings */
    .p-panel-profile .p-panel-header {
        background: #4072ee;
        border-color: #4072ee;
        border-radius: 0;
    }
    .p-panel-profile .p-panel-header .p-panel-title {
        color: #fff;
    }

    /* force z-index to show stores & snapshot modal over filters sidebar */
    .p-sidebar.p-component.p-sidebar-left.p-sidebar-active.p-sidebar-enter-done {
        z-index: 99 !important;
    }

    .mobileFilters {
        height: 70px;
        margin-bottom: -50px;
    }

    /*
    .mobileFilters .p-mr-3:first-child {
        margin-bottom: 7px;
    }
    */

    .mobileFilters .p-field-checkbox {
        margin-bottom: 0;
    }

    @media (max-width: 991px) {
        .p-sidebar {
            width: 100%;
        }
        .p-sidebar .p-sidebar-icons {
            z-index: 101;
        }
        .p-sidebar .p-sidebar-icons button {
            position: fixed;
            margin-top: 24px;
        }
        .mobileFilters {
            position: fixed;
            width: 100%;
            height: auto;
            margin: 0;
            background-color: #fff;
            z-index: 100;
            padding-top: 20px;
        }
        .mobileFilters .p-mr-3:last-child {
            margin: 0 !important;
        }
    }

    @media (min-width: 992px) and (max-width: 1199px) {
        .p-sidebar {
            margin-top: 128px;
            height: 100% !important;
        }
    }
`
