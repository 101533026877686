const FavIcon = (props: any) => {
	const { color } = props;
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width="12"
			height="12"
			viewBox="0 0 20 20"
		>
			<g fill="none" fillRule="evenodd">
				<g fill={color}>
					<path d="M18.75 21V4.688c0-.932-.756-1.688-1.688-1.688H6.938c-.932 0-1.688.756-1.688 1.688V21L12 17.062 18.75 21z" />
				</g>
			</g>
		</svg>
	);
};

export default FavIcon;
