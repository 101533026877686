import { Fragment, isValidElement } from 'react'
import { injectIntl } from 'react-intl'
import Divider from '../../Divider'
import { StyledColumn } from './StyledColumn'

const DetailsParagraph = (props: any) => {
    const { content } = props
    return (
        <StyledColumn>
            <div
                className="p-grid p-py-3 details-paragraph "
                style={{ backgroundColor: content.backgroundColor }}>
                {content.info &&
                    Object.keys(content.info).map((f, i) => {
                        const field = content.info[f].field
                        const fieldIsAReactNode = isValidElement(field)

                        return (
                            <div
                                className={`${
                                    fieldIsAReactNode ? '' : 'p-d-flex'
                                } p-col-12 p-px-1 p-py-2`}
                                key={i}>
                                <div className="title p-px-2 p-pl-md-5">
                                    {' '}
                                    {content.info[f].title}
                                </div>
                                {fieldIsAReactNode ? (
                                    field
                                ) : (
                                    <div className="field p-text-bold p-px-2 p-pr-md-5">
                                        {' '}
                                        {typeof field === 'object'
                                            ? Object.entries(
                                                  field as Record<
                                                      string,
                                                      string[]
                                                  >
                                              ).map(([key, value]) => {
                                                  return (
                                                      <div
                                                          key={key}
                                                          style={{
                                                              marginBottom: 15,
                                                          }}>
                                                          {key}
                                                          {value.map((v) => (
                                                              <Fragment
                                                                  key={`${key}_${v}`}>
                                                                  <p
                                                                      style={{
                                                                          marginTop: 3,
                                                                          marginBottom: 3,
                                                                          fontWeight: 500,
                                                                      }}>
                                                                      {v}
                                                                  </p>
                                                              </Fragment>
                                                          ))}
                                                      </div>
                                                  )
                                              })
                                            : field}
                                    </div>
                                )}
                            </div>
                        )
                    })}
            </div>
            <Divider />
        </StyledColumn>
    )
}

export default injectIntl(DetailsParagraph)
