import { AxiosPromise } from 'axios'
import { http } from './http'
/**
 * @export UserApi - Just an api interface for claiming user data and reducing the code needed for axios http functions
 * @class UserApi
 */
export class UserApi {
    /**
     * Base Url for User Api
     * @static
     * @type {string}
     * @memberof UserApi
     */
    static USER_BASE_URL: string = process.env.REACT_APP_RETAIL360_URL_BE || ''

    /**
     * Endpoint for user info
     * @static
     * @type {string}
     * @memberof UserApi
     */
    static USER_WHOAMI_ENDP: string = 'user/whoami'
    static USER_LOGOUT_ENDP: string = 'user/logout'
    static USER_APP_PERM_ENDP: string = 'user/permissions'
    static SET_USER_DATA_ENDP: string = 'user/userdata'

    /**
     * This functions execute a logout for the current user
     * @returns Promise of type AxiosPromise
     */
    static Logout = async (): Promise<AxiosPromise<any>> => {
        try {
            let response = await http.get<any>(UserApi.USER_LOGOUT_ENDP, {
                baseURL: UserApi.USER_BASE_URL,
            })
            return response
        } catch (error: any) {
            throw error
        }
    }
    /**
     * This functions gets the user info
     * @returns Promise of type AxiosPromise
     */
    static WhoAmI = async (): Promise<AxiosPromise<any>> => {
        try {
            let response = await http.get(UserApi.USER_WHOAMI_ENDP, {
                baseURL: UserApi.USER_BASE_URL,
            })
            return response
        } catch (error: any) {
            throw error
        }
    }

    /**
     * Get the user data
     * @returns Promise of type AxiosPromise
     */
    static UserData = async (): Promise<AxiosPromise<any>> => {
        try {
            let response = await http.get<any>(UserApi.USER_APP_PERM_ENDP, {
                baseURL: UserApi.USER_BASE_URL,
            })
            return response
        } catch (error: any) {
            throw error
        }
    }

    static GetFavouriteStoresList = () => {
        return http.get<any>(
            UserApi.USER_BASE_URL + 'user/favourite_store?details=true'
        )
    }

    static SetFavouriteStore = (storeId: string) => {
        return http.post<any>(UserApi.USER_BASE_URL + 'user/favourite_store', {
            store_id: storeId,
        })
    }

    static RemoveStoreFromFavourites = (storeId: string) => {
        return http.delete<any>(
            UserApi.USER_BASE_URL + `user/favourite_store/${storeId}`
        )
    }
    /**
     * Set the user language
     * @returns Promise of type AxiosPromise
     */
    static SetUserLanguage = async (
        langLocaleShort: string
    ): Promise<AxiosPromise<any>> => {
        try {
            let response = await http.post<any>(
                UserApi.SET_USER_DATA_ENDP,
                {
                    user_data: {
                        language: langLocaleShort,
                    },
                },
                {
                    baseURL: UserApi.USER_BASE_URL,
                }
            )
            return response
        } catch (error: any) {
            throw error
        }
    }
}
