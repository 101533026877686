import { SolrFields } from '../solr-fields'
import { BannerDescFilters } from './banner-desc-filters'
import { ChannelOfTradeFilters } from './channel-of-trade-filters'
import { DefaultSet } from './default'
import { SegmentFilters } from './segment-filters'
import { SelectionBestFilters } from './selection-best-filters'
import {
    StoreDesignFilters,
    StoreDesignGroupFilters,
} from './store-design-groups-filters'

export class BannerFilters extends DefaultSet {
    bannerDescFilters: BannerDescFilters
    channelOfTradeFilters: ChannelOfTradeFilters
    segmentFilters: SegmentFilters
    storeDesignFilters: StoreDesignFilters
    selectionBestFilters: SelectionBestFilters

    constructor(data?: any) {
        super()

        this.bannerDescFilters = new BannerDescFilters()
        this.channelOfTradeFilters = new ChannelOfTradeFilters()
        this.segmentFilters = new SegmentFilters()
        this.storeDesignFilters = new StoreDesignFilters()
        this.selectionBestFilters = new SelectionBestFilters()
    }

    static getSolrFQFilter = (
        bannerFilters: BannerFilters,
        exclude?: Set<string>
    ) => {
        let bannerSolrFq = ''

        let {
            bannerDescFilters,
            channelOfTradeFilters,
            segmentFilters,
            storeDesignFilters,
        } = bannerFilters

        if (!exclude?.has('sd')) {
            let storeDesignGroupFilters = storeDesignFilters.searchableFilters
            if (storeDesignGroupFilters) {
                storeDesignGroupFilters.forEach(
                    (sdGroup: StoreDesignGroupFilters) => {
                        if (sdGroup.selectedStoreDesigns.size < 1) return

                        bannerSolrFq +=
                            (bannerSolrFq.length < 1 ? '&fq=' : ' OR ') +
                            sdGroup.getSolrFQFilter(
                                sdGroup.selectedStoreDesigns,
                                SolrFields.StoreDesign
                            )
                    }
                )
            }
        }

        if (!exclude?.has('s')) {
            if (segmentFilters.selectedSegment.size) {
                bannerSolrFq +=
                    '&fq=' +
                    segmentFilters.getSolrFQFilter(
                        segmentFilters.selectedSegment,
                        SolrFields.Segment
                    )
            }
        }

        if (!exclude?.has('cot')) {
            if (channelOfTradeFilters.selectedChannelOfTrade.size) {
                bannerSolrFq +=
                    '&fq=' +
                    channelOfTradeFilters.getSolrFQChannelFilter(
                        channelOfTradeFilters.selectedChannelOfTrade,
                        SolrFields.ChannelOfTrade
                    )
            }
        }

        if (!exclude?.has('bd')) {
            if (bannerDescFilters.selectedBannerDesc.size) {
                bannerSolrFq +=
                    '&fq=' +
                    bannerDescFilters.getSolrFQFilter(
                        bannerDescFilters.selectedBannerDesc,
                        SolrFields.BannerDesc
                    )
            }
        }
        return bannerSolrFq
    }
}
