import { Colors } from '../colors'
import styled from 'styled-components'

const Divider = (props: any) => {
    // const { isVertical } = props
    // if necessary, add vertical css

    const Style = styled.div`
        .cssClass {
            background-color: ${Colors.grey1};
            width: 100%;
            height: 1px;
            display: flex;
        }
    `

    return (
        <Style style={{ display: 'inline-block' }}>
            <div className="cssClass"></div>
        </Style>
    )
}

export default Divider
