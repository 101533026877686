import moment from 'moment'
import { useEffect, useState } from 'react'
import { Photo, sections } from '../models/photo-list'

// moment().format('LT');   -> 15:38
// moment().format('LTS');  -> 15:38:43
// moment().format('L');    -> 15/01/2021
// moment().format('l');    -> 15/1/2021
// moment().format('LL');   -> 15 gennaio 2021
// moment().format('ll');   -> 15 gen 2021
// moment().format('LLL');  -> 15 gennaio 2021 15:38
// moment().format('lll');  -> 15 gen 2021 15:38
// moment().format('LLLL'); -> venerdì 15 gennaio 2021 15:38
// moment().format('llll'); -> ven 15 gen 2021 15:38

export const dateFormatter = (dateInMillis: string = '253402210800000') => {
    const date = moment(dateInMillis, 'x')
    if (date.format('YYYY') === '9999') return '--'
    return date.format('L')
}

// custom hook to check if the window is mobile after resize
export const useWindowResize = () => {
    const [isMobileNow, setIsMobileNow] = useState(window.innerWidth < 992)
    useEffect(() => {
        const handleResize = () => {
            if (window.innerWidth < 992) {
                setIsMobileNow(true)
            } else {
                setIsMobileNow(false)
            }
        }
        window.addEventListener('resize', handleResize)
        return () => {
            window.removeEventListener('resize', handleResize)
        }
    }, [])
    return isMobileNow
}

export const dividePhotosIntoSections = (
    photoList: Photo[],
    macroarea?: string
) => {
    return sections.reduce((obj: any, section) => {
        const newList = photoList.filter((ph: Photo, idx: number) => {
            ph.index = idx
            if (macroarea && macroarea !== 'All') {
                if (ph.macroarea === macroarea) {
                    return ph.macroarea === section
                }

                return false
            } else {
                return ph.macroarea === section
            }
        })
        if (newList.length > 0)
            obj[section] = {
                list: newList,
                sectionName: section,
            }
        return obj
    }, {})
}

export const sortPhotosByMacroarea = (unorderedPhotoList: Photo[]) => {
    sections.map((section: string, idx: number) => {
        unorderedPhotoList.map((photo: Photo) => {
            if (photo.macroarea === section) photo.index = idx
            return photo
        })
        return section
    })
    const orderedPhotoList = unorderedPhotoList.sort((a: Photo, b: Photo) => {
        if (a.index === b.index) return moment(a.date).isAfter(b.date) ? -1 : 1

        return a.index > b.index ? 1 : -1
    })
    return orderedPhotoList
}

export const getLastIntervalFromDateFilters = (
    // dateFilters: { selectedYears: Set<any>; selectedMonths: Set<any> }[]
    dateFilters: any[]
) => {
    let monthAndYear = {
        month: -1,
        year: -1,
    }
    for (const dateFilter of dateFilters) {
        // different filters have different keys to store years and months
        const array = Object.keys(dateFilter).map((key, i) => dateFilter[key])
        const selectedMonths: number[] =
            dateFilter instanceof Set
                ? [...array[0]]
                : dateFilter.selectedFrom
                ? [dateFilter.selectedFrom.getMonth() + 1]
                : []
        const selectedYears: number[] =
            dateFilter instanceof Set
                ? [...array[1]]
                : dateFilter.selectedTo
                ? [dateFilter.selectedTo.getYear()]
                : []
        if (selectedMonths.length > 0) {
            // get max month between selected months
            const tmpMax = Math.max(...selectedMonths) - 1
            if (monthAndYear.month < tmpMax) monthAndYear.month = tmpMax
        }
        if (selectedYears.length > 0) {
            // get max year between selected years
            let tmpMax = Math.max(...selectedYears)
            if (monthAndYear.year < tmpMax) monthAndYear.year = tmpMax
        }
    }

    // when no year is selected set current year
    if (monthAndYear.year === -1) monthAndYear.year = moment().year()

    // when no months are selected set start date 01/01 and end date to 31/12
    if (monthAndYear.month === -1) {
        const date = {
            from: moment
                .utc([monthAndYear.year - 3])
                .startOf('year')
                .valueOf(),
            to: moment.utc([monthAndYear.year]).endOf('year').valueOf(),
        }
        return date
    } else {
        const date = {
            from: moment
                .utc([monthAndYear.year, monthAndYear.month])
                .startOf('month')
                .valueOf(),
            to: moment
                .utc([monthAndYear.year, monthAndYear.month])
                .endOf('month')
                .valueOf(),
        }
        return date
    }
}
