import { BaseDispatcher } from './base'

/*
//// BANNER ////
- Banner Desc
- Channel of Trade
- Host
- Segment
- Store Design
- Selection Best

//// REGION ////
- MacroRegions
- Countries
- Zone Descriptions
- Cities
- Business Units

//// REMODEL ////

//// RELOCATION ////

//// NEW ////

//// HOSTED RETAIL ////

//// SURVEY DATE ////
*/

export enum FiltersDispatchTypes {
    FILTERS_ERROR = 'FILTERS_ERROR',
    FILTERS_SET_SELECTED_SIDEBAR_MENU_DONE = 'FILTERS_SET_SELECTED_SIDEBAR_MENU_DONE',
    FILTERS_SET_SELECTED_SIDEBAR_SIZE_TYPE_DONE = 'FILTERS_SET_SELECTED_SIDEBAR_SIZE_TYPE_DONE',
    FILTERS_CLEAR_ALL_FILTERS_DONE = 'FILTERS_CLEAR_ALL_FILTERS_DONE',

    /////////////////////// BANNER - START ///////////////////////
    FILTERS_CLEAR_ALL_BANNER_FILTERS_DONE = 'FILTERS_CLEAR_ALL_BANNER_FILTERS_DONE',
    /********************** BANNER DESC - START **********************/
    FILTERS_GET_BANNER_DESC_REQUEST = 'FILTERS_GET_BANNER_DESC_REQUEST',
    FILTERS_GET_BANNER_DESC_SUCCESS = 'FILTERS_GET_BANNER_DESC_SUCCESS',
    FILTERS_TOGGLE_BANNER_DESC_VIEW_ALL = 'FILTERS_TOGGLE_BANNER_DESC_VIEW_ALL',
    FILTERS_SET_SELECTED_BANNER_DESC_DONE = 'FILTERS_SET_SELECTED_BANNER_DESC_DONE',
    FILTERS_SET_SEARCH_BANNER_DESC_VALUE_DONE = 'FILTERS_SET_SEARCH_BANNER_DESC_VALUE_DONE',
    /********************** BANNER DESC - END **********************/

    /********************** CHANNEL OF TRADE - START **********************/
    FILTERS_GET_CHANNEL_OF_TRADE_REQUEST = 'FILTERS_GET_CHANNEL_OF_TRADE_REQUEST',
    FILTERS_GET_CHANNEL_OF_TRADE_SUCCESS = 'FILTERS_GET_CHANNEL_OF_TRADE_SUCCESS',
    FILTERS_TOGGLE_CHANNEL_OF_TRADE_VIEW_ALL = 'FILTERS_TOGGLE_CHANNEL_OF_TRADE_VIEW_ALL',
    FILTERS_SET_SELECTED_CHANNEL_OF_TRADE_DONE = 'FILTERS_SET_SELECTED_CHANNEL_OF_TRADE_DONE',
    FILTERS_SET_SEARCH_CHANNEL_OF_TRADE_VALUE_DONE = 'FILTERS_SET_SEARCH_CHANNEL_OF_TRADE_VALUE_DONE',
    /********************** CHANNEL OF TRADE - END **********************/

    /********************** SEGMENT - START **********************/
    FILTERS_GET_SEGMENT_REQUEST = 'FILTERS_GET_SEGMENT_REQUEST',
    FILTERS_GET_SEGMENT_SUCCESS = 'FILTERS_GET_SEGMENT_SUCCESS',
    FILTERS_TOGGLE_SEGMENT_VIEW_ALL = 'FILTERS_TOGGLE_SEGMENT_VIEW_ALL',
    FILTERS_SET_SELECTED_SEGMENT_DONE = 'FILTERS_SET_SELECTED_SEGMENT_DONE',
    FILTERS_SET_SEARCH_SEGMENT_VALUE_DONE = 'FILTERS_SET_SEARCH_SEGMENT_VALUE_DONE',

    /********************** STORE DESIGN - START **********************/
    FILTERS_GET_STORE_DESIGN_REQUEST = 'FILTERS_GET_STORE_DESIGN_REQUEST',
    FILTERS_GET_STORE_DESIGN_SUCCESS = 'FILTERS_GET_STORE_DESIGN_SUCCESS',
    FILTERS_TOGGLE_STORE_DESIGN_VIEW_ALL = 'FILTERS_TOGGLE_STORE_DESIGN_VIEW_ALL',
    FILTERS_TOGGLE_STORE_DESIGN_PANEL_COLLAPSED = 'FILTERS_TOGGLE_STORE_DESIGN_PANEL_COLLAPSED',
    FILTERS_TOGGLE_STORE_DESIGN_SELECT_ALL_SELECTED = 'FILTERS_TOGGLE_STORE_DESIGN_SELECT_ALL_SELECTED',
    FILTERS_TOGGLE_STORE_DESIGN_FIRST_LEVEL_PANEL_COLLAPSED = 'FILTERS_TOGGLE_STORE_DESIGN_FIRST_LEVEL_PANEL_COLLAPSED',
    FILTERS_SET_SELECTED_STORE_DESIGN_DONE = 'FILTERS_SET_SELECTED_STORE_DESIGN_DONE',
    FILTERS_SET_SEARCH_STORE_DESIGN_VALUE_DONE = 'FILTERS_SET_SEARCH_STORE_DESIGN_VALUE_DONE',
    /********************** STORE DESIGN - END **********************/

    /********************** SELECTION BEST - START **********************/
    FILTERS_GET_SELECTIONBEST_REQUEST = 'FILTERS_GET_SELECTIONBEST_REQUEST',
    FILTERS_GET_SELECTIONBEST_SUCCESS = 'FILTERS_GET_SELECTIONBEST_SUCCESS',
    FILTERS_SET_SELECTED_IMAGES_QUALITY_DONE = 'FILTERS_SET_SELECTED_IMAGES_QUALITY_DONE',
    FILTERS_TOGGLE_MAIN_PROJECTS_VIEW_ALL = 'FILTERS_TOGGLE_MAIN_PROJECTS_VIEW_ALL',
    FILTERS_SET_SELECTED_MAIN_PROJECTS_DONE = 'FILTERS_SET_SELECTED_MAIN_PROJECTS_DONE',
    FILTERS_SET_SEARCH_MAIN_PROJECTS_VALUE_DONE = 'FILTERS_SET_SEARCH_MAIN_PROJECTS_VALUE_DONE',
    /********************** SELECTION BEST - END **********************/
    /////////////////////// BANNER - END ///////////////////////

    /////////////////////// REGION - START ///////////////////////
    /********************** MACROREGIONS - START **********************/
    FILTERS_CLEAR_ALL_MACROREGIONS_DONE = 'FILTERS_CLEAR_ALL_MACROREGIONS_DONE',
    FILTERS_GET_MACROREGIONS_REQUEST = 'FILTERS_GET_MACROREGIONS_REQUEST',
    FILTERS_GET_MACROREGIONS_SUCCESS = 'FILTERS_GET_MACROREGIONS_SUCCESS',
    FILTERS_SET_SELECTED_MACROREGION_DONE = 'FILTERS_SET_SELECTED_MACROREGION_DONE',
    /********************** MACROREGIONS - END **********************/

    /********************** COUNTRIES - START **********************/
    FILTERS_CLEAR_ALL_COUNTRIES_DONE = 'FILTERS_CLEAR_ALL_COUNTRIES_DONE',
    FILTERS_GET_COUNTRIES_REQUEST = 'FILTERS_GET_COUNTRIES_REQUEST',
    FILTERS_GET_COUNTRIES_SUCCESS = 'FILTERS_GET_COUNTRIES_SUCCESS',
    FILTERS_TOGGLE_COUNTRIES_VIEW_ALL = 'FILTERS_TOGGLE_COUNTRIES_VIEW_ALL',
    FILTERS_SET_SELECTED_COUNTRY_DONE = 'FILTERS_SET_SELECTED_COUNTRY_DONE',
    FILTERS_SET_SEARCH_COUNTRY_VALUE_DONE = 'FILTERS_SET_SEARCH_COUNTRY_VALUE_DONE',
    /********************** COUNTRIES - END **********************/

    /********************** ZONE DESCRIPTIONS - START **********************/
    FILTERS_CLEAR_ALL_ZONE_DESCS_DONE = 'FILTERS_CLEAR_ALL_ZONE_DESCS_DONE',
    FILTERS_GET_ZONE_DESCS_REQUEST = 'FILTERS_GET_ZONE_DESCS_REQUEST',
    FILTERS_GET_ZONE_DESCS_SUCCESS = 'FILTERS_GET_ZONE_DESCS_SUCCESS',
    FILTERS_TOGGLE_ZONE_DESCS_VIEW_ALL = 'FILTERS_TOGGLE_ZONE_DESCS_VIEW_ALL',
    FILTERS_SET_SELECTED_ZONE_DESC_DONE = 'FILTERS_SET_SELECTED_ZONE_DESC_DONE',
    FILTERS_SET_SEARCH_ZONE_DESC_VALUE_DONE = 'FILTERS_SET_SEARCH_ZONE_DESC_VALUE_DONE',
    /********************** ZONE DESCRIPTIONS - END **********************/

    /********************** CITIES - START **********************/
    FILTERS_CLEAR_ALL_CITIES_DONE = 'FILTERS_CLEAR_ALL_CITIES_DONE',
    FILTERS_GET_CITIES_REQUEST = 'FILTERS_GET_CITIES_REQUEST',
    FILTERS_GET_CITIES_SUCCESS = 'FILTERS_GET_CITIES_SUCCESS',
    FILTERS_TOGGLE_CITIES_VIEW_ALL = 'FILTERS_TOGGLE_CITIES_VIEW_ALL',
    FILTERS_SET_SELECTED_CITY_DONE = 'FILTERS_SET_SELECTED_CITY_DONE',
    FILTERS_SET_SEARCH_CITY_VALUE_DONE = 'FILTERS_SET_SEARCH_CITY_VALUE_DONE',
    /********************** CITIES - END **********************/

    /********************** BUSINESS UNITS - START **********************/
    FILTERS_CLEAR_ALL_BUS_DONE = 'FILTERS_CLEAR_ALL_BUS_DONE',
    FILTERS_GET_BUS_REQUEST = 'FILTERS_GET_BUS_REQUEST',
    FILTERS_GET_BUS_SUCCESS = 'FILTERS_GET_BUS_SUCCESS',
    FILTERS_TOGGLE_BUS_VIEW_ALL = 'FILTERS_TOGGLE_BUS_VIEW_ALL',
    FILTERS_SET_SELECTED_BU_DONE = 'FILTERS_SET_SELECTED_BU_DONE',
    FILTERS_SET_SEARCH_BU_VALUE_DONE = 'FILTERS_SET_SEARCH_BU_VALUE_DONE',
    /********************** BUSINESS UNITS - END **********************/
    /////////////////////// REGION - END ///////////////////////

    /////////////////////// REMODEL - START ///////////////////////
    FILTERS_CLEAR_ALL_REMODEL_FILTERS_DONE = 'FILTERS_CLEAR_ALL_REMODEL_FILTERS_DONE',
    FILTERS_SET_SELECTED_REMODEL_MONTH_DONE = 'FILTERS_SET_SELECTED_REMODEL_MONTH_DONE',
    FILTERS_SET_SELECTED_REMODEL_YEAR_DONE = 'FILTERS_SET_SELECTED_REMODEL_YEAR_DONE',
    /////////////////////// REMODEL - END ///////////////////////

    /////////////////////// RELOCATION - START ///////////////////////
    FILTERS_CLEAR_ALL_RELOCATION_FILTERS_DONE = 'FILTERS_CLEAR_ALL_RELOCATION_FILTERS_DONE',
    FILTERS_SET_SELECTED_RELOCATION_MONTH_DONE = 'FILTERS_SET_SELECTED_RELOCATION_MONTH_DONE',
    FILTERS_SET_SELECTED_RELOCATION_YEAR_DONE = 'FILTERS_SET_SELECTED_RELOCATION_YEAR_DONE',
    /////////////////////// RELOCATION - END ///////////////////////

    /////////////////////// NEW - START ///////////////////////
    FILTERS_CLEAR_ALL_NEW_FILTERS_DONE = 'FILTERS_CLEAR_ALL_NEW_FILTERS_DONE',
    FILTERS_SET_SELECTED_NEW_MONTH_DONE = 'FILTERS_SET_SELECTED_NEW_MONTH_DONE',
    FILTERS_SET_SELECTED_NEW_YEAR_DONE = 'FILTERS_SET_SELECTED_NEW_YEAR_DONE',
    /////////////////////// NEW - END ///////////////////////

    /////////////////////// HOSTED RETAIL - START ///////////////////////
    FILTERS_CLEAR_ALL_HOSTED_RETAIL_FILTERS_DONE = 'FILTERS_CLEAR_ALL_HOSTED_RETAIL_FILTERS_DONE',
    FILTERS_GET_HOSTED_RETAIL_REQUEST = 'FILTERS_GET_HOSTED_RETAIL_REQUEST',
    FILTERS_GET_HOSTED_RETAIL_SUCCESS = 'FILTERS_GET_HOSTED_RETAIL_SUCCESS',
    FILTERS_TOGGLE_HOSTED_RETAIL_VIEW_ALL = 'FILTERS_TOGGLE_HOSTED_RETAIL_VIEW_ALL',
    FILTERS_SET_SELECTED_HOSTED_RETAIL_DONE = 'FILTERS_SET_SELECTED_HOSTED_RETAIL_DONE',
    FILTERS_SET_SEARCH_HOSTED_RETAIL_VALUE_DONE = 'FILTERS_SET_SEARCH_HOSTED_RETAIL_VALUE_DONE',
    /////////////////////// HOSTED RETAIL - END ///////////////////////

    /////////////////////// SURVEY DATE - START ///////////////////////
    FILTERS_CLEAR_ALL_SURVEY_DATE_FILTERS_DONE = 'FILTERS_CLEAR_ALL_SURVEY_DATE_FILTERS_DONE',
    FILTERS_SET_SELECTED_SURVEY_DATE_FROM_DONE = 'FILTERS_SET_SELECTED_SURVEY_DATE_FROM_DONE',
    FILTERS_SET_SELECTED_SURVEY_DATE_TO_DONE = 'FILTERS_SET_SELECTED_SURVEY_DATE_TO_DONE',
    /////////////////////// SURVEY DATE - END ///////////////////////
}

export class FiltersDispatcher extends BaseDispatcher {
    static FiltersError = (error: any): any => {
        return new FiltersDispatcher(
            FiltersDispatchTypes.FILTERS_ERROR,
            error
        ).onDispatchError()
    }

    static FiltersSetSelectedSidebarMenuSuccess = (success: any): any => {
        return new FiltersDispatcher(
            FiltersDispatchTypes.FILTERS_SET_SELECTED_SIDEBAR_MENU_DONE,
            success
        ).onDispatchSuccess()
    }

    static FiltersSetSelectedSidebarSizeTypeSuccess = (success: any): any => {
        return new FiltersDispatcher(
            FiltersDispatchTypes.FILTERS_SET_SELECTED_SIDEBAR_SIZE_TYPE_DONE,
            success
        ).onDispatchSuccess()
    }

    static FiltersClearAllFiltersDone = (success: any): any => {
        return new FiltersDispatcher(
            FiltersDispatchTypes.FILTERS_CLEAR_ALL_FILTERS_DONE,
            success
        ).onDispatchSuccess()
    }

    /////////////////////// BANNER - START ///////////////////////
    static FiltersClearAllBannerFiltersSuccess = (success: any): any => {
        return new FiltersDispatcher(
            FiltersDispatchTypes.FILTERS_CLEAR_ALL_BANNER_FILTERS_DONE,
            success
        ).onDispatchSuccess()
    }
    /********************** BANNER DESC - START **********************/
    static FiltersGetBannerDescRequest = (): any => {
        return new FiltersDispatcher(
            FiltersDispatchTypes.FILTERS_GET_BANNER_DESC_REQUEST,
            {}
        ).onDispatchSuccess()
    }

    static FiltersGetBannerDescSuccess = (success: any): any => {
        return new FiltersDispatcher(
            FiltersDispatchTypes.FILTERS_GET_BANNER_DESC_SUCCESS,
            success
        ).onDispatchSuccess()
    }

    static FiltersToggleBannerDescViewAll = (success: any): any => {
        return new FiltersDispatcher(
            FiltersDispatchTypes.FILTERS_TOGGLE_BANNER_DESC_VIEW_ALL,
            success
        ).onDispatchSuccess()
    }

    static FiltersSetSelectedBannerDescDone = (success: any): any => {
        return new FiltersDispatcher(
            FiltersDispatchTypes.FILTERS_SET_SELECTED_BANNER_DESC_DONE,
            success
        ).onDispatchSuccess()
    }

    static FiltersSetSearchBannerDescValueDone = (success: any): any => {
        return new FiltersDispatcher(
            FiltersDispatchTypes.FILTERS_SET_SEARCH_BANNER_DESC_VALUE_DONE,
            success
        ).onDispatchSuccess()
    }
    /********************** BANNER DESC - END **********************/

    /********************** CHANNEL OF TRADE - START **********************/
    static FiltersGetChannelOfTradeRequest = (): any => {
        return new FiltersDispatcher(
            FiltersDispatchTypes.FILTERS_GET_CHANNEL_OF_TRADE_REQUEST,
            {}
        ).onDispatchSuccess()
    }

    static FiltersGetChannelOfTradeSuccess = (success: any): any => {
        return new FiltersDispatcher(
            FiltersDispatchTypes.FILTERS_GET_CHANNEL_OF_TRADE_SUCCESS,
            success
        ).onDispatchSuccess()
    }

    static FiltersToggleChannelOfTradeViewAll = (success: any): any => {
        return new FiltersDispatcher(
            FiltersDispatchTypes.FILTERS_TOGGLE_CHANNEL_OF_TRADE_VIEW_ALL,
            success
        ).onDispatchSuccess()
    }

    static FiltersSetSelectedChannelOfTradeDone = (success: any): any => {
        return new FiltersDispatcher(
            FiltersDispatchTypes.FILTERS_SET_SELECTED_CHANNEL_OF_TRADE_DONE,
            success
        ).onDispatchSuccess()
    }

    static FiltersSetSearchChannelOfTradeValueDone = (success: any): any => {
        return new FiltersDispatcher(
            FiltersDispatchTypes.FILTERS_SET_SEARCH_CHANNEL_OF_TRADE_VALUE_DONE,
            success
        ).onDispatchSuccess()
    }
    /********************** CHANNEL OF TRADE - END **********************/

    /********************** SEGMENT - START **********************/
    static FiltersGetSegmentRequest = (): any => {
        return new FiltersDispatcher(
            FiltersDispatchTypes.FILTERS_GET_SEGMENT_REQUEST,
            {}
        ).onDispatchSuccess()
    }

    static FiltersGetSegmentSuccess = (success: any): any => {
        return new FiltersDispatcher(
            FiltersDispatchTypes.FILTERS_GET_SEGMENT_SUCCESS,
            success
        ).onDispatchSuccess()
    }

    static FiltersToggleSegmentViewAll = (success: any): any => {
        return new FiltersDispatcher(
            FiltersDispatchTypes.FILTERS_TOGGLE_SEGMENT_VIEW_ALL,
            success
        ).onDispatchSuccess()
    }

    static FiltersSetSelectedSegmentDone = (success: any): any => {
        return new FiltersDispatcher(
            FiltersDispatchTypes.FILTERS_SET_SELECTED_SEGMENT_DONE,
            success
        ).onDispatchSuccess()
    }

    static FiltersSetSearchSegmentValueDone = (success: any): any => {
        return new FiltersDispatcher(
            FiltersDispatchTypes.FILTERS_SET_SEARCH_SEGMENT_VALUE_DONE,
            success
        ).onDispatchSuccess()
    }
    /********************** SEGMENT - END **********************/

    /********************** STORE DESIGN - START **********************/
    static FiltersGetStoreDesignRequest = (): any => {
        return new FiltersDispatcher(
            FiltersDispatchTypes.FILTERS_GET_STORE_DESIGN_REQUEST,
            {}
        ).onDispatchSuccess()
    }

    static FiltersGetStoreDesignSuccess = (success: any): any => {
        return new FiltersDispatcher(
            FiltersDispatchTypes.FILTERS_GET_STORE_DESIGN_SUCCESS,
            success
        ).onDispatchSuccess()
    }

    static FiltersToggleStoreDesignViewAll = (success: any): any => {
        return new FiltersDispatcher(
            FiltersDispatchTypes.FILTERS_TOGGLE_STORE_DESIGN_VIEW_ALL,
            success
        ).onDispatchSuccess()
    }

    static FiltersStoreDesignPanelCollapsed = (success: any): any => {
        return new FiltersDispatcher(
            FiltersDispatchTypes.FILTERS_TOGGLE_STORE_DESIGN_PANEL_COLLAPSED,
            success
        ).onDispatchSuccess()
    }

    static FiltersStoreDesignSelectAllSelected = (success: any): any => {
        return new FiltersDispatcher(
            FiltersDispatchTypes.FILTERS_TOGGLE_STORE_DESIGN_SELECT_ALL_SELECTED,
            success
        ).onDispatchSuccess()
    }

    static FiltersStoreDesignFirstLevelPanelCollapsed = (success: any): any => {
        return new FiltersDispatcher(
            FiltersDispatchTypes.FILTERS_TOGGLE_STORE_DESIGN_FIRST_LEVEL_PANEL_COLLAPSED,
            success
        ).onDispatchSuccess()
    }

    static FiltersSetSelectedStoreDesignDone = (success: any): any => {
        return new FiltersDispatcher(
            FiltersDispatchTypes.FILTERS_SET_SELECTED_STORE_DESIGN_DONE,
            success
        ).onDispatchSuccess()
    }

    static FiltersSetSearchStoreDesignValueDone = (success: any): any => {
        return new FiltersDispatcher(
            FiltersDispatchTypes.FILTERS_SET_SEARCH_STORE_DESIGN_VALUE_DONE,
            success
        ).onDispatchSuccess()
    }
    /********************** STORE DESIGN - END **********************/

    /********************** SELECTION BEST - START **********************/
    static FiltersGetSelectionBestRequest = (): any => {
        return new FiltersDispatcher(
            FiltersDispatchTypes.FILTERS_GET_SELECTIONBEST_REQUEST,
            {}
        ).onDispatchSuccess()
    }

    static FiltersGetSelectionBestSuccess = (success: any): any => {
        return new FiltersDispatcher(
            FiltersDispatchTypes.FILTERS_GET_SELECTIONBEST_SUCCESS,
            success
        ).onDispatchSuccess()
    }

    static FiltersSetSelectedImagesQualityDone = (success: any): any => {
        return new FiltersDispatcher(
            FiltersDispatchTypes.FILTERS_SET_SELECTED_IMAGES_QUALITY_DONE,
            success
        ).onDispatchSuccess()
    }

    static FiltersToggleMainProjectsViewAll = (success: any): any => {
        return new FiltersDispatcher(
            FiltersDispatchTypes.FILTERS_TOGGLE_MAIN_PROJECTS_VIEW_ALL,
            success
        ).onDispatchSuccess()
    }

    static FiltersSetSelectedMainProjectsDone = (success: any): any => {
        return new FiltersDispatcher(
            FiltersDispatchTypes.FILTERS_SET_SELECTED_MAIN_PROJECTS_DONE,
            success
        ).onDispatchSuccess()
    }

    static FiltersSetSearchMainProjectsValueDone = (success: any): any => {
        return new FiltersDispatcher(
            FiltersDispatchTypes.FILTERS_SET_SEARCH_MAIN_PROJECTS_VALUE_DONE,
            success
        ).onDispatchSuccess()
    }
    /********************** SELECTION BEST - END **********************/
    /////////////////////// BANNER - END ///////////////////////

    /////////////////////// REGION - START ///////////////////////
    /********************** MACROREGIONS - START **********************/
    static FiltersClearAllMacroRegionsDone = (success: any): any => {
        return new FiltersDispatcher(
            FiltersDispatchTypes.FILTERS_CLEAR_ALL_MACROREGIONS_DONE,
            success
        ).onDispatchSuccess()
    }

    static FiltersGetMacroRegionsRequest = (): any => {
        return new FiltersDispatcher(
            FiltersDispatchTypes.FILTERS_GET_MACROREGIONS_REQUEST,
            {}
        ).onDispatchSuccess()
    }

    static FiltersGetMacroRegionsSuccess = (success: any): any => {
        return new FiltersDispatcher(
            FiltersDispatchTypes.FILTERS_GET_MACROREGIONS_SUCCESS,
            success
        ).onDispatchSuccess()
    }

    static FiltersSetSelectedMacroRegionDone = (success: any): any => {
        return new FiltersDispatcher(
            FiltersDispatchTypes.FILTERS_SET_SELECTED_MACROREGION_DONE,
            success
        ).onDispatchSuccess()
    }
    /********************** MACROREGIONS - END **********************/

    /********************** COUNTRIES - START **********************/
    static FiltersClearAllCountriesDone = (success: any): any => {
        return new FiltersDispatcher(
            FiltersDispatchTypes.FILTERS_CLEAR_ALL_COUNTRIES_DONE,
            success
        ).onDispatchSuccess()
    }

    static FiltersGetCountriesRequest = (): any => {
        return new FiltersDispatcher(
            FiltersDispatchTypes.FILTERS_GET_COUNTRIES_REQUEST,
            {}
        ).onDispatchSuccess()
    }

    static FiltersGetCountriesSuccess = (success: any): any => {
        return new FiltersDispatcher(
            FiltersDispatchTypes.FILTERS_GET_COUNTRIES_SUCCESS,
            success
        ).onDispatchSuccess()
    }

    static FiltersToggleCountriesViewAll = (success: any): any => {
        return new FiltersDispatcher(
            FiltersDispatchTypes.FILTERS_TOGGLE_COUNTRIES_VIEW_ALL,
            success
        ).onDispatchSuccess()
    }

    static FiltersSetSelectedCountryDone = (success: any): any => {
        return new FiltersDispatcher(
            FiltersDispatchTypes.FILTERS_SET_SELECTED_COUNTRY_DONE,
            success
        ).onDispatchSuccess()
    }

    static FiltersSetSearchCountryValueDone = (success: any): any => {
        return new FiltersDispatcher(
            FiltersDispatchTypes.FILTERS_SET_SEARCH_COUNTRY_VALUE_DONE,
            success
        ).onDispatchSuccess()
    }
    /********************** COUNTRIES - END **********************/

    /********************** ZONE DESCRIPTIONS - START **********************/
    static FiltersClearAllZoneDescsDone = (success: any): any => {
        return new FiltersDispatcher(
            FiltersDispatchTypes.FILTERS_CLEAR_ALL_ZONE_DESCS_DONE,
            success
        ).onDispatchSuccess()
    }

    static FiltersGetZoneDescsRequest = (): any => {
        return new FiltersDispatcher(
            FiltersDispatchTypes.FILTERS_GET_ZONE_DESCS_REQUEST,
            {}
        ).onDispatchSuccess()
    }

    static FiltersGetZoneDescsSuccess = (success: any): any => {
        return new FiltersDispatcher(
            FiltersDispatchTypes.FILTERS_GET_ZONE_DESCS_SUCCESS,
            success
        ).onDispatchSuccess()
    }

    static FiltersToggleZoneDescsViewAll = (success: any): any => {
        return new FiltersDispatcher(
            FiltersDispatchTypes.FILTERS_TOGGLE_ZONE_DESCS_VIEW_ALL,
            success
        ).onDispatchSuccess()
    }

    static FiltersSetSelectedZoneDescDone = (success: any): any => {
        return new FiltersDispatcher(
            FiltersDispatchTypes.FILTERS_SET_SELECTED_ZONE_DESC_DONE,
            success
        ).onDispatchSuccess()
    }

    static FiltersSetSearchZoneDescValueDone = (success: any): any => {
        return new FiltersDispatcher(
            FiltersDispatchTypes.FILTERS_SET_SEARCH_ZONE_DESC_VALUE_DONE,
            success
        ).onDispatchSuccess()
    }
    /********************** ZONE DESCRIPTIONS - END **********************/

    /********************** CITIES - START **********************/
    static FiltersClearAllCitiesDone = (success: any): any => {
        return new FiltersDispatcher(
            FiltersDispatchTypes.FILTERS_CLEAR_ALL_CITIES_DONE,
            success
        ).onDispatchSuccess()
    }

    static FiltersGetCitiesRequest = (): any => {
        return new FiltersDispatcher(
            FiltersDispatchTypes.FILTERS_GET_CITIES_REQUEST,
            {}
        ).onDispatchSuccess()
    }

    static FiltersGetCitiesSuccess = (success: any): any => {
        return new FiltersDispatcher(
            FiltersDispatchTypes.FILTERS_GET_CITIES_SUCCESS,
            success
        ).onDispatchSuccess()
    }

    static FiltersToggleCitiesViewAll = (success: any): any => {
        return new FiltersDispatcher(
            FiltersDispatchTypes.FILTERS_TOGGLE_CITIES_VIEW_ALL,
            success
        ).onDispatchSuccess()
    }

    static FiltersSetSelectedCityDone = (success: any): any => {
        return new FiltersDispatcher(
            FiltersDispatchTypes.FILTERS_SET_SELECTED_CITY_DONE,
            success
        ).onDispatchSuccess()
    }

    static FiltersSetSearchCityValueDone = (success: any): any => {
        return new FiltersDispatcher(
            FiltersDispatchTypes.FILTERS_SET_SEARCH_CITY_VALUE_DONE,
            success
        ).onDispatchSuccess()
    }
    /********************** CITIES - END **********************/

    /********************** BUSINESS UNITS - START **********************/
    static FiltersClearAllBUsDone = (success: any): any => {
        return new FiltersDispatcher(
            FiltersDispatchTypes.FILTERS_CLEAR_ALL_BUS_DONE,
            success
        ).onDispatchSuccess()
    }

    static FiltersGetBUsRequest = (): any => {
        return new FiltersDispatcher(
            FiltersDispatchTypes.FILTERS_GET_BUS_REQUEST,
            {}
        ).onDispatchSuccess()
    }

    static FiltersGetBUsSuccess = (success: any): any => {
        return new FiltersDispatcher(
            FiltersDispatchTypes.FILTERS_GET_BUS_SUCCESS,
            success
        ).onDispatchSuccess()
    }

    static FiltersToggleBUsViewAll = (success: any): any => {
        return new FiltersDispatcher(
            FiltersDispatchTypes.FILTERS_TOGGLE_BUS_VIEW_ALL,
            success
        ).onDispatchSuccess()
    }

    static FiltersSetSelectedBUDone = (success: any): any => {
        return new FiltersDispatcher(
            FiltersDispatchTypes.FILTERS_SET_SELECTED_BU_DONE,
            success
        ).onDispatchSuccess()
    }

    static FiltersSetSearchBUValueDone = (success: any): any => {
        return new FiltersDispatcher(
            FiltersDispatchTypes.FILTERS_SET_SEARCH_BU_VALUE_DONE,
            success
        ).onDispatchSuccess()
    }
    /********************** BUSINESS UNITS - END **********************/
    /////////////////////// REGION - END ///////////////////////

    /////////////////////// REMODEL - START ///////////////////////
    static FiltersClearAllRemodelFiltersSuccess = (success: any): any => {
        return new FiltersDispatcher(
            FiltersDispatchTypes.FILTERS_CLEAR_ALL_REMODEL_FILTERS_DONE,
            success
        ).onDispatchSuccess()
    }

    static FiltersSetSelectedRemodelMonthDone = (success: any): any => {
        return new FiltersDispatcher(
            FiltersDispatchTypes.FILTERS_SET_SELECTED_REMODEL_MONTH_DONE,
            success
        ).onDispatchSuccess()
    }

    static FiltersSetSelectedRemodelYearDone = (success: any): any => {
        return new FiltersDispatcher(
            FiltersDispatchTypes.FILTERS_SET_SELECTED_REMODEL_YEAR_DONE,
            success
        ).onDispatchSuccess()
    }
    /////////////////////// REMODEL - END ///////////////////////

    /////////////////////// RELOCATION - START ///////////////////////
    static FiltersClearAllRelocationFiltersSuccess = (success: any): any => {
        return new FiltersDispatcher(
            FiltersDispatchTypes.FILTERS_CLEAR_ALL_RELOCATION_FILTERS_DONE,
            success
        ).onDispatchSuccess()
    }

    static FiltersSetSelectedRelocationMonthDone = (success: any): any => {
        return new FiltersDispatcher(
            FiltersDispatchTypes.FILTERS_SET_SELECTED_RELOCATION_MONTH_DONE,
            success
        ).onDispatchSuccess()
    }

    static FiltersSetSelectedRelocationYearDone = (success: any): any => {
        return new FiltersDispatcher(
            FiltersDispatchTypes.FILTERS_SET_SELECTED_RELOCATION_YEAR_DONE,
            success
        ).onDispatchSuccess()
    }
    /////////////////////// RELOCATION - END ///////////////////////

    /////////////////////// NEW - START ///////////////////////
    static FiltersClearAllNewFiltersSuccess = (success: any): any => {
        return new FiltersDispatcher(
            FiltersDispatchTypes.FILTERS_CLEAR_ALL_NEW_FILTERS_DONE,
            success
        ).onDispatchSuccess()
    }

    static FiltersSetSelectedNewMonthDone = (success: any): any => {
        return new FiltersDispatcher(
            FiltersDispatchTypes.FILTERS_SET_SELECTED_NEW_MONTH_DONE,
            success
        ).onDispatchSuccess()
    }

    static FiltersSetSelectedNewYearDone = (success: any): any => {
        return new FiltersDispatcher(
            FiltersDispatchTypes.FILTERS_SET_SELECTED_NEW_YEAR_DONE,
            success
        ).onDispatchSuccess()
    }
    /////////////////////// NEW - END ///////////////////////

    /////////////////////// HOSTED RETAIL - START ///////////////////////
    static FiltersClearAllHostedRetailFiltersSuccess = (success: any): any => {
        return new FiltersDispatcher(
            FiltersDispatchTypes.FILTERS_CLEAR_ALL_HOSTED_RETAIL_FILTERS_DONE,
            success
        ).onDispatchSuccess()
    }

    static FiltersGetHostedRetailRequest = (): any => {
        return new FiltersDispatcher(
            FiltersDispatchTypes.FILTERS_GET_HOSTED_RETAIL_REQUEST,
            {}
        ).onDispatchSuccess()
    }

    static FiltersGetHostedRetailSuccess = (success: any): any => {
        return new FiltersDispatcher(
            FiltersDispatchTypes.FILTERS_GET_HOSTED_RETAIL_SUCCESS,
            success
        ).onDispatchSuccess()
    }

    static FiltersToggleHostedRetailViewAll = (success: any): any => {
        return new FiltersDispatcher(
            FiltersDispatchTypes.FILTERS_TOGGLE_HOSTED_RETAIL_VIEW_ALL,
            success
        ).onDispatchSuccess()
    }

    static FiltersSetSelectedHostedRetailDone = (success: any): any => {
        return new FiltersDispatcher(
            FiltersDispatchTypes.FILTERS_SET_SELECTED_HOSTED_RETAIL_DONE,
            success
        ).onDispatchSuccess()
    }

    static FiltersSetSearchHostedRetailValueDone = (success: any): any => {
        return new FiltersDispatcher(
            FiltersDispatchTypes.FILTERS_SET_SEARCH_HOSTED_RETAIL_VALUE_DONE,
            success
        ).onDispatchSuccess()
    }
    /////////////////////// HOSTED RETAIL - END ///////////////////////

    /////////////////////// SURVEY DATE - START ///////////////////////
    static FiltersClearAllSurveyDateFiltersSuccess = (success: any): any => {
        return new FiltersDispatcher(
            FiltersDispatchTypes.FILTERS_CLEAR_ALL_SURVEY_DATE_FILTERS_DONE,
            success
        ).onDispatchSuccess()
    }

    static FiltersSetSelectedSurveyDateFromDone = (success: any): any => {
        return new FiltersDispatcher(
            FiltersDispatchTypes.FILTERS_SET_SELECTED_SURVEY_DATE_FROM_DONE,
            success
        ).onDispatchSuccess()
    }

    static FiltersSetSelectedSurveyDateToDone = (success: any): any => {
        return new FiltersDispatcher(
            FiltersDispatchTypes.FILTERS_SET_SELECTED_SURVEY_DATE_TO_DONE,
            success
        ).onDispatchSuccess()
    }
    /////////////////////// SURVEY DATE - END ///////////////////////
}

