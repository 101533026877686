import {
    StyledSearchStoresClearSection,
    StyledLightGreyButtonContainer,
    StyledBlueButtonContainer,
} from './style'
import { injectIntl } from 'react-intl'
import { ProgressSpinner } from 'primereact/progressspinner'
import styled from 'styled-components'

export const StyledProgressSpinner = styled(ProgressSpinner)`
  /* center progress spinner */
  position: relative;
  top: 8px;
  left: 50%;
  z-index: 30;
  height: 30px;
  width: 30px;
  margin-right: 100px;
}`

const FilterSearchMainProjectsClearSection = (props: any) => {
    const { intl } = props

    const openSearchResultsSidebar = () => {
        props.onSearchResults()
    }

    return (
        <>
            <StyledSearchStoresClearSection>
                <div className="p-grid p-justify-between">
                    <div className="p-col-align-start">
                        <StyledLightGreyButtonContainer
                            onClick={
                                props.isSearchResultsDisabled
                                    ? null
                                    : props.onClearAllFilters
                            }>
                            {intl.formatMessage({
                                id: 'button.clear_all.text',
                            })}
                        </StyledLightGreyButtonContainer>
                    </div>
                    <div className="p-col-align-end">
                        {props.isLoadingSearchResults ? (
                            <StyledProgressSpinner />
                        ) : (
                            <StyledBlueButtonContainer
                                disabled={props.isSearchResultsDisabled}
                                onClick={(e: any) =>
                                    openSearchResultsSidebar()
                                }>
                                {intl.formatMessage({
                                    id: 'button.search_main_projects.text',
                                })}
                            </StyledBlueButtonContainer>
                        )}
                    </div>
                    <div className="children">{props.children}</div>
                </div>
            </StyledSearchStoresClearSection>
        </>
    )
}

export default injectIntl(FilterSearchMainProjectsClearSection)

