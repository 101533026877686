import React from 'react'

type NotFoundProps = {}
const NotFoundContainer = (props: NotFoundProps) => {
    return (
        <div>
            <img
                src="https://memegenerator.net/img/instances/58839354.jpg"
                alt="Not found"
            />
        </div>
    )
}

export default NotFoundContainer
