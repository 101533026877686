import { useEffect } from 'react'
import { connect } from 'react-redux'
import { loggedIn } from '../redux/actions/user'
import { createLoadingSelector } from '../redux/selectors'
import { AppState } from '../redux/states/appState'
import styled from 'styled-components'
import { Colors } from '../colors'
import { ReactComponent as StoreVisualizerLogo } from '../assets/svgs/logo.svg'

export const StyledAuthContainer = styled.div`
    height: 100vh;
    border: 3px solid ${Colors.grey5};
    background: ${Colors.blue};
}`

export const StyledCenteredContainer = styled.div`
    margin: 0;
    position: absolute;
    top: 50%;
    left: 50%;
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
}`

type AuthProps = {
    loggedIn: any
    isUserAuthenticated: boolean
    loading: boolean
}

const AuthContainer = (props: AuthProps) => {
    useEffect(() => {
        const path = window.location.pathname
        const urlParams = new URLSearchParams(window.location.search)
        const accessTokenParam = urlParams.get('jwt')
        if (path === '/' && accessTokenParam && !props.loading) {
            props.loggedIn(accessTokenParam)
        } else if (path === '/' && !props.loading) {
            window.location.href = process.env.REACT_APP_NETIQ_LOGIN || ''
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        if (props.isUserAuthenticated) {
            window.location.href = '/home'
            console.debug('REDIRECT COOKIE', document.cookie)
        }
    }, [props.isUserAuthenticated])

    return props.loading ? (
        <StyledAuthContainer>
            <StyledCenteredContainer>
                <StoreVisualizerLogo width={'100%'} />
            </StyledCenteredContainer>
        </StyledAuthContainer>
    ) : (
        <></>
    )
}

const loadingSelector = createLoadingSelector(['USER'])

const mapStateToProps = (state: AppState) => ({
    loading: loadingSelector(state),
    isUserAuthenticated: state.userReducer.isUserAuthenticated,
})

const mapDispatchToProps = (dispatch: any) => ({
    loggedIn: (accessToken: string) => dispatch(loggedIn(accessToken)),
})

export default connect(mapStateToProps, mapDispatchToProps)(AuthContainer)
