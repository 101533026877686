import { SnapshotState } from '../states/snapshotState'
import { StoresDispatchTypes } from '../dispatchers/stores'
import { StoresSnapshot } from '../../models/stores-snapshot'

let defaultState = {
    snapshot: new StoresSnapshot({
        stores_count: 2835,
        stores_with_image: 58,
        new_opening: 0.07,
        new_opening_year: '2021',
        new_opening_count: 0,
        remodeling_count: 0,
        remodeling_year: '2021',
        remodeling_percentage: 1,
        relocation_count: 0,
        relocation_year: '2021',
        relocation_percentage: 1,
        division_facet: {
            Optical: { count: 1054, selected: false },
            Sun: { count: 1781, selected: false },
        },
        cot_facet: {
            Core: { count: 62, selected: false },
            Hosted: { count: 30, selected: false },
            Outlet: { count: 8, selected: false },
        },
        core: 62,
        hosted: 30,
        outlet: 8,
    }),
}

const storesReducer = (state: SnapshotState = defaultState, action: any) => {
    switch (action.type) {
        case StoresDispatchTypes.STORES_SUCCESS:
            return {
                ...state,
                snapshot: action.response.storesSnapshot,
            }

        case StoresDispatchTypes.STORES_ERROR:
        default:
            return state
    }
}

export default storesReducer
