import { injectIntl } from 'react-intl'
import { InputText } from 'primereact/inputtext'
import { ReactComponent as CloseIcon } from '../../../utils/icons/elements-icon-close.svg'
import { ReactComponent as SearchIcon } from '../../../utils/icons/elements-icon-search.svg'
import './MainProjectSearch.css'

const MainProjectSearch = (props: {
    searchValue: string
    placeholder: string
    isLoading: boolean
    isSearching: boolean
    changeSearchValue: (searchValue: any) => void
    clearSearchValue: () => void
}) => {
    const {
        searchValue,
        isLoading,
        isSearching,
        placeholder,
        changeSearchValue,
        clearSearchValue,
    } = props

    return (
        <div className="searchInput">
            <span className="p-input-icon-left">
                <SearchIcon className="searchIcon" />
                <InputText
                    value={searchValue}
                    placeholder={placeholder}
                    onChange={(e: any) => changeSearchValue(e.target.value)}
                />
                {searchValue && !isLoading && !isSearching && (
                    <CloseIcon
                        className="closeIcon"
                        onClick={() => clearSearchValue()}
                    />
                )}
            </span>
        </div>
    )
}

export default injectIntl(MainProjectSearch)

