import { connect } from 'react-redux'
import { FilterCalendarComponent } from '../components/filter/filter-calendar/filter-calendar.component'
import FilterSearchStoresClearSection from '../components/filter/filter-searchstores-clear-section'
import { SidebarMenuKind } from '../models/sidebar-menu-kind'
import {
    clearAllFilters,
    setSelectedRelocationMonth,
    setSelectedRelocationYear,
    setSelectedSidebarMenu,
} from '../redux/actions/filters'
import { getSearchResults } from '../redux/actions/stores'
import { createLoadingSelector } from '../redux/selectors'
import { AppState } from '../redux/states/appState'

const RelocationFilter = (props: any) => {
    return (
        <>
            <FilterSearchStoresClearSection
                noGutter
                onClearAllFilters={(e: any) => props.clearAllFilters()}
                isLoadingSearchResults={props.loadingSearchResults}
                isSearchResultsDisabled={props.loadingStores}
                onSearchResults={() => props.getSearchResults()}
            />
            <FilterCalendarComponent
                selectedMonths={
                    props.relocationFilters.selectedRelocationMonths
                }
                selectedYears={props.relocationFilters.selectedRelocationYears}
                onMonthChange={props.setSelectedRelocationMonth}
                onYearChange={props.setSelectedRelocationYear}
            />
        </>
    )
}

const loadingStores = createLoadingSelector(['STORES'])
const loadingSearchResults = createLoadingSelector(['SEARCH_RESULTS'])

const mapStateToProps = (state: AppState) => ({
    loadingStores: loadingStores(state),
    loadingSearchResults: loadingSearchResults(state),
    relocationFilters: state.filtersReducer.relocationFilters,
})

const mapDispatchToProps = (dispatch: any) => ({
    clearAllFilters: () => dispatch(clearAllFilters()),
    setSelectedRelocationMonth: (month: number) =>
        dispatch(setSelectedRelocationMonth(month)),
    setSelectedRelocationYear: (year: number) =>
        dispatch(setSelectedRelocationYear(year)),
    getSearchResults: () =>
        dispatch(getSearchResults()).then((e: any) =>
            dispatch(setSelectedSidebarMenu(SidebarMenuKind.SEARCH_RESULTS))
        ),
})

export default connect(mapStateToProps, mapDispatchToProps)(RelocationFilter)
