import moment from 'moment'

export abstract class NRRFilters {
    static prepareSolrFQFilter = (
        selectedYears: Set<number>,
        selectedMonths: Set<number>
    ) => {
        let solrNRRCondition: string[] = []
        let solrOpenDateCondition: string[] = []
        let solrCloseDateCondition: string[] = []

        if (selectedYears.size > 0) {
            // Convert to arrays to allow iteration
            const selYearsArray = Array.from(selectedYears.values())
            const selMonthsArray = Array.from(selectedMonths.values())

            const dateNow = new Date()
            if (selectedMonths.size > 0) {
                // Filter by years and months
                for (let selYear of selYearsArray) {
                    for (let selMonth of selMonthsArray) {
                        const yearAndMonthToStart = Math.min(
                            moment([selYear, selMonth - 1]).valueOf(),
                            dateNow.getTime()
                        )
                        const yearAndMonthToEnd = Math.min(
                            moment([selYear, selMonth - 1])
                                .endOf('month')
                                .valueOf(),
                            dateNow.getTime()
                        )

                        solrNRRCondition.push(
                            `[${yearAndMonthToStart} TO ${yearAndMonthToEnd}]`
                        )

                        solrOpenDateCondition.push(
                            `[* TO ${yearAndMonthToEnd}]`
                        )

                        solrCloseDateCondition.push(
                            `[${yearAndMonthToEnd} TO *]`
                        )
                    }
                }
            } else {
                // Filter by years only
                for (let selYear of selYearsArray) {
                    const yearToStart = Math.min(
                        moment([selYear]).valueOf(),
                        dateNow.getTime()
                    )
                    const yearToEnd = Math.min(
                        moment([selYear]).endOf('year').valueOf(),
                        dateNow.getTime()
                    )

                    solrNRRCondition.push(`[${yearToStart} TO ${yearToEnd}]`)

                    solrOpenDateCondition.push(`[* TO ${yearToEnd}]`)

                    solrCloseDateCondition.push(`[${yearToEnd} TO *]`)
                }
            }
        }

        return {
            nrr: solrNRRCondition.join(' OR '),
            openDate: solrOpenDateCondition.join(' OR '),
            closeDate: solrCloseDateCondition.join(' OR '),
        }
    }

    static mostRecentYearSolrRange = (
        selectedYears: Set<number>,
        defaultYear: number
    ) => {
        const mostRecentYear = selectedYears.size
            ? Math.max.apply(Math, Array.from(selectedYears))
            : defaultYear
        const year = moment([mostRecentYear])
        const rangeStart = year.valueOf()
        const rangeEnd = year.clone().endOf('year').valueOf()
        const oneYearRangeGap = rangeEnd - rangeStart

        return {
            year: mostRecentYear,
            rangeStart: rangeStart,
            rangeEnd: rangeEnd,
            rangeGap: oneYearRangeGap,
        }
    }
}
