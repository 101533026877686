import { FormattedMessage } from 'react-intl'

function MapTopLegendItem({
    name,
    className,
}: {
    name: string
    className?: string
}) {
    return (
        <div className={`flex items-center ${className}`}>
            <div className={`w-3 h-3 mr-2 bg-${name} rounded-full`} />
            <div className="text-xs leading-none text-gray-400">
                <FormattedMessage id={`map.${name}`}>
                    {(text: string) => text.toUpperCase()}
                </FormattedMessage>
            </div>
        </div>
    )
}

export default MapTopLegendItem
