import { BaseDispatcher } from './base'

export enum UserDispatchTypes {
    USER_SUCCESS = 'USER_SUCCESS',
    USER_TOKEN = 'USER_TOKEN',
    USER_REQUEST = 'USER_REQUEST',
    USER_LOGOUT = 'USER_LOGOUT',
    USER_ERROR = 'USER_ERROR',
    USER_PERMISSIONS_SUCCESS = 'USER_PERMISSIONS_SUCCESS',
    FAVOURITE_STORES_LIST_REQUEST = 'FAVOURITE_STORES_LIST_REQUEST',
    FAVOURITE_STORES_LIST_SUCCESS = 'FAVOURITE_STORES_LIST_SUCCESS',
    FAVOURITE_STORES_LIST_ERROR = 'FAVOURITE_STORES_LIST_ERROR',
    SET_FAVOURITE_STORE_REQUEST = 'SET_FAVOURITE_STORE_REQUEST',
    SET_FAVOURITE_STORE_SUCCESS = 'SET_FAVOURITE_STORE_SUCCESS',
    SET_FAVOURITE_STORE_ERROR = 'SET_FAVOURITE_STORE_ERROR',
    REMOVE_FAVOURITE_STORE_REQUEST = 'REMOVE_FAVOURITE_STORE_REQUEST',
    REMOVE_FAVOURITE_STORE_SUCCESS = 'REMOVE_FAVOURITE_STORE_SUCCESS',
    REMOVE_FAVOURITE_STORE_ERROR = 'REMOVE_FAVOURITE_STORE_ERROR',
    SET_USER_LANGUAGE = 'SET_USER_LANGUAGE',
    SET_USER_LANGUAGE_UPDATE_DB = 'SET_USER_LANGUAGE_UPDATE_DB',
    REOPEN_SIDEBAR_MEMO_SUCCESS = 'REOPEN_SIDEBAR_MEMO_SUCCESS',
}
export class UserDispatcher extends BaseDispatcher {
    static UserRequest = (): any => {
        return new UserDispatcher(
            UserDispatchTypes.USER_REQUEST,
            {}
        ).onDispatchSuccess()
    }

    static UserSuccess = (success: any): any => {
        return new UserDispatcher(
            UserDispatchTypes.USER_SUCCESS,
            success
        ).onDispatchSuccess()
    }

    static UserToken = (success: any): any => {
        return new UserDispatcher(
            UserDispatchTypes.USER_TOKEN,
            success
        ).onDispatchSuccess()
    }

    static UserLogout = (): any => {
        return new UserDispatcher(
            UserDispatchTypes.USER_LOGOUT,
            {}
        ).onDispatchSuccess()
    }
    static UserPermissionsSuccess = (success: any): any => {
        return new UserDispatcher(
            UserDispatchTypes.USER_PERMISSIONS_SUCCESS,
            success
        ).onDispatchSuccess()
    }

    static UserError = (error: any): any => {
        return new UserDispatcher(
            UserDispatchTypes.USER_ERROR,
            error
        ).onDispatchError()
    }

    static SetFavouriteStoreRequest = (): any => {
        return new UserDispatcher(
            UserDispatchTypes.SET_FAVOURITE_STORE_REQUEST,
            {}
        ).onDispatchSuccess()
    }

    static SetFavouriteStoreSuccess = (success: any): any => {
        return new UserDispatcher(
            UserDispatchTypes.SET_FAVOURITE_STORE_SUCCESS,
            success
        ).onDispatchSuccess()
    }

    static SetUserLanguageSuccess = (success: any): any => {
        return new UserDispatcher(
            UserDispatchTypes.SET_USER_LANGUAGE,
            success
        ).onDispatchSuccess()
    }

    static SetUserLanguageUpdateDBSuccess = (): any => {
        return new UserDispatcher(
            UserDispatchTypes.SET_USER_LANGUAGE_UPDATE_DB,
            {}
        ).onDispatchSuccess()
    }

    static SetFavouriteStoreError = (error: any): any => {
        return new UserDispatcher(
            UserDispatchTypes.SET_FAVOURITE_STORE_ERROR,
            error
        ).onDispatchError()
    }

    static RemoveFavouriteStoreRequest = (): any => {
        return new UserDispatcher(
            UserDispatchTypes.REMOVE_FAVOURITE_STORE_REQUEST,
            {}
        ).onDispatchSuccess()
    }

    static RemoveFavouriteStoreSuccess = (success: any): any => {
        return new UserDispatcher(
            UserDispatchTypes.REMOVE_FAVOURITE_STORE_SUCCESS,
            success
        ).onDispatchSuccess()
    }

    static RemoveFavouriteStoreError = (error: any): any => {
        return new UserDispatcher(
            UserDispatchTypes.REMOVE_FAVOURITE_STORE_ERROR,
            error
        ).onDispatchError()
    }
    static GetFavouriteStoresListRequest = (): any => {
        return new UserDispatcher(
            UserDispatchTypes.FAVOURITE_STORES_LIST_REQUEST,
            {}
        ).onDispatchSuccess()
    }

    static GetFavouriteStoresListSuccess = (success: any): any => {
        return new UserDispatcher(
            UserDispatchTypes.FAVOURITE_STORES_LIST_SUCCESS,
            success
        ).onDispatchSuccess()
    }

    static GetFavouriteStoresListError = (error: any): any => {
        return new UserDispatcher(
            UserDispatchTypes.FAVOURITE_STORES_LIST_ERROR,
            error
        ).onDispatchError()
    }

    static SetReopenSidebarMemoSuccess = (success: any): any => {
        return new UserDispatcher(
            UserDispatchTypes.REOPEN_SIDEBAR_MEMO_SUCCESS,
            success
        ).onDispatchSuccess()
    }
}
