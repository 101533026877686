import React from 'react'
import { MonthAndYearPicker } from '../../inputs/month-year.component'
import styles from './filter-calendar.module.scss'

type FilterCalendarComponentProps = {
    selectedYears: Set<number>
    selectedMonths: Set<number>
    onYearChange: Function
    onMonthChange: Function
}

export const FilterCalendarComponent = (
    props: FilterCalendarComponentProps
) => {
    const { onYearChange, onMonthChange, selectedYears, selectedMonths } = props

    return (
        <div className={styles.filter_calendars}>
            <MonthAndYearPicker
                yearPicker
                selected={selectedYears}
                onItemSelected={(selection: any) => {
                    onYearChange(selection)
                }}
                style={{ marginTop: '1em', padding: 10 }}
            />
            <MonthAndYearPicker
                monthPicker
                selected={selectedMonths}
                onItemSelected={(selection: any) => {
                    onMonthChange(selection)
                }}
                style={{ marginTop: '1em', padding: 10 }}
            />
        </div>
    )
}
