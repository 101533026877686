import axios from 'axios'
import { Storage } from '../storage/storage'

export const http = axios.create({
    baseURL: process.env.REACT_APP_BASE_URL_BE,
    timeout: 60000,
    maxContentLength: Infinity,
    maxBodyLength: Infinity,
    headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        // 'Access-Control-Allow-Origin': '*',
        'Access-Control-Allow-Headers': '*',
    },
})

// Add a request interceptor
http.interceptors.request.use(
    (config) => {
        config.headers['Access-Control-Allow-Origin'] = 'true'
        const token = Storage.getAccessToken()
        if (token) {
            config.headers['Authorization'] = 'Bearer ' + token
        }
        return config
    },
    (error) => {
        Promise.reject(error)
    }
)

http.interceptors.response.use(
    (response) => {
        return response
    },
    (error: any) => {
        const originalRequest = error.config
        // console.log(error);
        if (
            error.response &&
            error.response.status === 401 &&
            !originalRequest._retry
        ) {
            // originalRequest._retry = true;
            // return http
            //   .post("/auth/refresh/", {
            //     refresh_token: LocalStorage.getRefreshToken(),
            //   })
            //   .then((res) => {
            //     if (res.status === 200) {
            //       if (res.data && res.data.accessToken && res.data.refreshToken)
            //         UserHelper._shared().doLogin(
            //           res.data.accessToken,
            //           res.data.refreshToken
            //         );
            //       // 2) Change Authorization header
            //       //instance.headers.common['Authorization'] = 'Bearer ' + localStorageService.getAccessToken();
            //       // 3) return originalRequest object with Axios.
            //       return http(originalRequest);
            //     }
            //   });
        }

        // return Error object with Promise
        return Promise.reject(error)
    }
)

