import { combineReducers } from 'redux'
import userReducer from './reducers/user'
import filtersReducer from './reducers/filters'
import storesReducer from './reducers/stores'
import snapshotReducer from './reducers/snapshot'
import loadingReducer from './reducers/loading'
import errorsReducer from './reducers/errors'
import successReducer from './reducers/success'
import photosReducer from './reducers/photos'
import mainprojectsReducer from './reducers/mainprojects'

const appReducer = combineReducers({
    userReducer,
    filtersReducer,
    storesReducer,
    snapshotReducer,
    loadingReducer,
    successReducer,
    errorsReducer,
    photosReducer,
    mainprojectsReducer,
})

const rootReducer = (state: any, action: any) => {
    if (action.type === 'USER_LOGOUT') {
        state = undefined
    }

    return appReducer(state, action)
}

export default rootReducer
