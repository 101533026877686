import enDictionary from "./assets/dictionaries/en.json";
import itDictionary from "./assets/dictionaries/it.json";

import enCalendar from "./assets/calendars/en.json";
import itCalendar from "./assets/calendars/it.json";

export class I18nHelper {
  static dictionaries = (): any => {
    return {
      "en-EN": enDictionary,
      "it-IT": itDictionary,
    };
  };

  static calendars = (): any => {
    return {
      "en-EN": enCalendar,
      "it-IT": itCalendar,
    };
  };

  static supportedLanguages = (): any => {
    return [
      { label: "en-EN", value: "en-EN" },
      { label: "it-IT", value: "it-IT" },
    ];
  };
}

export const i18nSupportedLanguages = I18nHelper.supportedLanguages();
export const i18nDictionaries: any = I18nHelper.dictionaries();
export const i18nCalendars: any = I18nHelper.calendars();
