import { IDBPDatabase, openDB } from 'idb'

export abstract class IndexedDbInfo {
    public static appDB = 'pictureViewer'
    public static fuseStoresTable = 'fuseStores'
    public static storesTable = 'stores'
    public static storesSnapshotTable = 'storesSnapshot'

    public static allTables = [
        IndexedDbInfo.fuseStoresTable,
        IndexedDbInfo.storesTable,
        IndexedDbInfo.storesSnapshotTable,
    ]
}

export class IndexedDb {
    private database: string
    private db: any

    constructor(database: string) {
        this.database = database
    }

    public async createObjectStore(tableNames: string[], version: number = 1) {
        try {
            this.db = await openDB(this.database, version, {
                upgrade(db: IDBPDatabase) {
                    for (const tableName of tableNames) {
                        if (db.objectStoreNames.contains(tableName)) {
                            continue
                        }
                        db.createObjectStore(tableName, {
                            autoIncrement: true,
                            keyPath: 'id',
                        })
                    }
                },
            })
        } catch (error) {
            return false
        }
    }

    public async getValue(tableName: string, id: number) {
        const tx = this.db.transaction(tableName, 'readonly')
        const store = tx.objectStore(tableName)
        const result = await store.get(id)
        //console.log('Get Data ', JSON.stringify(result))
        return result
    }

    public async getAllValues(tableName: string) {
        const tx = this.db.transaction(tableName, 'readonly')
        const store = tx.objectStore(tableName)
        const result = await store.getAll()
        //console.log('Get All Data', JSON.stringify(result))
        return result
    }

    public async putValue(tableName: string, value: object) {
        const tx = this.db.transaction(tableName, 'readwrite')
        const store = tx.objectStore(tableName)
        const result = await store.put(value)
        //console.log('Put Data ', JSON.stringify(result))
        return result
    }

    public async putBulkValues(tableName: string, values: object[]) {
        const tx = this.db.transaction(tableName, 'readwrite')
        const store = tx.objectStore(tableName)
        for (const value of values) {
            await store.put(value)
            //const result = await store.put(value)
            //console.log('Put Bulk Data ', JSON.stringify(result))
        }
        return this.getAllValues(tableName)
    }

    public async deleteValue(tableName: string, id: number) {
        const tx = this.db.transaction(tableName, 'readwrite')
        const store = tx.objectStore(tableName)
        const result = await store.get(id)
        if (!result) {
            //console.log('Id not found', id)
            return result
        }
        await store.delete(id)
        //console.log('Deleted Data', id)
        return id
    }
}
