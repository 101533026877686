import { Colors } from '../../../colors'
import styled from 'styled-components'

export const StyledWrapper = styled.div`
    .detail-page {
        margin-top: 110px;
        width: 100%;
    }
    .photo-container {
        border-right: solid 1px ${Colors.grey2};
        // padding: 40px 30px 40px 40px;
    }
    .details-container {
        padding: 0px;
    }
    .tab-view {
        margin-top: 95px;
    }
    .p-tabview-panels {
        padding: 0;
    }
    .p-tabview-nav {
        border: none;
    }
    .mobile-tab {
        width: 50%;
        min-height: 44px;
        border: none;
    }
    .p-tabview .p-tabview-nav li .p-tabview-nav-link {
        height: 100%;
        border-bottom: 4px solid ${Colors.grey1};
        border-top-left-radius: 0;
        border-top-right-radius: 0;
    }
    .p-tabview .p-tabview-nav li .p-tabview-nav-link:focus,
    .p-tabview .p-tabview-nav li .p-tabview-nav-link:active {
        box-shadow: none;
    }
    .p-tabview-title {
        margin-left: auto;
        margin-right: auto;
        text-transform: uppercase;
        letter-spacing: 0.27px;
        color: ${Colors.grey5};
        font-size: 14px;
        height: 100%;
        font-weight: 700;
    }
    .p-tabview .p-tabview-nav li.p-tabview-selected .p-tabview-nav-link {
        border-bottom: 4px solid ${Colors.blue};
    }
    .p-tabview-selected .p-tabview-title {
        color: ${Colors.blue};
    }
    .img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        border-radius: 4px;
        cursor: pointer;
        transition: 0.3s;
    }
    @media (max-width: 767px) {
        .tab-view {
            margin-top: 60px;
        }
    }
`

