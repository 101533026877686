import { Sidebar } from 'primereact/sidebar'
import { StyledFilterSidebar } from './style'
import FilterTitle from '../filter/filter-title'
import BannerFilter from '../../containers/banner-filter'
import { SidebarMenuKind } from '../../models/sidebar-menu-kind'
import { injectIntl } from 'react-intl'
import RegionFilter from '../../containers/region-filter'
import RemodelFilter from '../../containers/remodel-filter'
import RelocationFilter from '../../containers/relocation-filter'
import NewFilter from '../../containers/new-filter'
import HostedRetailFilter from '../../containers/hosted-retail-filter'
import { FiltersSidebarSizeType } from '../../models/filters-sidebar-size-type'
import SquaredCheckbox from '../squared-checkbox'
import ProfileSidebarContent from '../../components/profile-sidebar-content'
import ResultsSidebarContent from '../../components/results-sidebar-content'
import { User } from '../../models/user'
import { Store } from '../../models/store'
import SurveyDateFilter from '../../containers/survey-date-filter'
import { useWindowResize } from '../../utils/commonFunctions'
import { Photo } from '../../models/photo-list'
import MainProjectFilter from '../../containers/main-project-filter'

type FilterSidebarProps = {
    intl: any
    setSelectedSidebarMenu: (newSelectedFiltersMenu: SidebarMenuKind) => void
    setSelectedSidebarSizeType: (
        newSelectedSidebarSizeType: FiltersSidebarSizeType
    ) => void
    setLanguage: Function
    isOpen: boolean
    sizeType: FiltersSidebarSizeType
    selectedSidebarMenu: SidebarMenuKind
    currentUser: User
    stores: Store[]
    searchResults: Store[]
    favouriteStoresList: Store[]
    thumbnails: Photo[]
    getThumbnails: any
    dateFilters: any[]
}

const PVSidebar = (props: FilterSidebarProps) => {
    const { thumbnails, getThumbnails, dateFilters, intl } = props
    const isMobileNew = useWindowResize()

    return (
        <StyledFilterSidebar
            sizeType={
                isMobileNew ? props.sizeType : FiltersSidebarSizeType.DESKTOP
            }>
            <Sidebar
                visible={props.isOpen}
                position={isMobileNew ? 'bottom' : 'left'}
                showCloseIcon
                modal={false}
                baseZIndex={10}
                onHide={() =>
                    props.setSelectedSidebarMenu(SidebarMenuKind.NONE)
                }>
                <>
                    {isMobileNew ? (
                        <div className="p-d-flex p-jc-center p-ai-center mobileFilters">
                            <div className="p-mr-3">
                                <SquaredCheckbox
                                    size="small"
                                    checked={
                                        props.sizeType ===
                                        FiltersSidebarSizeType.SMALL
                                    }
                                    onChange={(e: any) =>
                                        props.setSelectedSidebarSizeType(
                                            FiltersSidebarSizeType.SMALL
                                        )
                                    }
                                />
                            </div>
                            <div className="p-mr-3">
                                <SquaredCheckbox
                                    checked={
                                        props.sizeType ===
                                        FiltersSidebarSizeType.MEDIUM
                                    }
                                    onChange={(e: any) =>
                                        props.setSelectedSidebarSizeType(
                                            FiltersSidebarSizeType.MEDIUM
                                        )
                                    }
                                />
                            </div>
                            <div className="p-mr-3">
                                <SquaredCheckbox
                                    size="large"
                                    checked={
                                        props.sizeType ===
                                        FiltersSidebarSizeType.LARGE
                                    }
                                    onChange={(e: any) =>
                                        props.setSelectedSidebarSizeType(
                                            FiltersSidebarSizeType.LARGE
                                        )
                                    }
                                />
                            </div>
                            <div className="p-mr-3">
                                <SquaredCheckbox
                                    size="extralarge"
                                    checked={
                                        props.sizeType ===
                                        FiltersSidebarSizeType.EXTRALARGE
                                    }
                                    onChange={(e: any) =>
                                        props.setSelectedSidebarSizeType(
                                            FiltersSidebarSizeType.EXTRALARGE
                                        )
                                    }
                                />
                            </div>
                        </div>
                    ) : (
                        <></>
                    )}
                    {(() => {
                        switch (props.selectedSidebarMenu) {
                            case SidebarMenuKind.BANNER:
                                return isMobileNew ? (
                                    <BannerFilter />
                                ) : (
                                    <FilterTitle
                                        label={intl.formatMessage({
                                            id: 'banner.filter.title',
                                        })}>
                                        <BannerFilter />
                                    </FilterTitle>
                                )
                            case SidebarMenuKind.REGION:
                                return isMobileNew ? (
                                    <RegionFilter />
                                ) : (
                                    <FilterTitle
                                        label={intl.formatMessage({
                                            id: 'region.filter.title',
                                        })}>
                                        <RegionFilter />
                                    </FilterTitle>
                                )
                            case SidebarMenuKind.REMODEL:
                                return isMobileNew ? (
                                    <RemodelFilter />
                                ) : (
                                    <FilterTitle
                                        label={intl.formatMessage({
                                            id: 'remodel.filter.title',
                                        })}>
                                        <RemodelFilter />
                                    </FilterTitle>
                                )
                            case SidebarMenuKind.RELOCATION:
                                return isMobileNew ? (
                                    <RelocationFilter />
                                ) : (
                                    <FilterTitle
                                        label={intl.formatMessage({
                                            id: 'relocation.filter.title',
                                        })}>
                                        <RelocationFilter />
                                    </FilterTitle>
                                )
                            case SidebarMenuKind.NEW:
                                return isMobileNew ? (
                                    <NewFilter />
                                ) : (
                                    <FilterTitle
                                        label={intl.formatMessage({
                                            id: 'new.filter.title',
                                        })}>
                                        <NewFilter />
                                    </FilterTitle>
                                )
                            case SidebarMenuKind.HOSTED_RETAIL:
                                return isMobileNew ? (
                                    <HostedRetailFilter />
                                ) : (
                                    <FilterTitle
                                        label={intl.formatMessage({
                                            id: 'hosted_retail.filter.title',
                                        })}>
                                        <HostedRetailFilter />
                                    </FilterTitle>
                                )
                            case SidebarMenuKind.SURVEY_DATE:
                                return isMobileNew ? (
                                    <SurveyDateFilter />
                                ) : (
                                    <FilterTitle
                                        label={intl.formatMessage({
                                            id: 'survey_date.filter.title',
                                        })}>
                                        <SurveyDateFilter />
                                    </FilterTitle>
                                )
                            case SidebarMenuKind.FAVOURITES:
                                return isMobileNew ? (
                                    <ResultsSidebarContent
                                        storesResults={
                                            props.favouriteStoresList
                                        }
                                        thumbnails={thumbnails}
                                        getThumbnails={getThumbnails}
                                        dateFilters={dateFilters}
                                        sizeType={props.sizeType}
                                    />
                                ) : (
                                    <FilterTitle
                                        label={intl.formatMessage({
                                            id: 'profile_and_settings.favourites',
                                        })}>
                                        <ResultsSidebarContent
                                            storesResults={
                                                props.favouriteStoresList
                                            }
                                            favouriteStoresList={
                                                props.favouriteStoresList
                                            }
                                            thumbnails={thumbnails}
                                            getThumbnails={getThumbnails}
                                            dateFilters={dateFilters}
                                            sizeType={props.sizeType}
                                        />
                                    </FilterTitle>
                                )
                            case SidebarMenuKind.PROFILE_AND_SETTINGS:
                                return isMobileNew ? (
                                    <ProfileSidebarContent
                                        currentUser={props.currentUser}
                                        setLanguage={props.setLanguage}
                                    />
                                ) : (
                                    <FilterTitle
                                        label={intl.formatMessage({
                                            id: 'profile_and_settings.profile_and_settings',
                                        })}
                                        className="p-panel-profile">
                                        <ProfileSidebarContent
                                            currentUser={props.currentUser}
                                            setLanguage={props.setLanguage}
                                        />
                                    </FilterTitle>
                                )
                            case SidebarMenuKind.SEARCH_RESULTS:
                                return isMobileNew ? (
                                    <ResultsSidebarContent
                                        storesResults={props.searchResults}
                                        thumbnails={thumbnails}
                                        getThumbnails={getThumbnails}
                                        dateFilters={dateFilters}
                                        sizeType={props.sizeType}
                                    />
                                ) : (
                                    <FilterTitle
                                        label={intl.formatMessage({
                                            id: 'search_results',
                                        })}>
                                        <ResultsSidebarContent
                                            storesResults={props.searchResults}
                                            favouriteStoresList={
                                                props.favouriteStoresList
                                            }
                                            thumbnails={thumbnails}
                                            getThumbnails={getThumbnails}
                                            dateFilters={dateFilters}
                                            sizeType={props.sizeType}
                                        />
                                    </FilterTitle>
                                )
                            case SidebarMenuKind.MAIN_PROJECT:
                                return isMobileNew ? (
                                    <MainProjectFilter />
                                ) : (
                                    <FilterTitle
                                        label={intl.formatMessage({
                                            id: 'main_project.filter.title',
                                        })}>
                                        <MainProjectFilter />
                                    </FilterTitle>
                                )
                            default:
                                return null
                        }
                    })()}
                </>
            </Sidebar>
        </StyledFilterSidebar>
    )
}

export default injectIntl(PVSidebar)
