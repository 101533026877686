export interface Photo {
    store_id: string
    url: string
    index: number
    macroarea?: string
    label?: string
    date_execution: string
    date?: Date
    photo_id?: string
}

// macroareas to sections
// overview, window -> overview
// wall, floor, ceiling -> interior
// lab, doctor area -> services
// back of house -> back of house
// -> floor plan
export const sections: string[] = [
    'Overview',
    'Interior',
    'Services',
    'Back of House',
    'Floor Plan',
]
