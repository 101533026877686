import React, { useContext, useEffect, useState } from 'react'
import {
    clearAllCitiesFilters,
    clearAllCountriesFilters,
    clearAllZoneDescsFilters,
    getBannerDesc,
    getBUs,
    getChannelOfTrade,
    getCities,
    getCountries,
    getHostedRetail,
    getMacroRegions,
    getSegment,
    getStoreDesign,
    getSelectionBest,
    getZoneDescs,
    setSelectedSidebarMenu,
    setSelectedSidebarSizeType,
} from '../redux/actions/filters'
import Map from '../components/map/map'
import { SidebarMenuKind } from '../models/sidebar-menu-kind'
import { connect } from 'react-redux'
import PVSidebar from '../components/pv-sidebar'
import AppToolbar from './app-toolbar/toolbar'
import Filters from './app-toolbar/filters'
import { AppState } from '../redux/states/appState'
import { createLoadingSelector } from '../redux/selectors'
import { ProgressSpinner } from 'primereact/progressspinner'
import styled from 'styled-components'
import { FiltersSidebarSizeType } from '../models/filters-sidebar-size-type'
import { useRefreshApplicationData } from '../utils/hooks'
import { getStores, getSearchResults } from '../redux/actions/stores'
import {
    setUserLanguage,
    getFavouriteStoresList,
    setReopenSidebarMemo,
} from '../redux/actions/user'
import { getAllPhotos, getDefaultThumbnails } from '../redux/actions/photos'
import { i18nContext } from '../utils/localization/i18nWrapper'
import moment from 'moment'

const StyledProgressSpinner = styled(ProgressSpinner)`
  /* center progress spinner */
  position: absolute;
  top: 50%;
  left: 50%;
  z-index: 30;
  height: 60px;
  width: 60px;
  margin-left: -30px;
  margin-top: -30px;
}`

const HomeContainer = (props: any) => {
    const isFilterBarOpen = props.selectedSidebarMenu !== SidebarMenuKind.NONE

    const {
        bannerFilters,
        regionFilters,
        remodelFilters,
        relocationFilters,
        newFilters,
        hostedRetailFilters,
        surveyDateFilters,
    } = props

    const [fibonacciNumber, setFibonacciNumber] = useState<{
        first: number
        second: number
    }>({
        first: 0,
        second: 1,
    })

    useRefreshApplicationData(
        props.currentUser,
        props.stores,
        bannerFilters,
        regionFilters,
        remodelFilters,
        relocationFilters,
        newFilters,
        hostedRetailFilters,
        surveyDateFilters,
        props.getStores,
        props.getFavouriteStoresList,
        props.getBannerDesc,
        props.getChannelOfTrade,
        props.getSegment,
        props.getStoreDesign,
        props.getSelectionBest,
        props.getMacroRegions,
        props.getCountries,
        props.getZoneDescs,
        props.getCities,
        props.getBUs,
        props.getHostedRetail,
        props.selectedSidebarMenu,
        props.clearAllCountriesFilters,
        props.clearAllZoneDescsFilters,
        props.clearAllCitiesFilters,
        fibonacciNumber
    )

    useEffect(() => {
        if (props.reopenSidebarMemo.shouldReopen) {
            props.setSelectedSidebarMenu(
                props.reopenSidebarMemo.sidebarMenuKindToReopen
            )
            props.setReopenSidebarMemo(false, SidebarMenuKind.NONE)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        if (props.stores?.length < 1) {
            let tmpFibonacciNumber = {
                first: fibonacciNumber.second,
                second: fibonacciNumber.first + fibonacciNumber.second,
            }
            setTimeout(() => {
                setFibonacciNumber(tmpFibonacciNumber)
            }, 3000 * tmpFibonacciNumber.second)
        } else {
            setFibonacciNumber({
                first: 0,
                second: 1,
            })
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.stores])

    const { switchLanguage } = useContext(i18nContext)

    useEffect(() => {
        if (props?.currentUser && props?.currentUser?.curr_language === '') {
            let locale: string = 'it-IT'
            let i18n: string | null = localStorage.getItem(
                'PICTURE-VIEWER_i18n'
            )
            if (i18n) {
                let i18nObj: { locale: string; default: string } =
                    JSON.parse(i18n)
                locale = i18nObj?.locale
            }
            localStorage.setItem('LANGUAGE_just_changed', 'true')
            switchLanguage(locale)
            moment.locale(locale)
            props.setLanguage(locale.split('-')[0])
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props?.currentUser])

    const toolbarProps = { leftContents: <Filters /> }

    return (
        <>
            {props.loading ? <StyledProgressSpinner /> : <></>}
            <Map
                stores={props.stores}
                isFilterBarOpen={isFilterBarOpen}
                thumbnails={props.photos}
                getThumbnails={props.getAllPhotos}
                dateFilters={[
                    surveyDateFilters,
                    remodelFilters,
                    relocationFilters,
                    newFilters,
                ]}
                loading={props.photoLoading}
            />
            <div className="p-d-md-flex p-flex-column z-20">
                <div className="p-mb-12">
                    <AppToolbar {...toolbarProps} />
                </div>
                <div className="p-mb-12">
                    <PVSidebar
                        setSelectedSidebarMenu={props.setSelectedSidebarMenu}
                        setSelectedSidebarSizeType={
                            props.setSelectedSidebarSizeType
                        }
                        isOpen={isFilterBarOpen}
                        sizeType={props.sidebarSizeType}
                        selectedSidebarMenu={props.selectedSidebarMenu}
                        currentUser={props.currentUser}
                        setLanguage={props.setLanguage}
                        stores={props.stores}
                        searchResults={props.searchResults}
                        favouriteStoresList={props.favouriteStoresList}
                        thumbnails={props.thumbnails}
                        getThumbnails={props.getThumbnails}
                        dateFilters={[
                            surveyDateFilters,
                            remodelFilters,
                            relocationFilters,
                            newFilters,
                        ]}
                    />
                </div>
            </div>
        </>
    )
}

const loadingSelector = createLoadingSelector(['STORES'])

const mapStateToProps = (state: AppState) => ({
    loading: loadingSelector(state),
    currentUser: state.userReducer.user,
    sidebarSizeType: state.filtersReducer.selectedSidebarSizeType,
    reopenSidebarMemo: state.userReducer.reopenSidebarMemo,
    selectedSidebarMenu: state.filtersReducer.selectedSidebarMenu,
    stores: state.storesReducer.stores,
    bannerFilters: state.filtersReducer.bannerFilters,
    regionFilters: state.filtersReducer.regionFilters,
    remodelFilters: state.filtersReducer.remodelFilters,
    relocationFilters: state.filtersReducer.relocationFilters,
    newFilters: state.filtersReducer.newFilters,
    hostedRetailFilters: state.filtersReducer.hostedRetailFilters,
    surveyDateFilters: state.filtersReducer.surveyDateFilters,
    searchResults: state.storesReducer.searchResults,
    favouriteStoresList: state.userReducer.favouriteStoresList,
    thumbnails: state.photosReducer.thumbnailList,
    photos: state.photosReducer.photoList,
    photoLoading: state.photosReducer.photoLoading
})

const mapDispatchToProps = (dispatch: any) => ({
    setSelectedSidebarMenu: (newSelectedSidebarMenu: SidebarMenuKind) =>
        dispatch(setSelectedSidebarMenu(newSelectedSidebarMenu)),
    setSelectedSidebarSizeType: (
        newSelectedSidebarSizeType: FiltersSidebarSizeType
    ) => dispatch(setSelectedSidebarSizeType(newSelectedSidebarSizeType)),
    setLanguage: (langLocaleShort: string) =>
        dispatch(setUserLanguage(langLocaleShort)),
    getStores: () => dispatch(getStores()),
    getBannerDesc: () => dispatch(getBannerDesc()),
    getChannelOfTrade: () => dispatch(getChannelOfTrade()),
    getSegment: () => dispatch(getSegment()),
    getStoreDesign: () => dispatch(getStoreDesign()),
    getSelectionBest: () => dispatch(getSelectionBest()),
    getMacroRegions: () => dispatch(getMacroRegions()),
    getCountries: () => dispatch(getCountries()),
    clearAllCountriesFilters: () => dispatch(clearAllCountriesFilters()),
    getZoneDescs: () => dispatch(getZoneDescs()),
    clearAllZoneDescsFilters: () => dispatch(clearAllZoneDescsFilters()),
    getCities: () => dispatch(getCities()),
    clearAllCitiesFilters: () => dispatch(clearAllCitiesFilters()),
    getBUs: () => dispatch(getBUs()),
    getHostedRetail: () => dispatch(getHostedRetail()),
    getSearchResults: () => dispatch(getSearchResults()),
    getFavouriteStoresList: () => dispatch(getFavouriteStoresList()),
    getAllPhotos: (storeIds: string[], 
        dates: {
            from: string,
            to: string
        }[]) => dispatch(getAllPhotos(storeIds, dates)),
    getThumbnails: (storeIds: string[], dates: {from: string, to: string}[]) =>
        dispatch(getDefaultThumbnails(storeIds, dates)),
    setReopenSidebarMemo: (
        shouldReopen: string,
        sidebarMenuKindToReopen: SidebarMenuKind
    ) => dispatch(setReopenSidebarMemo(shouldReopen, sidebarMenuKindToReopen)),
})

export default connect(mapStateToProps, mapDispatchToProps)(HomeContainer)
