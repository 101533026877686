import { Colors } from "../colors";
import styled from "styled-components";

const StyledWaitingContent = styled.p`
    font-family: AvenirLTPro;
    font-size: 16px;
    font-weight: 500;
    letter-spacing: 0.27px;
    color: ${Colors.grey5};
`

type WaitingContentLabelProps = {
    text: string
}

const WaitingContentLabel = (props: WaitingContentLabelProps) => {
	return (
		<StyledWaitingContent>{ props.text }</StyledWaitingContent>
	)
}

export default WaitingContentLabel;
