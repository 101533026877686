import { SearchableFilter } from './searchable-filter'

export type ZdElement = {
    count: number
    selected: boolean
}

export type ZoneDescsDict = Record<string, ZdElement>

export class ZoneDescsFilters extends SearchableFilter {
    selectedZoneDescs: Set<string>
    id: string
    title: string
    fuseEngine: any
    viewAllVisible: boolean
    searchableFilter: ZoneDescsDict
    searchedFilter: ZoneDescsDict
    searchValue: string
    getSearchableFilter = () =>
        Object.keys(this.searchedFilter).length > 0
            ? this.searchedFilter
            : this.searchableFilter
    isPanelCollapsed: boolean
    selectAllSelected: boolean

    constructor(data?: any) {
        super()

        this.selectedZoneDescs = new Set()
        this.id = 'zone_desc'
        this.title = 'region.zone_description.title'
        this.fuseEngine = null
        this.viewAllVisible = true
        this.searchableFilter = data
        this.searchedFilter = data
        this.searchValue = ''
        this.isPanelCollapsed = true
        this.selectAllSelected = false
    }
}
