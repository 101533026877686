import AuthContainer from '../containers/auth'
import HomeContainer from '../containers/home'
import NotFoundContainer from '../containers/not-found'
import StoreDetailContainer from '../containers/store-detail/StoreDetailContainer'
import MainProjectDetailContainer from '../containers/main-project-detail/MainProjectDetailContainer'

export class Route {
    path: string
    component: any
    _private: boolean
    children: Route[]
    redirectTo: string
    exact: boolean

    constructor(
        path: string,
        component: any,
        _private: boolean,
        redirectTo: string,
        children: Route[],
        exact?: boolean
    ) {
        this.path = path
        this.component = component
        this._private = _private
        this.children = children
        this.redirectTo = redirectTo
        this.exact = exact ? exact : false
    }

    static DefaultRoute() {
        return new Route('/home', HomeContainer, true, '/', [], true)
    }

    static StoreDetailRoute() {
        return new Route(
            '/store/:store_id_string',
            StoreDetailContainer,
            true,
            '/',
            [],
            true
        )
    }

    static MainProjectDetailRoute() {
        return new Route(
            '/main-project/:main_project_id_string',
            MainProjectDetailContainer,
            true,
            '/',
            [],
            true
        )
    }

    static NotFoundRoute() {
        return new Route('/404', NotFoundContainer, false, '', [])
    }

    static AuthRoute() {
        return new Route('/', AuthContainer, false, '', [], true)
    }

    static Logout() {
        return new Route('/logout', AuthContainer, false, '', [], true)
    }

    child(child: Route) {
        this.children.push(child)
        return this
    }

    _children(children: Route[]) {
        this.children.concat(children)
        return this
    }
}
