import moment from 'moment'
import { http } from './http'
import { Store } from '../../models/store'
import { SolrFields } from '../../solr-fields'
import axios, { CancelToken } from 'axios'
import { ObsoleteError } from '../obsoleteError'
import { StoreBestSelectionDict } from '../../models/store-best-selection'
import { StoresSnapshot } from '../../models/stores-snapshot'
import { FiltersApi } from './filtersApi'
import { FiltersState } from '../../redux/states/filtersState'
import { RemodelFilters } from '../../models/remodel-filters'
import { RelocationFilters } from '../../models/relocation-filters'
import { NewFilters } from '../../models/new-filters'

/**
 * @export StoresApi - Just an api interface for claiming stores data and reducing the code needed for axios http functions
 * @class StoresApi
 */
export class StoresApi {
    /**
     * Base Url for the Stores Api
     * @static
     * @type {string}
     * @memberof StoresApi
     */
    static STORES_BASE_URL: string = process.env.REACT_APP_BASE_URL_BE || ''

    /**
     * Endpoint for getting Stores data from the Solr machine
     * @static
     * @type {string}
     * @memberof StoresApi
     */
    static STORES_FROM_SOLR_ENDP: string = `${StoresApi.STORES_BASE_URL}solr`

    /**
     * Endpoint for getting Stores data from the Solr machine
     * @static
     * @type {string}
     * @memberof StoresApi
     */
    static STORES_BASE_QUERY_SINGLE_STORE_FROM_SOLR: string = `select?q=*:*`

    /**
     * This functions gets all the Stores
     * @returns Promise of type AxiosPromise
     */
    static GetSingleStore = async (storeId: string): Promise<Store> => {
        try {
            let response = await http.post(StoresApi.STORES_FROM_SOLR_ENDP, {
                solr_query: `lux_stores/${StoresApi.STORES_BASE_QUERY_SINGLE_STORE_FROM_SOLR
                    }&fq=${SolrFields.StoreID}:"${encodeURIComponent(storeId)}"`,
            })

            let stores = response.data.response.docs as Store[]
            return stores && stores.length > 0 ? stores[0] : new Store()
        } catch (error: any) {
            throw error
        }
    }

    /**
     * Endpoint for getting Search Results from the Solr machine
     * @static
     * @type {string}
     * @memberof StoresApi
     */
    static STORES_BASE_QUERY_SEARCH_RESULTS_FROM_SOLR: string = `select?q=*:*`

    /**
     * This functions gets the Search Results
     * @returns Promise of type AxiosPromise
     */
    static GetSearchResults = async (
        byStoreIds: string[],
        filtersReducer?: FiltersState
    ): Promise<Store[]> => {
        try {
            const solrFq = byStoreIds.reduce(
                (accumulator: string, val: string) => {
                    if (val) {
                        if (accumulator.length > 0) {
                            accumulator += ' OR '
                        }
                        accumulator += `${SolrFields.StoreID
                            }:"${encodeURIComponent(val)}"`
                    }

                    return accumulator
                },
                ''
            )

            const nrrFilters =
                filtersReducer != null
                    ? StoresApi.nrrFiltersbyDate(filtersReducer)
                    : ''

            let response = await http.post(StoresApi.STORES_FROM_SOLR_ENDP, {
                solr_query: `lux_stores/${StoresApi.STORES_BASE_QUERY_SEARCH_RESULTS_FROM_SOLR}&fq=${solrFq}${nrrFilters}&rows=2147483647`,
            })

            let stores = response.data.response.docs as Store[]
            // Sort by ascending Store ID
            return stores.sort(
                (store1, store2) =>
                    +store1.store_id_string - +store2.store_id_string
            )
        } catch (error: any) {
            throw error
        }
    }

    /**
     * Endpoint for getting Stores data from the Solr machine
     * @static
     * @type {string}
     * @memberof StoresApi
     */
    static STORES_BASE_QUERY_STORES_FROM_SOLR: string = `select?q=*:*&fl=${SolrFields.ID},${SolrFields.StoreName},${SolrFields.Address},${SolrFields.City},${SolrFields.Coordinate},${SolrFields.StoreID},${SolrFields.ChannelOfTrade},${SolrFields.BannerDesc},${SolrFields.OpenDate},${SolrFields.CloseDate},${SolrFields.RemodelDate},${SolrFields.RelocationDate}&start=0&rows=2147483647&facet.missing=true`

    /**
     * This functions gets all the Stores
     * @returns Promise of type AxiosPromise
     */
    static GetStores = async (
        solrFq?: string,
        cancelToken?: CancelToken
    ): Promise<Record<string, Store[] | StoresSnapshot>> => {
        try {
            let response = await http.post(
                StoresApi.STORES_FROM_SOLR_ENDP,
                {
                    solr_query: `lux_stores/${StoresApi.STORES_BASE_QUERY_STORES_FROM_SOLR
                        }${solrFq || ''}`,
                },
                {
                    cancelToken: cancelToken,
                }
            )

            let stores = response.data.response.docs as Store[]
            let storesSnapshot = new StoresSnapshot({
                new_opening_count:
                    response.data.facet_counts.facet_ranges.open_date_long
                        .counts[1] || 0,
                remodeling_count:
                    response.data.facet_counts.facet_ranges.remodel_date_long
                        .counts[1] || 0,
                relocation_count:
                    response.data.facet_counts.facet_ranges.relocation_date_long
                        .counts[1] || 0,
                division_facet: FiltersApi.normalizeFacets(
                    response.data.facet_counts.facet_fields.division_string
                ),
                cot_facet: FiltersApi.normalizeFacets(
                    response.data.facet_counts.facet_fields
                        .channel_of_trade_string
                ),
                stores_count: stores.length,
            })

            return { stores: stores, storesSnapshot: storesSnapshot }
        } catch (error: any) {
            if (axios.isCancel(error)) {
                throw new ObsoleteError('GetStores')
            } else {
                throw error
            }
        }
    }

    /**
     * Endpoint for getting all Stores data from the Solr machine
     * @static
     * @type {string}
     * @memberof StoresApi
     */
    static STORES_BASE_QUERY_FUSE_STORES_FROM_SOLR: string = `select?q=*:*&fl=${SolrFields.ID},${SolrFields.StoreName},${SolrFields.Address},${SolrFields.MacroRegionDesc},${SolrFields.City},${SolrFields.Coordinate},${SolrFields.StoreID},${SolrFields.ChannelOfTrade},${SolrFields.Division},${SolrFields.BannerDesc},${SolrFields.Market},${SolrFields.CountryDesc},${SolrFields.Region},${SolrFields.BusinessModel},${SolrFields.StoreType},${SolrFields.Segment},${SolrFields.ZoneDesc},${SolrFields.StoreDesignGroup},${SolrFields.StoreDesign},${SolrFields.HostedRetail}&rows=2147483647`

    /**
     * This functions gets all the Stores
     * @returns Promise of type AxiosPromise
     */
    static GetFuseStores = async (): Promise<Store[]> => {
        try {
            const dateNow = new Date()

            let dateFqFilter = `&fq=${SolrFields.OpenDate
                }:([* TO ${dateNow.getTime()}])&fq=${SolrFields.CloseDate
                }:([${dateNow.getTime()} TO *])`

            let response = await http.post(StoresApi.STORES_FROM_SOLR_ENDP, {
                solr_query: `lux_stores/${StoresApi.STORES_BASE_QUERY_FUSE_STORES_FROM_SOLR}${dateFqFilter}`,
            })

            let wholeStores = response.data.response.docs as Store[]
            return wholeStores
        } catch (error: any) {
            throw error
        }
    }

    /**
     * Endpoint for getting Stores Best Selection data
     * @static
     * @type {string}
     * @memberof StoresApi
     */
    static STORES_BEST_SELECTION_ENDP: string = `${StoresApi.STORES_BASE_URL}solr/stores`

    /**
     * This functions gets all the Stores' Best Selection
     * @returns Promise of type AxiosPromise
     */
    static GetStoresBestSelection = async (
        from: Date,
        to: Date,
        store_id_list: string[]
    ): Promise<StoreBestSelectionDict> => {
        try {
            const fromStr = moment(from.getTime(), 'x').format(
                'YYYY-MM-DD HH:MM:00'
            )
            const toStr = moment(to.getTime(), 'x')
                .utc()
                .format('YYYY-MM-DD HH:MM:00')

            let response = await http.post(
                StoresApi.STORES_BEST_SELECTION_ENDP,
                {
                    store_id_list: store_id_list,
                    date_from: fromStr,
                    date_to: toStr,
                }
            )

            let storesBestSelection = response.data as StoreBestSelectionDict
            return storesBestSelection
        } catch (error: any) {
            throw error
        }
    }

    /**
     * Endpoint for getting Stores by Survey Date
     * @static
     * @type {string}
     * @memberof StoresApi
     */
    static STORES_BY_SURVEY_DATE_ENDP: string = `${StoresApi.STORES_BASE_URL}solr/stores_by_date`

    /**
     * This functions gets all the Stores by Survey Date
     * @returns Promise of type AxiosPromise
     */
    static GetStoresBySurveyDate = async (
        from: Date | null,
        to: Date | null,
        store_id_list: string[]
    ): Promise<string[]> => {
        try {
            if (from == null && to == null) return store_id_list

            const fromStr =
                from != null
                    ? moment(from.getTime(), 'x').format('YYYY-MM-DD 00:00:00')
                    : '1970-01-01 00:00:00'
            const toStr =
                to != null
                    ? moment(to.getTime(), 'x')
                        .utc()
                        .format('YYYY-MM-DD 00:00:00')
                    : '2100-01-01 00:00:00'

            let response = await http.post(
                StoresApi.STORES_BY_SURVEY_DATE_ENDP,
                {
                    store_id_list: store_id_list,
                    date_from: fromStr,
                    date_to: toStr,
                    only_published: true,
                }
            )

            let storesBySurveyDate = response.data.store_id_list as string[]
            return storesBySurveyDate
        } catch (error: any) {
            throw error
        }
    }

    private static nrrFiltersbyDate(filtersReducer: FiltersState) {
        const { remodelFilters, relocationFilters, newFilters } = filtersReducer
        const { selectedRemodelYears } = remodelFilters
        const { selectedRelocationYears } = relocationFilters
        const { selectedNewYears } = newFilters

        const dateNow = new Date()

        let dateFqFilter = ''
        if (
            selectedRemodelYears.size < 1 &&
            selectedRelocationYears.size < 1 &&
            selectedNewYears.size < 1
        ) {
            dateFqFilter += `&fq=${SolrFields.OpenDate
                }:([* TO ${dateNow.getTime()}])&fq=${SolrFields.CloseDate
                }:([${dateNow.getTime()} TO *])`
        }

        // Fetch data from Solr
        return `${RemodelFilters.getSolrFQFilter(
            remodelFilters
        )}${RelocationFilters.getSolrFQFilter(
            relocationFilters
        )}${NewFilters.getSolrFQFilter(newFilters)}${dateFqFilter}`
    }
}

