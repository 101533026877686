import { connect } from 'react-redux'
import { Accordion, AccordionTab } from 'primereact/accordion'
import { injectIntl } from 'react-intl'
import { AppState } from '../redux/states/appState'
import FilterSearchStoresClearSection from '../components/filter/filter-searchstores-clear-section'
import FilterToggleButton from '../components/filter/filter-toggle-button'
import {
    clearAllFilters,
    setSelectedMacroRegion,
    setSelectedCountry,
    toggleCountriesViewAll,
    setSearchCountryValue,
    toggleZoneDescsViewAll,
    setSelectedZoneDesc,
    setSearchZoneDescValue,
    toggleCitiesViewAll,
    setSelectedCity,
    setSearchCityValue,
    toggleBUsViewAll,
    setSelectedBU,
    setSearchBUValue,
    getMacroRegions,
    setSelectedSidebarMenu,
} from '../redux/actions/filters'
import { getSearchResults } from '../redux/actions/stores'
import { MRElement } from '../models/macroregions-filters'
import { createLoadingSelector } from '../redux/selectors'
import { ProgressSpinner } from 'primereact/progressspinner'
import styled from 'styled-components'
import SmartSearchableFilter from '../components/smart-searchable-filter'
import WaitingContentLabel from '../components/waiting-content-label'
import { useEffect } from 'react'
import { SidebarMenuKind } from '../models/sidebar-menu-kind'
import { ScrollPanel } from 'primereact/scrollpanel'

const StyledProgressSpinner = styled(ProgressSpinner)`
    /* center progress spinner */
    position: relative;
    top: 50%;
    left: 50%;
    z-index: 30;
    height: 60px;
    width: 60px;
    margin-left: -30px;
    margin-top: -30px;
`

const RegionFilter = (props: any) => {
    const { intl } = props
    const { regionFilters } = props
    const {
        macroregionsFilters,
        countriesFilters,
        zoneDescsFilters,
        citiesFilters,
        busFilters,
    } = regionFilters

    const countryFiltersCount =
        countriesFilters.selectedCountries.size +
        zoneDescsFilters.selectedZoneDescs.size +
        citiesFilters.selectedCities.size
    var countryHeader =
        intl.formatMessage({ id: 'region.country.title' }) +
        (countryFiltersCount ? ` (${countryFiltersCount})` : '')

    const buFiltersCount = busFilters.selectedBUs.size
    var buHeader =
        intl.formatMessage({ id: 'region.business_unit.title' }) +
        (buFiltersCount ? ` (${buFiltersCount})` : '')

    useEffect(() => {
        props.getMacroRegions()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return (
        <ScrollPanel
            style={{
                width: '100%',
                height: 'calc(100vh - 175px)',
            }}
            className="pvscroll">
            <FilterSearchStoresClearSection
                onClearAllFilters={(e: any) => props.clearAllFilters()}
                isLoadingSearchResults={props.loadingSearchResults}
                hasMoreFilters
                isSearchResultsDisabled={props.loadingStores}
                onSearchResults={() => props.getSearchResults()}>
                <div className="p-grid">
                    <div className="p-col-12">
                        {props.loading ? <StyledProgressSpinner /> : <></>}
                    </div>
                    {macroregionsFilters.macroregions ? (
                        Object.entries<MRElement>(
                            macroregionsFilters.macroregions
                        ).map((macroRegion: [string, MRElement]) => {
                            return (
                                <div className="p-col-6" key={macroRegion[0]}>
                                    <FilterToggleButton
                                        size="small"
                                        checked={macroRegion[1].selected}
                                        onChange={(e: any) =>
                                            props.setSelectedMacroRegion(
                                                macroRegion[0],
                                                !macroRegion[1].selected
                                            )
                                        }
                                        label={`${macroRegion[1].desc.toUpperCase()} (${
                                            macroRegion[1].count
                                        })`}
                                    />
                                </div>
                            )
                        })
                    ) : (
                        <></>
                    )}
                </div>
            </FilterSearchStoresClearSection>
            <Accordion
                expandIcon="pi pi-chevron-down"
                collapseIcon="pi pi-chevron-up"
                className="styleFilterAccordion">
                <AccordionTab header={countryHeader}>
                    {macroregionsFilters.selectedMacroRegions.size > 0 ? (
                        <>
                            <SmartSearchableFilter
                                loading={props.loadingCountries}
                                placeholderSearch={intl.formatMessage({
                                    id: 'region.country.search.placeholder',
                                })}
                                searchValue={countriesFilters.searchValue}
                                setSearchValue={props.setSearchCountryValue}
                                searchableFilters={[countriesFilters]}
                                toggleViewAllVisible={
                                    props.toggleCountriesViewAll
                                }
                                setFilterSelection={props.setSelectedCountry}
                            />
                            {countriesFilters.selectedCountries.size > 0 ? (
                                <SmartSearchableFilter
                                    loading={props.loadingZoneDescs}
                                    placeholderSearch={intl.formatMessage({
                                        id: 'region.zone_desc.search.placeholder',
                                    })}
                                    searchValue={zoneDescsFilters.searchValue}
                                    setSearchValue={
                                        props.setSearchZoneDescValue
                                    }
                                    searchableFilters={[zoneDescsFilters]}
                                    toggleViewAllVisible={
                                        props.toggleZoneDescsViewAll
                                    }
                                    setFilterSelection={
                                        props.setSelectedZoneDesc
                                    }
                                />
                            ) : (
                                <></>
                            )}
                            {zoneDescsFilters.selectedZoneDescs.size > 0 ? (
                                <SmartSearchableFilter
                                    loading={props.loadingCities}
                                    placeholderSearch={intl.formatMessage({
                                        id: 'region.city.search.placeholder',
                                    })}
                                    searchValue={citiesFilters.searchValue}
                                    setSearchValue={props.setSearchCityValue}
                                    searchableFilters={[citiesFilters]}
                                    toggleViewAllVisible={
                                        props.toggleCitiesViewAll
                                    }
                                    setFilterSelection={props.setSelectedCity}
                                />
                            ) : (
                                <></>
                            )}
                        </>
                    ) : (
                        <WaitingContentLabel
                            text={intl.formatMessage({
                                id: 'region.select_mr.text',
                            })}
                        />
                    )}
                </AccordionTab>
                <AccordionTab header={buHeader}>
                    {macroregionsFilters.selectedMacroRegions.size > 0 ? (
                        <>
                            <SmartSearchableFilter
                                loading={props.loadingBUs}
                                placeholderSearch={intl.formatMessage({
                                    id: 'region.bu.search.placeholder',
                                })}
                                searchValue={busFilters.searchValue}
                                setSearchValue={props.setSearchBUValue}
                                searchableFilters={[busFilters]}
                                toggleViewAllVisible={props.toggleBUsViewAll}
                                setFilterSelection={props.setSelectedBU}
                            />
                        </>
                    ) : (
                        <WaitingContentLabel
                            text={intl.formatMessage({
                                id: 'region.select_mr.text',
                            })}
                        />
                    )}
                </AccordionTab>
            </Accordion>
        </ScrollPanel>
    )
}

const loadingSelector = createLoadingSelector(['FILTERS_GET_MACROREGIONS'])
const loadingStores = createLoadingSelector(['STORES'])
const loadingSearchResults = createLoadingSelector(['SEARCH_RESULTS'])
const loadingCountriesSelector = createLoadingSelector([
    'FILTERS_GET_COUNTRIES',
])
const loadingZoneDescsSelector = createLoadingSelector([
    'FILTERS_GET_ZONE_DESCS',
])
const loadingCitiesSelector = createLoadingSelector(['FILTERS_GET_CITIES'])
const loadingBUsSelector = createLoadingSelector(['FILTERS_GET_BUS'])

const mapStateToProps = (state: AppState) => ({
    loading: loadingSelector(state),
    loadingStores: loadingStores(state),
    loadingSearchResults: loadingSearchResults(state),
    loadingCountries: loadingCountriesSelector(state),
    loadingZoneDescs: loadingZoneDescsSelector(state),
    loadingCities: loadingCitiesSelector(state),
    loadingBUs: loadingBUsSelector(state),
    regionFilters: state.filtersReducer.regionFilters,
})

const mapDispatchToProps = (dispatch: any) => ({
    clearAllFilters: () => dispatch(clearAllFilters()),
    getMacroRegions: () => dispatch(getMacroRegions()),
    setSelectedMacroRegion: (macroregion: string, selection: boolean) =>
        dispatch(setSelectedMacroRegion(macroregion, selection)),
    toggleCountriesViewAll: () => dispatch(toggleCountriesViewAll()),
    setSelectedCountry: (country: string, selection: boolean) =>
        dispatch(setSelectedCountry(country, selection)),
    setSearchCountryValue: (searchValue: string) =>
        dispatch(setSearchCountryValue(searchValue)),
    toggleZoneDescsViewAll: () => dispatch(toggleZoneDescsViewAll()),
    setSelectedZoneDesc: (zoneDesc: string, selection: boolean) =>
        dispatch(setSelectedZoneDesc(zoneDesc, selection)),
    setSearchZoneDescValue: (searchValue: string) =>
        dispatch(setSearchZoneDescValue(searchValue)),
    toggleCitiesViewAll: () => dispatch(toggleCitiesViewAll()),
    setSelectedCity: (zoneDesc: string, selection: boolean) =>
        dispatch(setSelectedCity(zoneDesc, selection)),
    setSearchCityValue: (searchValue: string) =>
        dispatch(setSearchCityValue(searchValue)),
    toggleBUsViewAll: () => dispatch(toggleBUsViewAll()),
    setSelectedBU: (businessUnit: string, selection: boolean) =>
        dispatch(setSelectedBU(businessUnit, selection)),
    setSearchBUValue: (searchValue: string) =>
        dispatch(setSearchBUValue(searchValue)),
    getSearchResults: () =>
        dispatch(getSearchResults()).then((e: any) =>
            dispatch(setSelectedSidebarMenu(SidebarMenuKind.SEARCH_RESULTS))
        ),
})

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(injectIntl(RegionFilter))
