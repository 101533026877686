import { StylesResetFiltersInToggleButton, StyledToggleButton } from './style'
import { ToggleButton } from 'primereact/togglebutton'
import { useWindowResize } from '../../../utils/commonFunctions'

const FilterToggleButton = (props: any) => {
    const labelToUse = props.labelSuffix
        ? `${props.label}${props.labelSuffix}`
        : props.label
    const isMobileNow = useWindowResize()

    return (
        <StyledToggleButton size={props.size} isMobileNow={isMobileNow}>
            {props.size !== 'small' && props.checked ? (
                <StylesResetFiltersInToggleButton
                    size={props.size}
                    isMobileNow={isMobileNow}
                    onClick={props.onClearAll}
                    className="selectFilter">
                    <span
                        className="p-sidebar-close-icon pi pi-times"
                        style={{
                            fontSize: isMobileNow ? '18px' : '9px',
                            position: 'relative',
                            top: '2px',
                        }}></span>
                </StylesResetFiltersInToggleButton>
            ) : (
                <></>
            )}
            <ToggleButton
                checked={props.checked}
                onChange={props.onChange}
                onLabel={labelToUse}
                offLabel={labelToUse}
                onIcon={props.noIcons ? '' : 'pi pi-times-circle'}
            />
        </StyledToggleButton>
    )
}

export default FilterToggleButton
