import { SidebarMenuKind } from '../../models/sidebar-menu-kind'
import {
    MacroRegionsDict,
    MacroRegionsFilters,
} from '../../models/macroregions-filters'
import { FiltersApi } from '../../utils/api/filtersApi'
import { FiltersDispatcher } from '../dispatchers/filters'
import { FiltersState } from '../states/filtersState'
import { AppState } from '../states/appState'
import { SearchableFiltersDict } from '../../models/searchable-filter'
import { CountriesFilters } from '../../models/countries-filters'
import Fuse from 'fuse.js'
import { ZoneDescsFilters } from '../../models/zonedesc-filters'
import { CitiesFilters } from '../../models/cities-filters'
import { BUsFilters } from '../../models/business-unit-filters'
import { HostedRetailDict } from '../../models/hosted-retail-filters'
import { FiltersSidebarSizeType } from '../../models/filters-sidebar-size-type'
import { HostedRetailFilters } from '../../models/hosted-retail-filters'
import {
    BannerDescDict,
    BannerDescFilters,
} from '../../models/banner-desc-filters'
import {
    ChannelOfTradeDict,
    ChannelOfTradeFilters,
} from '../../models/channel-of-trade-filters'
import { SegmentDict, SegmentFilters } from '../../models/segment-filters'
import { StoreDesign, StoreDesignGroup } from '../../models/store-design-group'
import {
    StoreDesignDict,
    StoreDesignFilters,
    StoreDesignGroupFilters,
} from '../../models/store-design-groups-filters'
import {
    ImagesQualityDict,
    ImagesQualityFilters,
    MainProjectDict,
    MainProjectsFilters,
    SelectionBestFilters,
} from '../../models/selection-best-filters'
import { UserDispatcher } from '../dispatchers/user'

/*
//// BANNER ////
- Banner Desc
- Channel of Trade
- Host
- Segment
- Store Design
- Selection Best

//// REGIONS ////
- MacroRegions
- Countries
- Zone Descriptions
- Cities
- Business Units

//// REMODEL ////

//// RELOCATION ////

//// NEW ////

//// HOSTED RETAIL ////

//// SURVEY DATE ////
*/

export const setSelectedSidebarMenu = (
    newSelectedSidebarMenu: SidebarMenuKind
) => {
    return async (dispatch: any, getState: any) => {
        dispatch(
            FiltersDispatcher.FiltersSetSelectedSidebarMenuSuccess({
                selectedSidebarMenu: newSelectedSidebarMenu,
            } as unknown as FiltersState)
        )
    }
}

export const setSelectedSidebarSizeType = (
    newSelectedSidebarSizeType: FiltersSidebarSizeType
) => {
    return async (dispatch: any, getState: any) => {
        dispatch(
            FiltersDispatcher.FiltersSetSelectedSidebarSizeTypeSuccess({
                selectedSidebarSizeType: newSelectedSidebarSizeType,
            } as FiltersState)
        )
    }
}

export const clearAllFilters = () => {
    return async (dispatch: any, getState: any) => {
        dispatch(clearAllBannerFilters())
        dispatch(clearAllRegionFilters())
        dispatch(clearAllRemodelFilters())
        dispatch(clearAllRelocationFilters())
        dispatch(clearAllNewFilters())
        dispatch(clearAllHostedRetailFilters())
        dispatch(clearAllSurveyDateFilters())
    }
}

/////////////////////// BANNER - START ///////////////////////
export const clearAllBannerFilters = () => {
    return async (dispatch: any, getState: any) => {
        let currentState: AppState = getState()

        let { bannerFilters } = currentState.filtersReducer
        let {
            bannerDescFilters,
            channelOfTradeFilters,
            segmentFilters,
            storeDesignFilters,
            selectionBestFilters,
        } = bannerFilters
        let { imagesQualityFilters, mainProjectsFilters } = selectionBestFilters

        // Clear the "selected" value of the correspondings Banner Desc
        bannerDescFilters.selectedBannerDesc.forEach((selBDItem: string) => {
            bannerDescFilters.searchableFilter[selBDItem].selected = false
        })
        // Clear the selected Banner set
        bannerDescFilters.selectedBannerDesc = new Set()

        // Clear the "selected" value of the correspondings Channel Of Trade
        channelOfTradeFilters.selectedChannelOfTrade.forEach(
            (selCoTItem: string) => {
                channelOfTradeFilters.searchableFilter[selCoTItem].selected =
                    false
            }
        )
        // Clear the selected Channel of Trade set
        channelOfTradeFilters.selectedChannelOfTrade = new Set()

        // Clear the "selected" value of the correspondings Segment
        segmentFilters.selectedSegment.forEach((selSItem: string) => {
            segmentFilters.searchableFilter[selSItem].selected = false
        })
        // Clear the selected Segment set
        segmentFilters.selectedSegment = new Set()

        let storeDesignGroupFilters = storeDesignFilters.searchableFilters
        if (storeDesignGroupFilters) {
            // For each Store Design Group
            storeDesignGroupFilters.forEach(
                (sdGroup: StoreDesignGroupFilters) => {
                    // Clear the "selected" value of the correspondings Store Design
                    sdGroup.selectedStoreDesigns.forEach(
                        (selSDItem: string) => {
                            sdGroup.searchableFilter[selSDItem].selected = false
                        }
                    )

                    // Clear the selected Store Design set
                    sdGroup.selectedStoreDesigns = new Set()
                }
            )
        }

        // Clear the "selected" value of the correspondings Images Quality
        imagesQualityFilters.selectedImagesQuality.forEach(
            (selIQItem: string) => {
                imagesQualityFilters.searchableFilter[selIQItem].selected =
                    false
            }
        )
        // Clear the selected Main Project set
        imagesQualityFilters.selectedImagesQuality = new Set()

        // Clear the "selected" value of the correspondings Main Project
        mainProjectsFilters.selectedMainProject.forEach((selMPItem: string) => {
            mainProjectsFilters.searchableFilter[selMPItem].selected = false
        })
        // Clear the selected Main Project set
        mainProjectsFilters.selectedMainProject = new Set()

        dispatch(
            FiltersDispatcher.FiltersClearAllBannerFiltersSuccess({
                bannerDescFilters: bannerDescFilters,
            })
        )
    }
}

/********************** BANNER DESC - START **********************/
export const getBannerDesc = () => {
    return async (dispatch: any, getState: any) => {
        dispatch(FiltersDispatcher.FiltersGetBannerDescRequest())

        let currentState: AppState = getState()

        let { selectedBannerDesc } =
            currentState.filtersReducer.bannerFilters.bannerDescFilters

        try {
            const newBannerDesc: BannerDescDict =
                await FiltersApi.GetBannerDesc(currentState.filtersReducer)

            let newBannerDescFilters = new BannerDescFilters(newBannerDesc)
            newBannerDescFilters.fuseEngine = new Fuse(
                Object.entries(newBannerDesc),
                {
                    findAllMatches: true,
                    threshold: 0.2,
                    location: 0,
                    keys: ['0'],
                }
            )

            // Check the old selected BannerDesc to see if there is any common BannerDesc between current result and old result
            // If yes, we attempt to preserve the previous selections for those common filters
            selectedBannerDesc.forEach((bd: string) => {
                const isThisACommonBD = bd in newBannerDesc
                if (isThisACommonBD) {
                    // Preserve selection and count
                    newBannerDesc[bd].selected = true
                    newBannerDescFilters.selectedBannerDesc.add(bd)
                }
            })

            dispatch(
                FiltersDispatcher.FiltersGetBannerDescSuccess({
                    bannerDescFilters: newBannerDescFilters,
                })
            )
        } catch (error: any) {
            dispatch(UserDispatcher.UserError(error))

            dispatch(FiltersDispatcher.FiltersError(error))
        }
    }
}

export const toggleBannerDescViewAll = () => {
    return async (dispatch: any, getState: any) => {
        let currentState: AppState = getState()

        let { bannerDescFilters } = currentState.filtersReducer.bannerFilters

        bannerDescFilters.viewAllVisible = !bannerDescFilters.viewAllVisible

        try {
            dispatch(
                FiltersDispatcher.FiltersToggleBannerDescViewAll({
                    bannerDescFilters: bannerDescFilters,
                })
            )
        } catch (error: any) {
            dispatch(FiltersDispatcher.FiltersError(error))
        }
    }
}

export const setSelectedBannerDesc = (
    bannerDesc: string,
    selection: boolean
) => {
    return async (dispatch: any, getState: any) => {
        let currentState: AppState = getState()

        let { bannerDescFilters } = currentState.filtersReducer.bannerFilters

        // Store the selection for the corresponding Banner Desc
        bannerDescFilters.searchableFilter[bannerDesc].selected = selection
        selection
            ? bannerDescFilters.selectedBannerDesc.add(bannerDesc)
            : bannerDescFilters.selectedBannerDesc.delete(bannerDesc)

        dispatch(
            FiltersDispatcher.FiltersSetSelectedBannerDescDone({
                bannerDescFilters: bannerDescFilters,
            })
        )
    }
}

export const setSearchBannerDescValue = (searchValue: string) => {
    return async (dispatch: any, getState: any) => {
        let currentState: AppState = getState()

        let { bannerDescFilters } = currentState.filtersReducer.bannerFilters
        const { fuseEngine } = bannerDescFilters

        // Set the search value in the state
        bannerDescFilters.searchValue = searchValue

        // Perform a Fuse search over the current records (return all Hosted Retail if searchValue is empty)
        if (searchValue) {
            const fuseSearchResults = fuseEngine.search(searchValue)

            // Convert Fuse search results to a BannerDescDict
            bannerDescFilters.searchedFilter = fuseSearchResults.reduce(
                (accum: any, elem: any) => {
                    accum[elem.item[0]] = elem.item[1]
                    return accum
                },
                {}
            )
        } else {
            // Reset the searchableFilter
            bannerDescFilters.searchedFilter =
                bannerDescFilters.searchableFilter
        }

        // Then dispatch an actual Search action
        dispatch(
            FiltersDispatcher.FiltersSetSearchBannerDescValueDone({
                bannerDescFilters: bannerDescFilters,
            })
        )
    }
}
/********************** BANNER DESC - END **********************/

/********************** CHANNEL OF TRADE - START **********************/
export const getChannelOfTrade = () => {
    return async (dispatch: any, getState: any) => {
        dispatch(FiltersDispatcher.FiltersGetChannelOfTradeRequest())

        let currentState: AppState = getState()

        let { selectedChannelOfTrade } =
            currentState.filtersReducer.bannerFilters.channelOfTradeFilters

        try {
            const newChannelOfTrade: ChannelOfTradeDict =
                await FiltersApi.GetChannelOfTrade(currentState.filtersReducer)

            let newChannelOfTradeFilters = new ChannelOfTradeFilters(
                newChannelOfTrade
            )
            newChannelOfTradeFilters.fuseEngine = new Fuse(
                Object.entries(newChannelOfTrade),
                {
                    findAllMatches: true,
                    threshold: 0.2,
                    location: 0,
                    keys: ['0'],
                }
            )

            // Check the old selected Channel of Trade to see if there is any common Channel of Trade between current result and old result
            // If yes, we attempt to preserve the previous selections for those common filters
            selectedChannelOfTrade.forEach((bd: string) => {
                const isThisACommonBD = bd in newChannelOfTrade
                if (isThisACommonBD) {
                    // Preserve selection and count
                    newChannelOfTrade[bd].selected = true
                    newChannelOfTradeFilters.selectedChannelOfTrade.add(bd)
                }
            })

            dispatch(
                FiltersDispatcher.FiltersGetChannelOfTradeSuccess({
                    channelOfTradeFilters: newChannelOfTradeFilters,
                })
            )
        } catch (error: any) {
            dispatch(UserDispatcher.UserError(error))

            dispatch(FiltersDispatcher.FiltersError(error))
        }
    }
}

export const toggleChannelOfTradeViewAll = () => {
    return async (dispatch: any, getState: any) => {
        let currentState: AppState = getState()

        let { channelOfTradeFilters } =
            currentState.filtersReducer.bannerFilters

        channelOfTradeFilters.viewAllVisible =
            !channelOfTradeFilters.viewAllVisible

        try {
            dispatch(
                FiltersDispatcher.FiltersToggleChannelOfTradeViewAll({
                    channelOfTradeFilters: channelOfTradeFilters,
                })
            )
        } catch (error: any) {
            dispatch(FiltersDispatcher.FiltersError(error))
        }
    }
}

export const setSelectedChannelOfTrade = (
    channelOfTrade: string,
    selection: boolean
) => {
    return async (dispatch: any, getState: any) => {
        let currentState: AppState = getState()

        let { channelOfTradeFilters } =
            currentState.filtersReducer.bannerFilters

        // Store the selection for the corresponding Channel of Trade
        channelOfTradeFilters.searchableFilter[channelOfTrade].selected =
            selection
        selection
            ? channelOfTradeFilters.selectedChannelOfTrade.add(channelOfTrade)
            : channelOfTradeFilters.selectedChannelOfTrade.delete(
                channelOfTrade
            )

        dispatch(
            FiltersDispatcher.FiltersSetSelectedChannelOfTradeDone({
                channelOfTradeFilters: channelOfTradeFilters,
            })
        )
    }
}

export const setSearchChannelOfTradeValue = (searchValue: string) => {
    return async (dispatch: any, getState: any) => {
        let currentState: AppState = getState()

        let { channelOfTradeFilters } =
            currentState.filtersReducer.bannerFilters
        const { fuseEngine } = channelOfTradeFilters

        // Set the search value in the state
        channelOfTradeFilters.searchValue = searchValue

        // Perform a Fuse search over the current records (return all Hosted Retail if searchValue is empty)
        if (searchValue) {
            const fuseSearchResults = fuseEngine.search(searchValue)

            // Convert Fuse search results to a HostedRetailDict
            channelOfTradeFilters.searchedFilter = fuseSearchResults.reduce(
                (accum: any, elem: any) => {
                    accum[elem.item[0]] = elem.item[1]
                    return accum
                },
                {}
            )
        } else {
            // Reset the searchableFilter
            channelOfTradeFilters.searchedFilter =
                channelOfTradeFilters.searchableFilter
        }

        // Then dispatch an actual Search action
        dispatch(
            FiltersDispatcher.FiltersSetSearchChannelOfTradeValueDone({
                channelOfTradeFilters: channelOfTradeFilters,
            })
        )
    }
}
/********************** CHANNEL OF TRADE - END **********************/

/********************** SEGMENT - START **********************/
export const getSegment = () => {
    return async (dispatch: any, getState: any) => {
        dispatch(FiltersDispatcher.FiltersGetSegmentRequest())

        let currentState: AppState = getState()

        let { selectedSegment } =
            currentState.filtersReducer.bannerFilters.segmentFilters

        try {
            const newSegment: SegmentDict = await FiltersApi.GetSegment(
                currentState.filtersReducer
            )

            let newSegmentFilters = new SegmentFilters(newSegment)
            newSegmentFilters.fuseEngine = new Fuse(
                Object.entries(newSegment),
                {
                    findAllMatches: true,
                    threshold: 0.2,
                    location: 0,
                    keys: ['0'],
                }
            )

            // Check the old selected Segment to see if there is any common Segment between current result and old result
            // If yes, we attempt to preserve the previous selections for those common filters
            selectedSegment.forEach((s: string) => {
                const isThisACommonS = s in newSegment
                if (isThisACommonS) {
                    // Preserve selection and count
                    newSegment[s].selected = true
                    newSegmentFilters.selectedSegment.add(s)
                }
            })

            dispatch(
                FiltersDispatcher.FiltersGetSegmentSuccess({
                    segmentFilters: newSegmentFilters,
                })
            )
        } catch (error: any) {
            dispatch(UserDispatcher.UserError(error))

            dispatch(FiltersDispatcher.FiltersError(error))
        }
    }
}

export const toggleSegmentViewAll = () => {
    return async (dispatch: any, getState: any) => {
        let currentState: AppState = getState()

        let { segmentFilters } = currentState.filtersReducer.bannerFilters

        segmentFilters.viewAllVisible = !segmentFilters.viewAllVisible

        try {
            dispatch(
                FiltersDispatcher.FiltersToggleSegmentViewAll({
                    segmentFilters: segmentFilters,
                })
            )
        } catch (error: any) {
            dispatch(FiltersDispatcher.FiltersError(error))
        }
    }
}

export const setSelectedSegment = (segment: string, selection: boolean) => {
    return async (dispatch: any, getState: any) => {
        let currentState: AppState = getState()

        let { segmentFilters } = currentState.filtersReducer.bannerFilters

        // Store the selection for the corresponding Segment
        segmentFilters.searchableFilter[segment].selected = selection
        selection
            ? segmentFilters.selectedSegment.add(segment)
            : segmentFilters.selectedSegment.delete(segment)

        dispatch(
            FiltersDispatcher.FiltersSetSelectedSegmentDone({
                segmentFilters: segmentFilters,
            })
        )
    }
}

export const setSearchSegmentValue = (searchValue: string) => {
    return async (dispatch: any, getState: any) => {
        let currentState: AppState = getState()

        let { segmentFilters } = currentState.filtersReducer.bannerFilters
        const { fuseEngine } = segmentFilters

        // Set the search value in the state
        segmentFilters.searchValue = searchValue

        // Perform a Fuse search over the current records (return all Segment if searchValue is empty)
        if (searchValue) {
            const fuseSearchResults = fuseEngine.search(searchValue)

            // Convert Fuse search results to a SegmentDict
            segmentFilters.searchedFilter = fuseSearchResults.reduce(
                (accum: any, elem: any) => {
                    accum[elem.item[0]] = elem.item[1]
                    return accum
                },
                {}
            )
        } else {
            // Reset the searchableFilter
            segmentFilters.searchedFilter = segmentFilters.searchableFilter
        }

        // Then dispatch an actual Search action
        dispatch(
            FiltersDispatcher.FiltersSetSearchSegmentValueDone({
                segmentFilters: segmentFilters,
            })
        )
    }
}
/********************** SEGMENT - END **********************/

/********************** STORE DESIGN - START **********************/
export const getStoreDesign = () => {
    return async (dispatch: any, getState: any) => {
        dispatch(FiltersDispatcher.FiltersGetStoreDesignRequest())

        let currentState: AppState = getState()

        let storeDesignGroupFilters =
            currentState.filtersReducer.bannerFilters.storeDesignFilters
                .searchableFilters

        try {
            const newStoreDesignGroup: StoreDesignGroup[] =
                await FiltersApi.GetStoreDesign(currentState.filtersReducer)

            let allSearchableDicts: StoreDesignDict = {}
            // Convert each Store Design Group as read from Solr to SearchableFilters
            let searchableFilters = newStoreDesignGroup.map(
                (storeDesignGroup: StoreDesignGroup) => {
                    // Empty pivots only have a store design group available
                    if (!storeDesignGroup.pivot) {
                        return new StoreDesignGroupFilters()
                    }

                    let newStoreDesignGroupFilters =
                        new StoreDesignGroupFilters()

                    // Convert pivot results to a SearchableFiltersDict
                    let normalizedPivot: StoreDesignDict =
                        storeDesignGroup.pivot.reduce(
                            (
                                accumulator: StoreDesignDict,
                                sd: StoreDesign,
                                idx: number
                            ) => {
                                accumulator[sd.value] = {
                                    count: sd.count,
                                    selected: false,
                                    groupFilter: newStoreDesignGroupFilters,
                                }

                                return accumulator
                            },
                            {}
                        )

                    // Store the SearchableFiltersDict together with the others for a generalized Fuse search later
                    allSearchableDicts = {
                        ...allSearchableDicts,
                        ...normalizedPivot,
                    }

                    // Create the Store Design Group Filter
                    newStoreDesignGroupFilters.id = storeDesignGroup.value
                    newStoreDesignGroupFilters.searchableFilter =
                        normalizedPivot
                    newStoreDesignGroupFilters.searchedFilter = normalizedPivot
                    newStoreDesignGroupFilters.title = `${storeDesignGroup.value}intl.store_design.group.titlesuffix`
                    return newStoreDesignGroupFilters
                }
            )

            //////// Sort Store Design Group
            searchableFilters.sort(
                (
                    sdg1: StoreDesignGroupFilters,
                    sdg2: StoreDesignGroupFilters
                ) => {
                    return sdg1.id.charAt(0).localeCompare(sdg2.id.charAt(0))
                }
            )

            //////// Put the ALL OTHERS Store Design Group at the bottom - START ////////
            let allOthersIndex = -1
            let currIndex = 0
            for (const sdgf of searchableFilters) {
                if (sdgf.id === 'ALL OTHERS') {
                    allOthersIndex = currIndex
                    break
                }
                currIndex++
            }

            if (allOthersIndex > -1) {
                searchableFilters.push(searchableFilters[allOthersIndex])
                searchableFilters.splice(allOthersIndex, 1)
            }
            //////// Swap the ALL OTHERS Store Design Family at the bottom - END ////////

            // Add Searchable Filters to the StoreDesignFilters class and set Fuse search to be "global" in the context of Store Design
            let newStoreDesignFilters = new StoreDesignFilters(
                searchableFilters
            )
            newStoreDesignFilters.fuseEngine = new Fuse(
                Object.entries(allSearchableDicts),
                {
                    findAllMatches: true,
                    threshold: 0.2,
                    location: 0,
                    keys: ['0', '1.groupFilter.id'],
                }
            )

            // Check the old selected Store Design Groups to see if there is any common Store Design between current result and old result in them
            // If yes, we attempt to preserve the previous selections for those common filters
            if (storeDesignGroupFilters) {
                storeDesignGroupFilters.forEach(
                    (sdGroup: StoreDesignGroupFilters) => {
                        sdGroup.selectedStoreDesigns.forEach((sd: string) => {
                            const isThisACommonSD = sd in allSearchableDicts
                            if (isThisACommonSD) {
                                // Preserve selection and count
                                allSearchableDicts[sd].selected = true
                                allSearchableDicts[
                                    sd
                                ].groupFilter.selectedStoreDesigns.add(sd)
                            }
                        })
                    }
                )
            }

            dispatch(
                FiltersDispatcher.FiltersGetStoreDesignSuccess({
                    storeDesignFilters: newStoreDesignFilters,
                })
            )
        } catch (error: any) {
            dispatch(UserDispatcher.UserError(error))

            dispatch(FiltersDispatcher.FiltersError(error))
        }
    }
}

export const toggleStoreDesignViewAll = (storeDesignGroup: string) => {
    return async (dispatch: any, getState: any) => {
        let currentState: AppState = getState()

        let { bannerFilters } = currentState.filtersReducer
        let { searchableFiltersByDict } = bannerFilters.storeDesignFilters

        searchableFiltersByDict[storeDesignGroup].viewAllVisible =
            !searchableFiltersByDict[storeDesignGroup].viewAllVisible

        try {
            dispatch(
                FiltersDispatcher.FiltersToggleStoreDesignViewAll({
                    storeDesignFilters: bannerFilters.storeDesignFilters,
                })
            )
        } catch (error: any) {
            dispatch(FiltersDispatcher.FiltersError(error))
        }
    }
}

export const toggleStoreDesignPanelCollapsed = (storeDesignGroup: string) => {
    return async (dispatch: any, getState: any) => {
        let currentState: AppState = getState()

        let { bannerFilters } = currentState.filtersReducer
        let { searchableFiltersByDict } = bannerFilters.storeDesignFilters

        searchableFiltersByDict[storeDesignGroup].isPanelCollapsed =
            !searchableFiltersByDict[storeDesignGroup].isPanelCollapsed

        try {
            dispatch(
                FiltersDispatcher.FiltersStoreDesignPanelCollapsed({
                    storeDesignFilters: bannerFilters.storeDesignFilters,
                })
            )
        } catch (error: any) {
            dispatch(FiltersDispatcher.FiltersError(error))
        }
    }
}

export const toggleStoreDesignSelectAllSelected = (
    storeDesignGroup: string
) => {
    return async (dispatch: any, getState: any) => {
        let currentState: AppState = getState()

        let { bannerFilters } = currentState.filtersReducer
        let { searchableFiltersByDict } = bannerFilters.storeDesignFilters

        searchableFiltersByDict[storeDesignGroup].selectAllSelected =
            !searchableFiltersByDict[storeDesignGroup].selectAllSelected

        try {
            dispatch(
                FiltersDispatcher.FiltersStoreDesignPanelCollapsed({
                    storeDesignFilters: bannerFilters.storeDesignFilters,
                })
            )
        } catch (error: any) {
            dispatch(FiltersDispatcher.FiltersError(error))
        }
    }
}

export const toggleStoreDesignFirstLevelSelectAllSelected = (
    storeDesignGroupFirstLevel: string
) => {
    return async (dispatch: any, getState: any) => {
        let currentState: AppState = getState()

        let { bannerFilters } = currentState.filtersReducer
        let { firstLevelPanelsCollapsed } = bannerFilters.storeDesignFilters

        firstLevelPanelsCollapsed[storeDesignGroupFirstLevel] =
            !firstLevelPanelsCollapsed[storeDesignGroupFirstLevel]

        try {
            dispatch(
                FiltersDispatcher.FiltersStoreDesignPanelCollapsed({
                    storeDesignFilters: bannerFilters.storeDesignFilters,
                })
            )
        } catch (error: any) {
            dispatch(FiltersDispatcher.FiltersError(error))
        }
    }
}

export const setSelectedStoreDesign = (
    storeDesignGroup: string,
    storeDesign: string,
    selection: boolean
) => {
    return async (dispatch: any, getState: any) => {
        let currentState: AppState = getState()

        let { bannerFilters } = currentState.filtersReducer
        let { searchableFiltersByDict } = bannerFilters.storeDesignFilters

        // Store the selection for the corresponding Store Design
        searchableFiltersByDict[storeDesignGroup].searchableFilter[
            storeDesign
        ].selected = selection
        selection
            ? searchableFiltersByDict[
                storeDesignGroup
            ].selectedStoreDesigns.add(storeDesign)
            : searchableFiltersByDict[
                storeDesignGroup
            ].selectedStoreDesigns.delete(storeDesign)

        dispatch(
            FiltersDispatcher.FiltersSetSelectedStoreDesignDone({
                storeDesignFilters: bannerFilters.storeDesignFilters,
            })
        )
    }
}

export const setSearchStoreDesignValue = (searchValue: string) => {
    return async (dispatch: any, getState: any) => {
        let currentState: AppState = getState()

        let { storeDesignFilters } = currentState.filtersReducer.bannerFilters
        const { fuseEngine } = storeDesignFilters

        // Set the search value in the state
        storeDesignFilters.searchValue = searchValue

        // Perform a Fuse search over the current records (return all Store Design if searchValue is empty)
        if (searchValue) {
            const fuseSearchResults = fuseEngine.search(searchValue)

            // Reset each searchedFilter in preparation of the reduce below
            storeDesignFilters.searchableFilters.forEach((elem) => {
                elem.searchedFilter = {}
            })

            // Set searchedFilter directly from the reduce call
            fuseSearchResults.reduce((accum: any, elem: any) => {
                const storeDesignKey = elem.item[0]
                const sdElement = elem.item[1]

                accum[storeDesignKey] = sdElement
                sdElement.groupFilter.searchedFilter[storeDesignKey] = sdElement

                return accum
            }, {})
        } else {
            // Reset each searchableFilter
            storeDesignFilters.searchableFilters.forEach((elem) => {
                elem.searchedFilter = elem.searchableFilter
            })
        }

        // Then dispatch an actual Search action
        dispatch(
            FiltersDispatcher.FiltersSetSearchStoreDesignValueDone({
                storeDesignFilters: storeDesignFilters,
            })
        )
    }
}
/********************** STORE DESIGN - END **********************/

/********************** SELECTION BEST - START **********************/
export const getSelectionBest = () => {
    return async (dispatch: any, getState: any) => {
        dispatch(FiltersDispatcher.FiltersGetSelectionBestRequest())

        let currentState: AppState = getState()

        let { selectionBestFilters } = currentState.filtersReducer.bannerFilters

        try {
            const imagesQuality: string[] = ['layout', 'execution']
            // Convert results to a ImagesQualityDict
            let newImagesQuality: ImagesQualityDict = imagesQuality.reduce(
                (accumulator: ImagesQualityDict, iq: string) => {
                    accumulator[iq] = { count: 0, selected: false }

                    return accumulator
                },
                {}
            )
            const newImagesQualityFilters = new ImagesQualityFilters(
                newImagesQuality
            )
            newImagesQualityFilters.fuseEngine = new Fuse(
                Object.entries(newImagesQuality),
                {
                    findAllMatches: true,
                    threshold: 0.2,
                    location: 0,
                    keys: ['0'],
                }
            )

            // Check the old selected Main Projects to see if there is any common Main Project between current result and old result
            // If yes, we attempt to preserve the previous selections for those common filters
            selectionBestFilters?.imagesQualityFilters?.selectedImagesQuality.forEach(
                (iq: string) => {
                    const isThisACommonIQ = iq in newImagesQuality
                    if (isThisACommonIQ) {
                        // Preserve selection and count
                        newImagesQuality[iq].selected = true
                        newImagesQualityFilters.selectedImagesQuality.add(iq)
                    }
                }
            )

            const mainProjects: string[] =
                await FiltersApi.GetFiltersMainProjects()

            // Convert results to a MainProjectDict
            let newMainProjects: MainProjectDict = mainProjects.reduce(
                (accumulator: MainProjectDict, mp: string) => {
                    accumulator[mp] = { count: 0, selected: false }

                    return accumulator
                },
                {}
            )

            const newMainProjectsFilters = new MainProjectsFilters(
                newMainProjects
            )
            newMainProjectsFilters.fuseEngine = new Fuse(
                Object.entries(newMainProjects),
                {
                    findAllMatches: true,
                    threshold: 0.2,
                    location: 0,
                    keys: ['0'],
                }
            )

            // Check the old selected Main Projects to see if there is any common Main Project between current result and old result
            // If yes, we attempt to preserve the previous selections for those common filters
            selectionBestFilters?.mainProjectsFilters?.selectedMainProject.forEach(
                (mp: string) => {
                    const isThisACommonMP = mp in newMainProjects
                    if (isThisACommonMP) {
                        // Preserve selection and count
                        newMainProjects[mp].selected = true
                        newMainProjectsFilters.selectedMainProject.add(mp)
                    }
                }
            )

            dispatch(
                FiltersDispatcher.FiltersGetSelectionBestSuccess({
                    selectionBestFilters: new SelectionBestFilters({
                        imagesQualityFilters: newImagesQualityFilters,
                        mainProjectsFilters: newMainProjectsFilters,
                    }),
                })
            )
        } catch (error: any) {
            dispatch(UserDispatcher.UserError(error))

            dispatch(FiltersDispatcher.FiltersError(error))
        }
    }
}

export const setSelectedImagesQuality = (
    imagesQuality: string,
    selection: boolean
) => {
    return async (dispatch: any, getState: any) => {
        let currentState: AppState = getState()

        let { selectionBestFilters } = currentState.filtersReducer.bannerFilters
        let { imagesQualityFilters } = selectionBestFilters

        // Store the selection for the corresponding Images Quality
        imagesQualityFilters.searchableFilter[imagesQuality].selected =
            selection
        selection
            ? imagesQualityFilters.selectedImagesQuality.add(imagesQuality)
            : imagesQualityFilters.selectedImagesQuality.delete(imagesQuality)

        dispatch(
            FiltersDispatcher.FiltersSetSelectedImagesQualityDone({
                selectionBestFilters: selectionBestFilters,
            })
        )
    }
}

export const toggleMainProjectsViewAll = () => {
    return async (dispatch: any, getState: any) => {
        let currentState: AppState = getState()

        let { selectionBestFilters } = currentState.filtersReducer.bannerFilters
        let { mainProjectsFilters } = selectionBestFilters

        mainProjectsFilters.viewAllVisible = !mainProjectsFilters.viewAllVisible

        try {
            dispatch(
                FiltersDispatcher.FiltersToggleMainProjectsViewAll({
                    selectionBestFilters: selectionBestFilters,
                })
            )
        } catch (error: any) {
            dispatch(FiltersDispatcher.FiltersError(error))
        }
    }
}

export const setSelectedMainProjects = (
    mainProject: string,
    selection: boolean
) => {
    return async (dispatch: any, getState: any) => {
        let currentState: AppState = getState()

        let { selectionBestFilters } = currentState.filtersReducer.bannerFilters
        let { mainProjectsFilters } = selectionBestFilters

        // Store the selection for the corresponding Main Project
        mainProjectsFilters.searchableFilter[mainProject].selected = selection
        selection
            ? mainProjectsFilters.selectedMainProject.add(mainProject)
            : mainProjectsFilters.selectedMainProject.delete(mainProject)

        dispatch(
            FiltersDispatcher.FiltersSetSelectedMainProjectsDone({
                selectionBestFilters: selectionBestFilters,
            })
        )
    }
}

export const setSearchMainProjectsValue = (searchValue: string) => {
    return async (dispatch: any, getState: any) => {
        let currentState: AppState = getState()

        let { selectionBestFilters } = currentState.filtersReducer.bannerFilters
        let { mainProjectsFilters } = selectionBestFilters
        const { fuseEngine } = mainProjectsFilters

        // Set the search value in the state
        mainProjectsFilters.searchValue = searchValue

        // Perform a Fuse search over the current records (return all Main Projects if searchValue is empty)
        if (searchValue) {
            const fuseSearchResults = fuseEngine.search(searchValue)

            // Convert Fuse search results to a SegmentDict
            mainProjectsFilters.searchedFilter = fuseSearchResults.reduce(
                (accum: any, elem: any) => {
                    accum[elem.item[0]] = elem.item[1]
                    return accum
                },
                {}
            )
        } else {
            // Reset the searchableFilter
            mainProjectsFilters.searchedFilter =
                mainProjectsFilters.searchableFilter
        }

        // Then dispatch an actual Search action
        dispatch(
            FiltersDispatcher.FiltersSetSearchMainProjectsValueDone({
                selectionBestFilters: selectionBestFilters,
            })
        )
    }
}
/********************** SELECTION BEST - END **********************/
/////////////////////// BANNER - END ///////////////////////

/////////////////////// REGION - START ///////////////////////
export const clearAllRegionFilters = () => {
    return async (dispatch: any, getState: any) => {
        dispatch(clearAllMacroRegionsFilters())
        dispatch(clearAllCountriesFilters())
        dispatch(clearAllZoneDescsFilters())
        dispatch(clearAllCitiesFilters())
        dispatch(clearAllBUsFilters())
    }
}

/********************** MACROREGIONS - START **********************/
export const clearAllMacroRegionsFilters = () => {
    return async (dispatch: any, getState: any) => {
        let currentState: AppState = getState()

        let { regionFilters } = currentState.filtersReducer
        let { macroregionsFilters } = regionFilters

        // Clear the "selected" value of the correspondings MacroRegions
        macroregionsFilters.selectedMacroRegions.forEach(
            (selMRItem: string) => {
                macroregionsFilters.macroregions[selMRItem].selected = false
            }
        )
        // Clear the selected MacroRegions set
        macroregionsFilters.selectedMacroRegions = new Set()

        dispatch(
            FiltersDispatcher.FiltersClearAllMacroRegionsDone({
                macroregionsFilters: macroregionsFilters,
            })
        )
    }
}

export const getMacroRegions = () => {
    return async (dispatch: any, getState: any) => {
        dispatch(FiltersDispatcher.FiltersGetMacroRegionsRequest())

        let currentState: AppState = getState()

        let { regionFilters } = currentState.filtersReducer
        let { selectedMacroRegions } = regionFilters.macroregionsFilters

        try {
            const newMacroRegions: MacroRegionsDict =
                await FiltersApi.GetMacroRegions(currentState.filtersReducer)
            let newMacroRegionsFilters = new MacroRegionsFilters(
                newMacroRegions
            )

            // Check the old selected MacroRegions to see if there is any common MacroRegion between current result and old result
            // If yes, we attempt to preserve the previous selections for those common filters
            selectedMacroRegions.forEach((mr: string) => {
                const isThisACommonMR = mr in newMacroRegions
                if (isThisACommonMR) {
                    // Preserve selection and count
                    newMacroRegions[mr].selected = true
                    newMacroRegionsFilters.selectedMacroRegions.add(mr)
                }
            })

            dispatch(
                FiltersDispatcher.FiltersGetMacroRegionsSuccess({
                    macroregionsFilters: newMacroRegionsFilters,
                })
            )
        } catch (error: any) {
            dispatch(UserDispatcher.UserError(error))

            dispatch(FiltersDispatcher.FiltersError(error))
        }
    }
}

export const setSelectedMacroRegion = (
    macroregion: string,
    selection: boolean
) => {
    return async (dispatch: any, getState: any) => {
        let currentState: AppState = getState()

        let { regionFilters } = currentState.filtersReducer
        let { macroregionsFilters } = regionFilters
        let { selectedMacroRegions } = macroregionsFilters

        // Store the selection for the corresponding MacroRegion
        macroregionsFilters.macroregions[macroregion].selected = selection
        selection
            ? selectedMacroRegions.add(macroregion)
            : selectedMacroRegions.delete(macroregion)

        dispatch(
            FiltersDispatcher.FiltersSetSelectedMacroRegionDone({
                macroregionsFilters: macroregionsFilters,
            })
        )
    }
}
/********************** MACROREGIONS - END **********************/

/********************** COUNTRIES - START **********************/
export const clearAllCountriesFilters = () => {
    return async (dispatch: any, getState: any) => {
        let currentState: AppState = getState()

        let { regionFilters } = currentState.filtersReducer
        let { countriesFilters } = regionFilters

        // Clear the "selected" value of the correspondings Countries
        countriesFilters.selectedCountries.forEach((selCoItem: string) => {
            countriesFilters.searchableFilter[selCoItem].selected = false
        })
        // Clear the selected Countries set
        countriesFilters.selectedCountries = new Set()

        dispatch(
            FiltersDispatcher.FiltersClearAllCountriesDone({
                countriesFilters: countriesFilters,
            })
        )
    }
}

export const getCountries = () => {
    return async (dispatch: any, getState: any) => {
        dispatch(FiltersDispatcher.FiltersGetCountriesRequest())

        let currentState: AppState = getState()

        let { regionFilters } = currentState.filtersReducer
        let { selectedCountries } = regionFilters.countriesFilters

        try {
            var newCountries: SearchableFiltersDict =
                await FiltersApi.GetCountries(currentState.filtersReducer)

            var newCountriesFilters = new CountriesFilters(newCountries)
            newCountriesFilters.fuseEngine = new Fuse(
                Object.entries(newCountries),
                {
                    findAllMatches: true,
                    threshold: 0.2,
                    location: 0,
                    keys: ['0'],
                }
            )

            // Check the old selected Countries to see if there is any common Country between current result and old result
            // If yes, we attempt to preserve the previous selections for those common filters
            selectedCountries.forEach((country: string) => {
                const isThisACommonCountry = country in newCountries
                if (isThisACommonCountry) {
                    // Preserve a previous selection and count
                    newCountries[country].selected = true
                    newCountriesFilters.selectedCountries.add(country)
                }
            })

            dispatch(
                FiltersDispatcher.FiltersGetCountriesSuccess({
                    countriesFilters: newCountriesFilters,
                })
            )
        } catch (error: any) {
            dispatch(UserDispatcher.UserError(error))

            dispatch(FiltersDispatcher.FiltersError(error))
        }
    }
}

export const toggleCountriesViewAll = () => {
    return async (dispatch: any, getState: any) => {
        let currentState: AppState = getState()

        let { regionFilters } = currentState.filtersReducer

        regionFilters.countriesFilters.viewAllVisible =
            !regionFilters.countriesFilters.viewAllVisible

        try {
            dispatch(
                FiltersDispatcher.FiltersToggleCountriesViewAll({
                    countriesFilters: regionFilters.countriesFilters,
                })
            )
        } catch (error: any) {
            dispatch(FiltersDispatcher.FiltersError(error))
        }
    }
}

export const setSelectedCountry = (country: string, selection: boolean) => {
    return async (dispatch: any, getState: any) => {
        let currentState: AppState = getState()

        let { regionFilters } = currentState.filtersReducer
        let { countriesFilters } = regionFilters
        let { selectedCountries } = countriesFilters

        // Store the selection for the corresponding Country
        countriesFilters.searchableFilter[country].selected = selection
        selection
            ? selectedCountries.add(country)
            : selectedCountries.delete(country)

        dispatch(
            FiltersDispatcher.FiltersSetSelectedCountryDone({
                countriesFilters: countriesFilters,
            })
        )
    }
}

export const setSearchCountryValue = (searchValue: string) => {
    return async (dispatch: any, getState: any) => {
        let currentState: AppState = getState()

        let { regionFilters } = currentState.filtersReducer

        // Set the search value in the state
        regionFilters.countriesFilters.searchValue = searchValue
        const { fuseEngine } = regionFilters.countriesFilters

        // Perform a Fuse search over the current records (return all Countries if searchValue is empty)
        if (searchValue) {
            const fuseSearchResults = fuseEngine.search(searchValue)

            // Convert Fuse search results to a CountriesDict
            regionFilters.countriesFilters.searchedFilter =
                fuseSearchResults.reduce((accum: any, elem: any) => {
                    accum[elem.item[0]] = elem.item[1]
                    return accum
                }, {})
        } else {
            // Reset the searchableFilter
            regionFilters.countriesFilters.searchedFilter =
                regionFilters.countriesFilters.searchableFilter
        }

        // Then dispatch an actual Search action
        dispatch(
            FiltersDispatcher.FiltersSetSearchCountryValueDone({
                countriesFilters: regionFilters.countriesFilters,
            })
        )
    }
}
/********************** COUNTRIES - END **********************/

/********************** ZONE DESCRIPTIONS - START **********************/
export const clearAllZoneDescsFilters = () => {
    return async (dispatch: any, getState: any) => {
        let currentState: AppState = getState()

        let { regionFilters } = currentState.filtersReducer
        let { zoneDescsFilters } = regionFilters

        // Clear the "selected" value of the correspondings Zone Descs
        zoneDescsFilters.selectedZoneDescs.forEach((selZdItem: string) => {
            zoneDescsFilters.searchableFilter[selZdItem].selected = false
        })
        // Clear the selected ZoneDescs set
        zoneDescsFilters.selectedZoneDescs = new Set()

        dispatch(
            FiltersDispatcher.FiltersClearAllZoneDescsDone({
                zoneDescsFilters: zoneDescsFilters,
            })
        )
    }
}

export const getZoneDescs = () => {
    return async (dispatch: any, getState: any) => {
        dispatch(FiltersDispatcher.FiltersGetZoneDescsRequest())

        let currentState: AppState = getState()

        let { regionFilters } = currentState.filtersReducer
        let { selectedZoneDescs } = regionFilters.zoneDescsFilters

        try {
            let newZoneDescs: SearchableFiltersDict =
                await FiltersApi.GetZoneDescs(currentState.filtersReducer)

            let newZoneDescsFilters = new ZoneDescsFilters(newZoneDescs)
            newZoneDescsFilters.fuseEngine = new Fuse(
                Object.entries(newZoneDescs),
                {
                    findAllMatches: true,
                    threshold: 0.2,
                    location: 0,
                    keys: ['0'],
                }
            )

            // Check the old selected zones to see if there is any common zone between current result and old result
            // If yes, we attempt to preserve the previous selections for those common filters
            selectedZoneDescs.forEach((zoneDesc: string) => {
                const isThisACommonZone = zoneDesc in newZoneDescs
                if (isThisACommonZone) {
                    // Preserve selection and count
                    newZoneDescs[zoneDesc].selected = true
                    newZoneDescsFilters.selectedZoneDescs.add(zoneDesc)
                }
            })

            dispatch(
                FiltersDispatcher.FiltersGetZoneDescsSuccess({
                    zoneDescsFilters: newZoneDescsFilters,
                })
            )
        } catch (error: any) {
            dispatch(UserDispatcher.UserError(error))

            dispatch(FiltersDispatcher.FiltersError(error))
        }
    }
}

export const toggleZoneDescsViewAll = () => {
    return async (dispatch: any, getState: any) => {
        let currentState: AppState = getState()

        let { regionFilters } = currentState.filtersReducer

        regionFilters.zoneDescsFilters.viewAllVisible =
            !regionFilters.zoneDescsFilters.viewAllVisible

        try {
            dispatch(
                FiltersDispatcher.FiltersToggleZoneDescsViewAll({
                    zoneDescsFilters: regionFilters.zoneDescsFilters,
                })
            )
        } catch (error: any) {
            dispatch(UserDispatcher.UserError(error))

            dispatch(FiltersDispatcher.FiltersError(error))
        }
    }
}

export const setSelectedZoneDesc = (zoneDesc: string, selection: boolean) => {
    return async (dispatch: any, getState: any) => {
        let currentState: AppState = getState()

        let { regionFilters } = currentState.filtersReducer
        let { zoneDescsFilters } = regionFilters
        let { selectedZoneDescs } = zoneDescsFilters

        // Store the selection for the corresponding Zone Desc
        zoneDescsFilters.searchableFilter[zoneDesc].selected = selection
        selection
            ? selectedZoneDescs.add(zoneDesc)
            : selectedZoneDescs.delete(zoneDesc)

        dispatch(
            FiltersDispatcher.FiltersSetSelectedZoneDescDone({
                zoneDescsFilters: zoneDescsFilters,
            })
        )
    }
}

export const setSearchZoneDescValue = (searchValue: string) => {
    return async (dispatch: any, getState: any) => {
        let currentState: AppState = getState()

        let { regionFilters } = currentState.filtersReducer
        const { fuseEngine } = regionFilters.zoneDescsFilters

        // Set the search value in the state
        regionFilters.zoneDescsFilters.searchValue = searchValue

        // Perform a Fuse search over the current records (return all ZoneDescs if searchValue is empty)
        if (searchValue) {
            const fuseSearchResults = fuseEngine.search(searchValue)

            // Convert Fuse search results to a ZoneDescsDict
            regionFilters.zoneDescsFilters.searchedFilter =
                fuseSearchResults.reduce((accum: any, elem: any) => {
                    accum[elem.item[0]] = elem.item[1]
                    return accum
                }, {})
        } else {
            // Reset the searchableFilter
            regionFilters.zoneDescsFilters.searchedFilter =
                regionFilters.zoneDescsFilters.searchableFilter
        }

        // Then dispatch an actual Search action
        dispatch(
            FiltersDispatcher.FiltersSetSearchZoneDescValueDone({
                zoneDescsFilters: regionFilters.zoneDescsFilters,
            })
        )
    }
}
/********************** ZONE DESCRIPTIONS - END **********************/

/********************** CITIES - START **********************/
export const clearAllCitiesFilters = () => {
    return async (dispatch: any, getState: any) => {
        let currentState: AppState = getState()

        let { regionFilters } = currentState.filtersReducer
        let { citiesFilters } = regionFilters

        // Clear the "selected" value of the correspondings Cities
        citiesFilters.selectedCities.forEach((selCiItem: string) => {
            citiesFilters.searchableFilter[selCiItem].selected = false
        })
        // Clear the selected Cities set
        citiesFilters.selectedCities = new Set()

        dispatch(
            FiltersDispatcher.FiltersClearAllCitiesDone({
                citiesFilters: citiesFilters,
            })
        )
    }
}

export const getCities = () => {
    return async (dispatch: any, getState: any) => {
        dispatch(FiltersDispatcher.FiltersGetCitiesRequest())

        let currentState: AppState = getState()

        let { regionFilters } = currentState.filtersReducer
        let { selectedCities } = regionFilters.citiesFilters

        try {
            var newCities: SearchableFiltersDict = await FiltersApi.GetCities(
                currentState.filtersReducer
            )

            var newCitiesFilters = new CitiesFilters(newCities)
            newCitiesFilters.fuseEngine = new Fuse(Object.entries(newCities), {
                findAllMatches: true,
                threshold: 0.2,
                location: 0,
                keys: ['0'],
            })

            // Check the old selected cities to see if there is any common city between current result and old result
            // If yes, we attempt to preserve the previous selections for those common filters
            selectedCities.forEach((city: string) => {
                const isThisACommonCity = city in newCities
                if (isThisACommonCity) {
                    // Preserve selection and count
                    newCities[city].selected = true
                    newCitiesFilters.selectedCities.add(city)
                }
            })

            dispatch(
                FiltersDispatcher.FiltersGetCitiesSuccess({
                    citiesFilters: newCitiesFilters,
                })
            )
        } catch (error: any) {
            dispatch(UserDispatcher.UserError(error))

            dispatch(FiltersDispatcher.FiltersError(error))
        }
    }
}

export const toggleCitiesViewAll = () => {
    return async (dispatch: any, getState: any) => {
        let currentState: AppState = getState()

        let { regionFilters } = currentState.filtersReducer

        regionFilters.citiesFilters.viewAllVisible =
            !regionFilters.citiesFilters.viewAllVisible

        try {
            dispatch(
                FiltersDispatcher.FiltersToggleCitiesViewAll({
                    citiesFilters: regionFilters.citiesFilters,
                })
            )
        } catch (error: any) {
            dispatch(FiltersDispatcher.FiltersError(error))
        }
    }
}

export const setSelectedCity = (city: string, selection: boolean) => {
    return async (dispatch: any, getState: any) => {
        let currentState: AppState = getState()

        let { regionFilters } = currentState.filtersReducer
        let { citiesFilters } = regionFilters

        // Store the selection for the corresponding City
        citiesFilters.searchableFilter[city].selected = selection
        selection
            ? citiesFilters.selectedCities.add(city)
            : citiesFilters.selectedCities.delete(city)

        dispatch(
            FiltersDispatcher.FiltersSetSelectedCityDone({
                citiesFilters: citiesFilters,
            })
        )
    }
}

export const setSearchCityValue = (searchValue: string) => {
    return async (dispatch: any, getState: any) => {
        let currentState: AppState = getState()

        let { regionFilters } = currentState.filtersReducer
        const { fuseEngine } = regionFilters.citiesFilters

        // Set the search value in the state
        regionFilters.citiesFilters.searchValue = searchValue

        // Perform a Fuse search over the current records (return all Cities if searchValue is empty)
        if (searchValue) {
            const fuseSearchResults = fuseEngine.search(searchValue)

            // Convert Fuse search results to a CitiesDict
            regionFilters.citiesFilters.searchedFilter =
                fuseSearchResults.reduce((accum: any, elem: any) => {
                    accum[elem.item[0]] = elem.item[1]
                    return accum
                }, {})
        } else {
            // Reset the searchableFilter
            regionFilters.citiesFilters.searchedFilter =
                regionFilters.citiesFilters.searchableFilter
        }

        // Then dispatch an actual Search action
        dispatch(
            FiltersDispatcher.FiltersSetSearchCityValueDone({
                citiesFilters: regionFilters.citiesFilters,
            })
        )
    }
}
/********************** CITIES - END **********************/

/********************** BUSINESS UNITS - START **********************/
export const clearAllBUsFilters = () => {
    return async (dispatch: any, getState: any) => {
        let currentState: AppState = getState()

        let { regionFilters } = currentState.filtersReducer
        let { busFilters } = regionFilters

        // Clear the "selected" value of the correspondings Business Units
        busFilters.selectedBUs.forEach((selBUItem: string) => {
            busFilters.searchableFilter[selBUItem].selected = false
        })
        // Clear the selected Business Units set
        busFilters.selectedBUs = new Set()

        dispatch(
            FiltersDispatcher.FiltersClearAllBUsDone({
                busFilters: busFilters,
            })
        )
    }
}

export const getBUs = () => {
    return async (dispatch: any, getState: any) => {
        dispatch(FiltersDispatcher.FiltersGetBUsRequest())

        let currentState: AppState = getState()

        let { regionFilters } = currentState.filtersReducer
        let { selectedBUs } = regionFilters.busFilters

        try {
            var newBusinessUnits: SearchableFiltersDict =
                await FiltersApi.GetBUs(currentState.filtersReducer)

            var newBUsFilters = new BUsFilters(newBusinessUnits)
            newBUsFilters.fuseEngine = new Fuse(
                Object.entries(newBusinessUnits),
                {
                    findAllMatches: true,
                    threshold: 0.2,
                    location: 0,
                    keys: ['0'],
                }
            )

            // Check the old selected business units to see if there is any common BU between current result and old result
            // If yes, we attempt to preserve the previous selections for those common filters
            selectedBUs.forEach((bu: string) => {
                const isThisACommonBU = bu in newBusinessUnits
                if (isThisACommonBU) {
                    // Preserve selection and count
                    newBusinessUnits[bu].selected = true
                    newBUsFilters.selectedBUs.add(bu)
                }
            })

            dispatch(
                FiltersDispatcher.FiltersGetBUsSuccess({
                    busFilters: newBUsFilters,
                })
            )
        } catch (error: any) {
            dispatch(UserDispatcher.UserError(error))

            dispatch(FiltersDispatcher.FiltersError(error))
        }
    }
}

export const toggleBUsViewAll = () => {
    return async (dispatch: any, getState: any) => {
        let currentState: AppState = getState()

        let { regionFilters } = currentState.filtersReducer

        regionFilters.busFilters.viewAllVisible =
            !regionFilters.busFilters.viewAllVisible

        try {
            dispatch(
                FiltersDispatcher.FiltersToggleBUsViewAll({
                    busFilters: regionFilters.busFilters,
                })
            )
        } catch (error: any) {
            dispatch(FiltersDispatcher.FiltersError(error))
        }
    }
}

export const setSelectedBU = (businessUnit: string, selection: boolean) => {
    return async (dispatch: any, getState: any) => {
        let currentState: AppState = getState()

        let { regionFilters } = currentState.filtersReducer
        let { busFilters } = regionFilters

        // Store the selection for the corresponding Business Unit
        busFilters.searchableFilter[businessUnit].selected = selection
        selection
            ? busFilters.selectedBUs.add(businessUnit)
            : busFilters.selectedBUs.delete(businessUnit)

        dispatch(
            FiltersDispatcher.FiltersSetSelectedBUDone({
                busFilters: busFilters,
            })
        )
    }
}

export const setSearchBUValue = (searchValue: string) => {
    return async (dispatch: any, getState: any) => {
        let currentState: AppState = getState()

        let { regionFilters } = currentState.filtersReducer
        const { fuseEngine } = regionFilters.busFilters

        // Set the search value in the state
        regionFilters.busFilters.searchValue = searchValue

        // Perform a Fuse search over the current records (return all Business Units if searchValue is empty)
        if (searchValue) {
            const fuseSearchResults = fuseEngine.search(searchValue)

            // Convert Fuse search results to a BUsDict
            regionFilters.busFilters.searchedFilter = fuseSearchResults.reduce(
                (accum: any, elem: any) => {
                    accum[elem.item[0]] = elem.item[1]
                    return accum
                },
                {}
            )
        } else {
            // Reset the searchableFilter
            regionFilters.busFilters.searchedFilter =
                regionFilters.busFilters.searchableFilter
        }

        // Then dispatch an actual Search action
        dispatch(
            FiltersDispatcher.FiltersSetSearchBUValueDone({
                busFilters: regionFilters.busFilters,
            })
        )
    }
}
/********************** BUSINESS UNITS - END **********************/
/////////////////////// REGION - END ///////////////////////

/////////////////////// REMODEL - START ///////////////////////
export const clearAllRemodelFilters = () => {
    return async (dispatch: any, getState: any) => {
        let currentState: AppState = getState()

        let { remodelFilters } = currentState.filtersReducer

        // Clear the selected Months and Years set
        remodelFilters.selectedRemodelMonths = new Set()
        remodelFilters.selectedRemodelYears = new Set()

        dispatch(
            FiltersDispatcher.FiltersClearAllRemodelFiltersSuccess({
                remodelFilters: remodelFilters,
            })
        )
    }
}

export const setSelectedRemodelMonth = (month: number) => {
    return async (dispatch: any, getState: any) => {
        let currentState: AppState = getState()
        let { remodelFilters } = currentState.filtersReducer

        // Store the selection for the corresponding Remodeling month
        remodelFilters.selectedRemodelMonths.has(month)
            ? remodelFilters.selectedRemodelMonths.delete(month)
            : remodelFilters.selectedRemodelMonths.add(month)

        dispatch(
            FiltersDispatcher.FiltersSetSelectedRemodelMonthDone({
                remodelFilters: remodelFilters,
            })
        )
    }
}

export const setSelectedRemodelYear = (year: number) => {
    return async (dispatch: any, getState: any) => {
        let currentState: AppState = getState()
        let { remodelFilters } = currentState.filtersReducer

        // Store the selection for the corresponding Remodeling year
        remodelFilters.selectedRemodelYears.has(year)
            ? remodelFilters.selectedRemodelYears.delete(year)
            : remodelFilters.selectedRemodelYears.add(year)

        dispatch(
            FiltersDispatcher.FiltersSetSelectedRemodelYearDone({
                remodelFilters: remodelFilters,
            })
        )
    }
}
/////////////////////// REMODEL - END ///////////////////////

/////////////////////// RELOCATION - START ///////////////////////
export const clearAllRelocationFilters = () => {
    return async (dispatch: any, getState: any) => {
        let currentState: AppState = getState()

        let { relocationFilters } = currentState.filtersReducer

        // Clear the selected Months and Years set
        relocationFilters.selectedRelocationMonths = new Set()
        relocationFilters.selectedRelocationYears = new Set()

        dispatch(
            FiltersDispatcher.FiltersClearAllRelocationFiltersSuccess({
                relocationFilters: relocationFilters,
            })
        )
    }
}

export const setSelectedRelocationMonth = (month: number) => {
    return async (dispatch: any, getState: any) => {
        let currentState: AppState = getState()
        let { relocationFilters } = currentState.filtersReducer

        // Store the selection for the corresponding Relocation month
        relocationFilters.selectedRelocationMonths.has(month)
            ? relocationFilters.selectedRelocationMonths.delete(month)
            : relocationFilters.selectedRelocationMonths.add(month)

        dispatch(
            FiltersDispatcher.FiltersSetSelectedRelocationMonthDone({
                relocationFilters: relocationFilters,
            })
        )
    }
}

export const setSelectedRelocationYear = (year: number) => {
    return async (dispatch: any, getState: any) => {
        let currentState: AppState = getState()
        let { relocationFilters } = currentState.filtersReducer

        // Store the selection for the corresponding Relocation year
        relocationFilters.selectedRelocationYears.has(year)
            ? relocationFilters.selectedRelocationYears.delete(year)
            : relocationFilters.selectedRelocationYears.add(year)

        dispatch(
            FiltersDispatcher.FiltersSetSelectedRelocationYearDone({
                relocationFilters: relocationFilters,
            })
        )
    }
}
/////////////////////// RELOCATION - END ///////////////////////

/////////////////////// NEW - START ///////////////////////
export const clearAllNewFilters = () => {
    return async (dispatch: any, getState: any) => {
        let currentState: AppState = getState()

        let { newFilters } = currentState.filtersReducer

        // Clear the selected Months and Years set
        newFilters.selectedNewMonths = new Set()
        newFilters.selectedNewYears = new Set()

        dispatch(
            FiltersDispatcher.FiltersClearAllNewFiltersSuccess({
                newFilters: newFilters,
            })
        )
    }
}

export const setSelectedNewMonth = (month: number) => {
    return async (dispatch: any, getState: any) => {
        let currentState: AppState = getState()
        let { newFilters } = currentState.filtersReducer

        // Store the selection for the corresponding New month
        newFilters.selectedNewMonths.has(month)
            ? newFilters.selectedNewMonths.delete(month)
            : newFilters.selectedNewMonths.add(month)

        dispatch(
            FiltersDispatcher.FiltersSetSelectedNewMonthDone({
                newFilters: newFilters,
            })
        )
    }
}

export const setSelectedNewYear = (year: number) => {
    return async (dispatch: any, getState: any) => {
        let currentState: AppState = getState()
        let { newFilters } = currentState.filtersReducer

        // Store the selection for the corresponding New year
        newFilters.selectedNewYears.has(year)
            ? newFilters.selectedNewYears.delete(year)
            : newFilters.selectedNewYears.add(year)

        dispatch(
            FiltersDispatcher.FiltersSetSelectedNewYearDone({
                newFilters: newFilters,
            })
        )
    }
}
/////////////////////// NEW - END ///////////////////////

/////////////////////// HOSTED RETAIL - START ///////////////////////
export const clearAllHostedRetailFilters = () => {
    return async (dispatch: any, getState: any) => {
        let currentState: AppState = getState()

        let { hostedRetailFilters } = currentState.filtersReducer

        // Clear the "selected" value of the correspondings Hosted Retail
        hostedRetailFilters.selectedHostedRetail.forEach(
            (selHRItem: string) => {
                hostedRetailFilters.searchableFilter[selHRItem].selected = false
            }
        )
        // Clear the selected Hosted Retail set
        hostedRetailFilters.selectedHostedRetail = new Set()

        dispatch(
            FiltersDispatcher.FiltersClearAllHostedRetailFiltersSuccess({
                hostedRetailFilters: hostedRetailFilters,
            })
        )
    }
}

export const getHostedRetail = () => {
    return async (dispatch: any, getState: any) => {
        dispatch(FiltersDispatcher.FiltersGetHostedRetailRequest())

        let currentState: AppState = getState()

        let { selectedHostedRetail } =
            currentState.filtersReducer.hostedRetailFilters

        try {
            const newHostedRetail: HostedRetailDict =
                await FiltersApi.GetHostedRetail(currentState.filtersReducer)

            let newHostedRetailFilters = new HostedRetailFilters(
                newHostedRetail
            )
            newHostedRetailFilters.fuseEngine = new Fuse(
                Object.entries(newHostedRetail),
                {
                    findAllMatches: true,
                    threshold: 0.2,
                    location: 0,
                    keys: ['0'],
                }
            )

            // Check the old selected HostedRetail to see if there is any common HostedRetail between current result and old result
            // If yes, we attempt to preserve the previous selections for those common filters
            selectedHostedRetail.forEach((hr: string) => {
                const isThisACommonHR = hr in newHostedRetail
                if (isThisACommonHR) {
                    // Preserve selection and count
                    newHostedRetail[hr].selected = true
                    newHostedRetailFilters.selectedHostedRetail.add(hr)
                }
            })

            dispatch(
                FiltersDispatcher.FiltersGetHostedRetailSuccess({
                    hostedRetailFilters: newHostedRetailFilters,
                })
            )
        } catch (error: any) {
            dispatch(UserDispatcher.UserError(error))

            dispatch(FiltersDispatcher.FiltersError(error))
        }
    }
}

export const toggleHostedRetailViewAll = () => {
    return async (dispatch: any, getState: any) => {
        let currentState: AppState = getState()

        let { hostedRetailFilters } = currentState.filtersReducer

        hostedRetailFilters.viewAllVisible = !hostedRetailFilters.viewAllVisible

        try {
            dispatch(
                FiltersDispatcher.FiltersToggleHostedRetailViewAll({
                    hostedRetailFilters: hostedRetailFilters,
                })
            )
        } catch (error: any) {
            dispatch(FiltersDispatcher.FiltersError(error))
        }
    }
}

export const setSelectedHostedRetail = (
    hostedRetail: string,
    selection: boolean
) => {
    return async (dispatch: any, getState: any) => {
        let currentState: AppState = getState()

        let { hostedRetailFilters } = currentState.filtersReducer

        // Store the selection for the corresponding Hosted Retail
        hostedRetailFilters.searchableFilter[hostedRetail].selected = selection
        selection
            ? hostedRetailFilters.selectedHostedRetail.add(hostedRetail)
            : hostedRetailFilters.selectedHostedRetail.delete(hostedRetail)

        dispatch(
            FiltersDispatcher.FiltersSetSelectedHostedRetailDone({
                hostedRetailFilters: hostedRetailFilters,
            })
        )
    }
}

export const setSearchHostedRetailValue = (searchValue: string) => {
    return async (dispatch: any, getState: any) => {
        let currentState: AppState = getState()

        let { hostedRetailFilters } = currentState.filtersReducer
        const { fuseEngine } = hostedRetailFilters

        // Set the search value in the state
        hostedRetailFilters.searchValue = searchValue

        // Perform a Fuse search over the current records (return all Hosted Retail if searchValue is empty)
        if (searchValue) {
            const fuseSearchResults = fuseEngine.search(searchValue)

            // Convert Fuse search results to a HostedRetailDict
            hostedRetailFilters.searchedFilter = fuseSearchResults.reduce(
                (accum: any, elem: any) => {
                    accum[elem.item[0]] = elem.item[1]
                    return accum
                },
                {}
            )
        } else {
            // Reset the searchableFilter
            hostedRetailFilters.searchedFilter =
                hostedRetailFilters.searchableFilter
        }

        // Then dispatch an actual Search action
        dispatch(
            FiltersDispatcher.FiltersSetSearchHostedRetailValueDone({
                hostedRetailFilters: hostedRetailFilters,
            })
        )
    }
}
/////////////////////// HOSTED RETAIL - END ///////////////////////

/////////////////////// SURVEY DATE - START ///////////////////////
export const clearAllSurveyDateFilters = () => {
    return async (dispatch: any, getState: any) => {
        let currentState: AppState = getState()

        let { surveyDateFilters } = currentState.filtersReducer

        // Clear the selected Months and Years set
        surveyDateFilters.selectedFrom = null
        surveyDateFilters.selectedTo = null

        dispatch(
            FiltersDispatcher.FiltersClearAllSurveyDateFiltersSuccess({
                surveyDateFilters: surveyDateFilters,
            })
        )
    }
}

export const setSelectedSurveyDateFrom = (from: Date | null) => {
    return async (dispatch: any, getState: any) => {
        let currentState: AppState = getState()
        let { surveyDateFilters } = currentState.filtersReducer

        // Store the selection for the corresponding Survey Date from
        surveyDateFilters.selectedFrom = from

        dispatch(
            FiltersDispatcher.FiltersSetSelectedSurveyDateFromDone({
                surveyDateFilters: surveyDateFilters,
            })
        )
    }
}

export const setSelectedSurveyDateTo = (to: Date | null) => {
    return async (dispatch: any, getState: any) => {
        let currentState: AppState = getState()
        let { surveyDateFilters } = currentState.filtersReducer

        // Store the selection for the corresponding Survey Date to
        surveyDateFilters.selectedTo = to

        dispatch(
            FiltersDispatcher.FiltersSetSelectedSurveyDateToDone({
                surveyDateFilters: surveyDateFilters,
            })
        )
    }
}
/////////////////////// SURVEY DATE - END ///////////////////////

