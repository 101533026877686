import { injectIntl } from 'react-intl'
import { Colors } from '../../../colors'
import DetailsParagraph from './GeneralInfoParagraph'
import StatisticsCard from './StatisticsCard'
import { StyledColumn } from './StyledColumn'
import { useWindowResize } from '../../../utils/commonFunctions'
import Banners from './Banners'
import { Accordion, AccordionTab } from 'primereact/accordion'

const GeneralInfoColumn = (props: any) => {
    const { intl, mainproject, onPhotoNumberClick, photosLength } = props

    const isMobileNow = useWindowResize()

    const isSecondRelease = false

    const statistics = [
        {
            icon: 'glasses',
            number: 1780,
            name: intl.formatMessage({
                id: 'store_detail.visible_capacity',
            }),
            button: intl.formatMessage({
                id: 'store_detail.view_assortment',
            }),
        },
        {
            icon: 'image',
            number: photosLength,
            name: intl.formatMessage({
                id: 'store_detail.uploaded_images',
            }),
            button: undefined,
            label: intl.formatMessage({
                id: 'store_detail.few_images',
            }),
        },
    ]

    let details = null

    const storesByMacroregion: Record<string, Record<string, string[]>> = {}

    for (const store of mainproject.store_list) {
        const macroregion = store.macroregion_description
        const banner = store.banner
        const storeId = store.store_id
        const storeName = store.store_name

        if (!storesByMacroregion[macroregion]) {
            storesByMacroregion[macroregion] = {}
        }

        if (!storesByMacroregion[macroregion][banner]) {
            storesByMacroregion[macroregion][banner] = []
        }

        storesByMacroregion[macroregion][banner].push(
            `#${storeId} ${storeName}`
        )
    }

    const renderStoresAccordion = () => {
        const renderBanner = (macroregion: string, banner: string) => {
            const stores = storesByMacroregion[macroregion][banner]
            return (
                <div key={banner} className="child-accordion">
                    <Accordion>
                        <AccordionTab header={banner}>
                            {stores.map((store: any) => {
                                return (
                                    <div key={store}>
                                        <p>{store}</p>
                                    </div>
                                )
                            })}
                        </AccordionTab>
                    </Accordion>
                </div>
            )
        }

        return (
            <div className="accordion-container">
                <Accordion>
                    {Object.entries(storesByMacroregion).map(
                        ([macroregion, banners]) => (
                            <AccordionTab
                                key={macroregion}
                                header={macroregion}>
                                {Object.keys(banners).map((banner) =>
                                    renderBanner(macroregion, banner)
                                )}
                            </AccordionTab>
                        )
                    )}
                </Accordion>
            </div>
        )
    }

    if (mainproject) {
        details = [
            {
                backgroundColor: Colors.grey1,
                info: {
                    name: {
                        title: 'Name',
                        field: mainproject.name ? mainproject.name : '-',
                    },
                    years: {
                        title: 'Years',
                        field: mainproject.year_list
                            ? mainproject.year_list?.join(', ')
                            : '-',
                    },
                    stores: {
                        title: 'Stores',
                        field: renderStoresAccordion(),
                    },
                },
            },
        ]
    }

    return (
        <StyledColumn>
            <div>
                {!isMobileNow && <Banners banners={mainproject?.banner_list} />}
                {/*isSecondRelease && (
                    <div className="p-col">
                        <StatisticsCard cardContent={statistics[0]} />
                    </div>
                )*/}
                <div className="p-col">
                    <StatisticsCard
                        cardContent={statistics[1]}
                        onClick={onPhotoNumberClick}
                    />
                </div>
                <div className="p-d-flex p-flex-column p-flex-lg-column-reverse">
                    <div className="p-col">
                        {details &&
                            details.map((d, idx) => (
                                <div key={idx} className="p-col p-p-0">
                                    <DetailsParagraph content={d} />
                                </div>
                            ))}
                    </div>
                </div>
            </div>
        </StyledColumn>
    )
}
export default injectIntl(GeneralInfoColumn)
