import React, { useEffect, useRef, useState } from 'react'
import { ReactComponent as UserProfileIcon } from '../../assets/svgs/elements-icon-profile.svg'
// import { ReactComponent as UserProfileArrowUp } from '../../assets/svgs/elements-icon-chevron-up.svg'
// import { ReactComponent as UserProfileArrowDown } from '../../assets/svgs/elements-icon-chevron-down.svg'
import { ReactComponent as SidebarIconFavourites } from '../../assets/svgs/elements-icon-favourite.svg'
import { ReactComponent as SidebarIconSettings } from '../../assets/svgs/elements-icon-settings.svg'
import { ReactComponent as SidebarIconSignOut } from '../../assets/svgs/elements-icon-log-out.svg'
import { ReactComponent as SidebarLogo } from '../../assets/svgs/elements-desktop-brand.svg'
import './style.css'
import { Dialog } from 'primereact/dialog'
import { Sidebar } from 'primereact/sidebar'
import { connect } from 'react-redux'
import { injectIntl } from 'react-intl'
import { AppState } from '../../redux/states/appState'
import { SidebarMenuKind } from '../../models/sidebar-menu-kind'
import { setSelectedSidebarMenu } from '../../redux/actions/filters'
import { User } from '../../models/user'
import ProfileSidebarContent from '../profile-sidebar-content'
import { logout, setUserLanguage } from '../../redux/actions/user'
import { useWindowResize } from '../../utils/commonFunctions'
import { Menu } from 'primereact/menu'
import { Button } from 'primereact/button'

type FilterSidebarProps = {
    intl: any
    setSelectedSidebarMenu: (newSelectedFiltersMenu: SidebarMenuKind) => void
    setLanguage: Function
    selectedSidebarMenu: SidebarMenuKind
    currentUser: User
    logout: any
}

const UserProfile = (props: FilterSidebarProps) => {
    const { intl } = props

    const isMobileNew = useWindowResize()

    // const [isDropdownOpen, setIsDropdownOpen] = useState(false)
    const [visibleLeft, setVisibleLeft] = useState(false)
    const [displayModal, setDisplayModal] = useState(false)

    /*
    const handleIsDropdownOpen = (state: any) => {
        if (state) {
            setIsDropdownOpen(state)
        } else {
            if (isDropdownOpen) {
                setIsDropdownOpen(false)
            } else {
                setIsDropdownOpen(true)
            }
        }
    }
    */

    const openSidebar = (type: string) => {
        switch (type) {
            case 'PROFILE_AND_SETTINGS':
                props.setSelectedSidebarMenu(
                    SidebarMenuKind.PROFILE_AND_SETTINGS
                )
                break
            case 'FAVOURITES':
                setVisibleLeft(false)
                props.setSelectedSidebarMenu(SidebarMenuKind.FAVOURITES)
                break
            case 'SEARCH_RESULTS':
                props.setSelectedSidebarMenu(SidebarMenuKind.SEARCH_RESULTS)
                break
            default:
                props.setSelectedSidebarMenu(SidebarMenuKind.NONE)
                break
        }
    }

    const openSidebarLeft = () => {
        props.setSelectedSidebarMenu(SidebarMenuKind.NONE)
        setVisibleLeft(true)
    }

    useEffect(() => {
        const justSwitchedLanguage =
            (localStorage.getItem('LANGUAGE_just_changed') || 'false') ===
            'true'

        if (justSwitchedLanguage) {
            // The following was causing the modal to show after login and it shouldn't
            //setDisplayModal(true)
        }

        localStorage.setItem('LANGUAGE_just_changed', 'false')
    }, [])

    const dialogFuncMap = {
        displayModal: setDisplayModal,
    }

    const openModal = () => {
        dialogFuncMap['displayModal'](true)
    }

    const closeModal = () => {
        dialogFuncMap['displayModal'](false)
    }

    const menu: any = useRef(null)
    const [arrow, setArrow] = useState('pi pi-angle-down')

    const items = [
        {
            items: [
                {
                    label: intl.formatMessage({
                        id: 'profile_and_settings.favourites',
                    }),
                    command: (e: any) => {
                        openSidebar('FAVOURITES')
                    },
                },
                {
                    label: intl.formatMessage({
                        id: 'profile_and_settings.profile_and_settings',
                    }),
                    command: (e: any) => {
                        openSidebar('PROFILE_AND_SETTINGS')
                    },
                },
                {
                    label: intl.formatMessage({
                        id: 'profile_and_settings.sign_out',
                    }),
                    command: (e: any) => {
                        props.logout()
                    },
                },
            ],
        },
    ]

    return (
        <>
            {!isMobileNew && (
                <div className="prUserProfile">
                    <Menu
                        model={items}
                        popup
                        ref={menu}
                        id="popup_menu"
                        className="prUserProfileMenu"
                        onShow={() => setArrow('pi pi-angle-up')}
                        onHide={() => setArrow('pi pi-angle-down')}
                    />
                    <div style={{ position: 'relative', zIndex: 9999 }}>
                        <div
                            className="userProfileIcon"
                            onClick={() => openSidebarLeft()}>
                            <UserProfileIcon />
                        </div>
                        <Button
                            label={`${props.currentUser.given_name} ${props.currentUser.family_name}`}
                            icon={arrow}
                            iconPos="right"
                            className="prUserProfileButton"
                            onClick={(event) => menu.current.toggle(event)}
                            aria-controls="popup_menu"
                            aria-haspopup
                        />
                    </div>
                </div>
            )}
            {isMobileNew && (
                <div className="userProfileNav">
                    <div>
                        <div className="userProfileRow">
                            {isMobileNew && (
                                <div>
                                    <Sidebar
                                        visible={visibleLeft}
                                        baseZIndex={100}
                                        onHide={() => setVisibleLeft(false)}>
                                        <div className="sidebarName">{`${props.currentUser.given_name} ${props.currentUser.family_name}`}</div>
                                        <ul className="sidebarNav">
                                            <li
                                                onClick={() =>
                                                    openSidebar('FAVOURITES')
                                                }>
                                                <SidebarIconFavourites />
                                                <span>
                                                    {intl.formatMessage({
                                                        id: 'profile_and_settings.favourites',
                                                    })}
                                                </span>
                                            </li>
                                            <li onClick={() => openModal()}>
                                                <SidebarIconSettings />
                                                {intl.formatMessage({
                                                    id: 'profile_and_settings.profile_and_settings',
                                                })}
                                            </li>
                                            <li onClick={props.logout}>
                                                <SidebarIconSignOut />
                                                {intl.formatMessage({
                                                    id: 'profile_and_settings.sign_out',
                                                })}
                                            </li>
                                        </ul>
                                        <div className="sidebarLogo">
                                            <SidebarLogo />
                                        </div>
                                    </Sidebar>
                                    <div
                                        className="userProfileIcon"
                                        onClick={() => openSidebarLeft()}>
                                        <UserProfileIcon />
                                    </div>
                                </div>
                            )}
                        </div>
                        <div className="settingsModal">
                            <Dialog
                                header={intl.formatMessage({
                                    id: 'profile_and_settings.profile_and_settings',
                                })}
                                visible={displayModal}
                                baseZIndex={1000001}
                                onHide={() => closeModal()}>
                                <ProfileSidebarContent
                                    currentUser={props.currentUser}
                                    setLanguage={props.setLanguage}
                                />
                            </Dialog>
                        </div>
                    </div>
                    <div className="settingsModal">
                        <Dialog
                            header={intl.formatMessage({
                                id: 'profile_and_settings.profile_and_settings',
                            })}
                            visible={displayModal}
                            baseZIndex={1000001}
                            onHide={() => closeModal()}>
                            <ProfileSidebarContent
                                currentUser={props.currentUser}
                                setLanguage={props.setLanguage}
                            />
                        </Dialog>
                    </div>
                </div>
            )}
            {/*
            <div
                className={
                    'userProfileNav ' + (isDropdownOpen ? 'opened' : 'closed')
                }>
                <div>
                    <div className="userProfileRow">
                        {isMobileNew && (
                            <div>
                                <Sidebar
                                    visible={visibleLeft}
                                    baseZIndex={100}
                                    onHide={() => setVisibleLeft(false)}>
                                    <div className="sidebarName">{`${props.currentUser.given_name} ${props.currentUser.family_name}`}</div>
                                    <ul className="sidebarNav">
                                        <li
                                            onClick={() =>
                                                openSidebar('FAVOURITES')
                                            }>
                                            <SidebarIconFavourites />
                                            <span>
                                                {intl.formatMessage({
                                                    id:
                                                        'profile_and_settings.favourites',
                                                })}
                                            </span>
                                        </li>
                                        <li onClick={() => openModal()}>
                                            <SidebarIconSettings />
                                            {intl.formatMessage({
                                                id:
                                                    'profile_and_settings.profile_and_settings',
                                            })}
                                        </li>
                                        <li onClick={props.logout}>
                                            <SidebarIconSignOut />
                                            {intl.formatMessage({
                                                id:
                                                    'profile_and_settings.sign_out',
                                            })}
                                        </li>
                                    </ul>
                                    <div className="sidebarLogo">
                                        <SidebarLogo />
                                    </div>
                                </Sidebar>
                                <div
                                    className="userProfileIcon"
                                    onClick={() => openSidebarLeft()}>
                                    <UserProfileIcon />
                                </div>
                            </div>
                        )}
                        {!isMobileNew && (
                            <div className="userProfileHiddenMobile">
                                <div
                                    className="userProfileIcon"
                                    onClick={() => handleIsDropdownOpen('')}>
                                    <UserProfileIcon />
                                </div>
                                <div
                                    className="userProfileName"
                                    onClick={() => handleIsDropdownOpen('')}>
                                    {`${props.currentUser.given_name} ${props.currentUser.family_name}`}
                                </div>
                                <div className="userProfileArrow">
                                    {isDropdownOpen ? (
                                        <UserProfileArrowUp
                                            onClick={() =>
                                                handleIsDropdownOpen(false)
                                            }
                                        />
                                    ) : (
                                        <UserProfileArrowDown
                                            onClick={() =>
                                                handleIsDropdownOpen(true)
                                            }
                                        />
                                    )}
                                </div>
                            </div>
                        )}
                    </div>
                    {!isMobileNew && isDropdownOpen && (
                        <ul className="userProfileMenu">
                            <li onClick={() => openSidebar('FAVOURITES')}>
                                {intl.formatMessage({
                                    id: 'profile_and_settings.favourites',
                                })}
                            </li>
                            <li
                                onClick={() =>
                                    openSidebar('PROFILE_AND_SETTINGS')
                                }>
                                {intl.formatMessage({
                                    id:
                                        'profile_and_settings.profile_and_settings',
                                })}
                            </li>
                            <li onClick={props.logout}>
                                {intl.formatMessage({
                                    id: 'profile_and_settings.sign_out',
                                })}
                            </li>
                        </ul>
                    )}
                    {isMobileNew && (
                        <div className="settingsModal">
                            <Dialog
                                header={intl.formatMessage({
                                    id:
                                        'profile_and_settings.profile_and_settings',
                                })}
                                visible={displayModal}
                                baseZIndex={1000001}
                                onHide={() => closeModal()}>
                                <ProfileSidebarContent
                                    currentUser={props.currentUser}
                                    setLanguage={props.setLanguage}
                                />
                            </Dialog>
                        </div>
                    )}
                </div>
                {isMobileNew && (
                    <div className="settingsModal">
                        <Dialog
                            header={intl.formatMessage({
                                id: 'profile_and_settings.profile_and_settings',
                            })}
                            visible={displayModal}
                            baseZIndex={1000001}
                            onHide={() => closeModal()}>
                            <ProfileSidebarContent
                                currentUser={props.currentUser}
                                setLanguage={props.setLanguage}
                            />
                        </Dialog>
                    </div>
                )}
            </div>
            */}
        </>
    )
}

const mapStateToProps = (state: AppState) => ({
    selectedSidebarMenu: state.filtersReducer.selectedSidebarMenu,
    currentUser: state.userReducer.user,
})

const mapDispatchToProps = (dispatch: any) => ({
    setSelectedSidebarMenu: (newSelectedFiltersMenu: SidebarMenuKind) =>
        dispatch(setSelectedSidebarMenu(newSelectedFiltersMenu)),
    setLanguage: (langLocaleShort: string) =>
        dispatch(setUserLanguage(langLocaleShort)),
    logout: () => dispatch(logout()),
})

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(injectIntl(UserProfile))

