import SquaredCheckbox from '../squared-checkbox'
import { Panel } from 'primereact/panel'
import {
    SearchableFilter,
    SearchableFiltersArray,
    SearchableFilterElement,
} from '../../models/searchable-filter'
import { StyledProgressSpinner, StyledTitle, StyledButton } from './style'
import { injectIntl } from 'react-intl'
import Search from '../search'
import { ReactNode } from 'react'

type SmartSearchableFilterProps = {
    intl: any
    loading: boolean
    hasIntlSplittedTitle: boolean
    placeholderSearch: string
    searchValue: string
    setSearchValue: (searchValue: string) => any
    visibleCount: number
    hideCount: boolean
    searchableFilters: SearchableFilter[]
    toggleViewAllVisible: Function
    togglePanelCollapsed: Function
    toggleSelectAllSelected?: Function
    toggleFirstLevelSelectAllSelected?: Function
    firstLevelPanelsCollapsed?: Record<string, boolean>
    setFilterSelection: Function
    render: Function
}

const defaultProps = {
    visibleCount: 6,
    hideCount: false,
}

const firstLevelAllowedKeys = new Set([
    'LC',
    'SG',
    'TO',
    'OP',
    'LP',
    'RB',
    'OO',
    'SV',
    'DC',
])

const SmartSearchableFilter = (props: SmartSearchableFilterProps) => {
    props = { ...defaultProps, ...props }

    const { intl } = props

    const { setFilterSelection } = props

    const mapFiltersToSquaredCheckboxesOrOther = (
        searchableFilterId: string,
        searchableFilter: SearchableFiltersArray,
        fromIdx: number,
        toIdx: number
    ) => {
        return (
            <div className="p-grid">
                {searchableFilter
                    .slice(fromIdx, toIdx)
                    .map(
                        (
                            searchableFilterElem: [
                                string,
                                SearchableFilterElement
                            ]
                        ) => {
                            const checkboxLabel = props.hideCount
                                ? searchableFilterElem[0].toUpperCase()
                                : `${searchableFilterElem[0].toUpperCase()} (${
                                      searchableFilterElem[1].count
                                  })`

                            return (
                                <div
                                    className="p-col-12 p-md-4 p-lg-6"
                                    key={searchableFilterElem[0]}>
                                    {props.render ? (
                                        props.render(searchableFilterElem)
                                    ) : (
                                        <SquaredCheckbox
                                            checked={
                                                searchableFilterElem[1].selected
                                            }
                                            onChange={(e: any) =>
                                                setFilterSelection.length > 2
                                                    ? setFilterSelection(
                                                          searchableFilterId,
                                                          searchableFilterElem[0],
                                                          !searchableFilterElem[1]
                                                              .selected
                                                      )
                                                    : setFilterSelection(
                                                          searchableFilterElem[0],
                                                          !searchableFilterElem[1]
                                                              .selected
                                                      )
                                            }
                                            label={checkboxLabel}
                                        />
                                    )}
                                </div>
                            )
                        }
                    )}
            </div>
        )
    }

    const isASingleSearchableFilter = props.searchableFilters.length < 2
    const itemsCount = props.searchableFilters[0].searchableFilter
        ? Object.keys(props.searchableFilters[0].searchableFilter).length
        : 0

    const hasPanel = props.togglePanelCollapsed ? true : false
    const elementsReferenceTitleChunks: Record<
        string,
        {
            count: number
            chunks: ReactNode[]
            selectAllAll: Function[]
            selectAllSelections: boolean[]
            hasSomeSelections: boolean
        }
    > = {}
    if (!props.loading) {
        props.searchableFilters.forEach(
            (searchableFilterInstance: SearchableFilter, idx: number) => {
                const isLastItem = idx === props.searchableFilters.length - 1
                const searchableFilter: SearchableFiltersArray =
                    searchableFilterInstance.searchableFilter
                        ? Object.entries<SearchableFilterElement>(
                              SearchableFilter.getSearchableFilterByInstance(
                                  searchableFilterInstance,
                                  true
                              )
                          )
                        : []
                const searchableFilterCount: number = searchableFilter.length
                const searchableFilterInnerCount: number =
                    searchableFilter.reduce(
                        (
                            accumulator: number,
                            sf: [string, SearchableFilterElement]
                        ) => {
                            accumulator += sf[1].count

                            return accumulator
                        },
                        0
                    )
                const viewAllButtonText =
                    searchableFilterInstance.viewAllVisible
                        ? `${intl.formatMessage({
                              id: 'generic.view_all.label',
                          })} (${
                              searchableFilterCount - props.visibleCount
                          } ${intl.formatMessage({
                              id: 'generic.more.label',
                          })})`
                        : `${intl.formatMessage({
                              id: 'generic.view_less.label',
                          })}`

                const styledTitleComponents =
                    searchableFilterInstance.title.split('intl.')

                const onSelectAll = (e: any) => {
                    props.toggleSelectAllSelected?.(searchableFilterInstance.id)

                    for (const searchableFilterElem of searchableFilter) {
                        setFilterSelection(
                            searchableFilterInstance.id,
                            searchableFilterElem[0],
                            searchableFilterInstance.selectAllSelected
                        )
                    }
                }

                const hasIntlSplittedTitle = props.hasIntlSplittedTitle
                const SmartTitle = (props: any) => {
                    return !hasPanel &&
                        itemsCount > 0 &&
                        searchableFilterInstance.title ? (
                        <>
                            <StyledTitle className="p-col-12">
                                {!hasIntlSplittedTitle
                                    ? intl.formatMessage({
                                          id: searchableFilterInstance.title,
                                      })
                                    : `${styledTitleComponents[0]}${intl
                                          .formatMessage({
                                              id: styledTitleComponents[1],
                                          })
                                          .replace(
                                              'PVREPLACE',
                                              searchableFilterInnerCount.toString()
                                          )}`}
                            </StyledTitle>
                            {props.children}
                        </>
                    ) : itemsCount > 0 && searchableFilterInstance.title ? (
                        searchableFilterInnerCount > 0 ? (
                            <Panel
                                onToggle={props.onToggle}
                                collapsed={props.collapsed}
                                className="specialTitle"
                                header={
                                    <div
                                        style={{
                                            display: 'flex',
                                            flexDirection: 'row',
                                            justifyContent: 'space-between',
                                            alignItems: 'center',
                                            paddingRight: 20,
                                        }}>
                                        <div>
                                            {!hasIntlSplittedTitle
                                                ? intl.formatMessage({
                                                      id: searchableFilterInstance.title,
                                                  })
                                                : `${
                                                      styledTitleComponents[0]
                                                  }${intl
                                                      .formatMessage({
                                                          id: styledTitleComponents[1],
                                                      })
                                                      .replace(
                                                          'PVREPLACE',
                                                          searchableFilterInnerCount.toString()
                                                      )}`}
                                        </div>
                                        {props.onToggleSelectAllSelected !=
                                            null && (
                                            <div>
                                                <SquaredCheckbox
                                                    style={{
                                                        marginBottom: 'auto',
                                                    }}
                                                    checked={Object.values(
                                                        searchableFilterInstance.searchableFilter
                                                    ).every(
                                                        (sf) => sf.selected
                                                    )}
                                                    onChange={onSelectAll}
                                                />
                                            </div>
                                        )}
                                    </div>
                                }
                                toggleable>
                                {props.children}
                            </Panel>
                        ) : (
                            <></>
                        )
                    ) : (
                        <>{props.children}</>
                    )
                }

                const hasSomeSelections =
                    searchableFilterInstance.searchableFilter != null &&
                    Object.values(
                        searchableFilterInstance.searchableFilter
                    ).some((sf) => sf.selected)

                const currentElement = (
                    <SmartTitle
                        className="p-grid"
                        collapsed={
                            !isASingleSearchableFilter &&
                            searchableFilterInstance.isPanelCollapsed &&
                            !hasSomeSelections
                        }
                        onToggle={(e: any) =>
                            props.togglePanelCollapsed(
                                searchableFilterInstance.id
                            )
                        }
                        onToggleSelectAllSelected={
                            props.toggleSelectAllSelected != null
                                ? (e: any) =>
                                      props.toggleSelectAllSelected?.(
                                          searchableFilterInstance.id
                                      )
                                : undefined
                        }
                        key={`${searchableFilterInstance.id}_${idx}`}>
                        <>
                            {/* Title */}
                            {
                                /* Search - for a single searchableFilters */
                                isASingleSearchableFilter &&
                                props.setSearchValue ? (
                                    itemsCount > props.visibleCount ? (
                                        <div className="p-col-12">
                                            <Search
                                                placeholder={
                                                    props.placeholderSearch
                                                }
                                                searchValue={props.searchValue}
                                                setSearchValue={
                                                    props.setSearchValue
                                                }
                                            />
                                        </div>
                                    ) : (
                                        <></>
                                    )
                                ) : (
                                    <></>
                                )
                            }
                            {
                                /* Checkboxes or custom render component */
                                searchableFilterCount > props.visibleCount ? (
                                    <>
                                        {searchableFilterInstance.viewAllVisible
                                            ? mapFiltersToSquaredCheckboxesOrOther(
                                                  searchableFilterInstance.id,
                                                  searchableFilter,
                                                  0,
                                                  props.visibleCount
                                              )
                                            : mapFiltersToSquaredCheckboxesOrOther(
                                                  searchableFilterInstance.id,
                                                  searchableFilter,
                                                  0,
                                                  searchableFilterCount
                                              )}
                                        {/* View All */}
                                        <StyledButton
                                            className="p-col-12 viewAllButtonText"
                                            onClick={(e: any) =>
                                                props.toggleViewAllVisible(
                                                    searchableFilterInstance.id
                                                )
                                            }
                                            style={
                                                isLastItem
                                                    ? {
                                                          marginTop:
                                                              props.render
                                                                  ? 10
                                                                  : 0,
                                                          marginBottom: 10,
                                                      }
                                                    : {
                                                          marginTop:
                                                              props.render
                                                                  ? 10
                                                                  : 0,
                                                      }
                                            }>
                                            {viewAllButtonText}
                                        </StyledButton>
                                    </>
                                ) : (
                                    <div
                                        className="p-col-12"
                                        style={{ paddingTop: 15 }}>
                                        {mapFiltersToSquaredCheckboxesOrOther(
                                            searchableFilterInstance.id,
                                            searchableFilter,
                                            0,
                                            searchableFilterCount
                                        )}
                                    </div>
                                )
                            }
                        </>
                    </SmartTitle>
                )

                if (hasPanel) {
                    let key = searchableFilterInstance.title.substring(0, 2)

                    if (!firstLevelAllowedKeys.has(key)) key = 'AL'

                    elementsReferenceTitleChunks[key] =
                        elementsReferenceTitleChunks[key] || {
                            count: 0,
                            chunks: [],
                            searchableFilters: [],
                        }
                    elementsReferenceTitleChunks[key].count +=
                        searchableFilterInnerCount
                    elementsReferenceTitleChunks[key].chunks.push(
                        currentElement
                    )
                    elementsReferenceTitleChunks[key].selectAllAll = (
                        elementsReferenceTitleChunks[key].selectAllAll || []
                    ).concat(onSelectAll)
                    elementsReferenceTitleChunks[key].selectAllSelections = (
                        elementsReferenceTitleChunks[key].selectAllSelections ||
                        []
                    ).concat(searchableFilterInstance.selectAllSelected)
                    elementsReferenceTitleChunks[key].hasSomeSelections =
                        elementsReferenceTitleChunks[key].hasSomeSelections ||
                        hasSomeSelections
                } else {
                    elementsReferenceTitleChunks['ALL'] =
                        elementsReferenceTitleChunks['ALL'] || {
                            count: 0,
                            chunks: [],
                            searchableFilters: [],
                        }
                    elementsReferenceTitleChunks['ALL'].count +=
                        searchableFilterInnerCount
                    elementsReferenceTitleChunks['ALL'].chunks.push(
                        currentElement
                    )
                    elementsReferenceTitleChunks['ALL'].selectAllAll = (
                        elementsReferenceTitleChunks['ALL'].selectAllAll || []
                    ).concat(onSelectAll)
                    elementsReferenceTitleChunks['ALL'].selectAllSelections = (
                        elementsReferenceTitleChunks['ALL']
                            .selectAllSelections || []
                    ).concat(searchableFilterInstance.selectAllSelected)
                    elementsReferenceTitleChunks['ALL'].hasSomeSelections =
                        hasSomeSelections
                }
            }
        )
    }

    return (
        <>
            <div className="p-grid">
                {
                    /* Progress Spinner */
                    props.loading ? (
                        <div className="p-col-12">
                            <StyledProgressSpinner />
                        </div>
                    ) : /* Search - for multiple searchableFilters in one shot */
                    !isASingleSearchableFilter && props.setSearchValue ? (
                        <div className="p-col-12">
                            <Search
                                placeholder={props.placeholderSearch}
                                searchValue={props.searchValue}
                                setSearchValue={props.setSearchValue}
                            />
                        </div>
                    ) : (
                        <></>
                    )
                }
            </div>
            {
                /*
                Title
                Checkboxes or custom render component
                View All
                ...
                Title
                Checkboxes or custom render component
                View All
                ...
                */
                props.loading ? (
                    <></>
                ) : hasPanel ? (
                    Object.entries(elementsReferenceTitleChunks)
                        .sort(([key1], [key2]) => {
                            // Order should be: LC, SG, TO, OP, LP, RB, OO, SV, DC and then AL
                            if (key1 === key2) return 0
                            if (key1 === 'LC') return -1
                            if (key2 === 'LC') return 1
                            if (key1 === 'SG') return -1
                            if (key2 === 'SG') return 1
                            if (key1 === 'TO') return -1
                            if (key2 === 'TO') return 1
                            if (key1 === 'OP') return -1
                            if (key2 === 'OP') return 1
                            if (key1 === 'LP') return -1
                            if (key2 === 'LP') return 1
                            if (key1 === 'RB') return -1
                            if (key2 === 'RB') return 1
                            if (key1 === 'OO') return -1
                            if (key2 === 'OO') return 1
                            if (key1 === 'SV') return -1
                            if (key2 === 'SV') return 1
                            if (key1 === 'DC') return -1
                            if (key2 === 'DC') return 1
                            return 0
                        })
                        .map(([key, element]) => {
                            return element.count > 0 ? (
                                <Panel
                                    key={key}
                                    className="specialCategoryTitle"
                                    header={
                                        <div
                                            style={{
                                                display: 'flex',
                                                flexDirection: 'row',
                                                justifyContent: 'space-between',
                                                alignItems: 'center',
                                                paddingRight: 25,
                                            }}>
                                            {`${
                                                key === 'LC'
                                                    ? 'LENSCRAFTERS'
                                                    : key === 'SG'
                                                    ? 'SUNGLASS HUT'
                                                    : key === 'TO'
                                                    ? 'TARGET'
                                                    : key === 'OP'
                                                    ? 'OPSM'
                                                    : key === 'LP'
                                                    ? 'LAUBMAN & PANK'
                                                    : key === 'RB'
                                                    ? 'RAY-BAN'
                                                    : key === 'OO'
                                                    ? 'OAKLEY'
                                                    : key === 'SV'
                                                    ? 'SALMOIRAGHI & VIGANO'
                                                    : key === 'DC'
                                                    ? 'DAVID CLULOW'
                                                    : 'ALL OTHERS'
                                            }`}
                                            <SquaredCheckbox
                                                style={{
                                                    marginBottom: 'auto',
                                                }}
                                                checked={elementsReferenceTitleChunks[
                                                    key
                                                ].selectAllSelections.every(
                                                    (sas) => sas
                                                )}
                                                onChange={(e: any) => {
                                                    const isChecked =
                                                        elementsReferenceTitleChunks[
                                                            key
                                                        ].selectAllSelections.every(
                                                            (sas) => sas
                                                        )

                                                    let idxChunk = 0
                                                    for (const selectAll of elementsReferenceTitleChunks[
                                                        key
                                                    ].selectAllAll) {
                                                        // Trigger the selection only if different than the current first level
                                                        // Otherwise a weird toggle effect is created
                                                        if (
                                                            elementsReferenceTitleChunks[
                                                                key
                                                            ]
                                                                .selectAllSelections[
                                                                idxChunk
                                                            ] === isChecked
                                                        )
                                                            selectAll()
                                                        idxChunk += 1
                                                    }
                                                }}
                                            />
                                        </div>
                                    }
                                    toggleable
                                    onToggle={() =>
                                        props.toggleFirstLevelSelectAllSelected?.(
                                            key
                                        )
                                    }
                                    collapsed={
                                        props.firstLevelPanelsCollapsed?.[
                                            key
                                        ] &&
                                        !elementsReferenceTitleChunks[key]
                                            .hasSomeSelections
                                    }>
                                    {element.chunks}
                                </Panel>
                            ) : (
                                <></>
                            )
                        })
                ) : (
                    Object.values(elementsReferenceTitleChunks).map(
                        (e) => e.chunks
                    )
                )
            }
        </>
    )
}

export default injectIntl(SmartSearchableFilter)
