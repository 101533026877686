import React from 'react'
import PrivateRoute from './privateRoute'
import { PublicRoute } from './publicRoute'

const AppRoute = (props: any) => {
    const { _private, component, path, redirectTo, exact } = props
    if (_private) {
        return (
            <PrivateRoute
                exact={exact}
                component={component}
                path={path}
                redirectTo={redirectTo}
            />
        )
    }

    return <PublicRoute exact={exact} component={component} path={path} />
}

export default AppRoute
