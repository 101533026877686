import { SolrFields } from '../solr-fields'
import { BUsFilters } from './business-unit-filters'
import { CitiesFilters } from './cities-filters'
import { CountriesFilters } from './countries-filters'
import { DefaultSet } from './default'
import { MacroRegionsFilters } from './macroregions-filters'
import { ZoneDescsFilters } from './zonedesc-filters'

export class RegionFilters extends DefaultSet {
    macroregionsFilters: MacroRegionsFilters
    countriesFilters: CountriesFilters
    zoneDescsFilters: ZoneDescsFilters
    citiesFilters: CitiesFilters
    busFilters: BUsFilters

    constructor(data?: any) {
        super()

        this.macroregionsFilters = new MacroRegionsFilters()
        this.countriesFilters = new CountriesFilters()
        this.zoneDescsFilters = new ZoneDescsFilters()
        this.citiesFilters = new CitiesFilters()
        this.busFilters = new BUsFilters()
    }

    static getSolrFQFilter = (regionFilters?: RegionFilters) => {
        let regionSolrFq = ''

        if (regionFilters) {
            let {
                macroregionsFilters,
                countriesFilters,
                zoneDescsFilters,
                citiesFilters,
                busFilters,
            } = regionFilters

            if (citiesFilters.selectedCities.size) {
                regionSolrFq +=
                    '&fq=' +
                    citiesFilters.getSolrFQFilter(
                        citiesFilters.selectedCities,
                        SolrFields.City
                    )
            }

            if (zoneDescsFilters.selectedZoneDescs.size) {
                regionSolrFq +=
                    '&fq=' +
                    zoneDescsFilters.getSolrFQFilter(
                        zoneDescsFilters.selectedZoneDescs,
                        SolrFields.ZoneDesc
                    )
            }

            if (countriesFilters.selectedCountries.size) {
                regionSolrFq +=
                    '&fq=' +
                    countriesFilters.getSolrFQFilter(
                        countriesFilters.selectedCountries,
                        SolrFields.CountryDesc
                    )
            }

            if (macroregionsFilters.selectedMacroRegions.size) {
                regionSolrFq +=
                    '&fq=' +
                    macroregionsFilters.getSolrFQFilter(
                        macroregionsFilters.selectedMacroRegions,
                        SolrFields.MacroRegion
                    )
            }

            if (busFilters.selectedBUs.size) {
                regionSolrFq +=
                    '&fq=' +
                    busFilters.getSolrFQFilter(
                        busFilters.selectedBUs,
                        SolrFields.BusinessUnit
                    )
            }
        }

        return regionSolrFq
    }
}
