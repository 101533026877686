import { AppState } from '../states/appState'
import { MainProjectsApi } from '../../utils/api/mainprojectsApi'
import { MainProjectsDispatcher } from '../dispatchers/mainprojects'
import { Store } from '../../models/store'
import { MainProjectPhoto } from '../../models/main-projects'
import { ExportDeckType } from '../../models/export-deck-type'
import { UserDispatcher } from '../dispatchers/user'

export const getMainProjects = (name: string) => {
    return async (dispatch: any, getState: any) => {
        dispatch(MainProjectsDispatcher.GetMainProjectsRequest())
        let state: AppState = getState()
        let stores = state.storesReducer.stores
        let selectedBannerDesc =
            state.filtersReducer.bannerFilters?.bannerDescFilters
                ?.selectedBannerDesc

        let store_id_list = stores.map((s: Store) => {
            return s.store_id_string
        })
        try {
            let res: any = await MainProjectsApi.GetMainProjects(
                name,
                store_id_list,
                Array.from(selectedBannerDesc)
            )
            if (res.data) {
                dispatch(
                    MainProjectsDispatcher.GetMainProjectsSuccess(
                        res.data.mainprojects
                    )
                )
            }
        } catch (error: any) {
            dispatch(UserDispatcher.UserError(error))

            dispatch(MainProjectsDispatcher.GetMainProjectsError(error))
        }
    }
}

export const getMainProjectDetail = (id: number) => {
    return async (dispatch: any, getState: any) => {
        dispatch(MainProjectsDispatcher.GetMainProjectDetailRequest())
        let state: AppState = getState()
        let selectedBannerDesc =
            state.filtersReducer.bannerFilters?.bannerDescFilters
                ?.selectedBannerDesc

        try {
            let res: any = await MainProjectsApi.GetMainProjectDetail(
                id,
                Array.from(selectedBannerDesc)
            )
            if (res.data) {
                dispatch(
                    MainProjectsDispatcher.GetMainProjectDetailSuccess(res.data)
                )
            }
        } catch (error: any) {
            dispatch(UserDispatcher.UserError(error))

            dispatch(MainProjectsDispatcher.GetMainProjectDetailError(error))
        }
    }
}

export const setMainProjectSelectedDeckPhotos = (
    selectedDeckPhotos: MainProjectPhoto[]
) => {
    return async (dispatch: any) => {
        dispatch(
            MainProjectsDispatcher.SetMainProjectSelectedDeckPhotosRequest()
        )
        try {
            dispatch(
                MainProjectsDispatcher.SetMainProjectSelectedDeckPhotosSuccess(
                    selectedDeckPhotos
                )
            )
        } catch (error: any) {
            dispatch(UserDispatcher.UserError(error))

            dispatch(
                MainProjectsDispatcher.SetMainProjectSelectedDeckPhotosError(
                    error
                )
            )
        }
    }
}

export const exportMainProjectDeck = (
    mainProjectID: string,
    exportDeckType: ExportDeckType,
    intl: any,
    storearea?: string,
    dates?: string[]
) => {
    return async (dispatch: any, getState: any) => {
        dispatch(MainProjectsDispatcher.ExportMainProjectDeckRequest())

        let responseTitle = ''
        let responseDescription = ''

        let state: AppState = getState()
        let selectedBannerDesc =
            state.filtersReducer.bannerFilters?.bannerDescFilters
                ?.selectedBannerDesc

        try {
            let currentState: AppState = getState()

            const res = await MainProjectsApi.GetMainProjectPhotosDeck(
                Number(mainProjectID),
                exportDeckType,
                exportDeckType === ExportDeckType.CUSTOM
                    ? currentState.mainprojectsReducer.selectedDeckPhotos
                          .filter((dp) => dp.photo_id != null)
                          .map((dp) => dp.photo_id || '0')
                    : [],
                storearea,
                dates,
                Array.from(selectedBannerDesc)
            )

            if (res.status === 200) {
                responseTitle = intl.formatMessage({
                    id: 'store_detail.download_deck.confirmed_title',
                })
                responseDescription = intl.formatMessage({
                    id: 'store_detail.download_deck.confirmed_description',
                })
            } else {
                responseTitle = intl.formatMessage({
                    id: 'generic.oops',
                })
                responseDescription = intl.formatMessage({
                    id: 'store_detail.download_deck.error',
                })
            }
        } catch (error: any) {
            dispatch(UserDispatcher.UserError(error))

            responseTitle = intl.formatMessage({
                id: 'generic.oops',
            })
            responseDescription = intl.formatMessage({
                id: 'store_detail.download_deck.error',
            })
        }

        dispatch(MainProjectsDispatcher.ExportMainProjectDeckSuccess())

        return { responseTitle, responseDescription }
    }
}
