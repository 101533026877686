import { Colors } from '../../../colors'
import styled from 'styled-components'

export const StyledFilterTitle = styled.div`
    .p-panel.specialCategoryTitle .p-panel-title {
        font-size: 16px; !important;
        color: ${Colors.grey6};
        width: 100% !important;
        margin-left: 5px;
    }

    .p-panel.specialCategoryTitle .p-panel-header {
        width: auto !important;
        height: auto !important;
    }

    .p-panel.specialCategoryTitle .p-panel-content {
        width: auto !important;
        height: auto !important;
        padding: 5px;
        border: solid 1px ${Colors.grey3};
    }

    .p-panel.specialCategoryTitle .p-field-checkbox {
        margin-bottom: auto !important;
    }

    .p-panel.specialCategoryTitle .p-panel-icons {
        box-shadow: none !important;
        outline: none !important;
    }

    .p-panel.specialTitle .p-panel-title {
        font-size: 16px; !important;
        color: ${Colors.grey6};
        width: 100% !important;
        margin-left: 0px;
    }

    .p-panel.specialTitle .p-panel-header {
        width: auto !important;
        height: auto !important;
    }

    .p-panel.specialTitle .p-panel-content {
        width: auto !important;
        height: auto !important;
        border: 0px;
    }

    .p-panel.specialTitle .p-field-checkbox {
        margin-bottom: auto !important;
    }

    .p-panel.specialTitle .p-toggleable-content {
        margin-top: 10px;
    }

    .p-panel.specialTitle .p-panel-icons {
        box-shadow: none !important;
        outline: none !important;
    }

    .p-panel-header {
        width: 496px;
        max-width: 100vw;
        height: 64px;
        padding: 17px 16px 17px 17px;
        background-image: linear-gradient(to left, #fff, #f1f2f4 0%);
    }

    .p-panel-title {
        font-family: AvenirLTPro;
        font-size: 21px;
        font-weight: 500 !important;
        color: ${Colors.silverChalice};
    }

    .p-panel-content {
        width: 496px;
        max-width: 100vw;
        height: 64px;
        margin: 0;
        padding: 0;
        border: 0;
        background-image: linear-gradient(
            to left,
            var(--white),
            var(--grey-1) 0%
        );
    }
`

