export abstract class Colors {
  public static blue = "#4072ee";
  public static yellow = "#fed100";
  public static pink = "#fa7b83";
  public static green = "#36d4a6";
  public static white = "#ffffff";
  public static grey0 = "#f6f7f8";
  public static grey1 = "#f1f2f4";
  public static grey2 = "#e8ebed";
  public static grey3 = "#ccd1d6";
  public static grey4 = "#a2abb5";
  public static grey5 = "#7b8a9a";
  public static grey6 = "#535b64";
  public static grey7 = "#464e54";
  public static grey8 = "#2d3238";
  public static grey9 = "#292e33";
  public static grey10 = "#1f2327";
  public static grey11 = "#171a1c";
  public static grey12 = "#111214";
  public static lightGrey = "#7e8a97";
  public static darkGrey = "#4e5b69";
  public static backgroundDark = "#0e0f10";
  public static silverChalice = "#acacac";
  public static coreChannelColor = "#36d4a6";
  public static outletChannelColor = "#fed100";
  public static hostedChannelColor = "#fa7b83";
}
