import { Colors } from '../../../colors'
import styled from 'styled-components'

export const StyledInfoCard = styled.div`
    .bg-core {
        background-color: ${Colors.green};
    }
    .bg-hosted {
        background-color: ${Colors.pink};
    }
    .bg-outlet {
        background-color: ${Colors.yellow};
    }
    .bg-noChannelOfTrade {
        background-color: ${Colors.grey3};
    }
    .info-card {
        position: absolute;
        bottom: 0;
        left: 0;
        transform: translate(calc(-50% + 16px), -10px);

      .p-carousel-content {
        overflow: hidden;
      }
    }
    .info-card .box {
        background-color: ${Colors.white};
        border-radius: 12px;
    }
    .arrow-down {
        position: relative;
        top: -2px;
        width: 0;
        height: 0;
        border-left: 15px solid transparent;
        border-right: 15px solid transparent;
        margin-left: auto;
        margin-right: auto;
        border-top: 20px solid ${Colors.white};
    }
    .logo img {
        max-width: 72px;
        width: 100%;
        height: 30px;
        object-fit: contain;
    }
    .store-id {
        margin-top: auto;
        font-size: 14px;
        font-weight: 500;
        color: ${Colors.grey6};
    }
    .store-name {
        font-size: 16px;
        font-weight: 700;
        letter-spacing: 0.15px;
        color: ${Colors.grey7};
    }
    .store-address {
        font-size: 12px;
        letter-spacing: 0.17px;
        color: ${Colors.grey5};
    }
    .channel-label {
        padding: 5px 9px;
        display: inline-flex;
        border-radius: 100px;
    }
`
