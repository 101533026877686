import { useEffect, useState } from 'react'
import moment from 'moment'
import { range } from 'lodash'
import { FormattedMessage } from 'react-intl'
import styles from './month-year.module.scss'
import { Colors } from '../../colors'
import 'moment/locale/en-gb'
import 'moment/locale/it'

type MonthAndYearPickerProps = {
    yearPicker?: boolean
    monthPicker?: boolean
    selected: Set<number>
    onItemSelected: Function
    style?: { [key: string]: any }
}

export const MonthAndYearPicker = (props: MonthAndYearPickerProps) => {
    const { monthPicker, yearPicker, selected, onItemSelected, style } = props

    const getInitialPageFromOldestYear = (
        years: Set<number>,
        yearsPerPage: number
    ) => {
        let initialPage = 0

        if (years.size) {
            const oldestYear = Math.min.apply(Math, Array.from(years))

            const diff = oldestYear - 2020

            // Compute the number of pages before or after 2020 to start with
            initialPage +=
                Math.floor(diff / yearsPerPage) +
                (diff < 0 && diff % yearsPerPage > 0 ? 1 : 0)
        }

        return initialPage
    }

    const months = moment.months()
    const yearsPerPage = 12

    let pageToStartWith = 0
    if (yearPicker) {
        pageToStartWith = getInitialPageFromOldestYear(selected, yearsPerPage)
    }

    const [page, setPage] = useState(pageToStartWith)
    let years = range(
        2020 + page * yearsPerPage,
        moment().year() + yearsPerPage - 1 + page * yearsPerPage
    )

    const [previousRow, setPreviousRow] = useState(-1)

    const processShiftSelection = (e: any, index: number) => {
        // If the Shift key is selected, then the user is attempting a multiple selection
        if (e.shiftKey && previousRow > -1) {
            const shouldDeleteSelections = selected.has(index)

            // If previous row is above current row in table
            if (previousRow < index) {
                // We select all cells down below
                for (let i = previousRow; i <= index; i++) {
                    // We select or deselect according to the previous action
                    if (
                        (shouldDeleteSelections && selected.has(i)) ||
                        (!shouldDeleteSelections && !selected.has(i))
                    ) {
                        onItemSelected(i)
                    }
                }
                // Or the opposite
            } else {
                // We select up above
                const lastRowToProcess = previousRow
                for (let i = index; i <= lastRowToProcess; i++) {
                    // We select or deselect according to the previous action

                    if (
                        (shouldDeleteSelections && selected.has(i)) ||
                        (!shouldDeleteSelections && !selected.has(i))
                    ) {
                        onItemSelected(i)
                    }
                }
            }
        } else {
            setPreviousRow(index)
            onItemSelected(index)
        }
    }

    // Reset the previous row when Shift Key is released
    useEffect(() => {
        const handleKeyUp = (e: any) => {
            if (e.key === 'Shift') {
                setPreviousRow(-1)
            }
        }

        window.document.addEventListener('keyup', handleKeyUp)

        return () => {
            window.document.removeEventListener('keyup', handleKeyUp)
        }
    }, [])

    return (
        <div className={styles.myp_container} style={style}>
            <div className={styles.myp_header}>
                {monthPicker ? (
                    <FormattedMessage
                        id="myp.month.title"
                        defaultMessage="Months"
                    />
                ) : yearPicker ? (
                    <>
                        <span
                            onClick={(e: any) => setPage(page - 1)}
                            className="pi pi-chevron-left"
                            style={{
                                position: 'absolute',
                                fontSize: 22,
                                color: Colors.grey4,
                                left: 31,
                            }}
                        />
                        <span>{`${years[0]} - ${
                            years[years.length - 1]
                        }`}</span>
                        <span
                            onClick={(e: any) => setPage(page + 1)}
                            className="pi pi-chevron-right"
                            style={{
                                position: 'absolute',
                                fontSize: 22,
                                color: Colors.grey4,
                                right: 31,
                            }}
                        />
                    </>
                ) : (
                    <></>
                )}
            </div>
            <div className={`p-grid ${styles.myp_row}`}>
                {monthPicker ? (
                    months.map((month: string, index: number) => {
                        return (
                            <div
                                key={'month_' + index}
                                className={`p-col-3 ${
                                    selected.has(index + 1)
                                        ? styles.myp_item_selected
                                        : styles.myp_item
                                }`}
                                onClick={(e: any) =>
                                    processShiftSelection(e, index + 1)
                                }>
                                <p className={styles.no_highlight}>{month}</p>
                            </div>
                        )
                    })
                ) : yearPicker ? (
                    years.map((year: number, index: number) => {
                        return (
                            <div
                                key={'year_' + index}
                                className={`p-col-3 ${
                                    selected.has(year)
                                        ? styles.myp_item_selected
                                        : styles.myp_item
                                }`}
                                onClick={(e: any) =>
                                    processShiftSelection(e, year)
                                }>
                                <p className={styles.no_highlight}>{year}</p>
                            </div>
                        )
                    })
                ) : (
                    <></>
                )}
            </div>
        </div>
    )
}
