import { Colors } from '../../../colors'
import styled from 'styled-components'

export const StyledNavigation = styled.div`
    .navigation-link {
        width: 100%;
        text-align: center;
        display: flex;
    }
    .navigation-link i {
        font-size: 24px;
        margin-right: 24px;
        margin-top: auto;
        margin-bottom: auto;
        color: ${Colors.grey4};
    }
    .mainproject-name {
        font-size: 24px;
        padding-left: 2rem;
        font-weight: 700;
        color: ${Colors.blue};
    }
    .mainproject-code {
        font-size: 13px;
        color: #7b8a9a;
        font-weight: 500;
    }
    .navigation-link.mobile i {
        position: absolute;
        top: 50%;
        transform: translate(0, -50%);
        font-size: 32px;
    }
    .mobile .name-container {
        margin-left: auto;
        margin-right: auto;
    }
    .main-project-code {
        font-size: 13px;
        color: ${Colors.grey5};
        font-weight: 500 !important;
    }
    @media (max-width: 767px) {
        .mainproject-name {
            font-size: 16px;
            letter-spacing: 0.27px;
            line-height: 1;
            color: #464e54;
            margin-bottom: 8px;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
        }
        .mainproject-code {
            font-size: 13px;
            letter-spacing: 0.33px;
            line-height: 1;
        }
    }
`
