import { Accordion, AccordionTab } from 'primereact/accordion'
import { ScrollPanel } from 'primereact/scrollpanel'
import { useEffect } from 'react'
import { injectIntl } from 'react-intl'
import { connect } from 'react-redux'
import FilterSearchStoresClearSection from '../components/filter/filter-searchstores-clear-section'
import FilterToggleButton from '../components/filter/filter-toggle-button'
import SmartSearchableFilter from '../components/smart-searchable-filter'
import { SearchableFilterElement } from '../models/searchable-filter'
import { getSearchResults } from '../redux/actions/stores'
import {
    clearAllFilters,
    getBannerDesc,
    getChannelOfTrade,
    getHostedRetail,
    getSegment,
    getStoreDesign,
    setSearchBannerDescValue,
    setSearchChannelOfTradeValue,
    setSearchHostedRetailValue,
    setSearchMainProjectsValue,
    setSearchSegmentValue,
    setSearchStoreDesignValue,
    setSelectedBannerDesc,
    setSelectedChannelOfTrade,
    setSelectedHostedRetail,
    setSelectedImagesQuality,
    setSelectedMainProjects,
    setSelectedSegment,
    setSelectedSidebarMenu,
    setSelectedStoreDesign,
    toggleBannerDescViewAll,
    toggleChannelOfTradeViewAll,
    toggleHostedRetailViewAll,
    toggleMainProjectsViewAll,
    toggleSegmentViewAll,
    toggleStoreDesignViewAll,
    toggleStoreDesignPanelCollapsed,
    toggleStoreDesignSelectAllSelected,
    toggleStoreDesignFirstLevelSelectAllSelected,
} from '../redux/actions/filters'
import { createLoadingSelector } from '../redux/selectors'
import { AppState } from '../redux/states/appState'
import { SidebarMenuKind } from '../models/sidebar-menu-kind'

const BannerFilter = (props: any) => {
    const { intl } = props
    const { bannerFilters } = props
    const { hostedRetailFilters } = props
    const {
        bannerDescFilters,
        channelOfTradeFilters,
        segmentFilters,
        storeDesignFilters,
        selectionBestFilters,
    } = bannerFilters

    const channelOfTradeFiltersCount =
        channelOfTradeFilters.selectedChannelOfTrade.size
    const channelOfTradeHeader =
        intl.formatMessage({ id: 'banner.channel_of_trade.title' }) +
        (channelOfTradeFiltersCount ? ` (${channelOfTradeFiltersCount})` : '')

    const hostFiltersCount = hostedRetailFilters.selectedHostedRetail.size
    const hostHeader =
        intl.formatMessage({ id: 'banner.host.title' }) +
        (hostFiltersCount ? ` (${hostFiltersCount})` : '')

    const segmentFiltersCount = segmentFilters.selectedSegment.size
    const segmentHeader =
        intl.formatMessage({ id: 'banner.segment.title' }) +
        (segmentFiltersCount ? ` (${segmentFiltersCount})` : '')

    const storeDesignFiltersCount = storeDesignFilters.searchableFilters
        ? storeDesignFilters.getSelectedStoreDesignsCount()
        : 0
    const storeDesignHeader =
        intl.formatMessage({ id: 'banner.store_design.title' }) +
        (storeDesignFiltersCount ? ` (${storeDesignFiltersCount})` : '')
    const storeDesignFirstLevelPanelsCollapsed =
        storeDesignFilters.firstLevelPanelsCollapsed

    const selectionBestFiltersCount =
        selectionBestFilters.getSelectedSelectionBestCount()
    const selectionBestHeader =
        intl.formatMessage({ id: 'banner.selection_best.title' }) +
        (selectionBestFiltersCount ? ` (${selectionBestFiltersCount})` : '')

    useEffect(() => {
        props.getBannerDesc()
        props.getChannelOfTrade()
        props.getHostedRetail()
        props.getSegment()
        props.getStoreDesign()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return (
        <ScrollPanel
            style={{
                width: '100%',
                height: 'calc(100vh - 175px)',
            }}
            className="pvscroll">
            <FilterSearchStoresClearSection
                noGutter
                hasMoreFilters
                onClearAllFilters={(e: any) => props.clearAllFilters()}
                isLoadingSearchResults={props.loadingSearchResults}
                isSearchResultsDisabled={props.loadingStores}
                onSearchResults={() => props.getSearchResults()}>
                <SmartSearchableFilter
                    render={(
                        searchableFilterItem: [string, SearchableFilterElement]
                    ) => (
                        <FilterToggleButton
                            size="small"
                            checked={searchableFilterItem[1].selected}
                            onChange={(e: any) =>
                                props.setSelectedBannerDesc(
                                    searchableFilterItem[0],
                                    !searchableFilterItem[1].selected
                                )
                            }
                            label={`${searchableFilterItem[0].toUpperCase()} (${
                                searchableFilterItem[1].count
                            })`}
                        />
                    )}
                    loading={props.loading}
                    placeholderSearch={intl.formatMessage({
                        id: 'banner_desc.search.placeholder',
                    })}
                    searchValue={bannerDescFilters.searchValue}
                    setSearchValue={props.setSearchBannerDescValue}
                    searchableFilters={[bannerDescFilters]}
                    toggleViewAllVisible={props.toggleBannerDescViewAll}
                    setFilterSelection={props.setSelectedBannerDesc}
                />
            </FilterSearchStoresClearSection>
            <Accordion
                expandIcon="pi pi-chevron-down"
                collapseIcon="pi pi-chevron-up"
                className="styleFilterAccordion">
                <AccordionTab header={channelOfTradeHeader}>
                    <SmartSearchableFilter
                        loading={props.loadingChannelOfTrade}
                        placeholderSearch={intl.formatMessage({
                            id: 'channel_of_trade.search.placeholder',
                        })}
                        searchValue={channelOfTradeFilters.searchValue}
                        setSearchValue={props.setSearchChannelOfTradeValue}
                        searchableFilters={[channelOfTradeFilters]}
                        toggleViewAllVisible={props.toggleChannelOfTradeViewAll}
                        setFilterSelection={props.setSelectedChannelOfTrade}
                    />
                </AccordionTab>
                <AccordionTab header={hostHeader}>
                    <SmartSearchableFilter
                        loading={props.loadingHost}
                        placeholderSearch={intl.formatMessage({
                            id: 'hosted_retail.search.placeholder',
                        })}
                        searchValue={hostedRetailFilters.searchValue}
                        setSearchValue={props.setSearchHostedRetailValue}
                        searchableFilters={[hostedRetailFilters]}
                        toggleViewAllVisible={props.toggleHostedRetailViewAll}
                        setFilterSelection={props.setSelectedHostedRetail}
                    />
                </AccordionTab>
                <AccordionTab header={segmentHeader}>
                    <SmartSearchableFilter
                        loading={props.loadingSegment}
                        placeholderSearch={intl.formatMessage({
                            id: 'segment.search.placeholder',
                        })}
                        searchValue={segmentFilters.searchValue}
                        setSearchValue={props.setSearchSegmentValue}
                        searchableFilters={[segmentFilters]}
                        toggleViewAllVisible={props.toggleSegmentViewAll}
                        setFilterSelection={props.setSelectedSegment}
                    />
                </AccordionTab>
                <AccordionTab header={storeDesignHeader}>
                    <SmartSearchableFilter
                        loading={props.loadingStoreDesignSelector}
                        placeholderSearch={intl.formatMessage({
                            id: 'store_design.search.placeholder',
                        })}
                        searchValue={storeDesignFilters.searchValue}
                        setSearchValue={props.setSearchStoreDesignValue}
                        searchableFilters={storeDesignFilters.searchableFilters}
                        toggleViewAllVisible={props.toggleStoreDesignViewAll}
                        togglePanelCollapsed={
                            props.toggleStoreDesignPanelCollapsed
                        }
                        toggleSelectAllSelected={
                            props.toggleStoreDesignSelectAllSelected
                        }
                        toggleFirstLevelSelectAllSelected={
                            props.toggleStoreDesignFirstLevelSelectAllSelected
                        }
                        firstLevelPanelsCollapsed={
                            storeDesignFirstLevelPanelsCollapsed
                        }
                        setFilterSelection={props.setSelectedStoreDesign}
                        hasIntlSplittedTitle
                    />
                </AccordionTab>
                <AccordionTab header={selectionBestHeader}>
                    <SmartSearchableFilter
                        searchableFilters={[
                            selectionBestFilters.imagesQualityFilters,
                        ]}
                        setFilterSelection={props.setSelectedImagesQuality}
                        hideCount
                    />

                    <SmartSearchableFilter
                        placeholderSearch={intl.formatMessage({
                            id: 'main_projects.search.placeholder',
                        })}
                        searchValue={
                            selectionBestFilters.mainProjectsFilters
                                ?.searchValue
                        }
                        setSearchValue={props.setSearchMainProjectsValue}
                        searchableFilters={[
                            selectionBestFilters.mainProjectsFilters,
                        ]}
                        toggleViewAllVisible={props.toggleMainProjectsViewAll}
                        setFilterSelection={props.setSelectedMainProjects}
                        hideCount
                    />
                </AccordionTab>
            </Accordion>
        </ScrollPanel>
    )
}

const loadingSelector = createLoadingSelector(['FILTERS_GET_BANNER_DESC'])
const loadingStores = createLoadingSelector(['STORES'])
const loadingSearchResults = createLoadingSelector(['SEARCH_RESULTS'])
const loadingChannelOfTradeSelector = createLoadingSelector([
    'FILTERS_GET_CHANNEL_OF_TRADE',
])
const loadingHostSelector = createLoadingSelector(['FILTERS_GET_HOSTED_RETAIL'])
const loadingSegmentSelector = createLoadingSelector(['FILTERS_GET_SEGMENT'])
const loadingStoreDesignSelector = createLoadingSelector([
    'FILTERS_GET_STORE_DESIGN',
])

const mapStateToProps = (state: AppState) => ({
    loading: loadingSelector(state),
    loadingStores: loadingStores(state),
    loadingSearchResults: loadingSearchResults(state),
    loadingChannelOfTrade: loadingChannelOfTradeSelector(state),
    loadingHost: loadingHostSelector(state),
    loadingSegment: loadingSegmentSelector(state),
    loadingStoreDesign: loadingStoreDesignSelector(state),
    bannerFilters: state.filtersReducer.bannerFilters,
    hostedRetailFilters: state.filtersReducer.hostedRetailFilters,
})

const mapDispatchToProps = (dispatch: any) => ({
    clearAllFilters: () => dispatch(clearAllFilters()),
    getBannerDesc: () => dispatch(getBannerDesc()),
    toggleBannerDescViewAll: () => dispatch(toggleBannerDescViewAll()),
    setSelectedBannerDesc: (bannerDesc: string, selection: boolean) =>
        dispatch(setSelectedBannerDesc(bannerDesc, selection)),
    setSearchBannerDescValue: (searchValue: string) =>
        dispatch(setSearchBannerDescValue(searchValue)),
    getChannelOfTrade: () => dispatch(getChannelOfTrade()),
    toggleChannelOfTradeViewAll: () => dispatch(toggleChannelOfTradeViewAll()),
    setSelectedChannelOfTrade: (channelOfTrade: string, selection: boolean) =>
        dispatch(setSelectedChannelOfTrade(channelOfTrade, selection)),
    setSearchChannelOfTradeValue: (searchValue: string) =>
        dispatch(setSearchChannelOfTradeValue(searchValue)),
    getHostedRetail: () => dispatch(getHostedRetail()),
    toggleHostedRetailViewAll: () => dispatch(toggleHostedRetailViewAll()),
    setSelectedHostedRetail: (hostedRetail: string, selection: boolean) =>
        dispatch(setSelectedHostedRetail(hostedRetail, selection)),
    setSearchHostedRetailValue: (searchValue: string) =>
        dispatch(setSearchHostedRetailValue(searchValue)),
    getSegment: () => dispatch(getSegment()),
    toggleSegmentViewAll: () => dispatch(toggleSegmentViewAll()),
    setSelectedSegment: (segment: string, selection: boolean) =>
        dispatch(setSelectedSegment(segment, selection)),
    setSearchSegmentValue: (searchValue: string) =>
        dispatch(setSearchSegmentValue(searchValue)),
    getStoreDesign: () => dispatch(getStoreDesign()),
    toggleStoreDesignViewAll: (storeDesignGroup: string) =>
        dispatch(toggleStoreDesignViewAll(storeDesignGroup)),
    toggleStoreDesignPanelCollapsed: (storeDesignGroup: string) =>
        dispatch(toggleStoreDesignPanelCollapsed(storeDesignGroup)),
    toggleStoreDesignSelectAllSelected: (storeDesignGroup: string) =>
        dispatch(toggleStoreDesignSelectAllSelected(storeDesignGroup)),
    toggleStoreDesignFirstLevelSelectAllSelected: (storeDesignGroup: string) =>
        dispatch(
            toggleStoreDesignFirstLevelSelectAllSelected(storeDesignGroup)
        ),
    setSelectedStoreDesign: (
        storeDesignGroup: string,
        storeDesign: string,
        selection: boolean
    ) =>
        dispatch(
            setSelectedStoreDesign(storeDesignGroup, storeDesign, selection)
        ),
    setSearchStoreDesignValue: (searchValue: string) =>
        dispatch(setSearchStoreDesignValue(searchValue)),
    setSelectedImagesQuality: (iq: string, selection: boolean) =>
        dispatch(setSelectedImagesQuality(iq, selection)),
    toggleMainProjectsViewAll: () => dispatch(toggleMainProjectsViewAll()),
    setSelectedMainProjects: (mp: string, selection: boolean) =>
        dispatch(setSelectedMainProjects(mp, selection)),
    setSearchMainProjectsValue: (searchValue: string) =>
        dispatch(setSearchMainProjectsValue(searchValue)),
    getSearchResults: () =>
        dispatch(getSearchResults()).then((e: any) =>
            dispatch(setSelectedSidebarMenu(SidebarMenuKind.SEARCH_RESULTS))
        ),
})

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(injectIntl(BannerFilter))

