import React from 'react'
import { connect } from 'react-redux'
import { Redirect, Route } from 'react-router-dom'

export const PublicRoute = (props: any) => {
    const RouteComponent = props.component
    const RouteProps = RouteComponent.props
    return (
        <Route
            {...props.rest}
            path={props.path}
            exact={props.exact}
            component={(childProps: any) =>
                props.isUserAuthenticated ? (
                    <Redirect to={props.redirectTo} />
                ) : (
                    <RouteComponent {...RouteProps} path={props.path} />
                )
            }
        />
    )
}

const mapStateToProps = (state: any) => ({
    isUserAuthenticated: state.userReducer.isUserAuthenticated,
})

export default connect(mapStateToProps)(PublicRoute)
