import React from 'react'
import { connect } from 'react-redux'
import { Redirect, Route } from 'react-router-dom'
import { AppState } from '../redux/states/appState'

const PrivateRoute = (props: any) => {
    const RouteComponent = props.component
    const RouteProps = RouteComponent.props

    return (
        <Route
            path={props.path}
            exact={props.exact}
            component={(childProps: any) =>
                props.isUserAuthenticated ? (
                    <RouteComponent {...RouteProps} path={props.path} />
                ) : (
                    <Redirect to={props?.redirectTo} />
                    // <div>AM I AUTENTHICATED ? {props.state}</div>
                )
            }
        />
    )
}

const mapStateToProps = (state: AppState) => ({
    isUserAuthenticated: state.userReducer.isUserAuthenticated,
})

export default connect(mapStateToProps)(PrivateRoute)
