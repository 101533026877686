import styled from 'styled-components'
import { Colors } from '../../colors'
import { ReactComponent as SearchIcon } from './../../utils/icons/elements-icon-search.svg'

export const StyledSearchIcon = styled(SearchIcon)`
    position: absolute;
    height: 21px;
`

export const SearchContainer = styled.div`
    display: inline-flex;
    border-bottom: 1px solid rgba(0, 0, 0, 0.14);
    input {
        border: none;
        outline: none;
        color: ${Colors.grey4};
        box-shadow: none;
        padding-right: 40px;
        font-family: AvenirLTPro;
        font-size: 16px;
        font-weight: 500;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: 0.27px;
        margin-left: 28px;
        margin-top: 0;
    }
    width: 100%;
    height: 44px;
    padding: 9px 16px;
    border-radius: 30px;
    border: solid 2px ${Colors.grey2};
    background-color: ${Colors.white};
    margin-bottom: 16px;

    .closeSearchbar {
        cursor: pointer;
        width: auto;
        height: 30px;
        position: relative;
        top: -4px;
    }
`

export const SearchInput = styled.input`
    width: 100%;
    margin: 4px 0px 0px 8px;
    font-family: AvenirLTPro;
    font-size: 14px;
    font-weight: 500;
    letter-spacing: 0.27px;
    color: ${Colors.grey4};
`

export const SearchResults = styled.div`
    height: auto;
    padding: 0px;
    .searchingFor {
        font-family: AvenirLTPro;
        font-size: 14px;
        font-weight: 500;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: 0.27px;
        color: #a2abb5;
        margin-bottom: 27px;
    }

    .p-col-12 {
        cursor: pointer;
        transition: 0.3s;
        padding: 12px 28px;
    }

    .p-col-12:hover {
        background-color: #f1f2f4;
        transition: 0.3s;
    }
`

export const SearchingForContainer = styled.div`
    position: fixed;
    background: ${Colors.white};
    padding: 16px 0px;
    border-radius: 8px;
    margin-top: 3px !important;
    margin-left: -16px !important;
    width: 362px !important;
    max-height: 400px;
    overflow-y: scroll;
    padding-left: 16px;
    padding-right: 16px;
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
    box-shadow: 0 2px 4px -1px rgb(204 209 214 / 20%),
        0 6px 10px 0 rgb(204 209 214 / 20%), 0 4px 5px 0 rgb(162 171 181 / 20%);

    @media (max-width: 991px) {
        width: 100% !important;
    }
    @media (min-width: 1200px) and (max-width: 1399px) {
        width: 367px !important;
    }
    @media (min-width: 1200px) and (max-width: 1300px) {
        width: 267px !important;
    }
`

export const SearchingForTitle = styled.div`
    font-family: AvenirLTPro;
    font-size: 14px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 0.27px;
    color: ${Colors.grey4};
    padding-left: 8px;
    margin-bottom: 8px;
    padding-left: 28px;
`
