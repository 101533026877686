import React, { useState, useRef, useEffect } from 'react'
import GoogleMapReact from 'google-map-react'
import useSupercluster from 'use-supercluster'
import ClusterMarker from './cluster-marker'
import InfoCard from './info-card/InfoCard'
import SingleMarker from './single-marker'
import MapTopLegend from './map-top-legend'
import MapSideLegend from './map-side-legend'
import { Store } from '../../models/store'
import mapStylesObject from './map-styles-object.json'
import MapLogo from './map-logo'
import { useWindowResize } from '../../utils/commonFunctions'
import { Photo } from '../../models/photo-list'

function buildPoints(stores: Store[]) {
    const points: object[] = []
    stores.forEach((store: Store) => {
        if (store && store.store_coordinate && store.channel_of_trade_string) {
            let [lat, lng] = store.store_coordinate.split(',')

            points.push({
                type: 'Feature',
                id: store.id,
                properties: {
                    ...store,
                },
                geometry: {
                    type: 'Point',
                    coordinates: [Number(lng), Number(lat)],
                },
            })
        }
    })
    return points
}

function updateLocalStorageMapData(center: any, zoom: any) {
    localStorage.setItem('MAP_center', center)
    localStorage.setItem('MAP_zoom', zoom)
}

function getLocalStorageMapData(item: string) {
    if (localStorage.getItem(item)) {
        return localStorage.getItem(item)
    }
    return false
}

const Map = ({
    isFilterBarOpen,
    stores,
    thumbnails,
    getThumbnails,
    dateFilters,
    loading
}: {
    isFilterBarOpen: boolean
    stores: Store[]
    thumbnails: Photo[]
    getThumbnails: any
    dateFilters: any[]
    loading?: boolean
}) => {
    const mapRef = useRef()
    const isMobileNow = useWindowResize()
    let basicLat = '37.09024'
    let basicLng = '-95.712891'
    let getLocalStorageMapCenter = getLocalStorageMapData('MAP_center')
    if (getLocalStorageMapCenter) {
        ;[basicLat, basicLng] = getLocalStorageMapCenter.split(',')
    }
    const [center, setCenter] = useState({
        lat: Number(basicLat),
        lng: Number(basicLng),
    })

    let basicZoom = isMobileNow ? 1 : 4
    let getLocalStorageMapZoom = Number(getLocalStorageMapData('MAP_zoom'))
    if (getLocalStorageMapZoom) {
        basicZoom = getLocalStorageMapZoom
    }

    const [zoom, setZoom] = useState(basicZoom)
    const [bounds, setBounds] = useState<number[] | null>(null)
    const [fitBounds, setFitBounds] = useState(false)
    const [selectedStore, setSelectedStore] = useState<Store | null>(null)

    const points = buildPoints(stores)

    useEffect(() => {
        let coordinates: any = []
        let latSum: number = 0
        let lngSum: number = 0
        let storesLenght: number = 0
        stores.forEach((store: Store) => {
            if (store && store.store_coordinate) {
                let [lat, lng] = store.store_coordinate.split(',')
                latSum += Number(lat)
                lngSum += Number(lng)
                storesLenght++
            }
        })
        coordinates.push(latSum / storesLenght, lngSum / storesLenght)
        let newLat = coordinates[0]
        let newLng = coordinates[1]
        if (newLat && newLng && fitBounds) {
            setCenter({ lat: newLat, lng: newLng })
            if (zoom > 4) {
                setZoom(1)
            }
            updateLocalStorageMapData([center.lat, center.lng], zoom)
        }
        setTimeout(function () {
            setFitBounds(true)
        }, 1)
        setSelectedStore(null)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [stores])

    useEffect(() => {
        function deleteLocalStorageMapData() {
            localStorage.removeItem('MAP_center')
            localStorage.removeItem('MAP_zoom')
        }
        window.addEventListener('beforeunload', deleteLocalStorageMapData)
    })

    const { clusters, supercluster } = useSupercluster({
        points,
        bounds,
        zoom,
        options: { radius: 300, maxZoom: 10 },
    })

    const handleClusterClick = ({
        lat,
        lng,
        expansionZoom,
    }: {
        lat: number
        lng: number
        expansionZoom: number
    }) => {
        let newZoom = expansionZoom
        if (expansionZoom - zoom > 2) {
            if (zoom < 6 && expansionZoom > 6) {
                setZoom(6)
                newZoom = 6
            } else {
                setZoom(expansionZoom)
            }
        } else {
            setZoom(expansionZoom)
        }
        setCenter({ lat, lng })
        updateLocalStorageMapData([lat, lng], newZoom)
    }

    const handleSingleMarkerClick = ({ store }: { store: Store }) => {
        setSelectedStore(selectedStore?.id === store.id ? null : store)
    }

    const googleMapsApiKey: string =
        process.env['REACT_APP_GOOGLE_MAPS_API_KEY'] || ''

    return (
        <div
            style={{
                height: window.innerHeight,
                width: '100vw',
                paddingTop: 110,
                position: 'relative',
            }}>
            <GoogleMapReact
                bootstrapURLKeys={{ key: googleMapsApiKey }}
                zoom={zoom}
                center={center}
                yesIWantToUseGoogleMapApiInternals={true}
                onChange={({ zoom, bounds }) => {
                    setZoom(zoom)
                    setBounds([
                        bounds.nw.lng,
                        bounds.se.lat,
                        bounds.se.lng,
                        bounds.nw.lat,
                    ])
                    updateLocalStorageMapData([center.lat, center.lng], zoom)
                }}
                options={(map) => {
                    return {
                        styles: mapStylesObject,
                        fullscreenControl: false,
                        zoomControlOptions: {
                            position: isMobileNow
                                ? map.ControlPosition.RIGHT_CENTER
                                : map.ControlPosition.RIGHT_TOP,
                            style: map.ZoomControlStyle.SMALL,
                        },
                        keyboardShortcuts: false,
                    }
                }}
                onDragEnd={(e) => {
                    setCenter({ lat: e.center.lat(), lng: e.center.lng() })
                    updateLocalStorageMapData(
                        [e.center.lat(), e.center.lng()],
                        zoom
                    )
                }}
                onGoogleApiLoaded={({ map }) => {
                    mapRef.current = map
                }}>
                {clusters.map((cluster) => {
                    const [longitude, latitude] = cluster.geometry.coordinates
                    const { cluster: isCluster, point_count: pointCount } =
                        cluster.properties
                    if (isCluster) {
                        return (
                            <ClusterMarker
                                onClick={() => {
                                    handleClusterClick({
                                        lat: latitude,
                                        lng: longitude,
                                        expansionZoom:
                                            supercluster.getClusterExpansionZoom(
                                                cluster.id
                                            ),
                                    })
                                }}
                                leaves={supercluster.getLeaves(
                                    cluster.id,
                                    Infinity
                                )}
                                key={`cluster-${cluster.id}`}
                                lat={latitude}
                                lng={longitude}
                                pointCount={pointCount}
                                isCluster
                            />
                        )
                    }
                    return (
                        <SingleMarker
                            onClick={() =>
                                handleSingleMarkerClick({
                                    store: cluster.properties,
                                })
                            }
                            key={cluster.properties.store_id_string}
                            lat={latitude}
                            lng={longitude}
                            type={cluster.properties.channel_of_trade_string}
                        />
                    )
                })}
                {selectedStore && (
                    <InfoCard
                        store={selectedStore}
                        photoList={thumbnails}
                        lat={Number(
                            selectedStore.store_coordinate.split(',')[0]
                        )}
                        lng={Number(
                            selectedStore.store_coordinate.split(',')[1]
                        )}
                        getThumbnails={getThumbnails}
                        dateFilters={dateFilters}
                        loading={loading}
                        setSelectedStore={setSelectedStore}
                    />
                )}
            </GoogleMapReact>
            <div
                className={`left-0 inline-flex items-center justify-center pt-4`}
                style={{
                    top: !isMobileNow ? 110 : 'auto',
                    bottom: isMobileNow ? 0 : 'auto',
                    marginBottom: isMobileNow ? 40 : 0,
                    left: isMobileNow ? 20 : '50%',
                    transform: isMobileNow ? '' : 'translate(-50%, 0)',
                    position: 'absolute',
                }}>
                <MapTopLegend />
            </div>
            <div
                className="bottom-0 right-0 flex flex-col items-end mb-4 mr-2"
                style={{ position: 'absolute' }}>
                <MapSideLegend />
                {!isMobileNow && <MapLogo />}
            </div>
        </div>
    )
}

export default Map
