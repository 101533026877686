import styled from 'styled-components'

export const StyledModal = styled.div`
    .p-dialog .p-dialog-header {
        padding: 0;
    }
    .p-dialog-content::-webkit-scrollbar {
        display: none;
    }
    .p-dialog .p-dialog-header .p-dialog-title {
        margin-left: 2rem;
    }
    .p-dialog-content {
        overflow-y: hidden;
        -ms-overflow-style: none; /* IE and Edge */
        scrollbar-width: none; /* Firefox */
    }

    .p-dialog .p-dialog-header .p-dialog-header-icon span {
        font-size: 22px;
    }

    .p-dialog .p-dialog-header .p-dialog-header-icon:focus {
        box-shadow: inherit;
    }

    @media (max-width: 991px) {
        .p-dialog .p-dialog-header {
            padding: 16px 16px 8px 16px;
        }
        .p-dialog .p-dialog-header .p-dialog-title {
            margin-left: auto;
            margin-right: auto;
        }
        .p-dialog .p-dialog-header-icons {
            position: absolute;
            right: 16px;
        }
        .p-dialog .p-dialog-header .p-dialog-header-icon {
            width: 16px;
            height: 16px;
        }
        .p-dialog .p-dialog-header .p-dialog-header-icon span {
            font-size: 16px;
        }
        .p-dialog .p-dialog-content {
            padding: 0;
        }
    }
`
