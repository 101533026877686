import React, { useState } from 'react'
import { injectIntl } from 'react-intl'
import { Carousel } from 'primereact/carousel'
import { StyledCarousel } from './StyledCarousel'
import { Photo } from '../../models/photo-list'
import 'primeicons/primeicons.css'
import { useWindowResize } from '../../utils/commonFunctions'
import IconButton from '../icon-button/IconButton'
import { PhotosApi } from '../../utils/api/photosApi'
import PrismaZoom from 'react-prismazoom'
import './style.css'
// import IconButton from '../icon-button/IconButton'
import Slider from 'react-slick'
import './slick/slick.min.css'
import './slick/slick-theme.min.css'

const GalleryCarousel = (props: any) => {
    const {
        intl,
        isApp,
        photoList,
        footer,
        page,
        tabControl,
        isFullScreen,
        close,
        banner,
        storeName
    } = props

    const isMobileNow = useWindowResize()

    const shouldFullScreen = isFullScreen || isMobileNow

    const [, setZoom] = useState(1)

    const onZoomChange = (newZoom: number) => {
        setZoom(newZoom)
    }



    const photoTemplate = (photo: Photo) => {
        return (
            <div style={{ position: "relative" }}>
                <div className="carousel-item" style={{ position: "absolute", backgroundColor: "rgba(0, 0, 0, 0.6)", zIndex: "10" }}>
                    <div className="p-grid p-d-flex p-jc-flex-start p-pt-2 p-justify-between">
                        <div style={{ display: 'flex', justifyContent: 'space-between', minWidth: '31em', flexDirection: 'column', paddingTop: 5 }}>
                            <span className={"p-px-3"} style={{ fontWeight: '1000', color: 'white', fontSize: "18px" }}>
                                {storeName}
                            </span>
                            <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', minWidth: '31em' }}>

                                <span
                                    className={`p-px-3 p-text-bold p-pt-1 p-text-${isMobileNow ? 'center' : 'left'
                                        }`}
                                    style={{ minHeight: '32px', marginTop: '8px', fontSize: "14px", color: 'rgb(216, 216, 216)' }}>
                                    {!isFullScreen || isMobileNow
                                        ? '#' + photo.store_id?.substring(10, 15)
                                        : ''}
                                </span>
                                <span className={"p-px-3"} style={{ fontWeight: '700', fontSize: "14px", color: 'rgb(216, 216, 216)' }}>
                                    {banner}
                                </span>
                                <span className=" p-px-3 p-d-lg-none mobile-macroarea">
                                    {!isFullScreen || isMobileNow
                                        ? photo.macroarea
                                        : ''}
                                </span>
                                <span className={"p-px-3"} style={{ fontWeight: '700', fontSize: "14px", color: 'rgb(216, 216, 216)' }}>
                                    {photo?.date?.toString().substring(0, 16)}
                                </span>
                                {photo.index !== undefined && (
                                    <span className="p-text-center p-text-bold p-px-3" style={{
                                        backgroundColor: 'rgb(136, 139, 143)', fontSize: "14px", color: 'rgb(216, 216, 216)',
                                        padding: '0.5em',
                                        borderRadius: '10px'
                                    }}>
                                        {!isFullScreen || isMobileNow
                                            ? `${photo.index + 1}/${photoList.length}`
                                            : ''}
                                    </span>
                                )}
                            </div>

                        </div>
                        <span className="p-text-right" style={{
                            padding: '5px',
                            marginRight: '30px',
                        }}>
                            {!isMobileNow &&
                                !shouldFullScreen &&
                                (isApp ? (
                                    <div style={{ display: 'flex', justifyContent: 'space-between', gap: '10px', alignItems: 'center' }}>
                                        <div style={{ backgroundColor: 'rgb(224, 236, 239)', borderRadius: '5px', color: 'rgb(216, 216, 216)' }}>
                                            <IconButton
                                                icon="download"
                                                label="Download"
                                                tooltipIntl="store_detail.download_photo"
                                                onClick={() =>
                                                    (
                                                        window as any
                                                    ).webkit.messageHandlers.singleDownload.postMessage(
                                                        photo.url
                                                    )
                                                }
                                            />
                                        </div>
                                        <div style={{ backgroundColor: 'rgb(92, 146, 229)', borderRadius: '5px' }}>

                                            <IconButton
                                                icon="times"
                                                label="Back"
                                                tooltipIntl="Back"
                                                onClick={() =>
                                                    (
                                                        window as any
                                                    ).webkit.messageHandlers.singleDownload.postMessage(
                                                        photo.url
                                                    )
                                                }
                                                style={{ color: 'white' }}

                                            />
                                        </div>

                                    </div>
                                ) : (
                                    <div style={{ display: 'flex', justifyContent: 'space-between', gap: '10px', alignItems: 'center' }}>
                                        <div style={{ backgroundColor: 'rgb(224, 236, 239)', borderRadius: '5px', color: 'rgb(216, 216, 216)' }}>

                                            <IconButton
                                                icon="download"
                                                label="Download"
                                                tooltipIntl="store_detail.download_photo"
                                                onClick={() =>
                                                    PhotosApi.downloadSinglePhoto(
                                                        photo,
                                                        intl
                                                    )
                                                }
                                            />
                                        </div>
                                        <div style={{ backgroundColor: 'rgb(92, 146, 229)', borderRadius: '5px', }}>

                                            <IconButton
                                                icon="times"
                                                label="Back"
                                                tooltipIntl="Back"
                                                onClick={() =>
                                                    close()
                                                }
                                                style={{ color: 'white' }}
                                            />
                                        </div>

                                    </div>
                                ))}
                        </span>

                    </div>
                </div>

                <PrismaZoom onZoomChange={(e: any) => onZoomChange(e)}>
                    <img
                        className={
                            'fullScreenHeight carousel-img gallery'
                        }
                        style={{
                            // width:  '100vw',
                            // maxHeight: '100%',
                            // height: '85vh',
                            objectFit: 'contain',
                            // transformOrigin: "0 0",
                            // transform: `translate(${pos.x}px, ${pos.y}px) scale(${pos.scale})`
                        }}
                        src={photo.url}
                        alt="carousel-full"
                    />
                </PrismaZoom>
                {/* <img
                        className={
                            'fullScreenHeight carousel-img gallery'
                        }
                        style={{
                            // width:  '100vw',
                            // maxHeight: '100%',
                            // height: '85vh',
                            objectFit: 'contain',
                            transformOrigin: "0 0",
                            transform: `translate(${pos.x}px, ${pos.y}px) scale(${pos.scale})`
                        }}
                        src={photo.url}
                        alt="carousel-full"
                    /> */}
            </div>
        )
    }


    const settings = {
        arrows: true,
        dots: false,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        swipe: false,
        touchMove: false,
        initialSlide: page,
    }


    // isMobileNow && photoList.length === 0 ? (
    return isMobileNow ? (
        <div id="slick-slider">
            <Slider {...settings}>
                {photoList.map((store: any, i: number) => {
                    return (
                        <div key={i} style={{ position: 'relative' }}>
                            <PrismaZoom className="prismaZoom">
                                <img src={store.url} alt={store.label || ''} />
                            </PrismaZoom>
                            <div className="carousel-item">
                                <div className="p-grid p-d-flex p-jc-center p-pt-2">
                                    <span className="p-px-3 p-col-12 p-lg-3 p-text-bold p-pt-1 p-text-center">
                                        {store.date_execution}
                                    </span>
                                    <span className="p-d-lg-none mobile-macroarea">
                                        {store.macroarea}
                                    </span>
                                    <span className="p-col-12 p-lg-2 p-text-center p-text-bold">
                                        {i + 1} / {photoList.length}
                                    </span>
                                </div>
                            </div>
                        </div>
                    )
                })}
            </Slider>
        </div>
    ) : (
        <StyledCarousel
            className="store-carousel"
            isFullScreen={shouldFullScreen}
            isMobileNow={isMobileNow}>
            <Carousel
                // NB se si attiva il circular gli indici sballano
                // circular
                id="galleryCarousel"
                value={photoList}
                itemTemplate={photoTemplate}
                numVisible={1}
                numScroll={1}
                footer={!shouldFullScreen ? footer : null}
                page={page}
                indicatorsContentClassName={isMobileNow ? 'p-d-none' : ''}
                onPageChange={(e) => {
                    tabControl(e.page)
                }}
            />
        </StyledCarousel>
    )
}

export default injectIntl(GalleryCarousel)
