import { SolrFields } from '../solr-fields'
import { SearchableFilter } from './searchable-filter'

export type HRElement = {
    count: number
    selected: boolean
}

export type HostedRetailDict = Record<string, HRElement>

export class HostedRetailFilters extends SearchableFilter {
    selectedHostedRetail: Set<string>
    id: string
    title: string
    fuseEngine: any
    viewAllVisible: boolean
    searchableFilter: HostedRetailDict
    searchedFilter: HostedRetailDict
    searchValue: string
    isPanelCollapsed: boolean
    selectAllSelected: boolean

    constructor(data?: any) {
        super()

        this.selectedHostedRetail = new Set()
        this.id = 'hosted_retail'
        this.title = ''
        this.fuseEngine = null
        this.viewAllVisible = true
        this.searchableFilter = data
        this.searchedFilter = data
        this.searchValue = ''
        this.isPanelCollapsed = true
        this.selectAllSelected = false
    }

    static getSolrFQFilter = (hostedRetailFilters?: HostedRetailFilters) => {
        let hostedRetailSolrFq = ''

        if (hostedRetailFilters) {
            if (hostedRetailFilters.selectedHostedRetail.size) {
                hostedRetailSolrFq +=
                    '&fq=' +
                    hostedRetailFilters.getSolrFQFilter(
                        hostedRetailFilters.selectedHostedRetail,
                        SolrFields.HostedRetail
                    )
            }
        }

        return hostedRetailSolrFq
    }
}
